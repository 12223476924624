import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const MsisdnAutoLogin = () => {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(0);
    const Auth = useContext(AuthContext);
    let [searchParams, setSearchParams] = useSearchParams();
    useEffect(()=> {
        setLoading(1);        
        const msisdn = searchParams.get("msisdn")
        const token = searchParams.get("token")
        Auth.authorizedRequest({'Content-type':'application/x-www-form-urlencoded'}).post('autoLogin',{msisdn,token}).then(res => {

            setLoading(2); 
            if(res.data.status == 1){
                Auth.successLoginProcess(res,'courses');
            }
            else{
                navigate('/404');
            }
        }).catch(err => {
            navigate('/404');
            setLoading(2); 
        });

    },[])


    return (
        <>
        <div style={{height:'200px'}}>
        {  isLoading ==1 &&             
                <div className="loader"></div>            
        }
        </div>
        </>              
    )
}

export default MsisdnAutoLogin;