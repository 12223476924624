import { useField } from "formik";
import { useContext } from "react";
import { UserContext as language } from '../../context/languageContext';
const CustomTextarea = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    const Lang = useContext(language);
    return (
      <>
      {label && <label>{label}</label>}        
        <textarea
          {...field}
          {...props}
          className={meta.touched && meta.error ? props.className+" input-error" : props.className}
        >
        </textarea>
        {meta.touched && meta.error && <div className="error">{Lang[meta.error]}</div>}
      </>
    );
  };
  export default CustomTextarea;