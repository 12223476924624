import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const MenaRoute = () => {

    const navigate = useNavigate();
    useEffect(()=>{
        cookies.set('isMena', 1, { path: '/' , maxAge: (3600*24*365)});
        navigate('/');
        
    },[])
    return (
        <div></div>
    )
}

export default MenaRoute;