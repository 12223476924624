import {Analytics} from '../../core/Analytics';
import {Event} from '../../enums/Event';
import {MIMETypes} from '../../enums/MIMETypes';
import {Player} from '../../enums/Player';
import {Feature} from '../../features/Feature';
import {FeatureConfig} from '../../features/FeatureConfig';
import {ErrorDetailBackend} from '../../features/errordetails/ErrorDetailBackend';
import {ErrorDetailTracking} from '../../features/errordetails/ErrorDetailTracking';
import {AnalyticsStateMachineOptions} from '../../types/AnalyticsStateMachineOptions';
import {FeatureConfigContainer} from '../../types/FeatureConfigContainer';
import {QualityLevelInfo} from '../../types/QualityLevelInfo';
import {logger} from '../../utils/Logger';

import {HTML5InternalAdapter} from './HTML5InternalAdapter';
import {InternalAdapterAPI} from './InternalAdapterAPI';

export class DashjsInternalAdapter extends HTML5InternalAdapter implements InternalAdapterAPI {
  constructor(
    private mediaPlayer: any,
    opts?: AnalyticsStateMachineOptions,
  ) {
    super(undefined, opts);
  }

  override initialize(analytics: Analytics): Array<Feature<FeatureConfigContainer, FeatureConfig>> {
    super.initialize(analytics);
    let videoEl: HTMLVideoElement | null = null;
    try {
      videoEl = this.mediaPlayer.getVideoElement();
    } catch (e) {
      // empty
    }

    if (!videoEl) {
      this.mediaPlayer.on(
        'manifestLoaded',
        () => {
          try {
            videoEl = this.mediaPlayer.getVideoElement();
            this.setMediaElement(videoEl);
            this.registerMediaElementEventsForDashJS();
          } catch (e) {
            logger.errorMessageToUser(
              'Something went wrong while getting underlying HTMLVideoElement. Not possible to attach adapter and initialize Bitmovin Analytics. Error: ',
              e,
            );
            this.release();
          }
        },
        this,
      );
    } else {
      this.setMediaElement(videoEl);
      this.registerMediaElementEventsForDashJS();
    }
    const errorDetailTracking = new ErrorDetailTracking(
      analytics.errorDetailTrackingSettingsProvider,
      new ErrorDetailBackend(analytics.errorDetailTrackingSettingsProvider.collectorConfig),
      [analytics.errorDetailSubscribable],
      undefined,
    );
    return [errorDetailTracking];
  }

  registerMediaElementEventsForDashJS() {
    const mediaElement = this.mediaElement;

    if (!mediaElement) {
      return;
    }

    this.listenToMediaElementEvent('playing', () => {
      const {currentTime} = mediaElement;
      this.needsFirstPlayIntent = false;
      this.eventCallback(Event.TIMECHANGED, {
        currentTime,
      });
    });
  }

  override getPlayerName = () => Player.DASHJS;

  getPlayerVersion = () => this.mediaPlayer.getVersion();

  override getMIMEType() {
    return MIMETypes.DASH;
  }

  override getStreamURL(): string | undefined {
    if (!this.mediaPlayer) {
      return undefined;
    }

    const source = this.mediaPlayer.getSource();
    if (source) {
      return source.toString();
    }

    return undefined;
  }
  /**
   * Implemented by sub-class to deliver current quality-level info
   * specific to media-engine.
   * @override
   * @returns {QualityLevelInfo}
   */
  getCurrentQualityLevelInfo(): QualityLevelInfo | null {
    if (!this.mediaPlayer) {
      return null;
    }

    // info not available on source change, methods exist but throw exception
    try {
      const videoBitrateInfoList = this.mediaPlayer.getBitrateInfoListFor('video');
      const currentVideoQualityIndex = this.mediaPlayer.getQualityFor('video');
      const currentVideoQuality = videoBitrateInfoList[currentVideoQualityIndex];

      if (currentVideoQuality == null) {
        return null;
      }

      const {width, height, bitrate} = currentVideoQuality;
      return {
        width,
        height,
        bitrate,
      };
    } catch (error) {
      logger.warning('Quality information not available');
    }

    return null;
  }
}
