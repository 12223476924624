import { useContext } from "react";
import { NavLink } from "react-router-dom";
import logo from '../assets/images/logo.svg';
import { UserContext } from "../context/languageContext";
import arrow from "./images/arrow-left.svg";

const SurveyHeader = (props) => {
    const values = props?.values 
    const currentStep = props?.values?.current_step || 0
    const totalQuestion = props.totalSteps;//props?.values?.values?.total_steps || 0
    const finished = currentStep == totalQuestion;
    const language = useContext(UserContext);
    const percentage = (currentStep/totalQuestion) *100;


    const goBack = () => {
        props.dispatchWizard({type:'PREV',data:{}})
    }

  

    return (
        <>
        <div className="survey-header">
            <div className="survey-header-container">
                { currentStep>0 && !finished ?
                <>
                <div className="survey-header-item">
                    <img src={arrow} onClick={goBack} alt={language.back_to_question} />
                    <div onClick={goBack}>{language.back_to_question}</div>
                </div>
                <div className="survey-header-item m-logo">
                <NavLink  onClick={()=> window.location.href = '/'}><img src={logo} className="mawaheb-logo" alt={language.mawaheb_logo}/></NavLink>
                </div>
                <div className="survey-header-item">{currentStep+'/'+totalQuestion}</div>
                </>
                :
                <>
                <div className="survey-header-item">
                    <NavLink onClick={()=> window.location.href = '/'}><img src={logo} className="mawaheb-logo" alt={language.mawaheb_logo}/></NavLink>
                </div>
                </>            
                }
            </div>

            { currentStep>0 && !finished &&
            <div className="survey-progress-contriner">
                <div className="survey-progress-bar" style={{width:percentage+'%'}}></div>
            </div>
            }
        </div>
        </>
    )
}

export default SurveyHeader