export const WizardInitState = {
    total_steps:3,
    current_step:1,
    next_step:2,
    previous_step:1,
    is_first_step:true,
    is_last_step:true,
    is_loading:false,
    values:{}
};



const WizardReducer = (state=  WizardInitState, action) => {
    switch (action.type) {
        case 'NEXT':          
        //console.log(action.data)     
            return {...state,current_step:state.current_step+1, values:{...state.values, ...action.data.values}};
        case 'PREV':          
        //console.log(action.data)     
            return {...state,current_step:state.current_step-1, values:{...state.values, ...action.data.values}};            
        case 'GOTO':               
            return {...state,current_step:action.data.step, values:{...state.values, ...action.data.values}};            
        case 'RESET':
            if(action?.data?.goTo){
                setTimeout(() => {
                    window.location.href = action.data.goTo;
                }, 100);
            }            
            return WizardInitState    
        case 'SET_VALUES':          
        //console.log(action.data)     
            return {...state, values:action.data.values};            
        default:
            break;
    }

    return state;
}


export default WizardReducer;