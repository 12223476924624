import { useContext, useState } from "react";
import { UserContext } from "../../context/languageContext";
import config from "../config";
import CourseItem from "./courseItem";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation} from 'swiper/modules';
import 'swiper/css/navigation';


const CategoryItem = ({id,courses}) => {
    const lang = useContext(UserContext);

   
    const breakpoints={
        0: {
            slidesPerView: 2.5,
        },
        450: {
            slidesPerView: 2.5,
        },
        600: {
            slidesPerView: 2.5,
        },
        700: {
            slidesPerView: 2.5,
        },
        1000: {
            slidesPerView: 3.5,
        },
        1500:{
            slidesPerView: 4.5,
        },
        2000:{
            slidesPerView: 6,
        }
    }


    return (
        <>
            <Swiper 
            modules={[Navigation]}
            navigation 
            breakpoints={breakpoints}
            spaceBetween={10}
            slidesPerView={3.5}
            slidesPerGroup={1}
            observer
            observeParents
            parallax
            dir={lang.getSelectedLanguage() == 'ar' ? "rtl" : "ltr"}

            className="slider-items"
            // breakpoints={breakpoints}
            >

        {/* <div> category name : {lang['cat_name_'+id]}</div>
        <div> category image : <img src={config.imgDns+ "icon/cat_"+id+".svg"}/></div> */}
        {            
            courses.map( item => {
                return <SwiperSlide><CourseItem key={item.id} data={item}/></SwiperSlide>

            })
        }
        {/* <hr style={{'borderColor':'black'}}/> */}
        </Swiper>

        </>

    );
}

export default CategoryItem;