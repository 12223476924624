import { useEffect, useContext, useState } from "react"
import {NavLink} from 'react-router-dom'
import ChildCard from "./sections/childCard"
import { ProfileContext } from "../context/ProfileContext"
import ChildForm from "./sections/childForm";
import ChildsReducer, { ChildsInitState } from './reducers/childsReducer';
import { useReducer } from "react";
import { AuthContext } from "../context/AuthContext";
import {UserContext as LangaugeContext} from "../context/languageContext";
import MessageModal from "./modals/messageModal";
import ChildCardLoader from "./loaders/childCardLoader";
import '../assets/css/profile.css';
import '../assets/css/courses.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation } from "swiper/modules";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";

const AddedChildren = () => {

    const Lang = useContext(LangaugeContext);
    const Profile = useContext(ProfileContext);
    const Auth = useContext(AuthContext);
    const [ChildsStates, dispatch] = useReducer(ChildsReducer,ChildsInitState);
    const [parentEmail, setParentEmail] = useState();
    const location = useLocation();
    const params = useParams();
    const refresh = params?.refresh || '';
    const passedState = location?.state || {rand:0};
    const breakpoints={
        0: {
            slidesPerView: 1,
        },
        450: {
            slidesPerView: 1,
        },
        600: {
            slidesPerView: 1,
        },
        700: {
            slidesPerView: 1,
        },
        1000: {
            slidesPerView: 3,
        },
        1500:{
            slidesPerView: 3,
        },
        2000:{
            slidesPerView: 3,
        }
    }
    useEffect(()=>{
        
        if(!Auth.isLogged){
            window.location.href = '/';
        }
        
        Auth.getUserEmail().then(res => {
            if(res.data?.status==1){
                setParentEmail(res.data.payload.email);
            }
        })
    });    


    useEffect(()=>{
        Profile.canAddChild(dispatch);
        dispatch({type:'START_INITIAL_LOADING',data:[]})
            Profile.getUserChildren().then( (res)=>{
            let data = res?.data?.payload || {};
            let status = res?.data?.status || 0;
            if(status && data.children.length >0){
                dispatch({type:'SET_CHILDREN',data:data.children});                
            }
            dispatch({type:'END_INITIAL_LOADING',data:[]});
        }).catch((err)=>{
            dispatch({type:'END_INITIAL_LOADING'});
        })
    },[refresh]);




    const addNewChild = () => {
        dispatch({type:'PREPARE_NEW_USER'});
    }
    return(                
        <div className="cards-container" style={{backgroundColor: '#e9e9e9'}}>

            <div className="dashboard-contents" style={{backgroundColor: '#fff', height:'100%'}}>
                <div style={{backgroundColor: '#fff'}}>
                <div className="top-titles" style={{'margin':'0 auto', 'width':'85%', 'padding-top': '20px'}}>
                    <p className="profile-icon"><img src={process.env.PUBLIC_URL + "/assets/images/added_children.svg"} alt={Lang.added_children} /><p>{Lang.added_children}</p></p>
                </div>
                <div className={`${ChildsStates.can_add_child && !ChildsStates.isLoading.check_add_ability?'':'hide'}`}>
                    {/* <button className={`btn btn-primary btn-round`} onClick={addNewChild}>{Lang.add_new_child}</button> */}
                </div>
                <div className="container child-container">
                    <div className="row-added-children"> 
         <Swiper
             breakpoints={breakpoints}
             modules={[Navigation]}
            //  spaceBetween={70}
             navigation={{
                 nextEl: '.nextSwipeCard',
                 prevEl: '.prevSwipeCard',
               }}            
         >
            {/* <SwiperSlide> */}
                <div className="swiper-button-prev prevSwipeCard"></div>
                <div className="swiper-button-next nextSwipeCard"></div>

                    { ChildsStates.isLoading.initial && ChildsStates.showCardLoader == true && <ChildCardLoader total={ChildsStates.totalLoaders}/> }
                    {
                        ChildsStates.children.map( (child) => {
                            return <SwiperSlide key={child.uid}><ChildCard 
                            key={child.uid}
                            user-id={child.uid}
                            last-login={child.last_login} 
                            gender={child.gender} 
                            completed-courses={child.completed} 
                            progress-courses={child.progress} 
                            fname={child.first_name} 
                            lname={child.last_name}
                            img={child.img}
                            data={child}
                            uploadImageBtn ={true}
                            EditUserBtn ={true}
                            removeUserBtn={false}
                            dispatch = {dispatch}
                            states = {ChildsStates}
                            
                            /></SwiperSlide>
                        })
                    }    

                    { 
                    !ChildsStates.isLoading.initial && ChildsStates.total_childs_can_add > ChildsStates.children.length &&
                    <SwiperSlide>
                    <div className="add-child" onClick={addNewChild}>
                        <img src={process.env.PUBLIC_URL + "/assets/images/add-child.svg"} />
                        <p>{Lang.add_new_child}</p>
                    </div>
                    </SwiperSlide>
                    }
                    {/* </SwiperSlide> */}
                    </Swiper>

                    </div>
                    {!ChildsStates.isLoading.initial && ChildsStates.children.length==0 && <center>{Lang.no_children_added}</center> }                    

                    {
                    !ChildsStates.can_add_child &&  
                    !ChildsStates.isLoading.check_add_ability && 
                    !ChildsStates.isLoading.initial && 
                    <center>Go to <NavLink className="anchor-link call-to-plans" to={'/prices'}>plan page</NavLink> </center> }
                
                </div>
                </div>
                {    ChildsStates.showForm && 
                    <ChildForm parentEmail={parentEmail} dispatch={dispatch} isNew={ChildsStates.isNew} userId={ChildsStates.userId} data={ChildsStates.selectedChild}/>
                }
            </div>

            <MessageModal title={ChildsStates.messageTitle} id="general-message" okLabel="Close">
            {ChildsStates.messageBody}
            </MessageModal>            
        </div>
    )
}

export default AddedChildren;