import './style.css'
const BoxLoader = (props) => {

    const total = props?.total || 1;
    const elements = [];    
    const Wrapper = props.wrapper;
    const item = (key) => {
        return (
        <div key={key} className="is-loading" {...props}>
            <div className="image img-thumb" style={{'height':'250px',width:'100%'}} ></div>
        </div>  
        )       
    };
    if(Wrapper){
        for(var x=0;x<total;x++){
            elements.push(( <Wrapper key={x}>{item(x)} </Wrapper> ));
        }
    }
    else{
        for(var x=0;x<total;x++){
            elements.push(( item(x) ));
        }
    }




    return (
        elements
    )
}

export default BoxLoader;