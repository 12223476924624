import React,{memo, useContext, useState, useEffect} from "react" ;
import '../assets/css/landingPage.css';
import '../assets/css/campTheme.css';
import Config from './config';
import {UserContext} from '../context/languageContext'
import ApiHelper from "../helpers/ApiHelper";
import parse from 'html-react-parser';
import {useNavigate,NavLink} from 'react-router-dom';
import playIcon from '../assets/images/parts_play_icon.svg' 
import MessageModal from "./modals/messageModal";
import { AuthContext } from "../context/AuthContext"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import Newsletter from "./newsletter";
import Testimonials from "./testimonials";
import { Navigation } from "swiper";
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { htmlBrToReacBr, otherLanguageCourseName } from "../helpers/functions";
import Image from "./elements/image";
//import BitmovinPlayer from "./elements/bitmovinPlayer";
import SurveyBanner from '../assets/images/surveyBanner1.png';
import Img_1 from '../assets/images/mawahebProp1.svg';
import Img_2 from '../assets/images/mawahebProp2.svg';
import Img_3 from '../assets/images/mawahebProp3.svg';
import Img_4 from '../assets/images/mawahebProp4.svg';
import Img_5 from '../assets/images/mawahebProp5.svg';
import Img_6 from '../assets/images/mawahebProp6.svg';
import Img_7 from '../assets/images/mawahebProp7.svg';
import Step_1 from '../assets/images/step1.svg';
import Step_2 from '../assets/images/step2.svg';
import Step_3 from '../assets/images/step3.svg';

export const GetCoursesForSelectedCat=(props)=>{
  const Language = localStorage.getItem('lang');
  const lagKeywords = React.useContext(UserContext);   
  const categoryId=(props.categoryId)
//   const introCourses=props.introCourses
    const mainIntroCourses = props.introCourses.filter((item)=> categoryId == item.categoryid);
    const introCourses = categoryId == 1 ? mainIntroCourses.slice(0,3) : mainIntroCourses.slice(0,4);
 
    return (
    <>
    {

        introCourses.length > 0 && introCourses.map((introCoursesObj, index) => {   
            // console.log(categoryId);
           if(introCoursesObj.visible !=1){
                return '';
            }  
            introCoursesObj.displayname = otherLanguageCourseName(introCoursesObj, lagKeywords.getSelectedLanguage());
          
            return ( 
            <>
         
            {/* {categoryId && categoryId==introCoursesObj.categoryid &&( */}
                <>
                    <div className="courseCard" key={introCoursesObj.id} id={introCoursesObj.id}>
                        <NavLink className="courseCardAncor" to={'/course/'+introCoursesObj.id+'/'+introCoursesObj.displayname?.trim().split(' ').join('-')}>
                            <img src={Config.imgDns+ "icon/"+introCoursesObj.id+"_large.png"} alt={introCoursesObj.displayname} />
                            <div>
                                <div className="courseName">{introCoursesObj.displayname}</div>
                                <div className="courseDesc">{Language == 'ar' ? introCoursesObj.desc_ar : introCoursesObj.desc }</div>
                                <NavLink className="courseMore" to={'/course/'+introCoursesObj.id+'/'+introCoursesObj.displayname?.trim().split(' ').join('-')}>{lagKeywords.more}</NavLink>
                            </div>
                        </NavLink>
                    </div> 
                  </>
             {/* )} */}
             </>
                  )
                  
                 
            
        })
        
    }
    
    {
        // sub cat.
        props.categoriesList && props.categoriesList.length > 0 && props.categoriesList.map((categoriesListObj,index)=>{
                if(props.categoryId==categoriesListObj.parent)
                {
                    return (    
                    <div className="courseCard" id={"cat_"+categoriesListObj.id} key={categoriesListObj.id}>
                        <NavLink className="courseCardAncor" to={ categoriesListObj?.redirect?categoriesListObj?.redirect: 'categoryCourses/'+categoriesListObj.id+'/'+categoriesListObj.name}>
                            <img src={Config.imgDns+ "icon/cat_"+categoriesListObj.id+".png"} alt={props.lagKeywords['cat_name_'+categoriesListObj.id]} />
                            <div>
                                <div className="courseName">{props.lagKeywords['cat_name_'+categoriesListObj.id]}</div>
                                <div className="courseDesc">{Language == 'ar' ? categoriesListObj.desc_ar : categoriesListObj.desc }</div>
                                <NavLink className="courseMore" to={ categoriesListObj?.redirect?categoriesListObj?.redirect: 'categoryCourses/'+categoriesListObj.id+'/'+categoriesListObj.name}>{lagKeywords.more}</NavLink>
                            </div>
                        </NavLink>
                    </div>
    
                    )
                }
        })
    }
     </>
    )
    }

export const GetCoursesFormSubCat=(props)=>{
  
  const lagKeywords = React.useContext(UserContext);   
  //categoryIdSelected=2
  const categoryId=(props.categoryId)
  const introCourses=props.introCourses
      return (

        introCourses.length >0 && introCourses.map((introCoursesObj, index) => {                 
           
           //console.log(introCoursesObj);
           if(introCoursesObj.visible !=1){
                return '';
            }           
            introCoursesObj.displayname = otherLanguageCourseName(introCoursesObj, lagKeywords.getSelectedLanguage());
          return ( 
            <>    
            
            {categoryId && categoryId==introCoursesObj.categoryid &&(
                <>
                  <div className="single-course" key={introCoursesObj.id}>   
                    <NavLink className="each-course" to={'/course/'+introCoursesObj.id+'/'+introCoursesObj.displayname?.trim().split(' ').join('-')}>
                      <img src={Config.imgDns+ "icon/course_"+introCoursesObj.id+".svg"} alt={introCoursesObj.displayname} />
                      <p>{introCoursesObj.displayname}</p>
                    </NavLink>
                  </div>
                  </>)}
                  </>
                  )      
           })   
  )
}

const LandingPage=(props)=>{

/*
    apis calling
*/
    const value = React.useContext(UserContext);  
    const Auth = useContext(AuthContext);
    let [videosLp, setvideosLp] = useState([]); 
    let [introLp, setintroLp] = useState([]); 
    //let [introCourses, setCourses] = useState([]); 
    const introCourses = props.sharedCourses;
    const [isMobile, setIsMobile] = useState(false);
    let [categoriesList,setCategoriesList]=useState([])
    let [categoryIdFilter,setcategoryIdFilter]=useState('-1')
    let [categorySelectedImage,setcategorySelectedImage]=useState('')
    let [categorySelectedName,setcategorySelectedName]=useState('')
    let [popup, setPopup] = useState({title:'',content:''});
    const [activeCat, setActiveCat] = useState('1');

    if(typeof(introCourses) == 'string'){
        introCourses = JSON.parse(introCourses);
        }

    if(typeof(categoriesList) == 'string'){
        categoriesList = JSON.parse(categoriesList);
        }        
    let api=Config.videoLp;
    let introApi=Config.introApi;
    const navigate = useNavigate();
    useEffect(()=>{
        ApiHelper.excuteApi(api,'get','{}',setvideosLp);
        ApiHelper.excuteApi(introApi+'+homepage_content&lang='+value.getSelectedLanguage(),'get','{}').then( res => {
            
            res.homepage_content = res.homepage_content.replace('#iframe#','');
            res.homepage_content = parse(res.homepage_content);

            setintroLp(res);
        });
        ApiHelper.excuteApi(Config.categoriesApi,'get','{}',setCategoriesList);

        window.$('body').on('change','input[name=accordion-2]',function(e){
            e.stopPropagation();
            e.stopImmediatePropagation();
            window.$('input[name=accordion-2]').not(window.$(this)).parent().find('.tab_q_content').slideUp();
            window.$(this).parent().find('.tab_q_content').slideDown();
            window.$('input[name=accordion-2]').not(window.$(this)).prop('checked',false);
            if(!window.$(this).is(':checked')){
                window.$(this).parent().find('.tab_q_content').slideUp();
            }
        })


    },[]        
    )

    const Language = localStorage.getItem('lang');
    const mawahebData = [
        {
            data : [
                {"id" :1, 
                "title_ar": "فيديوهات بسيطة وواضحة",
                "title_en": "Simple and clear videos",
                "icon": Img_1},
        
                {"id" :2, 
                "title_ar": "محتوى موثوق به",
                "title_en": "Created by experts",
                "icon": Img_2},
                
                {"id" :3, 
                "title_ar": "شاهد مساقاتك في أي مكان...في أي وقت",
                "title_en": "Watch your courses Anywhere…Any time",
                "icon": Img_3},
    
                {"id" :4, 
                "title_ar": "لا واجبات منزلية، لا اختبارات، ولا ضغط",
                "title_en": "No homework no tests no pressure",
                "icon": Img_4},

                {"id" :5, 
                "title_ar": "مُعتمد",
                "title_en": "Safe for children",
                "icon": Img_5},

                {"id" :6, 
                "title_ar": "اشتراك واحد يصل لجميع المساقات",
                "title_en": "One subscription access to all courses",
                "icon": Img_6},

                {"id" :7, 
                "title_ar": "قم بتحميل مشاريعك وشاركها مع الأصدقاء",
                "title_en": "Upload your projects and share it with friends",
                "icon": Img_7},
            ], 
            questions : [
                {"id" :1, 
                "question_ar": "ما هي منصة مواهب؟",
                "question_en": "What is MasterHobbies platform?",
                "answer_ar": "مواهب هي منصة تعليمية تقدم فيديوهات تفاعلية مصممة لمساعدة الأفراد على اكتشاف وتطوير مواهبهم مثل الموسيقى، والبرمجة، والرسم، وغيرها الكثير. تم إعداد هذه الدورات من قبل خبراء، مما يجعل عملية التعلم بسيطة وممتعة. صممت المنصة لتناسب الأطفال والكبار على حد سواء، وتقدم فرصة لاكتساب المهارات وتوسيع الآفاق بسرعة وفعالية. مواهب تقدم فيديوهات باللغتين العربية والإنجليزية، مع خيار الترجمة للغة الإشارة قريبًا",
                "answer_en": "MasterHobbies is an educational platform that offers interactive courses designed to help individuals discover and develop talents like music, programming, drawing, and much more. Courses are created by experts, making learning simple and enjoyable. The platform welcomes both children and adults, offering quick skill acquisition and broadening horizons. Mawaheb provides courses in Arabic and English, with sign language options coming soon."},
        
                {"id" :2, 
                "question_ar": "ما هو مصدر معلومات المساقات المطروحة في منصة مواهب؟",
                "question_en": "What is the source of the information for the courses offered on MasterHobbies?",
                "answer_ar": "تم تقديم مساقات منصة مواهب بالتعاون مع أكاديميين متميزين، ومتحمسين لتوسيع دائرة المستفيدين من مخزونهم العلمي والمعرفي المتخصص.",
                "answer_en": "MasterHobbies courses were created in collaboration with distinguished academics that are eager to expand the circle of beneficiaries of their scientific and specialized knowledge stock."},
                
                {"id" :3, 
                "question_ar": "ما هو الحد الأقصى لعدد المساقات التي يمكنني الالتحاق بها؟",
                "question_en": "What is the maximum number of courses I can enroll in?",
                "answer_ar": "لا يوجد عدد أقصى لعدد الدورات التي يمكنك الالتحاق بها حيث أنك بمجرد اشتراكك بالمنصة ستتمكن من مشاهدة جميع المساقات.",
                "answer_en": "There is no maximum number of courses that you can enroll in. Once you subscribe to the platform you will be able to view all the courses."},
    
                {"id" :4, 
                "question_ar": "ما هي أسعار الاشتراكات في مواهب؟",
                "question_en": "What are the subscription prices in MasterHobbies?",
                "answer_ar": "يعتمد سعر الاشتراك في منصة مواهب على نوع الخطة التي تختارها. للاطلاع على الخطط يرجى اتباع هذا الرابط: https://MasterHobbies.online/ar/prices",
                "answer_en": "The price of subscribing to the MasterHobbies platform depends on the type of plan you choose. To view the plans, please follow this link: https://MasterHobbies.online/en/prices"},

                {"id" :5, 
                "question_ar": "ما هي الميزات الرئيسية في الحسابات الفردية والعائلية؟",
                "question_en": "What are the main features of individual and Family Plans?",
                "answer_ar": "الاشتراك الفردي يشمل:<br/>1. حساب مستخدم واحد<br/>2. لوحة تحكم شخصية<br/> 3. وصول غير محدود لجميع المواضيع<br/><br/> اما الاشتراك العائلي يشمل: <br/>1. حساب والد واحد<br/> 2.  3حسابات للأطفال<br/>3. لوحة تحكم عائلية<br/> 4. وصول غير محدود لجميع المواضيع ",
                "answer_en": "Individual Plan:<br/> 1. 1 user account. <br/>2. Progress tracking. <br/>3. Unlimited access to all courses. <br/><br/>and Family Plan:<br/>1. 1 parent account. <br/>2. 3 children's accounts <br/>3. Progress tracking. <br/>4. Unlimited access to all courses."},

                {"id" :6, 
                "question_ar": "هل يمكنني إلغاء الاشتراك؟",
                "question_en": "Can I cancel my subscription?",
                "answer_ar": "نعم يمكنك إلغاء اشتراكك من خلال صفحة معلومات الدفع ثم الضغط على الغاء الاشتراك, الغاء الاشتراك لن یؤدي إلی استرداد مبالغ الدفع السابقة، وستبقی العضویة نشطة حتی نهایة المدة المدفوعة.",
                "answer_en": 'Yes, you can cancel your subscription by going to your payment info page and clicking on "Cancel Subscription." Canceling your subscription will not result in a refund of previous payments, and your membership will remain active until the end of the paid period'},
            ],          
        },
        ];
          
    const getSurveyTrigger = () => {
        var Language = localStorage.getItem('lang');

        if(isMobile){
            return <NavLink className="surveyButton" href={`/survey?lang=${Language}&mobile=1`}>{lagKeywords.survey_btn_LP}</NavLink>
        }
        else{
            return <NavLink className="surveyButton" href="javascript:" onClick={()=> window.$('#survey_modal').modal('show')}>{lagKeywords.survey_btn_LP}</NavLink> 
        }
    } 

    window.$('.hide-survey').on('click', function() {
        window.$(".surveyBannerContainer").hide(500);
    })
    const openRelatedCourses = (categoryId,name) => {
        setcategoryIdFilter(categoryId)
        setcategorySelectedImage(Config.imgDns+ "icon/cat_"+categoryId+".svg");
        setcategorySelectedName(name)
        window.$(".coursesCards").show(300);
      }

    window.$("body").on('click',".call-to-action-btn",function(event) {
        //event.stopImmediatePropagation();
        //event.stopPropagation();
        if(Auth.isLogged)
        {
            navigate('/dashboard');
        }
        else
        {
          //  window.$('#login').modal('show');
        }
    })
    
    const lagKeywords = React.useContext(UserContext);
    let videoString='';
    if(videosLp && videosLp.length > 0)
    {
        videoString=
            videosLp.map((videosLpObj, index) => {
            return ( 
                     `<div class="item" key="${videosLpObj.id}">
                      <iframe class="iframe-video-carousel " src="${videosLpObj.URL}" title='tetsing' frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            <p class="item-title">${videosLpObj.name}</p>
                      </div>`
                    )
            
        }).join(' ')
    }

const handleYouTubeVideo=(videoId,videoUrl)=>{
    setPopup({title:'no_title',content: <iframe className="iframe-video-carousel2" style={{"height":"256px"}} src={videoUrl} title="tetsing" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    })
    window.$('#content-video').modal({backdrop: 'static', keyboard: false}, 'show');
    return;
    window.$("#"+videoId).parent().html(
        '<iframe class="iframe-video-carousel " style="height:256px" src="'+videoUrl+'" title="tetsing" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>'
    )

}
var path = process.env.PUBLIC_URL;
let selectedLang = lagKeywords.getSelectedLanguage();
let poster = Config.imgDns+"icon/intro_poster.png";
let introVidSrc = "https://beecellmedia-euno.streaming.media.azure.net/2864d85e-eb74-4e86-a86e-e9507ccdc1f3/Mawaheb_Intro_presenter_Arabic_C.ism/manifest(format=m3u8-cmaf)";
if(selectedLang !='ar'){
    poster = Config.imgDns+"icon/intro_poster_"+selectedLang+".png";
    if(selectedLang == 'en'){
        introVidSrc = "https://beecellmedia-euno.streaming.media.azure.net/8a44a3a7-98e1-4c40-a426-8e5fbfa669f3/Mawaheb_Intro_presenter_Jena.ism/manifest(format=m3u8-cmaf)";        
    }
}
{
    /*
 data-target="#login" data-toggle="modal"
    */
}
    return(
        <>
        <section className="lp-intro-container web-intro">
            <h1 className="lp-intro-title">{lagKeywords.mawaheb_intro_title}</h1>
            <p className="lp-intro-p">{lagKeywords.mawaheb_intro_text}</p>
            {!Auth.isLogged  ? 
            <NavLink to="/userRegistration" className="lp-btn login-trigger call-to-action-btn">{lagKeywords.get_started}</NavLink>
           : '' }
            </section>
        <section className="lp-intro-container mob-intro"></section>
            <h1 className="lp-intro-title mob-intro">{lagKeywords.mawaheb_intro_title}</h1>
            <p className="lp-intro-p mob-intro">{lagKeywords.mawaheb_intro_text}</p>
            {!Auth.isLogged  ? 
            <NavLink to="/userRegistration" className="lp-btn mob-intro login-trigger call-to-action-btn" >{lagKeywords.get_started}</NavLink>     
            : '' }

    {/* Survey Banner */}
   {/* { Auth.isLogged ? '': */}
    <section className="surveyBannerContainer">
    <div className="hide-survey" title={lagKeywords.hide}><i class="fa fa-close" style={{fontSize: '15px', marginBottom:'2px'}}></i></div>
        <img src={SurveyBanner} alt={lagKeywords.survey} className="surveyBanner" />
        <div className="surveyBannerText">
            <p>{lagKeywords.survey_text_LP}</p>
            {
            getSurveyTrigger()
          }
        </div>
    </section>
   {/* } */}

   {/* talents section */}
    <main className="coursesView">
        <div className="sectionTitle">{lagKeywords.creative_talent}</div>
        <ul>
            {/* <li className="categoryList active" id="01">{lagKeywords.popular}</li>  */}
            {/* <li className="categoryList">Latest</li>  */}
            {categoriesList && categoriesList.length > 0 && categoriesList.map((categoriesListObj,index)=>{
                if(categoriesListObj.id >= -2 && categoriesListObj.parent==0){ 
                    return(
                    <li className={`categoryList ${categoriesListObj.id == categoryIdFilter ? "active" : ""}`}
                        id={"cat_"+categoriesListObj.id} 
                        key={categoriesListObj.id} 
                        data-toggle="collapse" 
                        onClick={()=> openRelatedCourses(categoriesListObj.id,lagKeywords['cat_name_'+categoriesListObj.id])}>
                        {lagKeywords['cat_name_'+categoriesListObj.id]}
                    </li>
                   
                        )  
                    }
            })}
        </ul>
        <div className="coursesCards">
            <GetCoursesForSelectedCat categoryId={categoryIdFilter} introCourses={introCourses} categoriesList={categoriesList} lagKeywords={lagKeywords} />
        </div>
        <NavLink to="/courses" className="viewAllCourses">{lagKeywords.view_courses}</NavLink>
    </main>

      {/* Why Choose Mawaheb? section */}
    <section>
        <div className="sectionTitle">{lagKeywords.why_mawaheb}</div>
        <div className="mawahebPropContainer">
        {mawahebData.map(({ data }, index) => (
            data.map(({ id, title_ar, title_en, icon }, index) => (
            <div id={id} className="mawahebProp">
                <img src={icon} />
                <p className="mawahebPropText">{Language == 'ar' ? title_ar : title_en }</p>
            </div>
             ))
             ))}
        </div>
    </section>
     <hr className="lp-separator"></hr>
    {/* 3 Steps to master your talent section */}
    <section className="masterTalents">
        <div className="sectionTitle">{lagKeywords.master_talent}</div>
        <div className="talentSteps">
            <div className="talentStep">
                <div><img src={Step_1} alt={lagKeywords.register}/></div>
                <p>{lagKeywords.register}</p>
            </div>
            <div className="talentStep">
                <div><img src={Step_2} alt={lagKeywords.pick_plan}/></div>
                <p>{lagKeywords.pick_plan}</p>
            </div>
            
            <div className="talentStep">
                <div><img src={Step_3} alt={lagKeywords.start_watching}/></div>
                <p>{lagKeywords.start_watching}</p>
            </div>
        </div>
        {
            /*
            data-target="#login" data-toggle="modal"
            */
        }
        {!Auth.isLogged  ? 
        <NavLink to="/userRegistration" className="lp-btn web-intro login-trigger call-to-action-btn" >{lagKeywords.get_started}</NavLink>     
        : ''}
        </section>

    {/* Questions section */}
    <section className="questions-accordion ">
    <div className="sectionTitle">{lagKeywords.common_questions}</div>
    {mawahebData.map(({ questions }, index) => (
            questions.map(({ id, question_ar, question_en, answer_ar, answer_en }, index) => (
        <>
        <div class="q-tab">
        <input type="checkbox" name="accordion-2" id={id} />
        <label for={id} className="tab_q_label">{Language == 'ar' ? question_ar : question_en }</label>
        {Language == 'ar' ? 
        <div className="tab_q_content">{htmlBrToReacBr(answer_ar)}</div>
        : 
        <div className="tab_q_content">{htmlBrToReacBr(answer_en)}</div>
        }
        </div>
        </>
            ))
            ))}
    </section>
    <Testimonials/>
    <Newsletter/>
        </>
    )
}

export default memo(LandingPage)
