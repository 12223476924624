import {useState, useContext, useEffect} from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {AuthContext} from '../context/AuthContext'
import { UserContext as language } from '../context/languageContext';
import '../assets/css/forms.css';
import LoadingButton from './elements/loadingButton';
import FbLogin from './elements/facebookLogin';
import Cookies from 'universal-cookie';
import GoogLogin from "./elements/googleLogin";
import {GoogleOAuthProvider} from '@react-oauth/google';
import googleConfig from './config/google.js'
import AppleLogin from "./elements/appleLogin";
import { updateUrlOnSignup } from '../helpers/functions.js';

const GOOGLECLIENTID = googleConfig.GOOGLE_CLIENT_ID;

const UserRegistrationStep1 = (props) => {
    const dataFromPrevStep = props?.values?.values || {};
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    let [method, setMethod] = useState(dataFromPrevStep?.method || 0);  
    const Auth = useContext(AuthContext);
    const Lang = useContext(language);
    const cookies= new Cookies();
    let isVas = cookies.get('isVas') || 0;

    useEffect(()=>{
        if(Auth.isLogged){
            navigate('/');
        } 
        updateUrlOnSignup();
        Auth.logTracking('registeration_page');

    },[])
       
    //console.log(dataFromPrevStep);

    const nextStep = async (newMethod=0) => {
        setLoading(true);
        if(newMethod){
            setMethod(newMethod);
            method = newMethod;
        }
        if(method != 0){   
            const values = {method};
            props.dispatchWizard({type:'NEXT',data:{values}})
        }
        setLoading(false);
      };   
      
    

    return(
  <>
            <div id="userReg-overlay"></div>    
            <div className='userReg-section' >

                <NavLink className="close-userReg" to="/"><span className="fa fa-fw fa-times" style={{color:'#000'}}></span></NavLink>
                <div className="reg-form" style={{maxWidth:'400px', width:'90%'}}>
                    <h2 className="reg-title" style={{'textAlign':'center', 'paddingBottom':'3%'}}>{Lang.RegisterNewAccount}</h2>

                    <div className='radio-card-container'>
                        
                    <GoogleOAuthProvider clientId={GOOGLECLIENTID} className="social-login-container">
                        <GoogLogin preRedirect = {()=>updateUrlOnSignup(3)} setLoading={setLoading}>
                        <div id={`google-signup`} className={`reg-button gray-bordered social-reg`}>
                            <img src={process.env.PUBLIC_URL + "/assets/images/google-logo.svg"} alt="google" style={{width:'30px', margin:'0 7px'}}  />
                            <p>{Lang.using_google}</p>
                            </div>
                        </GoogLogin>
                    </GoogleOAuthProvider>

                    { 
                    <div>
                        <AppleLogin preRedirect = {()=>updateUrlOnSignup(3)} id={`apple-signup`}  setLoading={setLoading}/>
                    </div>
}                       
{/* 
                     {!isVas &&    
                    <FbLogin setLoading={setLoading} getRedirectUrl = {true} className={`by-fb radio-card-box ${method ==3 ? 'selected':''}`} onClick={()=>setMethod(3)}>
  
                        <div className='radio-card-img'><i className='fa fa-facebook'></i></div>
                        <div className='radio-card-text'>{Lang.using_facebook}</div>                            
                                                         
                    </FbLogin>  
                    } */}
                    {isVas ? 
                        <div id={'mobile-signup'} className={`by-fb radio-card-box ${method ==1 ? '':''}`} onClick={()=>{ navigate('/authMobile')}}>
                            <div className='radio-card-img'><i className='fa fa-mobile'></i></div>
                            <div className='radio-card-text'>{Lang.complete_your_reg}</div>
                        </div> : ''
                    }       

                        <div style={{marginTop:'20px'}} className={` ${method ==1 ? '':''}`} onClick={()=>{nextStep(1)}}>
                        <div id={'email-signup'} className='reg-button gray-bordered social-reg'>
                            <img src={process.env.PUBLIC_URL + "/assets/images/gmail-logo-01-01.svg"} alt="email" style={{width:'30px', margin:'0 7px'}}  />
                            <p>{Lang.using_email}</p>
                        </div>
                        </div>

                        {/* <div className={`by-mob radio-card-box ${method ==2 ? '':''}`} onClick={()=>{nextStep(2)}}>
                            <div className='radio-card-img'><i className='fa fa-mobile'></i></div>
                            <div className='radio-card-text'>{Lang.using_mobile}</div>
                        </div>    */}


                    
                    </div>

                    {/* <LoadingButton disabled={method==0 ? true:false} loading={isLoading.toString()} onClick={nextStep} type="button" className="submit-button-auto reg-btn" loading-text="<i class='fa fa-circle-o-notch fa-spin'></i> Processing" >{Lang.next}</LoadingButton> */}


                </div>
                <div className="register-now user-reg-login" style={{fontWeight: 'normal', margin:'25px auto 25px auto', width: '100%'}}>{Lang.have_an_acc} <a id={'login-btn'} href="#" className="login-trigger" data-target="#login" data-toggle="modal">{Lang.login}</a></div>
             </div>
             </>
    );

}


export default UserRegistrationStep1;