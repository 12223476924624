import type {PlayerAPI, UserInteractionEvent} from 'bitmovin-player';

import {WindowEventTracker} from '../../../utils/WindowEventTracker';

import {AdCallbacks, AdModuleAPI} from './AdModuleAPI';

export class Bitmovin8AdModule implements AdModuleAPI {
  adCallbacks = new AdCallbacks();

  constructor(
    private player: PlayerAPI,
    private windowEventTracker: WindowEventTracker,
  ) {
    this.register();
  }

  isLinearAdActive = () => {
    if (this.player.ads) {
      return (this.player.ads as any).isLinearAdActive() || false;
    }
    return false;
  };
  getContainer = () => this.player.getContainer();
  getAdModuleInfo = () => {
    if (this.player.ads) {
      return (this.player.ads as any).getModuleInfo();
    }
    return {name: undefined, version: undefined};
  };
  currentTime = () => this.player.getCurrentTime();

  private register() {
    this.player.on(this.player.exports.PlayerEvent.Play, (e: UserInteractionEvent) => {
      if (e.issuer === 'advertising-api') {
        this.adCallbacks.onPlay(e.issuer);
      }
    });
    this.player.on(this.player.exports.PlayerEvent.Paused, (e: UserInteractionEvent) => {
      if (e.issuer === 'advertising-api') {
        this.adCallbacks.onPause(e.issuer);
      }
    });
    this.player.on(this.player.exports.PlayerEvent.AdStarted, (event: any) => {
      this.adCallbacks.onAdStarted(event);
    });
    this.player.on(this.player.exports.PlayerEvent.AdFinished, (event: any) => {
      this.adCallbacks.onAdFinished(event);
    });
    this.player.on(this.player.exports.PlayerEvent.AdBreakStarted, (event: any) => {
      this.adCallbacks.onAdBreakStarted(event);
    });
    this.player.on(this.player.exports.PlayerEvent.AdBreakFinished, (event: any) => {
      this.adCallbacks.onAdBreakFinished(event);
    });
    this.player.on(this.player.exports.PlayerEvent.AdClicked, (event: any) => {
      this.adCallbacks.onAdClicked(event);
    });
    this.player.on(this.player.exports.PlayerEvent.AdQuartile, (event: any) => {
      this.adCallbacks.onAdQuartile(event);
    });
    this.player.on(this.player.exports.PlayerEvent.AdSkipped, (event: any) => {
      this.adCallbacks.onAdSkipped(event);
    });
    this.player.on(this.player.exports.PlayerEvent.AdError, (event: any) => {
      this.adCallbacks.onAdError(event);
    });
    this.player.on(this.player.exports.PlayerEvent.AdManifestLoaded, (event: any) => {
      this.adCallbacks.onAdManifestLoaded(event);
    });
    const handlePageClose = () => {
      this.adCallbacks.onBeforeUnload();
    };
    this.windowEventTracker.addEventListener('beforeunload', handlePageClose);
    this.windowEventTracker.addEventListener('unload', handlePageClose);
  }
}
