import { useContext, useEffect } from "react"
import { AuthContext } from "../context/AuthContext"
import AdultProfile from "./sections/adultProfile";
import ChildProfile from "./sections/childProfile";
import ParentProfile from "./sections/parentProfile.js";

const UserProfile = () => {

    const Auth = useContext(AuthContext);
    const accType = Auth.getAccountType();
    if(!Auth.isLogged){
        window.location.href = '/';
        return;
    }

    return (
        accType == 2 ? <ParentProfile/> : accType == 1 ?  <AdultProfile/> : accType == 3 ? <ChildProfile/> : ''
    )
}

export default UserProfile