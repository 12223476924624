import * as Yup from 'yup';
import {getAge} from '../../helpers/functions';

//const professions = ['Doctor', 'Engineer', 'Programmer'];


const CampaignJourneyPayment1Schema = Yup.object().shape({


    email: Yup.string().required('email_required_err')
            //.required("email_required_err")
            //.email("email_format_err"),     
            
            .test('validator-username-email', (value, { createError,resolve }) =>{
                 
                //const emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
                const emailRegex = /^\s*([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+\s*$/;

                if(!emailRegex.test(value)){
                        return createError({
                                message: `email_format_err`,
                                path: 'email', // Fieldname
                        })
                }
                return true;
            }),      


  })

  export default CampaignJourneyPayment1Schema;