import React from 'react';
import { useEffect, useState, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import CourseModulesMenu from "./courseModulesMenu";
import VideoPlayer from "./elements/VideoPlayer";
import {fileInfoFromPath} from "../helpers/functions"
import {UserContext} from '../context/languageContext'
import { useNavigate } from 'react-router-dom';

const singsVideoName = 'signs.mp4'
const CourseModules = () => {

    const navigate = useNavigate();
    const lagKeywords = React.useContext(UserContext);
    const {id, courseId, courseName, videoLang} = useParams([]);
    const [data, setData] = useState(null);
    const [video, setVideo] = useState(null);
    const [loading, setLoading] = useState(1);
    const [signsVideo, setSignsVideo] = useState(null);
    const [selected, setSelected] = useState(id);
    const [next, setNext] = useState(id);
    let [isCompleted, setCompleted] = useState(false)
    let [allowAction, setAllowAction] = useState(false);
    const [videoCaption,setVideoCaption]=useState('');
    const Auth = useContext(AuthContext);

    let selectedVideoLang = videoLang || lagKeywords.getSelectedLanguage();
    useEffect( () => {
        setLoading(1);
        get_course_module_contents(id)
    },[id,videoLang]);


    let materialUrls=[];
    const get_course_module_contents = (id) => {



            setSelected(id);
            allowAction = false;
            setCompleted(-1);
            Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('course_module_content/'+id+'/'+courseId).then( (res) => {                
                setLoading(2);
                if(res.data.error ==1){
                    setData(null);
                    setVideo(null);
                    navigate('/404');
                }
                else{
                    //console.log(res.data.payload)
                    setData(res.data.payload);                    
                    if(res.data.payload?.run){                        
                        //console.log(res.data.payload?.run )
                        let videoForCurrentLang = res.data.payload.run.find(item => item['type'] == 'stream' && item['lang'] ==selectedVideoLang);

                        //console.log(otherItems)
                        //console.log(videoForCurrentLang);
                        if(!videoForCurrentLang){
                            videoForCurrentLang = res.data.payload.run.find(item => item['type'] == 'stream' && item['is_default'] ==1);
                            if(videoForCurrentLang){
                                videoForCurrentLang['lang'] = selectedVideoLang;    
                                let otherItems = res.data.payload.run.filter(item => {
                                    return item['type'] != 'stream' || item['lang'] !=selectedVideoLang
                                });                                                            
                                res.data.payload.run = [...otherItems, videoForCurrentLang];
                            }
                        }
                        
                        setVideo(res.data.payload.run);

                        if(!id || id==0){
                            //id = res.data.payload.run[0].module;
                            setSelected(res.data.payload.run[0].module);                            
                        }
                        setNext(res.data.payload.next);

                        const signsVideo = res.data.payload.run.find( item => {                            
                            let itemDetails = fileInfoFromPath(item['fileurl']);                            
                            return itemDetails.filename == singsVideoName//'signs.mp4'
                        });
                        //console.log(signsVideo);
                        setSignsVideo(signsVideo);
                        allowAction = true;

                        if(res.data.payload.requestModuleCompleted == 1){
                            setCompleted(true);
                        }
                        else{
                            setCompleted(false);                  
                        }

                    }
                    else{
                        setVideo(null);
                    }
                }                  
                setAllowAction(allowAction)                         
            }).catch((err)=>{
                setLoading(2);
                setData(null);
                setVideo(null);
                allowAction = false;
                setAllowAction(allowAction)
            });   
        }

    const playNext = () => {
       // console.log('/courseModules/'+next+'/'+courseId+'/'+courseName);
        if(next && selected != next){
           // //get_course_module_contents(next);
            //console.log('/courseModules/'+next+'/'+courseId+'/'+courseName);
            window.location.href='/courseModules/'+next+'/'+courseId+'/'+courseName;
        }
    }    


    const markAsCompleted = (moduleId,courseId) => {


            if(allowAction == true && !isCompleted){
                allowAction = false;
                //setAllowAction(allowAction); 
                const selectedLanguage = lagKeywords.getSelectedLanguage();
                Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('mark_as_complete/'+moduleId+'/'+courseId,{language:selectedLanguage}).then( (res) => {
                    if(res.data.error ==1){
                        //setCompleted(false);
                    }
                    else{
                        if(res.data.status ==1){
                            //setCompleted(true);
                            //get_course_module_contents()                            
                        }
                        else{
                            //setCompleted(false);
                        }
                    }            
                }).catch((err)=>{
                    setCompleted(false);
                });                

            }
             
        }

        return (
            <>
            {
                loading ==1 && <h3 style={{'paddingTop':'50px','paddingBottom':'50px'}} className="course-module-container">  <center>{lagKeywords.loading}</center></h3>
            }  
            {           
            <div className="course-module-container">                
             
                <CourseModulesMenu lang={lagKeywords.getSelectedLanguage()} data={data} selected={selected} isCompleted={isCompleted}/>
                  { data?.run &&
                <div className="module-right-side">
                    
                    <ul className="lesson-content-list">
                    {
                        
                        loading == 2 &&  video.map( (file,index) => {
                            let fileTypeArr = fileInfoFromPath(file['fileurl']);                            
                            let fileType = fileTypeArr.type;

                            if(fileTypeArr.filename == singsVideoName){
                                return;
                            }

                            if(fileType=='vtt')
                            {
                               // console.log(file['fileurl']);
                               // setVideoCaption(file['fileurl']);
                            }
                            //console.log(file);
                            if(file['type']=='stream')
                            {
                                //console.log(file['fileurl']);
                                //console.log('stream');
                               // setVideoCaption(file['fileurl']);
                            }                            
                            //console.log(videoCaption)
                            //if(fileType=='mp4' || fileType=='m4v' || fileType=='ism/manifest(format=m3u8-cmaf)' || fileType=='txt'){
                            if(file['type']=='stream' && file['lang']== selectedVideoLang){
                                //console.log(file);
                                return <li key={index}>
                                        <VideoPlayer cover={data.current_cover} captionEn = {video.find(file => file['type'] =='caption' && file['lang'] =='en')?.fileurl} playNext={playNext} autoplay={index==0 ? true: false} signsVideoSrc={signsVideo?.fileurl || ''}  markAsCompleted ={markAsCompleted} moduleId={selected} courseId={courseId} title={courseName} isCompleted={isCompleted} url={file['fileurl']} id={"video_"+selected+"_"+index}/>
                                       </li>                                
                            }

                            let print='';
                            if(video.length ==1){

                                print=(<div style={{width:'90%', margin:'0 auto'}} className={`mark-complete-btn`}>
                                            <button style={{float:'right'}} onClick={()=>markAsCompleted(selected,courseId)} className={`btn btn-success ${(isCompleted==true || isCompleted==-1) && 'hide'}`}>{lagKeywords.mark_as_complete}</button>
                                            <button style={{float:'right'}} className={`btn btn-success ${(!isCompleted || isCompleted==-1) && 'hide'}`}>{lagKeywords.completed}</button>
                                       </div>  )                                                          
                            }
                            if(fileType!='vtt')
                            {
                                // return [
                                //     <li key={index}><a href={file['fileurl']}>{fileTypeArr.icon} {fileTypeArr.filename}</a></li>,
                                //     print
                                // ];
                                materialUrls.push(<li key={index}><a href={file['fileurl']}>{fileTypeArr.icon} {fileTypeArr.filename}</a></li>)
                            }
                        })
                    }      
                    </ul>                    
                    {materialUrls.length > 0 && 
                    <>                   
                            {/* <button className="download-material" onClick={()=>{window.$("#materialsContent").toggleClass('hidden')}}>
                                <i className="fa fa-download" aria-hidden="true"></i>
                                <a>{materialUrls}<p>{lagKeywords.download_material}</p></a>
                            </button>
                            <div className='hidden' id="materialsContent">{materialUrls}</div> */}

                            <button className="download-material hide">
                                <i className="fa fa-download" aria-hidden="true"></i>
                                <a className='material'>{materialUrls}</a>
                            </button>
                    </>
                    }
                </div>
                }

            </div>
            }
            
        </>
        );
}


export default CourseModules;