import { useState, useEffect, useContext, useRef } from 'react'
import '../../assets/css/childCard.css'
import ChildAvatar from '../../assets/images/child-avatar.png'
import ChildAvatarFemale from '../../assets/images/child-avatar-female.png'
import FileUploadBtn from '../elements/fileUploadBtn'
import {UserContext as LangaugeContext} from "../../context/languageContext";
import LoadingButton from '../elements/loadingButton';
import { ProfileContext } from '../../context/ProfileContext';
import { animate } from 'framer-motion';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation } from "swiper";

const ChildCard = (props) => {
    const Lang = useContext(LangaugeContext);
    const Profile = useContext(ProfileContext);
    const removeBrn = useRef(null)
    const dispatch = props.dispatch;
    const states = props.states
    const[profileImg, setProfileImg] = useState(ChildAvatar);
    const breakpoints={
        0: {
            slidesPerView: 1,
        },
        450: {
            slidesPerView: 1,
        },
        600: {
            slidesPerView: 1,
        },
        700: {
            slidesPerView: 1,
        },
        1000: {
            slidesPerView: 3,
        },
        1500:{
            slidesPerView: 3,
        },
        2000:{
            slidesPerView: 3,
        }
    }
//console.log(props)
    useEffect(()=>{
        if(props?.gender ==2){
            setProfileImg(ChildAvatarFemale);
        }        
        if(props.img!=''){
            setProfileImg(props.img)
        }
        if(props?.index ==0){
            showActivites();
        }
    },[])

    const removeChild = (id) =>{
        dispatch({type:'SELECT_CHILD', data:props.data});
        dispatch({type:'START_REMOVE_LOADING', data:id});
        Profile.removeChild(false, {id}).then((res)=>{
            dispatch({type:'END_REMOVE_LOADING', data:true});
        }).catch((err)=>{
            dispatch({type:'END_REMOVE_LOADING', data:false});
        })
    }

    
    const animate = (id) => {
     
    //   window.$( ".col-md-12" ).addClass( "bubble top" );

    }
        let isRemoveLoading = false;        
        if(states?.isLoading?.remove && props['user-id']==states.selectedChild.uid){
            isRemoveLoading = true
        } 

        const showActivites = () => {

            if(props.activityBtn){
                props.printUserCoursesSummary(props['user-id']);
                props.setActiveChild(props['user-id']); 
                animate(props['user-id']);
            }            
        }

    return (
        <div>
        {/* <div className="swiper-button-prev previous-swipe"></div>
        <div className="swiper-button-next next-swipe"></div> */}
         {/* <Swiper
             breakpoints={breakpoints}
             modules={[Navigation]}
             spaceBetween={70}
             //navigation 
             navigation={{
                 nextEl: '.next-swipe',
                 prevEl: '.previous-swipe',
               }}            
            //  slidesPerView={6}
            //  slidesPerGroup={1}
            //  onSlideChange={() => console.log('slide change')}
             //onSwiper={(swiper) => console.log(swiper)}
         > */}
            {/* <SwiperSlide> */}

        <div className= {`col-xl-6 col-lg-6 col-md-12 child-card  ${props.activeChild == 1 ? 'bubble top' : ' '} `}>
        
        {/* <div className= {`col-xl-6 col-lg-6 col-md-12 `} id={props['user-id']}> */}

            <div className="child-card profile-header">
                <div className="body">
                    <div className="row">
                        <div onClick={showActivites} className={`col-12 child-img ${props.activityBtn ? 'clickable':''}`}>
                            <div className="profile-image float-md-right"><img src={profileImg} alt=""/> </div>
                            <h4 className="m-t-0 m-b-0">
                                <span>{props.fname}</span>
                             {/* {props.lname} */}
                             </h4>

                        </div>
                        <div className="col-lg-8 col-md-8 col-12 child-info">
                            <FileUploadBtn 
                                loadingText={Lang.uploading} 
                                functions ={{afterupload:(data)=>{setProfileImg(data.payload.img)}}} 
                                className={`${!props.uploadImageBtn && 'hide'}`} 
                                title={Lang.upload_change_photo} 
                                id={`profile_file_${props['user-id']}`} 
                                url={`uploadProfileImage`} 
                                
                                capture="false"
                                data={{userId:props['user-id']}} />

                           { props.showBasicInfo == "1" &&
                            <>
                            <div className="">{Lang.completed_courses} : {props['completed-courses']}</div>
                            <div className="">{Lang.progressing_courses} : {props['progress-courses']}</div>
                            <div className="">{Lang.last_login} : {props['last-login']}</div> 
                            </>  
                            }
                            <div className='m-t-5 child-btns'>
                                {/* <button className={`btn btn-primary btn-round ${!props.activityBtn && 'hide'}`} onClick={()=>{props.printUserCoursesSummary(props['user-id']);props.setActiveChild(props['user-id']); animate(props['user-id'])}}>{Lang.activites}</button> */}
                                <button className={`btn edit-child btn-round ${!props.EditUserBtn && 'hide'}`} onClick={()=>dispatch({type:'PREPARE_EDIT_USER',data:props.data})}><img className="fa-pencil" src={process.env.PUBLIC_URL + "/assets/images/editPen.svg"}/> {Lang.edit}</button>
                                <LoadingButton ref={removeBrn} loading={isRemoveLoading.toString()} className={`btn btn-primary btn-round ${!props.removeUserBtn && 'hide'}`} onClick={()=> {removeChild(props['user-id'])}} loading-text={`<i class='fa fa-circle-o-notch fa-spin'></i>${props.loadingText || 'Removing'}`}>{Lang.remove}</LoadingButton>                                 
                            </div>
                        </div>                
                    </div>
                </div>                    
            </div>
        </div> 
        {/* </SwiperSlide> */}

        {/* </Swiper> */}
        </div>
    )
}

export default ChildCard