import { useContext, useState } from "react";
import {AuthContext} from "../context/AuthContext";
import { ProfileContext } from "../context/ProfileContext";
import AdultDashboard from "./adultDashboard";
import ParentDashboard from "./parentDashboard";
import ChildDashboard from "./childDashboard";
import TipsForParent from "./sections/tipsForParent";
import CoursesSummary from "./sections/coursesSummary";
import Courses from "./sections/courses";
import '../assets/css/dashboard.css'


const Dashboard = () => {
    const [userIdSelected,setUserIdSelected]=useState('')
    const Auth = useContext(AuthContext);
    const [userCoursesSummary, setUserCoursesSummary] = useState([]);
    const [isLoading, setLoading] = useState(0);
    const accType = Auth.getAccountType();
    const Profile = useContext(ProfileContext);
    const printUserCoursesSummary = (userId) => {
        setLoading(1);
        Profile.getUserCouresSummary(userId).then( (res)=>{
            setLoading(2);
            let data = res.data?.payload || [];
            setUserCoursesSummary(data);
            setUserIdSelected(userId)
            //console.log(data);
        });
    }

    return (
        <div className="dashboard-container">
            <div className="dashboard-contents">
                {
                    accType == 1 ? 
                    <AdultDashboard printUserCoursesSummary={printUserCoursesSummary}/> : 
                    accType == 2 ? <ParentDashboard printUserCoursesSummary={printUserCoursesSummary}/> : 
                    accType == 3 ? <ChildDashboard printUserCoursesSummary={printUserCoursesSummary}/> : ''
                }
                <CoursesSummary data={userCoursesSummary} userIdSelected={userIdSelected}  isLoading={isLoading} accType={accType}/>
                <Courses/>
                <TipsForParent/>
            </div>
        </div>
    )
}

export default Dashboard