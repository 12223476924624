class Logger {
  constructor(private showLogs = false) {}

  setLogging(logging: boolean) {
    this.showLogs = logging;
  }

  isLogging() {
    return this.showLogs;
  }

  log(message?: unknown, ...optionalParams: unknown[]) {
    if (!this.showLogs) {
      return;
    }

    /* eslint-disable-next-line no-console */
    console.log(message, optionalParams);
  }

  logTable(tabularData: unknown, properties?: readonly string[]) {
    if (!this.showLogs) {
      return;
    }

    /* eslint-disable-next-line no-console */
    console.table(tabularData, properties);
  }

  errorMessageToUser(message?: unknown, ...optionalParams: unknown[]) {
    /* eslint-disable-next-line no-console */
    console.error(message, ...optionalParams);
  }

  error(message?: unknown, ...optionalParams: unknown[]) {
    if (!this.showLogs) {
      return;
    }

    /* eslint-disable-next-line no-console */
    console.error(message, ...optionalParams);
  }

  warning(message?: unknown, ...optionalParams: unknown[]) {
    if (!this.showLogs) {
      return;
    }

    /* eslint-disable-next-line no-console */
    console.warn(message);
  }
}

export const padRight = (str: string | undefined, length: number) => {
  const padStr = new Array(length).join(' ');
  return (str + padStr).slice(0, length);
};

export const logger = new Logger();
