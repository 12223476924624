import React, { Component } from 'react';
import '../index.css';
import {UserContext} from '../context/languageContext'
import {useState} from 'react';
import {useEffect} from 'react';
import Config from './config';
import ApiHelper from "../helpers/ApiHelper";
import '../assets/css/terms-policy.css'
// import '../../public/assets/css/terms-policy.css'
const PrivacyPolicy=()=>{

  
  const value = React.useContext(UserContext);  
  //console.log(user)
  let [introLp, setintroLp] = useState([]); 
  let introApi=Config.getGeneralData;
  useEffect(()=>{
    ApiHelper.excuteApi(introApi+'privacy_policy&lang='+value.getSelectedLanguage(),'get','{}',setintroLp);
    },[]        
  )  
  console.log(introApi+'privacy_policy')
  return(

    <>
    <div className="section-container" dangerouslySetInnerHTML={ {__html: introLp.privacy_policy} }></div>
    </>
  )
}

export default PrivacyPolicy 