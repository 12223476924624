import React, { Component } from 'react';
import 'chart.js/auto';
import { Doughnut } from "react-chartjs-2";
import Config from '../../config';

/*
import {Chart, ArcElement} from 'chart.js'
Chart.register(ArcElement);
*/




const DoughnutChart = (props) => {

    const options = {
        elements: {
            arc: {
                borderWidth: 0
            }
        },      
        maintainAspectRatio:false,
        responsive: true,
        legend: {
          display: false,
          position: "bottom",
          labels: {
            fontSize: 18,
            fontColor: "#6D7278",
            fontFamily: "kanit light"
          }
        },
        ...props.options
      };
    


      const chartData = [20, 80];
      //const showData = chartData[0] + "%";
      const data1 = {
        labels: props.labels || [],
        datasets: [
          {
            data: props.data || [],
            backgroundColor: props.backgroundColor || Config.chartColors
          }
        ],
        //text: showData
      };      



      let plugins=[];
      if(props.centerText){
        /** to center text inside doughnut */
        plugins = [{
            beforeDraw: function(chart) {
                var width = chart.width,
                height = chart.height,
                ctx = chart.ctx;
        
                ctx.restore();
                var fontSize = (height / 100).toFixed(2);
                ctx.font = fontSize + "em sans-serif";
                ctx.textBaseline = "middle";
            
                var text = props.centerText,
                    textX = Math.round((width - ctx.measureText(text).width) / 2),
                    textY = height / 2;
            
                ctx.fillText(text, textX, textY);
                ctx.save();
            } 
          }]

      }


    return(
        <>
            <Doughnut plugins={plugins}  data={data1} options={options} height={props.height} width={props.width} />
        </>
    )
}

export default DoughnutChart