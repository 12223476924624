import { useContext, useRef, useState } from "react"
import { AuthContext } from "../context/AuthContext";
import '../assets/css/modals.css';
const FileUpload = (props) => {
    //console.log(props)
    const inputRef = useRef(null);
    const Auth = useContext(AuthContext);
    const initalState = {
        selectedFiles: [],
        currentFile : {name:''},
        progress : 0,
        message : '',
        currentlyUploading:false
    }
    let disabled = 0;
    if(props.disabledsubmit !='undefined'){
      disabled = props.disabledsubmit;
    }

    let [filesState, setFiles] = useState(initalState);

    const setSelectedFiles = (files=[]) => {
        setFiles({...initalState, selectedFiles:files, currentFile:files[0]})
    }

    const upload = () => {
        let currentFile = filesState.currentFile;
        //console.log(filesState);
        let formData = new FormData();
        if(props.mutiple ==true){
            for(var x=0; x<filesState.selectedFiles; x++){
                formData.append("file["+x+"]", filesState.selectedFiles[x]);    
            }
        }
        else{
            formData.append("file", currentFile);
        }        
        if(props.data){
            Object.keys(props.data).map(k => formData.append(k, props.data[k]) );
        }

        filesState.currentlyUploading = true;
        Auth.authorizedRequest({"Content-Type": "multipart/form-data"}).post(props.url, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress,
          }).then( (res) => {
            if(res.data.error==1){
                setFiles({...initalState,message:'upload failed'})                    
            }
            else{
                setFiles({...initalState, message:'upload success'});
                props.fun.getUploadedFiles(props.data.courseId,props.data.userId);
                if(props?.onComplete){
                  props.onComplete();
                }
            }      
            filesState.currentlyUploading = false;   
               
          }).catch( (err) => {
            console.log(err);
            setFiles({...initalState,message:'upload failed'})
            filesState.currentlyUploading = false; 
          });
    }

    const onUploadProgress = (event) => {
        let progress = Math.round((100 * event.loaded) / event.total)
        console.log(progress);
        setFiles({...filesState, progress})
    }

    const fileUploadChange = () => {
        let files=inputRef.current.files
        setSelectedFiles(files)
        //console.log(files)
    }


    return (

        <div>



        {/* <label className="btn btn-default">
            <input  ref={inputRef} type="file" {...props} onChange={fileUploadChange}/>
        </label> */}


        <div className="form-group">
                <label>{props.title}</label>
                <input type="file" ref={inputRef} {...props} onChange={fileUploadChange} style={{position: "absolute", clip: "rect(0px, 0px, 0px, 0px)"}}/>
                    <div className="input-group">
                        <input value={filesState.currentFile.name} type="text" className="form-control " placeholder="" disabled/> 
                        <span className="input-group-btn">
                            <label onClick={()=> inputRef.current.click()} htmlFor="BSbtninfo" className="btn btn-info selectFile">
                                <span className="glyphicon glyphicon-folder-open"></span> 
                                <span className="buttonText"> Select a File</span>
                            </label>

                            {
                            props.hasbutton &&                             
                            <button className="table-btn" style={{margin:'0 0 0 10px'}} disabled={filesState.selectedFiles.length ==0 || filesState.currentlyUploading || disabled==1} onClick={upload}>Upload</button>                            
                            } 

                        </span>
                    </div>

                   
            </div>








        {filesState.currentFile && filesState.currentlyUploading==true && (
          <div className="progress" style={{'marginTop':'15px'}}>
            <div
              className="progress-bar progress-bar-info progress-bar-striped"
              role="progressbar"
              aria-valuenow={filesState.progress}
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: filesState.progress + "%" }}
            >
              {filesState.progress}%
            </div>
          </div>
        )}        

        {props.children}

        <div className="alert alert-light" role="alert">
          {filesState.message}
        </div>


      </div>        
    )
    
    
    
}

export default FileUpload