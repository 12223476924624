import React, { Component ,useContext} from 'react';
import '../index.css';
import {UserContext} from '../context/languageContext'
import {useState} from 'react';
import {useEffect} from 'react';
import Config from '../Components/config';
import ApiHelper from "../helpers/ApiHelper";
import '../assets/css/about-us.css';
import {useNavigate,NavLink} from 'react-router-dom';
import { AuthContext } from "../context/AuthContext"
const About=()=>{
  const Auth = useContext(AuthContext);
  const navigate = useNavigate();
  const value = React.useContext(UserContext);
  const lang = useContext(UserContext);
  
  //console.log(user)
  let [introLp, setintroLp] = useState([]); 
  let introApi=Config.getGeneralData;
  useEffect(()=>{
    ApiHelper.excuteApi(introApi+'about_us&lang='+value.getSelectedLanguage(),'get','{}',setintroLp);
   
    },[]        
  )  
  document.querySelector('meta[name="description"]').setAttribute("content", lang.about_us_tag_desc);  
  document.querySelector('meta[name="keywords"]').setAttribute("content", lang.about_us_tag_keywords);          
  document.title =  lang.about_us_page_title;

  window.$("body").on('click',".call-to-action-btn",function(event) {        
    event.stopImmediatePropagation();
    event.stopPropagation();
    //console.log('clicked')
    if(Auth.isLogged)
    {
        navigate('/dashboard');
    }
    else
    {
        //alert('not l;ogged');
        window.$('#login').modal('show');
    }
})

  return(
    <>

       <div className='about-page' style={{paddingBottom: '50px'}} dangerouslySetInnerHTML={ {__html: introLp.about_us} }></div>
   
    </>
  )
}

export default About 