import {useState, useContext, useEffect, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import {Form, Formik} from 'formik';
import CampaignJourneyMobileStep1Schema from './schema/campaignJourneyMobileStep1Schema';
import Elements from './elements/elements';
import {getAge} from '../helpers/functions';
import {AuthContext} from '../context/AuthContext'
import { UserContext as language } from '../context/languageContext';
import config from './config';
import '../assets/css/forms.css';
import { NavLink} from 'react-router-dom';
import MobileInput from './elements/mobileInput';
import { useFormik } from 'formik';

const {CustomInput, SelectInput, LoadingButton} = Elements;
var response = null; 

const CampaignJourneyMobileStep1 = (props) => {
    const searchParams = new URLSearchParams(document.location.search);
   
    let campaignType = searchParams.get('campType');
    let queryMobile = searchParams.get('mobile');
    let queryPrefix = searchParams.get('prefix');
    if(!campaignType || campaignType == undefined || campaignType==0 || campaignType==1){
      campaignType = 1
    }    
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [totalDaysInMonth, setTotalDaysInMonth] = useState(31);
    let [selectedMonth, setSelectedMonth] = useState(0);
    let [selectedYear, setSelectedYear] = useState(0);    
    const Auth = useContext(AuthContext);
    const Lang = useContext(language);
    const adultAge = 18;    
    const noDOB = props?.noDOB || false;
    const currentYear = new Date().getFullYear();    
    const [dynamicPrefix,setDynamicPrefix] = useState(queryPrefix || '962');

    const btnRef = useRef(null)
    const daysInMonthProccess = (values) => {
        let y =  selectedYear;//values.dob_year;
        let m =  selectedMonth;//values.dob_month;
        let d = 0;
        if(y && m){
            d = new Date(y, m, 0).getDate();
        }

        d = 31;
        //console.log(d)
        setTotalDaysInMonth(d);
    }




    useEffect(()=>{
        if(Auth.isLogged){
            navigate('/');
        } 

        if(!queryPrefix){

            Auth.getCountryPrefix().then(res => {
                setDynamicPrefix(res.data.payload.prefix || '962');
            }).catch(err => {
                setDynamicPrefix('962');
            });      
        
        }
        Auth.logTracking('registeration_page');

        if(btnRef?.current){
            btnRef?.current.click();
          //alert('loaded')
        }

    },[btnRef])
   
    const dataFromPrevStep = props?.values?.values || {};
    //console.log(dataFromPrevStep);

    const onSubmitRegister = async (values, actions) => {
        setLoading(true);
       
        let responseData = await Auth.registerCampaignJourneyMobile(values);
        response = responseData.status;
        //console.log(responseData);
        //console.log(actions);
        //await new Promise((resolve) => setTimeout(resolve, 1000));
        setLoading(false);
        //console.log(response)
        if(responseData.status == 1){
            actions.resetForm();
            values.token = responseData.token;
            values.clientSecret = responseData.clientSecret;
            values.userId = responseData.userId;
            values.email = '';
            values.phone = parseInt(responseData.phone+'',10);
            values.mobile = parseInt(values.mobile+'',10);
            props.dispatchWizard({type:'NEXT',data:{values:{...dataFromPrevStep, ...values}}})
        }
      };   
      
      
      const initValues = {
        dob_day:noDOB ? 1: (dataFromPrevStep.dob_day || ""),
        dob_month:noDOB ? 1: (dataFromPrevStep.dob_month || ""),
        dob_year:noDOB ? (currentYear - 20): (dataFromPrevStep.dob_year || ""),
        mobile:(dataFromPrevStep.mobile || queryMobile || "") , 

        mobile_prefix:dataFromPrevStep.mobile_prefix || ('+'+dynamicPrefix) , 
        mobile_prefix_tmp : dataFromPrevStep.mobile_prefix ? dataFromPrevStep.mobile_prefix.replace('+',''): dynamicPrefix,        
        //mobile_prefix:(dataFromPrevStep.mobile_prefix || "+962"), 
        no_dob: noDOB ? 1: 0
    };
    
    const formikProps = useFormik({
        initialValues:initValues,
        validationSchema:CampaignJourneyMobileStep1Schema,
        onSubmit: onSubmitRegister,
        enableReinitialize:true

    })    
    const onChangePrefix = (newPrefix) => {
        setDynamicPrefix(newPrefix)
        formikProps.setFieldValue("mobile_prefix",'+'+newPrefix);
    }

    return(
      <div className="section-container" style={{"padding": "0"}}>
        <div className='content-container' style={{"padding": "0"}}>
        <div className="reg-form">
        <h2 className="reg-title" style={{'textAlign':'center', 'paddingBottom':'3%'}}>{Lang.RegisterNewAccount}</h2>

        { queryMobile && <center style={{margin : '30px auto'}}>{Lang.waiting}</center> }
        <Formik 
        enableReinitialize
      initialValues={initValues}
      validationSchema={CampaignJourneyMobileStep1Schema}
      onSubmit={onSubmitRegister}  
    >

{(props) => {
    //console.log(props.values.dob_year);
    let {values, errors, touched, setErrors, setFieldValue} = props;

    if(response == 5){
        touched.mobile = true;
        setErrors({'mobile':'mobile_registered_before'});
    }      
    response= null;

    return(
        <Form autocomplete="off" className={queryMobile ? 'hide' : ''}>
  
            <div className={`form-group ${noDOB ? 'hide':''}`} style={{"margin": "0", "width":"100%"}}>
            <div className="col-md-3 date-label" style={{"width":"40%"}}>
                <label>{Lang.date_of_birth+'*'}</label>                
            </div>

<div className='dob_options_container'>
            <div className="col-md-3" style={{paddingRight: '0', width: '30%'}}>
                <SelectInput onChange={(e) => {
                                props.setFieldValue("dob_year",e.target.value);
                                //props.values.dob_year=e.target.value;
                                setSelectedYear(e.target.value);
                                selectedYear = e.target.value;                                
                                daysInMonthProccess(props.values)
                }} name="dob_year" id="dob_year" className="reg-input select-date">
                    <option value={Lang.year} disabled={false}>{Lang.year}</option>
                    { (()=>{
                        let eleArr = [];
                        let currentYear = new Date().getFullYear();
                        for (var i=config.birth_year_from; i<=currentYear; i++){
                            eleArr.push(<option key={i} value={i}>{i}</option>);
                        }
                        return eleArr;
                    })()
                    }

                </SelectInput>                
            </div>            
            <div className="col-md-3" style={{paddingRight: '0', width: '30%'}}>
                <SelectInput onChange={(e) => {
                                props.setFieldValue("dob_month",e.target.value);
                                //props.values.dob_month=e.target.value;
                                setSelectedMonth(e.target.value);
                                selectedMonth = e.target.value;                                
                                daysInMonthProccess(props.values)
                }}  name="dob_month" id="dob_month" className="reg-input select-date">
                    <option value={Lang.month} disabled={false}>{Lang.month}</option>
                    {
                        config.months.map( item => {
                            return (
                                <option key={item.id} value={item.id}>{Lang[item.name]}</option>
                            )
                        })
                    }                                       
                </SelectInput>                
            </div> 
            <div className="col-md-3" style={{paddingRight: '0', width: '30%'}}>
                <SelectInput name="dob_day" id="dob_day" className="select-date reg-input">  
                   <option value={Lang.day} disabled={false}>{Lang.day}</option>
                    {
                        ( ()=>{
                            let eleArr = [];
                            for(var j=1; j<=totalDaysInMonth ;j++){
                                eleArr.push(<option key={j} value={j}>{j}</option>);
                            }
                            return eleArr;
                        })()
                    }
                </SelectInput>                
            </div> 
            </div>            
            </div>                      

            <div className={`form-group`} style={{"margin": "0", position: 'relative'}}>
                {/* <SelectInput className="form-select" id="mobile_prefix" name="mobile_prefix" >
                    {
                        config.countries.map( item => {
                            return (
                                <option key={item.prefix.id} value={item.prefix.id}>{item.prefix.name}</option>
                            )
                        })
                    }                    
                </SelectInput> */}
                <CustomInput autocomplete="off" id="mobile_prefix" name="mobile_prefix" type="number" placeholder=" " className="hide" />
                {/* <CustomInput autocomplete="off" type="number" onKeyUp={(e) => {if(e.target.value.length ==3){document.getElementById('mobile').focus()};}} onChange={(e)=>{setFieldValue("mobile_prefix_tmp",e.target.value);setFieldValue("mobile_prefix",'+'+e.target.value);}} id="mobile_prefix_tmp" name="mobile_prefix_tmp" maxLength={3} style={{backgroundImage:'unset',top:'0'}} className="form-select camp-prefix" />  */}
                <div className={`reg-field-tel `}>
                    <label htmlFor="mobile" className="placeholder-mob2">{Lang.mobile_number}</label>
                    {/* <CustomInput id="mobile" name="mobile" type="number" placeholder=" " style={{"direction":"ltr"}} className="reg-input custom-input" /> */}
                    <MobileInput disabled={queryMobile ? true: false} selectedVal={initValues.mobile_prefix_tmp} onChangePrefix={onChangePrefix} id="mobile2" name="mobile" type="number" placeholder=" " style={{"direction":"ltr"}} className="reg-input custom-input" />
                    <CustomInput readOnly name="token" id="token" type="hidden" placeholder=" " className="reg-input" />
                    <CustomInput readOnly name="no_dob" id="no_dob" type="hidden" placeholder=" " className="reg-input" />
                </div>
         
                
                {/* <CustomInput id="mobile" name="mobile" type="tel" className="reg-input" placeholder={Lang.mobile_number} /> */}
            </div>


        
            <LoadingButton ref={btnRef} loading={isLoading.toString()} type="submit" className="submit-button-auto reg-btn camp-btn" loading-text="<i class='fa fa-circle-o-notch fa-spin'></i> Processing" >{Lang.submit}</LoadingButton>
            {/* <NavLink className="redirect-camp" to="/campaign?campType=5">{Lang.reg_by_mob}</NavLink> */}
            {campaignType==8 && 
            <a className="redirect-camp" href="/campaign?campType=5">{Lang.reg_by_email}</a>
            }            

    </Form>
          )}}
</Formik>

        </div>
        </div>
        </div>
    );
}


export default CampaignJourneyMobileStep1;