import CustomInput from './customInput';
import CustomTextarea from './customTextarea';
import DateInput from './dateInput';
import SelectInput from './selectInput';
import RadioInput from './radioInput';
import CheckboxInput from './checkboxInput';
import LoadingButton from './loadingButton';


const Elements = {
    CustomInput:CustomInput,
    CustomTextarea:CustomTextarea,
    DateInput:DateInput,
    SelectInput:SelectInput,
    RadioInput:RadioInput,
    CheckboxInput:CheckboxInput,
    LoadingButton:LoadingButton
}

export default Elements;