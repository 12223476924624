
import React,{  useReducer } from 'react';
import WizardReducer, { WizardInitState } from './wizardReducer';
import {  motion } from "framer-motion"


function addPropsToReactElement(element, props) {  
    if (React.isValidElement(element)) {
      return React.cloneElement(element, props)
    }
    return element
  }
  
  function addPropsToChildren(children, props) {
    if (!Array.isArray(children)) {
      return addPropsToReactElement(children, props)
    }
    return children.map(childElement => {        
        return addPropsToReactElement(childElement, props)
      }
    )
  }


export const WizardStep = (props) => {
    if(props?.values?.current_step != props?.step){
        return '';
    }else if(props.noAnimate === true){
       return(
        <>
       {addPropsToChildren(props.children, props)}
        </>
       )
    }else{

      let attributes = {
        initial :{ x: 1000, opacity: 0},
        animate :{ x: 0, opacity: 1 },
        transition :{ ease: "easeOut", delay: 0.1, duration: 0.6 }
      }      
      if(props?.values?.customAnimate){
        attributes = props?.values?.customAnimate;
      }
        return (
            <motion.div
              {...attributes}
            >
                {addPropsToChildren(props.children, props)}
            </motion.div>         
        )
        //return addPropsToChildren(props.children, props);
    }
}


export const Wizard = ({children, startFrom=1, totalSteps=3, initValues={}, customAnimate={}}) => {
    WizardInitState.current_step=startFrom;
    WizardInitState.total_steps=totalSteps;
    WizardInitState.values = initValues;
    WizardInitState.customAnimate = customAnimate;
    const [WizardValues, dispatchWizard] = useReducer(WizardReducer,WizardInitState);    
    return addPropsToChildren(children, { dispatchWizard, values : WizardValues } ) 

    
}