import {useState, useContext, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import {AuthContext} from '../context/AuthContext'
import { UserContext as language } from '../context/languageContext';
import '../assets/css/forms.css';
import UserRegistrationStep2Email from './userRegistrationStep2Email';
import UserRegistrationStep2Mobile from './userRegistrationStep2Mobile';
import { updateUrlOnSignup } from '../helpers/functions';

const UserRegistrationStep2 = (props) => {
    const dataFromPrevStep = props?.values?.values || {};
    console.log(dataFromPrevStep)
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [method, setMethod] = useState(dataFromPrevStep?.method || 0);  
    const Auth = useContext(AuthContext);
    const Lang = useContext(language);
    

    useEffect(()=>{
        if(Auth.isLogged){
            navigate('/');
        } 
        updateUrlOnSignup(1);
        // Auth.logTracking('registeration_page');
    },[])

    


    return(
        dataFromPrevStep?.method ==1 ? <UserRegistrationStep2Email parentProps={props}  /> : 
        (dataFromPrevStep?.method ==2 ? <UserRegistrationStep2Mobile  parentProps={props}/> : '')
    );
}


export default UserRegistrationStep2;