

const  InputValidations = {    
    required : (value, name='') => {
        let result = {isValid:false, message:''};
        result.isValid = value? true: false;
        if(!result.isValid)
            //result.message = `${name} is Mandatory`;        
            result.message = `required_field`;  
        return result
    },
    isEmail : (value,name='') => {
        let result = {isValid:false, message:''};
        result.isValid =  value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);        
        if(!result.isValid)
            //result.message = 'Inavlid Email';
            result.message = 'invalid_email';
        return result;
    },
    password: (value, name='') => {
        let result = {isValid:false, message:''};
        if(value.length<6){
            result.isValid = false;
            if(!result.isValid)
                //result.message = `${name} length should be greater than equal 6`;
                result.message = `password_min_err`;
        }
        /*
        else if(value){
            result.isValid = value.match(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/);
            if(!result.isValid)
                result.message = `${name} Must contain at least one number and one uppercase and lowercase letter`
        }
        */
        else{
            result.isValid = true;
            result.message = '';
        }
        return result;
    },
    strongPassword: (value='', name='', returnValidityOnly=false) => {
        let result = {isValid:false, message:''};
        if(value.length<6){
            result.isValid = false;
            if(!result.isValid)
                //result.message = `${name} length should be greater than equal 6`;
                result.message = `password_min_err`;
        }
        
        else if(value){
            result.isValid = value.match(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/);
            if(!result.isValid)
                //result.message = `${name} Must contain at least one number and one uppercase and lowercase letter`
                result.message = `password_pattern_err`
        }
        
        else{
            result.isValid = true;
            result.message = '';
        }
        if(returnValidityOnly){
            return result.isValid;
        }
        return result;
    }    
};

export default InputValidations;