import {useState, useContext, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import {Form, Formik} from 'formik';
import CampaignJourneyStep1Schema from './schema/campaignJourneyStep1Schema';
import Elements from './elements/elements';
import {getAge, ReplaceTagStringWithElement} from '../helpers/functions';
import {AuthContext} from '../context/AuthContext'
import { UserContext as language } from '../context/languageContext';
import config from './config';
import '../assets/css/forms.css';
import { gtag, install } from 'ga-gtag';
import ReactPixel from 'react-facebook-pixel';
import googleConfig from './config/google'
import facebookConfig from './config/facebook';
import { NavLink } from 'react-router-dom';
import Timer from './elements/timer';
import CustomInput from './elements/customInput';
import LoadingButton from './elements/loadingButton';
import CodeSchema from './schema/codeSchemaCampaign';
import { firebaseAuth,  RecaptchaVerifier,signInWithPhoneNumber } from './config/firebase';
import MultiboxesInput from './elements/multiboxesInput';
import SmileyIcon from '../assets/images/smiley.svg';
import Logo from '../assets/images/logo.svg';

const CampaignJourneyMobileStep3 = (props) => {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(0);   
    let [response, setResponse] = useState(null);
    const Auth = useContext(AuthContext);
    const Lang = useContext(language);    

    let checkMobileMsg = Lang.checkMobile;
    
    if(props?.values?.values?.payment_status == 0){ //insufficient
      checkMobileMsg = Lang.checkMobile2;
      props.values.values.message =  (
        <>
        <div class="_1-sentence">{Lang.insufficient_credit2_mobile_1}</div>
        <div class="_2-sentence">{Lang.insufficient_credit2_mobile_2}</div>
        <div class="_3-sentence">{Lang.insufficient_credit2_mobile_3}</div>
        <div class="_4-sentence">{Lang.insufficient_credit2_mobile_4}</div>
        </>
        )
    } 
    else{ // success response
      checkMobileMsg = Lang.checkMobile2;
      props.values.values.message = (
        <>
        <div class="_1-sentence">{Lang.insufficient_credit2_mobile_1}</div>
        <div class="_2-sentence">{Lang.insufficient_credit2_mobile_4}</div>
        </>
        )      
    }     
        
    let dataFromPrevStep = props?.values?.values || {};
    const [waitingToResend, setWaitingToResend] = useState(0)
    const [verifier, setVerifier] = useState(null);
    const [confirmationResult, setConfirmationResult] = useState(null);
    const [mobileNumber,setMobileNumber] = useState(dataFromPrevStep?.mobile_prefix && dataFromPrevStep?.mobile ? dataFromPrevStep.mobile_prefix+''+dataFromPrevStep.mobile:'')
    const componentProps = props;
    //const [mobileNumber,setMobileNumber] = useState('+972569212399')
    
 
    

    const generateRecaptch = () => {
      if(dataFromPrevStep?.verifier){
        return dataFromPrevStep?.verifier;
      }
      let verifier = new RecaptchaVerifier('recaptcha-container', {
          'size':'invisible',
          'callback': (res) => {
              
          }
      },firebaseAuth);
      dataFromPrevStep.verifier = verifier;
      return verifier;

  }


    useEffect(()=>{
        if(Auth.isLogged){
            navigate('/');
        } 

        setTimeout(() => {
              let vf = verifier || generateRecaptch();
              setVerifier(vf);

              if(mobileNumber){
                signInWithPhoneNumber(firebaseAuth, mobileNumber, vf).then(res => setConfirmationResult(res))
              }
        },5000);
                
        const advancedMatching = {};    
        const options = {
            autoConfig: true, 
            debug: false, 
          };

        const payment_status = dataFromPrevStep.payment_status;
        if(payment_status == 0){// failed billing
            gtag('event', googleConfig.GOODLE_TAG_SUBSCRIBE_EVENT_FAILED, { 
                'send_to':googleConfig.GOOGLE_TAG_AD_WORD+'/'+googleConfig.GOOGLE_TAG_CONVERSION_ID
            });
            ReactPixel.init(facebookConfig.FACEBOOK_PIXEL_ID, advancedMatching, options);
            ReactPixel.track(facebookConfig.FACEBOOK_PIXEL_SUBSCRIBE_EVENT_FAILED);
        }
        else if(payment_status == 1){//success billing
            gtag('event', googleConfig.GOODLE_TAG_SUBSCRIBE_EVENT_SUCCESS, { 
                'send_to':googleConfig.GOOGLE_TAG_AD_WORD+'/'+googleConfig.GOOGLE_TAG_CONVERSION_ID
            });
            ReactPixel.init(facebookConfig.FACEBOOK_PIXEL_ID, advancedMatching, options);
            ReactPixel.track(facebookConfig.FACEBOOK_PIXEL_SUBSCRIBE_EVENT_SUCCESS);
        }
        else if(payment_status == 2){//billing under processing//
            gtag('event', googleConfig.GOODLE_TAG_SUBSCRIBE_EVENT_UNDER_PROCESSING, { 
                'send_to':googleConfig.GOOGLE_TAG_AD_WORD+'/'+googleConfig.GOOGLE_TAG_CONVERSION_SigupID
            });
            ReactPixel.init(facebookConfig.FACEBOOK_PIXEL_ID, advancedMatching, options);
            ReactPixel.track(facebookConfig.FACEBOOK_PIXEL_SUBSCRIBE_EVENT_UNDER_PROCESSING);
        }    
                               
    },[])
   

    //console.log(props);
    //console.log(dataFromPrevStep);

    const showGewneralMessage = (title='no_title',message='') => {
        title = title || 'no_title';
        props.setMessage({title:title, message:message});
        window.$('#otp-message').modal('show');   
    
      }

      const initValues = {
        code:"",
    };
   

    const onSubmit = (values) => {
          const code = ''+values.code;
          if(code.length <6){
            return;
          }
          setLoading(1);
          setResponse(null);

          try{
          confirmationResult.confirm(code).then(res => {
            //console.log(res);
            if(res.user){ //success
                const uid = res.user.uid;
                setResponse(1);

                /** get url for complete registeration */
                Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded',}).post('getCompleteRegUrl',{...values,token:dataFromPrevStep?.token}).then(res => {
                  //console.log(res);
                  if(res?.data?.status ===1){ //success
                      if(res?.data?.payload?.url){
                        setResponse(1);
                        navigate(res.data.payload.url);
                      }
                  }
                  else if(res?.data?.status ===0){
                    setResponse(0);
                  }
                  setLoading(2);
              }).catch(err => {
                  //console.log(err);
                  //setResponse(-1);
                  setLoading(2);
              })




            }
            else{
              setResponse(0);
              setLoading(2)
            }
        }).catch(err => {
            //console.log(err);
            if(err.code== 'auth/invalid-verification-code'){
              //showGewneralMessage(Lang.error, Lang.invalid_code);
              setResponse(-3);
            }
            else{
              setResponse(-1);
            }
            setLoading(2);
        })


        }
        catch(err){
          setResponse(-4);
          setLoading(2)          
        }


    }
    return(
    <>
      <div className="section-container" style={{"padding": "0"}}> 
        <div className='content-container'>
        <div className="otp-form">
        {/* <h2 className="reg-title" style={{'textAlign':'center', 'paddigBottom': '3%'}}>{Lang.RegisterNewAccount}</h2> */}
         <img src={SmileyIcon} className='smiley-icon' alt="" /> 
         <div className='middle-text'>

        {dataFromPrevStep?.message && <center>{dataFromPrevStep.message}</center>}
        <center class="otp-text" style={{direction: Lang.getSelectedLanguage() =='ar' ? 'rtl' :'ltr'}}>
        {          
            ReplaceTagStringWithElement(checkMobileMsg, 'nav', 
              <a className={waitingToResend==1?'disabled-text':''} onClick={
                ()=>{
                  setWaitingToResend(1);
                  showGewneralMessage('',Lang.processingDots);

                    signInWithPhoneNumber(firebaseAuth, mobileNumber, verifier).then( res => {
                      showGewneralMessage('',Lang.success_send_mobile);
                      setConfirmationResult(res);
                    }).catch(err => {
                      if(err.code=='auth/too-many-requests'){
                        showGewneralMessage('',Lang.too_many_request);
                      }
                      else{
                        showGewneralMessage('',Lang.failed_send_mobile)
                      }
                  });
                    
                }
              }/>, waitingToResend==1 ? <Timer onTimeEnd={()=>setWaitingToResend(0)} showHours={false} minutes={1} seconds={0}/> :''
          )
        }

              <Formik initialValues={initValues} onSubmit={onSubmit} validationSchema={CodeSchema}>
              {(props) => {
                  let {values, errors, touched, handleChange, setErrors, setFieldValue, setValues} = props;
                  if(response == 0){
                    errors.code = 'invalid_code';
                  }
                  else if(response == -1){
                    errors.code = 'data_saved_faild';
                  }
                  else if(response == -2){
                    errors.code = 'code_length_error';
                  }
                  else if(response == -3){
                    errors.code = 'invalid_code';
                  }
                  else if(response == -4){
                    errors.code = 'data_saved_faild';
                  }                   
                  else if(response ==1){
                    errors.code = '';
                  }                  
                  response = null;
                                  

                  return(
                    <Form>
                      <MultiboxesInput id="code" name="code"/>
                          <div className="form-group" style={{"margin": "0", position: 'relative'}}>

                              <div className="reg-field">
                                  {/* <label htmlFor="mobile" className="placeholder-mob">{Lang.pin_code}</label> */}
                                  {/* <CustomInput id="code" name="code" type="text" placeholder=" " className="reg-input" /> */}
                              </div>

                          </div>
                          <div className='wizard-btns-container otp-btns'>
                          <LoadingButton loading={isLoading==1 ?`true`:`false`} type="submit" className="submit-button-auto reg-btn" loading-text="<i class='fa fa-circle-o-notch fa-spin'></i> Processing" >{Lang.verify}</LoadingButton>
                              <button type='button' className="submit-button-auto reg-btn wizard-btn secondary-btn" disabled={isLoading==1 ?true:false} onClick={()=>{ componentProps.dispatchWizard({type:'PREV',data:{values:{...componentProps.dataFromPrevStep}}}) }}>
                                {Lang.back}
                              </button> 
                          </div>
                    </Form>
                  )
              }}
              </Formik>

        </center>
        </div>
        <img src={Logo} alt="" style={{width: '130px'}}/>


        </div>
        </div>
        
        </div>        
    </>
    );
}


export default CampaignJourneyMobileStep3;