import {useState, useContext, useEffect} from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {Form, Formik} from 'formik';
// import CampaignJourneyStep1Schema from './schema/campaignJourneyStep1Schema';
import CampaignJourneyMobileStep1Schema from './schema/campaignJourneyMobileStep1PasswordSchema';
import Elements from './elements/elements';
import {getAge} from '../helpers/functions';
import {AuthContext} from '../context/AuthContext'
import { UserContext as language } from '../context/languageContext';
import config from './config';
import '../assets/css/forms.css';
import Cookies from 'universal-cookie';
import Image from "./elements/image";
import MobileInput from './elements/mobileInput';
import { useFormik } from 'formik';
// import Pic from '../assets/images/theme_images/gir_with_shape.png';

const Pic = config.domain+ "assets/images/theme_images/gir_with_shape.png" ;

const {CustomInput, SelectInput, LoadingButton} = Elements;
var response = null; 
const cookies = new Cookies();

const CampaignJourneyStep1ThemesMobile = (props) => {
    const searchParams = new URLSearchParams(document.location.search);
    const media = require('./config/campMedia.json');

    let campaignType = searchParams.get('campType');
    if(!campaignType || campaignType == undefined || campaignType==0 || campaignType==1){
      campaignType = 1
    }
    let courseSelected = searchParams.get('courseSelected');//view theme based on

    if(!courseSelected || courseSelected == undefined ){
        courseSelected = 'default'
      }    
    let titlteParam1='tmplt_title_'+courseSelected;
    let titlteParam2='tmplt_'+courseSelected;

    let mediaType = media.find(item=>item.key==courseSelected)?.mediaType || 1;
    let media_src = 'src'+mediaType+'_'+courseSelected;

    // console.log(config.imgDns+ "icon/theme_assets/"+media_src+".png");

    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [totalDaysInMonth, setTotalDaysInMonth] = useState(31);
    let [selectedMonth, setSelectedMonth] = useState(0);
    let [selectedYear, setSelectedYear] = useState(0);    
    const Auth = useContext(AuthContext);
    const Lang = useContext(language);
    const adultAge = 18;    
    const noDOB = props?.noDOB || false;
    const containPassword = props?.password || false;
    const currentYear = new Date().getFullYear();

    const [dynamicPrefix,setDynamicPrefix] = useState('962');
    // console.log(Lang[titlteParam1])

    const daysInMonthProccess = (values) => {
        let y =  selectedYear;//values.dob_year;
        let m =  selectedMonth;//values.dob_month;
        let d = 0;
        if(y && m){
            d = new Date(y, m, 0).getDate();
        }

        d = 31;
        //console.log(d)
        setTotalDaysInMonth(d);
    }

    useEffect(()=>{
        if(Auth.isLogged){
            navigate('/');
        } 

        Auth.logTracking('registeration_page');

    },[campaignType])
   
    const dataFromPrevStep = props?.values?.values || [];
    //console.log(dataFromPrevStep);

    const onSubmitRegister = async (values, actions) => {
        setLoading(true);
        let responseData = await Auth.registerCampaignJourneyMobile(values);        
        response = responseData.status;
        //console.log(responseData);
        //console.log(actions);
        //await new Promise((resolve) => setTimeout(resolve, 1000));
        setLoading(false);
        //console.log(response)
        if(responseData.status == 1){
            actions.resetForm();
            values.token = responseData.token;
            values.clientSecret = responseData.clientSecret;
            values.userId = responseData.userId;
            
            props.dispatchWizard({type:'NEXT',data:{values}})
        }
      };   
      
      
      const initValues = {
        dob_day:noDOB ? 1: (dataFromPrevStep.dob_day || ""),
        dob_month:noDOB ? 1: (dataFromPrevStep.dob_month || ""),
        dob_year:noDOB ? (currentYear - 20): (dataFromPrevStep.dob_year || ""),
        no_dob: noDOB ? 1: 0,
        is_password : containPassword ? 1 : 0,
        password : "",
        mobile:(dataFromPrevStep.mobile || "") , 

        mobile_prefix:dataFromPrevStep.mobile_prefix || ('+'+dynamicPrefix) , 
        mobile_prefix_tmp : dataFromPrevStep.mobile_prefix ? dataFromPrevStep.mobile_prefix.replace('+',''): dynamicPrefix,           
    };
    

    const formikProps = useFormik({
        initialValues:initValues,
        validationSchema:CampaignJourneyMobileStep1Schema,
        onSubmit: onSubmitRegister,
        enableReinitialize:true

    })    
    const onChangePrefix = (newPrefix) => {
        setDynamicPrefix(newPrefix)
        formikProps.setFieldValue("mobile_prefix",'+'+newPrefix);
    }    


    return(
      <div className="theme-section" style={{"padding": "0"}}>
        <div className="reg-form camp-theme-form">
        {/* 👇 the below dynamic text from json text */}
        {/* <h2 className="reg-title" style={{'textAlign':'center', 'paddingBottom':'3%'}}>{Lang[titlteParam]}</h2> */}
        <h2 className="camp-title">{(Lang[titlteParam1]) || Lang.tmplt_title1}</h2>
        <p className="camp-text">{(Lang[titlteParam2]) || Lang.tmplt_title2}</p>
        {/* 👇 the below dynamic image or video rendering, if u want to add image call it src1_(coursename).png/jpg
               if video call it src2_(coursename).mp4 */}

         {/* {mediaType == 1 ?
        <Image src={config.imgDns+ "icon/theme_assets/"+media_src+".png"} alt={courseSelected} />
         : 
        <video width="320" height="240" controls><source src={config.imgDns+ "icon/theme_assets/"+media_src+".mp4"} type="video/mp4"/></video>
         }  */}

        <Formik
        enableReinitialize
        initialValues={initValues}
        validationSchema={CampaignJourneyMobileStep1Schema}
        onSubmit={onSubmitRegister}  
        >

{(props) => {
    //console.log(props.values.dob_year);
    let {values, errors, touched, setErrors, setTouched} = props;
    if(response == 3){
        //setTouched({email:true});
        touched.email = true;
        setErrors({'email':'email_registered_before'});
    }      
    response= null;

    return(
        <Form>
  
            <div className={`form-group ${noDOB ? 'hide':''}`} style={{"margin": "0", "width":"100%"}}>
            <div className="col-md-3 date-label" style={{"width":"40%"}}>
                <label>{Lang.date_of_birth+'*'}</label>                
            </div>

<div className='dob_options_container'>
            <div className="col-md-3" style={{paddingRight: '0', width: '30%'}}>
                <SelectInput onChange={(e) => {
                                props.setFieldValue("dob_year",e.target.value);
                                //props.values.dob_year=e.target.value;
                                setSelectedYear(e.target.value);
                                selectedYear = e.target.value;                                
                                daysInMonthProccess(props.values)
                }} name="dob_year" id="dob_year" className="reg-input select-date">
                    <option value={Lang.year} disabled={false}>{Lang.year}</option>
                    { (()=>{
                        let eleArr = [];
                        let currentYear = new Date().getFullYear();
                        for (var i=config.birth_year_from; i<=currentYear; i++){
                            eleArr.push(<option key={i} value={i}>{i}</option>);
                        }
                        return eleArr;
                    })()
                    }

                </SelectInput>                
            </div>            
            <div className="col-md-3" style={{paddingRight: '0', width: '30%'}}>
                <SelectInput onChange={(e) => {
                                props.setFieldValue("dob_month",e.target.value);
                                //props.values.dob_month=e.target.value;
                                setSelectedMonth(e.target.value);
                                selectedMonth = e.target.value;                                
                                daysInMonthProccess(props.values)
                }}  name="dob_month" id="dob_month" className="reg-input select-date">
                    <option value={Lang.month} disabled={false}>{Lang.month}</option>
                    {
                        config.months.map( item => {
                            return (
                                <option key={item.id} value={item.id}>{Lang[item.name]}</option>
                            )
                        })
                    }                                       
                </SelectInput>                
            </div> 
            <div className="col-md-3" style={{paddingRight: '0', width: '30%'}}>
                <SelectInput name="dob_day" id="dob_day" className="select-date reg-input">  
                   <option value={Lang.day} disabled={false}>{Lang.day}</option>
                    {
                        ( ()=>{
                            let eleArr = [];
                            for(var j=1; j<=totalDaysInMonth ;j++){
                                eleArr.push(<option key={j} value={j}>{j}</option>);
                            }
                            return eleArr;
                        })()
                    }
                </SelectInput>                
            </div> 
            </div>            
            </div>                      
            <div className="form-group" style={{"margin": "0"}}>
                <CustomInput autocomplete="off" id="mobile_prefix" name="mobile_prefix" type="number" placeholder=" " className="hide" />
                <div className="reg-field-tel">
                    <label htmlFor="mobile" className="placeholder-mob2">{Lang.mobile_number}</label>
                    <MobileInput disabled={false} selectedVal={initValues.mobile_prefix_tmp} onChangePrefix={onChangePrefix} id="mobile2" name="mobile" type="number" placeholder=" " style={{"direction":"ltr"}} className="reg-input custom-input" />
                    <CustomInput readOnly name="token" id="token" type="hidden" placeholder=" " className="reg-input" />
                    <CustomInput readOnly name="no_dob" id="no_dob" type="hidden" placeholder=" " className="reg-input" />
                </div>
            </div>

            <div className={`form-group ${containPassword ? '':'hide'}`} style={{"margin": "0", "position":"relative"}}>
                <div className="reg-field">
                    <label htmlFor="password">{Lang.password+'*'}</label>
                    <CustomInput id="password" name="password" type="password" placeholder=" " className="reg-input custom-input"/>
                </div>
                <span toggle="#password" className="fa fa-fw fa-eye field-icon toggle-password"></span>
            </div>            
      
        
            <LoadingButton loading={isLoading.toString()} type="submit" className="submit-button-auto reg-btn camp-btn" loading-text="<i class='fa fa-circle-o-notch fa-spin'></i> Processing" >{Lang.subscribe_now}</LoadingButton>
            {campaignType==2 && 
            <a className="redirect-camp" href="/campaign?campType=4">{Lang.reg_by_mob}</a>
            }
    </Form>
          )}}
</Formik>

        </div>
        <div className='camp-pic-box'>
            {/* <Image className="camp-pic" src={Pic} alt={courseSelected} /> */}
            {mediaType == 1 ?
        <Image onError={(e)=>e.target.src=Pic} className="camp-pic" src={config.imgDns+ "icon/theme_assets/"+media_src+".png"} alt={courseSelected} />
         : 
        <video width="320" height="240" controls><source src={config.imgDns+ "icon/theme_assets/"+media_src+".mp4"} type="video/mp4"/></video>
         } 
            </div>
        </div>
    );
}


export default CampaignJourneyStep1ThemesMobile;