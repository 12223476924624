import * as Yup from 'yup';

const CodeSchema = Yup.object().shape({

    code: Yup.string()
            .required("code_required_err")
            //.typeError("numbers_only")
            .test('len', 'code_length_error', val => val?.length >= 4),
            
  })

  export default CodeSchema;