import {useState, useContext, useEffect} from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {Form, Formik} from 'formik';
import CampaignJourneyStep1Schema from '../Components/schema/campaignJourneyStep1Schema';
import Elements from './elements/elements';
import {getAge, ReplaceTagStringWithElement} from '../helpers/functions';
import {AuthContext} from '../context/AuthContext'
import { UserContext as language } from '../context/languageContext';
import config from './config';
import '../assets/css/forms.css';
import Cookies from 'universal-cookie';
import Image from "./elements/image";
import GoogLogin from "./elements/googleLogin";
import {GoogleOAuthProvider} from '@react-oauth/google';
import googleConfig from './config/google.js'
import FbLogin from './elements/facebookLogin';
import SpecialFooter from './sections/specialFooter';
import content from './sections/campThemeData';
import {getDeviceInfo} from '../helpers/deviceInfo';

const GOOGLECLIENTID = googleConfig.GOOGLE_CLIENT_ID;
const Language = localStorage.getItem('lang');

const Pic = config.domain+ "assets/images/theme_images/gir_with_shape.png" ;

const {CustomInput, SelectInput, LoadingButton} = Elements;
var response = null; 
const cookies = new Cookies();

const CampaignJourneyStep1Themes = (props) => {
    const searchParams = new URLSearchParams(document.location.search);
    const media = require('./config/campMedia.json');
    const [isMobile, setIsMobile] = useState(false);
    const [isTablet, setIsTablet] = useState(false);

    let campaignType = searchParams.get('campType');
    let courseSelected = searchParams.get('courseSelected'); //view theme based on

    if(!campaignType || campaignType == undefined || campaignType==0 || campaignType==1){
      campaignType = 1
    }

    if(!courseSelected || courseSelected == undefined ){
        courseSelected = 'default'
      } 

    let titleParam1='tmplt_title1_'+courseSelected;
    let titleParam2='tmplt_title2_'+courseSelected;
    let titleParam3='tmplt_'+courseSelected;
    let tmplt_content = 'tmplt_content_title_'+courseSelected;
    let tmplt_title2_course = "tmplt_title2_"+courseSelected;
    let tmplt_desc = 'tmplt_desc_'+courseSelected;
    let mediaType = media.find(item=>item.key==courseSelected)?.mediaType || 1;
    let media_src = 'src'+mediaType+'_'+courseSelected+'_'+Language;
    let img_poster = courseSelected+'_poster_'+Language;
    let specialFooter = media.find(item=>item.key==courseSelected)?.specialFooter || 0;
    let contentUnderImg = media.find(item=>item.key==courseSelected)?.contentUnderImg || 0;
  
    // console.log(config.imgDns+ "icon/theme_assets/"+media_src+".png");

    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [totalDaysInMonth, setTotalDaysInMonth] = useState(31);
    let [selectedMonth, setSelectedMonth] = useState(0);
    let [selectedYear, setSelectedYear] = useState(0);    
    const Auth = useContext(AuthContext);
    const Lang = useContext(language);
    const adultAge = 18;    
    const noDOB = props?.noDOB || false;
    const containPassword = props?.password || false;
    const currentYear = new Date().getFullYear();
    // console.log(Lang[titleParam1])
   

    const daysInMonthProccess = (values) => {
        let y =  selectedYear;//values.dob_year;
        let m =  selectedMonth;//values.dob_month;
        let d = 0;
        if(y && m){
            d = new Date(y, m, 0).getDate();
        }

        d = 31;
        //console.log(d)
        setTotalDaysInMonth(d);
    }

    useEffect(()=>{
        if(Auth.isLogged){
            navigate('/');
        } 
        getDeviceInfo().then(res => {
            setIsMobile(res?.isMobile);
        }); 
        getDeviceInfo().then(res => {
            setIsTablet(res?.isTablet);
        }); 
        Auth.logTracking('registeration_page');

    },[campaignType])
   
    const dataFromPrevStep = props?.values?.values || [];
    //console.log(dataFromPrevStep);

    const onSubmitRegister = async (values, actions, dispatchWizard = null) => {
        setLoading(true);

        if(!values?.email){
            response = 'email.email_required_err';
            setLoading(false);
            return;
        }
        let responseData = await Auth.registerCampaignJourney(values,'theme_'+courseSelected);        
        response = responseData.status;
        //console.log(responseData);
        //console.log(actions);
        //await new Promise((resolve) => setTimeout(resolve, 1000));
        setLoading(false);
        //console.log(response)
        if(responseData.status == 1){
            if(actions && actions?.resetForm){
                actions.resetForm();
            }
            values.token = responseData.token;
            values.clientSecret = responseData.clientSecret;
            values.userId = responseData.userId;
            






            values.skipCodeCheck = 0;
            if(values?.stk_type ==1 || values?.stk_type ==2){
                values.skipCodeCheck = 1;
            }
            if(dispatchWizard){
                dispatchWizard({type:'NEXT',data:{values}});
            }
            else{
                props.dispatchWizard({type:'NEXT',data:{values}})
            }
            
        }
      };   
      
      
      const initValues = {
        dob_day:noDOB ? 1: (dataFromPrevStep.dob_day || ""),
        dob_month:noDOB ? 1: (dataFromPrevStep.dob_month || ""),
        dob_year:noDOB ? (currentYear - 20): (dataFromPrevStep.dob_year || ""),
        email: (dataFromPrevStep.email || "") || (cookies.get('fb_email')) || "", 
        no_dob: noDOB ? 1: 0,
        is_password : containPassword ? 1 : 0,
        password : "",
    };
    
    // the below "contentUnderImg" checks if there is a content on not
    if(contentUnderImg == 1){
        window.$('.img-data-container').css('border','2px solid #6bcdb2');
        window.$('.img-data-container').css('max-width','400px');

    }else if(contentUnderImg == 0){
        window.$('.img-data-container').css('max-width' , '100%');
        window.$('.img-data-container img').css('box-shadow' , 'none');
        window.$('.img-data-container').css('border' , '0');
        window.$('.img-data-container img').css('border-radius' , '0');
        window.$('.text-under-img').css('display','none');
        if(window.$(window).width() <= 992){
            window.$('.img-data-container').css('width' , '100%');
            window.$('.camp-description').css('display' , 'none');
        }
    }

    return(
        <>
      <div className="theme-section" style={{"padding": "0"}}>
        <section className='step2-section'>
        <div className="reg-form camp-theme-form">
            <div className='camp-step1-form'>
        {/* 👇 the below dynamic text from json text */}
        {/* <h2 className="reg-title" style={{'textAlign':'center', 'paddingBottom':'3%'}}>{Lang[titlteParam]}</h2> */}
        {/* the below commented 2 lines dynamic text */}
        <h2 className="camp-title">{(Lang[titleParam1]) || Lang.tmplt_title1}</h2>
        {/* <h2 className="camp-title">{(Lang[titleParam2]) || ''}</h2> */}
        <p className="camp-text">{ (titleParam3 && Lang[titleParam3]) && ReplaceTagStringWithElement(Lang[titleParam3], 'navlink',
        <span style={{color:'#F39422', fontWeight:'bold'}} />) || Lang.tmplt_title2}</p>
        {/* <h2 className="camp-title">{Lang.tmplt_title1}</h2> */}

        {/* 👇 the below dynamic image or video rendering, if u want to add image call it src1_(coursename).png/jpg
               if video call it src2_(coursename).mp4 */}

         {/* {mediaType == 1 ?
        <Image src={config.imgDns+ "icon/theme_assets/"+media_src+".png"} alt={courseSelected} />
         : 
        <video width="320" height="240" controls><source src={config.imgDns+ "icon/theme_assets/"+media_src+".mp4"} type="video/mp4"/></video>
         }  */}


    {/* {isMobile & isTablet?  */}
    <div className='camp-pic-box mobile-camp-pic-box'>
            {/* <Image className="camp-pic" src={Pic} alt={courseSelected} /> */}
           
        <div className='img-data-container'>
            {mediaType == 1 ?
                <Image onError={(e)=>e.target.src=Pic} className="camp-pic" src={config.imgDns+ "icon/theme_assets/"+media_src+".png"} alt={courseSelected} />
                : 
                <video className='campTheme-video' width="320" height="240" controls poster={config.imgDns+ "icon/theme_assets/"+img_poster+".png"}>
                    <source src={config.imgDns+ "icon/theme_assets/"+media_src+".mp4"} type="video/mp4"/>
                </video>
            }
        </div>
        
        </div>
    {isMobile ?
    <p className="camp-text2" style={{paddingTop: '20px'}}>
        {(Lang[tmplt_title2_course]) || Lang.tmplt_title2_default}
    </p>
    : ' ' }
        <Formik
        enableReinitialize
        initialValues={initValues}
        validationSchema={CampaignJourneyStep1Schema}
        onSubmit={onSubmitRegister}  
        >

    {(props) => {
    //console.log(props.values.dob_year);
    let {values, errors, touched, setErrors, setTouched} = props;
    if(response == 3){
        //setTouched({email:true});
        touched.email = true;
        setErrors({'email':'email_registered_before'});
    }   
    else if(response == 'email.email_required_err'){
        //setTouched({email:true});
        touched.email = true;
        setErrors({'email':'email_required_err'});
    }         
    response= null;

    return(
        <Form>
        <div className="form-group" style={{"margin": "0"}}>
                <GoogleOAuthProvider clientId={GOOGLECLIENTID}>
                    <GoogLogin setLoading={setLoading} successLoginRedirect={'prices'} registerCalback={(email,stk)=> onSubmitRegister({...initValues,email,is_password:0,stk_type:2,stk},{},props.dispatchWizard)}>
                    <div className="reg-button campTheme-byGoogle">                
                        <img src={process.env.PUBLIC_URL + "/assets/images/google-icon.svg"} alt="google" style={{width:'30px'}}  />
                        <p><span>{Lang.reg_by}</span></p>
                    </div>
                    </GoogLogin>
                </GoogleOAuthProvider>
            </div>

            <div className="form-group" style={{"margin": "0"}}>
                <FbLogin className="hide" setLoading={setLoading} getRedirectUrl = {true} registerCalback={(email,stk)=> onSubmitRegister({...initValues,email,is_password:0,stk_type:1,stk},{},props.dispatchWizard)}>
                    <div className="reg-button">                
                        <img src={process.env.PUBLIC_URL + "/assets/images/facebook-icon.svg"} alt="facebook" style={{width:'30px'}} />
                        <p>{Lang.reg_by}</p>
                    </div>
                </FbLogin> 
            </div>

            <div className='camp-hr-line'>
                <span className='reg-by'>{Lang.or}</span>
            </div>

            <div className={`form-group ${noDOB ? 'hide':''}`} style={{"margin": "0", "width":"100%"}}>
            <div className="col-md-3 date-label" style={{"width":"40%"}}>
                <label>{Lang.date_of_birth+'*'}</label>                
            </div>
            <div className='dob_options_container'>
                <div className="col-md-3" style={{paddingRight: '0', width: '30%'}}>
                    <SelectInput onChange={(e) => {
                                    props.setFieldValue("dob_year",e.target.value);
                                    //props.values.dob_year=e.target.value;
                                    setSelectedYear(e.target.value);
                                    selectedYear = e.target.value;                                
                                    daysInMonthProccess(props.values)
                    }} name="dob_year" id="dob_year" className="reg-input select-date">
                        <option value={Lang.year} disabled={false}>{Lang.year}</option>
                        { (()=>{
                            let eleArr = [];
                            let currentYear = new Date().getFullYear();
                            for (var i=config.birth_year_from; i<=currentYear; i++){
                                eleArr.push(<option key={i} value={i}>{i}</option>);
                            }
                            return eleArr;
                        })()
                        }

                    </SelectInput>                
                </div>            
                <div className="col-md-3" style={{paddingRight: '0', width: '30%'}}>
                    <SelectInput onChange={(e) => {
                                    props.setFieldValue("dob_month",e.target.value);
                                    //props.values.dob_month=e.target.value;
                                    setSelectedMonth(e.target.value);
                                    selectedMonth = e.target.value;                                
                                    daysInMonthProccess(props.values)
                                    }}  
                                    name="dob_month" id="dob_month" className="reg-input select-date">
                        <option value={Lang.month} disabled={false}>{Lang.month}</option>
                        {
                            config.months.map( item => {
                                return (
                                    <option key={item.id} value={item.id}>{Lang[item.name]}</option>
                                )
                            })
                        }                                       
                    </SelectInput>                
                </div> 
                <div className="col-md-3" style={{paddingRight: '0', width: '30%'}}>
                    <SelectInput name="dob_day" id="dob_day" className="select-date reg-input">  
                    <option value={Lang.day} disabled={false}>{Lang.day}</option>
                        {
                            ( ()=>{
                                let eleArr = [];
                                for(var j=1; j<=totalDaysInMonth ;j++){
                                    eleArr.push(<option key={j} value={j}>{j}</option>);
                                }
                                return eleArr;
                            })()
                        }
                    </SelectInput>                
                </div> 
            </div>            
            </div>                      
            <div className="form-group" style={{"margin": "0"}}>
                <div className="reg-field">
                    <label htmlFor="email" className='campThemeLabel'>{Lang.enter_your_email+''}</label>
                    <CustomInput name="email" id="email" type="text" placeholder=" " className="reg-input custom-input campThemeInput" />
                    <CustomInput readOnly name="token" id="token" type="hidden" placeholder=" " className="reg-input" />
                    <CustomInput readOnly name="no_dob" id="no_dob" type="hidden" placeholder=" " className="reg-input" />
                </div>
            </div>

            <div className={`form-group ${containPassword ? '':'hide'}`} style={{"margin": "0", "position":"relative"}}>
                <div className="reg-field">
                    <label htmlFor="password" className='campThemeLabel'>{Lang.password+'*'}</label>
                    <CustomInput id="password" name="password" type="password" placeholder=" " className="reg-input custom-input campThemeInput"/>
                </div>
                <span toggle="#password" className="fa fa-fw fa-eye campEye field-icon toggle-password"></span>
            </div>            
      
            <LoadingButton loading={isLoading.toString()} type="submit" className="submit-button-auto reg-btn camp-btn campThemeBtn" loading-text="<i class='fa fa-circle-o-notch fa-spin'></i> Processing" >{Lang.free_sub}</LoadingButton>
            {campaignType==2 && 
            <a className="redirect-camp" href="/campaign?campType=4">{Lang.reg_by_mob}</a>
            }
        </Form>
          )}}
    </Formik>
    {!isMobile ?
    <p className="camp-text2">
        {(Lang[tmplt_title2_course]) || Lang.tmplt_title2_default}
    </p>
    : ' ' }
        </div>
        </div>
        <div className='camp-pic-box'>
            {/* <Image className="camp-pic" src={Pic} alt={courseSelected} /> */}
           
        <div className='img-data-container'>
        { !isMobile ?  
        <>
        {mediaType == 1 ?
        <Image onError={(e)=>e.target.src=Pic} className="camp-pic" src={config.imgDns+ "icon/theme_assets/"+media_src+".png"} alt={courseSelected} />
        : 
        <video className='campTheme-video video-camp' width="320" height="240" controls poster={config.imgDns+ "icon/theme_assets/"+img_poster+".png"}>
            <source src={config.imgDns+ "icon/theme_assets/"+media_src+".mp4"} type="video/mp4"/>
        </video>
         }
         </>
           : '' } 
        <div className='text-under-img'>{(Lang[tmplt_content]) || Lang.tmplt_content_title_default}</div>
        <div className='camp-list-desc'>
        {content.map(({ course, content_titles }, index) => (
        content_titles.map(({ id, title_ar, title_en}, index) => (
            courseSelected == course ? 
         <p className='camptheme-content-title'>{Language == 'ar' ? title_ar : title_en }</p>
         : ''
        ))
        ))}

        {content.map(({ course, content_desc }, index) => (
        content_desc.map(({ id, desc_ar, desc_en}, index) => (
            courseSelected == course ? 
         <ol>
            <span><i class="fa fa-check" aria-hidden="true"></i> </span>
            <li>{Language == 'ar' ? desc_ar : desc_en }</li>
         </ol>
         : ''
        ))
        ))}
        </div>
        </div>
        
        </div>
        </section>
        <p className="camp-description">{Lang[tmplt_desc]} </p>
        </div>
        {specialFooter == 1 ? 
             <SpecialFooter />
            : ''
            }
        
        </>
    );
}


export default CampaignJourneyStep1Themes;