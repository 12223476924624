import {useState, useContext, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import {Form, Formik} from 'formik';
import Elements from './elements/elements';
import {escapedNewLineToLineBreakTag, getAge, htmlBrToReacBr, ReplaceTagStringWithElement} from '../helpers/functions';
import {AuthContext} from '../context/AuthContext'
import { UserContext as language } from '../context/languageContext';
import config from './config';
import '../assets/css/forms.css';
import LoadingButton from './elements/loadingButton';

const CampaignJourneyMobileOtpStep3 = (props) => {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(0);   
    let [response, setResponse] = useState(null);
    const Auth = useContext(AuthContext);
    const Lang = useContext(language);    
    const dataFromPrevStep = props?.values?.values || [];
    const [waitingToResend, setWaitingToResend] = useState(0)
    const [mobileNumber,setMobileNumber] = useState(dataFromPrevStep?.mobile_prefix && dataFromPrevStep?.mobile ? dataFromPrevStep.mobile_prefix+''+dataFromPrevStep.mobile:'')
    //const [mobileNumber,setMobileNumber] = useState('+972569212399')
    //const [searchParams, setSearchParams] = useSearchParams();
    const searchParam = new URLSearchParams(document.location.search);
    const isMobileSurvey = searchParam.get('mobile') || 0;    

    let style={};
    if(Lang.getSelectedLanguage() == 'ar'){
        style = {textAlign:'right', direction:'rtl'};
    }




    useEffect(()=>{
        if(Auth.isLogged){
            navigate('/');
        } 

                


        setLoading(1);
        setResponse(null);        
        //console.log(dataFromPrevStep)
        /** get url for complete registeration */
        Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded',}).post('getSurveyResult',{mobileNumber,token:dataFromPrevStep?.token}).then(res => {
          //console.log(res);
          if(res?.data?.status ===1){ //success
              if(res?.data?.payload?.message){
                setResponse(res?.data?.payload?.message);
              }
          }
          else if(res?.data?.status ===0){
            setResponse('');
          }
          setLoading(2);
        }).catch(err => {
            //console.log(err);
            setResponse('');
            setLoading(2);
        });



                               
    },[])
   

      const initValues = {
        code:"",
    };

    const onSubmit = (values) => {
        props.dispatchWizard({type:'NEXT',data:{dataFromPrevStep}})
    }
    return(
    <>
      <div className="section-container" style={{"padding": "0"}}> 
        <div className='content-container'>
        <div className="reg-form" style={{'maxWidth' : 'unset'}}>

        {isMobileSurvey ? '' : <h2 className="reg-title" style={{'textAlign':'center', 'paddigBottom': '3%'}}>{Lang.RegisterNewAccount}</h2>}

        <div style={style}>
            {isLoading ==1 &&
            <>
            <h3>{Lang.waiting}</h3>
            </>
            }

            {isLoading ==2 &&
            <>
            <h3>{Lang.assessment_result} : </h3>
            <br/>
            <br/>
            <p>{htmlBrToReacBr(response)}</p>
            </>
            }            
        </div>
        <center>

              <Formik initialValues={initValues} onSubmit={onSubmit} >
              {(props) => {
                  let {values, errors, touched, handleChange, setErrors, setFieldValue, setValues} = props;
                                  
                  return(
                    <Form>
                        <div className='wizard-btns-container otp-btns'>
                          <LoadingButton loading={isLoading==1 ?`true`:`false`} type="submit" className="submit-button-auto reg-btn" loading-text="<i class='fa fa-circle-o-notch fa-spin'></i> Processing" >{Lang.continue}</LoadingButton>
                          <button type='button' className="submit-button-auto reg-btn wizard-btn secondary-btn" disabled={isLoading==1 ?true:false} onClick={()=>{ props.dispatchWizard({type:'PREV',data:{values:{...dataFromPrevStep}}}) }}>
                                {Lang.back}
                              </button>                           
                        </div>
                    </Form>
                  )
              }}
              </Formik>

        </center>


        </div>
        </div>
        
        </div>        
    </>
    );
}


export default CampaignJourneyMobileOtpStep3;