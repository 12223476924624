import React, {createContext, useContext, useEffect, useState} from 'react';
import Config from '../Components/config';
import { AuthContext } from './AuthContext';
export const UserContext = React.createContext();


const cssFiles = [
  "style",
  "style2",
]

const Langauge = (props) => {
    //const data = {en:[{hello:'hello'},{test:'taha'}]};

   // let v={hello:'hello from lang file 1',test:'taha'};

   const Auth = useContext(AuthContext);
    
    if(Config.defualtLang==="en")
    {
      // v={about:'About',prices:'Prices',courses:'Courses',login:'Login',register:'Register',
      // content_talents:'Content about talents ( for both children and parents )',
      // accreditations_ceriﬁcations:'Accreditations and Ceriﬁcations','prices':'Prices','course_title':'Course Title','course_description':'Course Description'};

    //let v={hello:'hello from lang file 1',test:'taha'};    
    }
    const setLanguage = async(langPrefix = 'en', reload=true, waiting = true, redirectTo='',callback=null) => {
     const availableLang = ['en','ar'];
     if(!availableLang.includes(langPrefix)){
        langPrefix = 'en';
     }
      localStorage.setItem('lang',langPrefix);
      const v = require('../languages/'+langPrefix+'.json');
      if(callback){
          setUser(v);
          callback();          
      }
      if(Auth.isLogged){
        if(waiting === true){
            await Auth.authorizedRequest({'Content-Type':"application/x-www-form-urlencoded"}).post('setUserLanguage/'+langPrefix);
        }
        else{
          Auth.authorizedRequest({'Content-Type':"application/x-www-form-urlencoded"}).post('setUserLanguage/'+langPrefix);
        }
      }

      if(redirectTo){
        window.location.href=redirectTo;
      }
      if(reload === true){
        window.location.reload();
      }
      return;
      setUser(v);
      setStylePath(cssFiles.map(file => stylePathPrefix+file+'_'+langPrefix+".css"))

    }

    const getSelectedLanguage = () => {
      if(localStorage.getItem('lang')){
        return localStorage.getItem('lang');
      }

      var browserLang = navigator.language || navigator.userLanguage; 
      if(browserLang){                  
        if(browserLang.includes("en-")){ //'en-US' 
          setLanguage('en',false,false);          
          return 'en'
        } 
        else if(browserLang.includes("ar-")){ // ar-sa
          setLanguage('ar',false,false);
          return 'ar';            
        }
      }
    
      return Config.defualtLang;
    }

    const selected = getSelectedLanguage(); 
    const v = require('../languages/'+selected+'.json');
    const [data, setUser] = useState(v);

    const stylePathPrefix = "/assets/css/global/";
    
    const [ stylePath, setStylePath ] = useState(cssFiles.map(file => stylePathPrefix+file+'_'+getSelectedLanguage()+".css"));    


    useEffect(() => {
      var head = document.head;

      var linksArr = [];
      for(var x =0;x<stylePath.length;x++){
        var link = document.createElement("link");
        link.type = "text/css";
        link.rel = "stylesheet";
        link.href = stylePath[x];
        head.appendChild(link);
        linksArr.push(link)
      }      
  

        
  
      return () => { 
        for(var x =0;x<linksArr.length;x++){
          head.removeChild(linksArr[x]); 
        }        
      }
  
    }, [stylePath]);

    
     return (
      <UserContext.Provider value={{...data, setLanguage, getSelectedLanguage}}>
       {props.children}
      </UserContext.Provider>      

  );
}

export default Langauge;