import {Event} from '../../../../enums/Event';
import {WindowEventTracker} from '../../../../utils/WindowEventTracker';
import {EventCallbackDispatcher} from '../../EventCallbackDispatcher';
import {PlayerContext} from '../player/AmazonIVSPlayerContext';
import CollectorEvents from '../utils/CollectorEventBus';

enum State {
  IDLE,
  UNLOADED,
}

export class WindowEventListener {
  private state = State.IDLE;
  constructor(
    private windowEventTracker: WindowEventTracker,
    private dispatcher: EventCallbackDispatcher,
    private playerContext: PlayerContext,
  ) {}

  startMonitoring() {
    this.windowEventTracker.addEventListener('beforeunload', this.onPageUnload);
    this.windowEventTracker.addEventListener('unload', this.onPageUnload);
  }

  private onPageUnload = () => {
    if (this.state != State.IDLE) {
      return;
    }

    this.dispatcher.eventCallback(Event.UNLOAD, {
      currentTime: this.playerContext.position,
    });
    this.state = State.UNLOADED;
    CollectorEvents.RELEASE.trigger();
  };
}
