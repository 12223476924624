import { useState, useContext, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import '../../assets/css/userNavbar.css';
import arrowDown from '../../assets/images/arrow-down.svg' 
import {UserContext as language} from '../../context/languageContext';
import {AuthContext} from '../../context/AuthContext';
import { otherLanguageCourseName } from "../../helpers/functions";
import {getDeviceInfo} from '../../helpers/deviceInfo';

const UserNavbar = (props) => {

    const [status, toggle] = useState('close');
    const lang = useContext(language);
    const langDetect = lang.getSelectedLanguage();

    
    const Auth = useContext(AuthContext);
    //let [introCourses, setCourses] = useState([]);
    const introCourses = props.sharedCourses;
    
    const [suggestions,setSuggestions]=useState([]);
    const [searchTextInput,setSearchText] = useState('');
    const [isDropdown, setDropdown] = useState(false);
    const [isDropdownLang, setDropdownLang] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(()=>{
        getDeviceInfo().then(res => {
            setIsMobile(res?.isMobile);
        }); 
        //ApiHelper.excuteApi(Config.coursesApi,'get','{}',setCourses);
    },[]        
    ) 
    if(typeof(introCourses) == 'string'){
        introCourses = JSON.parse(introCourses);
        }
        const onChangeHandler=(serachValue)=>{
            let matches=[];
            if(serachValue.length>0)
            {
                window.$('.suggestionContainerMob .allSearch').css('height','800px');
                window.$('.sd-body').css('overflow','hidden');
                matches=introCourses.filter(cours=>{
                    const regex=new RegExp(`${serachValue}`,"gi");

                    if(cours.categoryid != -1 && cours.categoryid != -2 && cours.visible==1 && cours.displayname.match(regex))
                    return cours.displayname.match(regex)
                })
        
            } else{
                window.$('.suggestionContainerMob .allSearch').css('height','auto');
                window.$('.sd-body').css('overflow','auto');
                }       
           
            setSearchText(serachValue)
            setSuggestions(matches);
           // console.log(matches);
        }
        const handleOnClickSuggestion=(serachValue)=>
        {
            setSearchText(serachValue);
            setSuggestions([]);
        }
    const userMenuTriggerRef = useRef(null);

    const ToggleDropdown = () => {
        isDropdown === true ? setDropdown(false) : setDropdown(true);
        window.$('.arrowImg').toggleClass('rotate');
        window.$('.submenu-item').on('click', function() {
            window.$('.sidebar').removeClass('active');
            window.$('.sidebar-overlay').removeClass('active');
        });      
    }

    const ToggleDropdownLang = () => {
        isDropdownLang === true ? setDropdownLang(false) : setDropdownLang(true);
        window.$('.arrowImgLang').toggleClass('rotate');
    }

    useEffect(()=>{
        document.onclick = ( (e) => {
          //console.log(userMenuTriggerRef.current.contains(e.target))
      var window_ht = window.$(window).height();
      window.$('.menu.active').css("width", "100%");
      window.$('.menu.active').css("height", window_ht );

          if(userMenuTriggerRef.current && !userMenuTriggerRef.current.contains(e.target)){
            toggle('close');
            window.$('body').css("overflow-x", "hidden");
              }
          else{
          }
        });
    },[])

    return (
        
        <div>
                <a className="side-item user-search">
                <div className="side-search-item" style={{padding: '0px', borderBottom: 'none'}}>
                <div className="mob-search">
                    <form id="se_search-form" style={{display: 'inline'}}>
                        <input id="se_search-box" 
                                size="20" 
                                placeholder={lang.search} 
                                className="se_search-coll" 
                                autoComplete="off" 
                                type="text" 
                                onChange={e=>onChangeHandler(e.target.value)} 
                                name="searchText"
                                value={searchTextInput} 
                                onBlur={()=>{
                                    setTimeout(()=>{
                                    setSuggestions([])
                                    },100);
                                    setSearchText('');
                                    }}/>
                        <input id="se_search-btn" className="se_search-coll" type="button" />
                        <div className="suggestionContainerMob">
                            <div className="allSearch">
                            {suggestions && suggestions.map((sug,i)=>
                            <div onClick={()=>handleOnClickSuggestion(sug.displayname)} className="autoCompleteResults" key={i}>
                            <NavLink  onClick={props.ToggleSidebar} className="menu-link" to={'course/'+sug.id+'/'+sug.displayname?.trim().split(' ').join('-')}>{sug.displayname}</NavLink>
                            </div>)
                            }
                            </div>
                        </div>
                    </form>
                </div>  
                <p className="search-title">{lang.search}</p>
                </div>  
                <hr className="horizontal-separator"></hr>
                </a>
               
                <div className="dropdown-item dropdown-sidenav">
                    <div className="side-icon dropdown">
                    <div className="side-icon">
                        <img className="sidenav-icon" src={process.env.PUBLIC_URL + "/assets/images/courses-icon.svg"} alt={lang.courses} />
                    </div>
                    <div onClick={ToggleDropdown} className="sub-btn">
                        {lang.courses}
                    <div className="side-icon">
                    <img src={arrowDown} className="arrowImg" style={{width: "13px", marginLeft: "6px"}} />
                    </div>
                    </div>
                    <ul className={` dropdownMenu ${isDropdown == true ? 'active' : ''}`}>
                    <li className="submenu-item submenu-i">   
                        <NavLink className="menu-link menu-l" to={'courses'}>{lang.all_courses}</NavLink>
                    </li>
                    {introCourses && introCourses.length > 0 && Array.isArray(introCourses) && introCourses.map((introCoursesObj, index) => {
                                introCoursesObj.displayname = otherLanguageCourseName(introCoursesObj, lang.getSelectedLanguage());
                                if(introCoursesObj.categoryid>0)
                                {

                                return ( 
                                    <li className="submenu-item submenu-i" key={introCoursesObj.id}>   
                                       <NavLink  onClick={props.ToggleSidebar} className="menu-link menu-l" to={'course/'+introCoursesObj.id+'/'+introCoursesObj.displayname?.trim().split(' ').join('-')}>{introCoursesObj.displayname}</NavLink>
                                    </li>

                                    )
                                }
                            }
                                    
                        )}    
                          
                       </ul>
                    </div>
                    <hr className="horizontal-separator"></hr>
                </div>
                <NavLink to="/dashboard" className="side-item sidebar-link user-search">
                <div className="side-icon"><img src={process.env.PUBLIC_URL + "/assets/images/dashboard-icon.svg"} alt={lang.dashboard} /></div>
                <p>{Auth.getAccountType() ==2 ? lang.family_dashboard : lang.dashboard}</p>
                <hr className="horizontal-separator"></hr>
                </NavLink>
                {
                //    Auth.getAccountType() != 3 &&  
                <NavLink to="/profile" className="side-item sidebar-link">
                <div className="side-icon"><img src={process.env.PUBLIC_URL + "/assets/images/profile-icon.svg"} alt={lang.profile} /></div>
                <p>{lang.profile}</p>
                <hr className="horizontal-separator"></hr>
                </NavLink>
                }
                
                {
                 Auth.getAccountType() != 3 && 
                <>
                <NavLink to="/prices" className="side-item sidebar-link prices-link hide-from-web">
                <div className="side-icon"><img src={process.env.PUBLIC_URL + "/assets/images/paymentPlans-icon.svg"} alt={lang.payment_paln} /></div>
                <p>{lang.payment_paln}</p>
                <hr className="horizontal-separator"></hr>
                </NavLink>
                </> 
                }
                {
                    Auth.getAccountType() != 3 &&  
                <NavLink to="/paymentInfo" className="side-item sidebar-link">
                <div className="side-icon"><img src={process.env.PUBLIC_URL + "/assets/images/paymentPlans-icon.svg"} alt={lang.payment_info} /></div>
                <p>{lang.payment_info}</p>
                <hr className="horizontal-separator"></hr>
                </NavLink>
                }                
                {
                    Auth.getAccountType() == 2 &&  
                <NavLink to="/added-children" className="side-item sidebar-link">
                <div className="side-icon"><img src={process.env.PUBLIC_URL + "/assets/images/addedCh-icon.svg"} alt={lang.added_children} /></div>
                <p>{lang.added_children}</p>
                <hr className="horizontal-separator"></hr>
                </NavLink>
                }
                {
                    //Auth.getAccountType() != 3 &&  
                <NavLink to="/notifications-management" className="side-item sidebar-link">
                <div className="side-icon"><img src={process.env.PUBLIC_URL + "/assets/images/notifManage-icon.svg"} alt={lang.notifi_manage} /></div>
                <p>{lang.notifi_manage}</p>
                <hr className="horizontal-separator"></hr>
                </NavLink>
                }
                {
                    //Auth.getAccountType() != 3 &&  
                <NavLink to="/devices" className="side-item sidebar-link">
                <div className="side-icon"><img src={process.env.PUBLIC_URL + "/assets/images/device-icon.svg"} alt={lang.notifi_manage} /></div>
                <p>{lang.saved_devices}</p>
                <hr className="horizontal-separator"></hr>
                </NavLink>
                }                
                {
                   // Auth.getAccountType() != 3 &&  
                <NavLink to="/notifications" className="side-item sidebar-link">
                <div className="side-icon"><img src={process.env.PUBLIC_URL + "/assets/images/notif-icon.svg"} alt={lang.notifications} /></div>
                <p>{lang.notifications}</p>
                <hr className="horizontal-separator"></hr>
                </NavLink>
                }
                <NavLink onClick={Auth.logout} className="logout side-item sidebar-link">
                <div className="side-icon"><img src={process.env.PUBLIC_URL + "/assets/images/logout-icon.svg"} alt={lang.logout} /></div>
                <p>{lang.logout}</p>
                <hr className="horizontal-separator"></hr>
                </NavLink>
            </div>
    )
}

export default UserNavbar;