
import { NavLink } from 'react-router-dom';
import ChatBot from 'react-simple-chatbot';
import { ThemeProvider } from 'styled-components';
import icon from '../assets/images/chatbot-icon.svg';
import '../assets/css/chatbot.css';

let currentLang = 'en';
let keywords = require('../languages/chatbot_en.json');
if(localStorage.getItem('lang') && localStorage.getItem('lang') != 'en'){
    currentLang = localStorage.getItem('lang');
    keywords = require('../languages/chatbot_'+currentLang+'.json');
  }
  let keyword_help = 'Help';

    let steps = [
        {
            id: '0',
            message:keywords['0-1'],
            trigger :'1'
        },         
        {
            id: '1',
            options: [
                { value: 1, label: keywords['1-1'] ,trigger:'1-1'},
                { value: 2, label: keywords['1-2'] ,trigger:'1-2'},
                { value: 3, label: keywords['1-3'] ,trigger:'1-3'},
                { value: 4, label: keywords['1-4'] ,trigger:'1-4'},
                { value: 5, label: keywords['1-5'] ,trigger:'1-5'},
                { value: 6, label: keywords['1-6'] ,trigger:'1-6-1'},
     
            ],
        }, 

        /** general */
        {
            id: '1-1',
            options: [
                 
                { value: 1, label: keywords['1-1-1-q'] ,trigger:'1-1-1'},
                { value: 2, label: keywords['1-1-2-q'] ,trigger:'1-1-2'},
                { value: 3, label: keywords['1-1-3-q'] ,trigger:'1-1-3'},
                { value: 4, label: keywords['1-1-4-q'] ,trigger:'1-1-4'},
                { value: 5, label: keywords['1-1-5-q'] ,trigger:'1-1-5'},
                { value: 6, label: keywords['1-1-6-q'] ,trigger:'1-1-6'},
                { value: 7, label: keywords['1-1-7-q'] ,trigger:'1-1-7'},
                { value: 8, label: keywords['1-1-8-q'] ,trigger:'1-1-8'},
                { value: 9, label: keywords['1-1-9-q'] ,trigger:'1-1-9'},
                { value: 10, label: keywords['0'] ,trigger:'1'},         
     
            ],
        }, 
        { id: '1-1-0', options: [ { value: 1, label: keywords['0'] ,trigger:'1-1'},],},         
        {
            id: '1-1-1',
            message: keywords['1-1-1-a'],
            trigger: '1-1-0'
        }, 
        {
            id: '1-1-2',
            message: keywords['1-1-2-a'],
            trigger: '1-1-0'
        },
        {
            id: '1-1-3',
            message: keywords['1-1-3-a'],
            trigger: '1-1-0'
        },
        {
            id: '1-1-4',
            message: keywords['1-1-4-a'],
            trigger: '1-1-0'
        },
        {
            id: '1-1-5',
            message: keywords['1-1-5-a'],
            trigger: '1-1-0'
        },
        {
            id: '1-1-6',
            message: keywords['1-1-6-a'],
            trigger: '1-1-0'
        },
        {
            id: '1-1-7',
            message: keywords['1-1-7-a'],
            trigger: '1-1-0'
        },
        {
            id: '1-1-8',
            message: keywords['1-1-8-a'],
            trigger: '1-1-0'
        },  
        // {
        //     id: '1-1-9',
        //     message: keywords['1-1-9-a'],
        //     trigger: '1-1-0'
        // }, 
        {
            id: '1-1-9',
            message: keywords['1-6-1-a'],
            trigger: ()=>{
                setTimeout(() => {
                    window.location.href="/ContactUs";
                }, 2000);
            }//'1-6-0'
        },           
        /** end general */


        /**registeration */
        {
            id: '1-2',
            options: [
                 
                { value: 1, label: keywords['1-2-1-q'] ,trigger:'1-2-1'},
                { value: 2, label: keywords['1-2-2-q'] ,trigger:'1-2-2'},
                { value: 3, label: keywords['1-2-3-q'] ,trigger:'1-2-3'},
                { value: 4, label: keywords['0'] ,trigger:'1'},         
     
            ],
        }, 
        { id: '1-2-0', options: [ { value: 1, label: keywords['0'] ,trigger:'1-2'},],},  
        {
            id: '1-2-1',
            message: keywords['1-2-1-a'],
            trigger: '1-2-0'
        }, 
        {
            id: '1-2-2',
            message: keywords['1-2-2-a'],
            trigger: '1-2-0'
        },
        {
            id: '1-2-3',
            message: keywords['1-2-3-a'],
            trigger: ()=>{
                setTimeout(() => {
                    window.location.href="/ContactUs";
                }, 2000);
            }//'1-6-0'
            //trigger: '1-2-0'
        },                               
        /** end registeration */
        


        /**payment */
        {
            id: '1-3',
            options: [
                 
                { value: 1, label: keywords['1-3-1-q'] ,trigger:'1-3-1'},
                { value: 2, label: keywords['1-3-2-q'] ,trigger:'1-3-2'},
                { value: 3, label: keywords['1-3-3-q'] ,trigger:'1-3-3'},
                { value: 4, label: keywords['1-3-4-q'] ,trigger:'1-3-4'},
                { value: 5, label: keywords['1-3-5-q'] ,trigger:'1-3-5'},
                { value: 6, label: keywords['1-3-6-q'] ,trigger:'1-3-6'},
                { value: 7, label: keywords['1-3-7-q'] ,trigger:'1-3-7'},
                { value: 8, label: keywords['1-3-8-q'] ,trigger:'1-3-8'},
                { value: 9, label: keywords['0'] ,trigger:'1'},         
     
            ],
        }, 
        { id: '1-3-0', options: [ { value: 1, label: keywords['0'] ,trigger:'1-3'},],},  
        {
            id: '1-3-1',
            message: keywords['1-3-1-a'],
            trigger: '1-3-0'
        }, 
        {
            id: '1-3-2',
            message: keywords['1-3-2-a'],
            trigger: '1-3-0'
        },
        {
            id: '1-3-3',
            message: keywords['1-3-3-a'],
            trigger: '1-3-0'
        },    
        {
            id: '1-3-4',
            message: keywords['1-3-4-a'],
            trigger: '1-3-0'
        },  
        {
            id: '1-3-5',
            message: keywords['1-3-5-a'],
            trigger: '1-3-0'
        }, 
        {
            id: '1-3-6',
            message: keywords['1-3-6-a'],
            trigger: '1-3-0'
        }, 
        {
            id: '1-3-7',
            message: keywords['1-3-7-a'],
            trigger: '1-3-0'
        }, 
        // {
        //     id: '1-3-8',
        //     message: keywords['1-3-8-a'],
        //     trigger: '1-3-0'
        // }, 
        {
            id: '1-3-8',
            message: keywords['1-6-1-a'],
            trigger: ()=>{
                setTimeout(() => {
                    window.location.href="/ContactUs";
                }, 2000);
            }//'1-6-0'
        },                                                   
        /** end payment */        

        


        /**account */
        {
            id: '1-4',
            options: [
                 
                { value: 1, label: keywords['1-4-1-q'] ,trigger:'1-4-1'},
                { value: 2, label: keywords['1-4-2-q'] ,trigger:'1-4-2'},
                { value: 3, label: keywords['1-4-3-q'] ,trigger:'1-4-3'},
                { value: 4, label: keywords['1-4-4-q'] ,trigger:'1-4-4'},
                { value: 5, label: keywords['1-4-5-q'] ,trigger:'1-4-5'},
                { value: 6, label: keywords['1-4-6-q'] ,trigger:'1-4-6'},
                { value: 7, label: keywords['1-4-7-q'] ,trigger:'1-4-7'},
                { value: 8, label: keywords['1-4-8-q'] ,trigger:'1-4-8'},
                { value: 9, label: keywords['1-4-9-q'] ,trigger:'1-4-9'},
                { value: 10, label: keywords['0'],trigger:'1'},         
     
            ],
        }, 
        { id: '1-4-0', options: [ { value: 1, label: keywords['0'] ,trigger:'1-4'},],},  
        {
            id: '1-4-1',
            message: keywords['1-4-1-a'],
            trigger: '1-4-0'
        }, 
        {
            id: '1-4-2',
            message: keywords['1-4-2-a'],
            trigger: '1-4-0'
        },
        {
            id: '1-4-3',
            message: keywords['1-4-3-a'],
            trigger: '1-4-0'
        },    
        {
            id: '1-4-4',
            message: keywords['1-4-4-a'],
            trigger: '1-4-0'
        },     
        {
            id: '1-4-5',
            message: keywords['1-4-5-a'],
            trigger: '1-4-0'
        },  
        {
            id: '1-4-6',
            message: keywords['1-4-6-a'],
            trigger: '1-4-0'
        },  
        {
            id: '1-4-7',
            message: keywords['1-4-7-a'],
            trigger: '1-4-0'
        },  
        {
            id: '1-4-8',
            message: keywords['1-4-8-a'],
            trigger: '1-4-0'
        },  
        // {
        //     id: '1-4-9',
        //     message: keywords['1-4-9-a'],
        //     trigger: '1-4-0'
        // },   
        {
            id: '1-4-9',
            message: keywords['1-6-1-a'],
            trigger: ()=>{
                setTimeout(() => {
                    window.location.href="/ContactUs";
                }, 2000);
            }//'1-6-0'
        },                                               
        /** end account */          


        


        /**courses */
        {
            id: '1-5',
            options: [
                 
                { value: 1, label: keywords['1-5-1-q'] ,trigger:'1-5-1'},
                { value: 2, label: keywords['1-5-2-q'] ,trigger:'1-5-2'},
                { value: 3, label: keywords['1-5-3-q'] ,trigger:'1-5-3'},
                { value: 4, label: keywords['1-5-4-q'] ,trigger:'1-5-4'},
                { value: 5, label: keywords['1-5-5-q'] ,trigger:'1-5-5'},
                { value: 6, label: keywords['1-5-6-q'] ,trigger:'1-5-6'},
                { value: 7, label: keywords['1-5-7-q'] ,trigger:'1-5-7'},
                { value: 8, label: keywords['1-5-8-q'] ,trigger:'1-5-8'},
                { value: 9, label: keywords['1-5-9-q'] ,trigger:'1-5-9'},
                { value: 10, label: keywords['1-5-10-q'] ,trigger:'1-5-10'},
                { value: 11, label: keywords['1-5-11-q'] ,trigger:'1-5-11'},
                { value: 12, label: keywords['0'] ,trigger:'1'},         
     
            ],
        }, 
        { id: '1-5-0', options: [ { value: 1, label: keywords['0'] ,trigger:'1-5'},],},  
        {
            id: '1-5-1',
            message: keywords['1-5-1-a'],
            trigger: '1-5-0'
        }, 
        {
            id: '1-5-2',
            message: keywords['1-5-2-a'],
            trigger: '1-5-0'
        },
        {
            id: '1-5-3',
            message: keywords['1-5-3-a'],
            trigger: '1-5-0'
        },    
        {
            id: '1-5-4',
            message: keywords['1-5-4-a'],
            trigger: '1-5-0'
        },    
        {
            id: '1-5-5',
            message: keywords['1-5-5-a'],
            trigger: '1-5-0'
        },  
        {
            id: '1-5-6',
            message: keywords['1-5-6-a'],
            trigger: '1-5-0'
        },  
        {
            id: '1-5-7',
            message: keywords['1-5-7-a'],
            trigger: '1-5-0'
        },  
        {
            id: '1-5-8',
            message: keywords['1-5-8-a'],
            trigger: '1-5-0'
        },  
        {
            id: '1-5-9',
            message: keywords['1-5-9-a'],
            trigger: '1-5-0'
        },  
        {
            id: '1-5-10',
            message: keywords['1-5-10-a'],
            trigger: '1-5-0'
        },  
        // {
        //     id: '1-5-11',
        //     message: keywords['1-5-11-a'],
        //     trigger: '1-5-0'
        // },      
        {
            id: '1-5-11',
            message: keywords['1-6-1-a'],
            trigger: ()=>{
                setTimeout(() => {
                    window.location.href="/ContactUs";
                }, 2000);
            }//'1-6-0'
        },                                             
        /** end courses */     


        


        /**other */
        {
            id: '1-6',
            options: [
                 
                { value: 1, label: keywords['1-6-1-q'] ,trigger:'1-6-1'},
                { value: 2, label: keywords['0'] ,trigger:'1'},         
     
            ],
        }, 
        { id: '1-6-0', options: [ { value: 1, label: keywords['0'] ,trigger:'1-6'},],},  
        {
            id: '1-6-1',
            message: keywords['1-6-1-a'],
            trigger: ()=>{
                setTimeout(() => {
                    window.location.href="/ContactUs";
                }, 2000);
            }//'1-6-0'
        },                                   
        /** end other */               
    ];


/*
const steps = [
    {
        id: '0',
        message: 'Hey Geek!',
 
        // This calls the next id
        // i.e. id 1 in this case
        trigger: '1',
    }, 
    {
        id: '1',
 
        // This message appears in
        // the bot chat bubble
        message: 'Please write your username',
        trigger: '2'
    }, 
    {
        id: '2',
 
        // Here we want the user
        // to enter input
        user: true,
        trigger: '3',
    }, 
    {
        id: '3',
        message: " hi {previousValue}, how can I help you?",
        trigger: 4
    }, 
    {
        id: '4',
        options: [
             
            { value: 1, label: 'View Courses' ,trigger:5},
            { value: 2, label: 'About Us' ,trigger:9},
 
        ],
       // end: true,
    },
    {
        id: '5',
        options: [
             
            { value: 1, label: 'Drawing' ,trigger:6},
            { value: 2, label: 'Piano' ,trigger:7},
            { value: 3, label: 'PHP' ,trigger:8},
 
        ],
    },
    {
        id: '6',
        message: " Drawing is a skill that is learned and developed and this video course is designed to give you the knowledge that you need to draw better almost immediately! Anyone can learn how to draw. Each aspect of drawing is broken down into â€œeasy to follow\" and â€œeasy to understand\" segments complete with drawing demonstrations and commentary. All lessons are presented in High Definition! There are a total of 9 videos with an average of 3-5 minutes. The course also includes Earn a Certificate upon completion.",
        trigger:5,        
        //end:true
    }  
    ,
    {
        id: '7',
        message: " Lorem ipsum dolor sit amet consectetur adipiscing elit, posuere nam pellentesque vitae at dictumst, a non neque et felis massa. Blandit luctus feugiat condimentum class nunc platea mollis etiam, molestie inceptos ut elementum nisi ad rutrum ante, nostra ac quam enim vehicula dictumst montes. Eleifend gravida habitant convallis curae aliquam integer, cum felis proin velit neque arcu quam, hac netus nisi euismod pretium.",
        trigger:5,
        //end:true
    } 
    ,
    {
        id: '8',
        message: " With our online \"PHP Tryit\" editor, you can edit the PHP code, and click on a button to view the result.",
        trigger:5, 
        //end:true
    },
    {
        id: '9',
        message: " Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat.",
        trigger:4,
        //end:true
    }     
];
*/
 
// Creating our own theme
const theme = {
    background: 'white',
    headerBgColor: '#F39422',
    headerFontSize: '20px',
    botBubbleColor: '#f2f2f2',
    headerFontColor: 'white',
    botFontColor: 'black',
    userBubbleColor: '#501d93',//'#2ca6f2',
    userFontColor: 'white',
};
 
// Set some properties of the bot
const config = {
    botAvatar: "https://e7.pngegg.com/pngimages/811/700/png-clipart-chatbot-internet-bot-business-natural-language-processing-facebook-messenger-business-people-logo-thumbnail.png",
    floating: true,
};
 
function Chatbot() {
    return (
            <ThemeProvider theme={theme}>
                <ChatBot
 
                    // This appears as the header
                    // text for the chat bot
                    headerTitle={keywords['bot_name']}
                    steps={steps}
                    floatingIcon= {<div className='chatBot-icon'><img src ={icon}/><span>{keyword_help}</span></div> }
                    {...config}
 
                />
            </ThemeProvider>
    );
}
 
export default Chatbot;