import * as Yup from 'yup';

const ResetPasswordSchema = Yup.object().shape({


    password: Yup.string()
            .required("password_required_err")
            .min(8, 'password_min_err')
            .matches(/^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+$/,"password_english_err"),
            //.matches(/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!-#_+])[a-zA-Z0-9!-#_+]+$/,"password_pattern_err"),
    confirm_password: Yup.string().required("password_required_err")
            .oneOf([Yup.ref('password')], 'Password_must_match')
            

  })

  export default ResetPasswordSchema;