export const CourseInitState = {
    details : [],
    modules : [],
    projects:[],
    isEnrolled:-1,
    enrollMessage:'',
    messageTitle:'',
    modulesStatus:0, //0:idle, 1:data returned, 2:loading
    detailsStatus:0, //0:idle, 1:data returned, 2:loading
    projectsStatus:0, //0:idle, 1:data returned, 2:loading
};



const CourseReducer = (state=  CourseInitState, action) => {
    //console.log('+++'+action.type+'+++'+state.projectsStatus+'+++')
    switch (action.type) {
        case 'MODULES_RESPONSE':               
            return {...state, modules:action.data, modulesStatus:1};
            break;
        case 'DETAILS_RESPONSE':               
            return {...state, details:action.data, detailsStatus:1};
            break;     
        case 'PROJECTS_RESPONSE':               
            return {...state, projects:action.data, projectsStatus:1};
            break;                      
        case 'MODULES_LOAING':               
            return {...state, modulesStatus:2};
            break;  
        case 'DETAILS_LOADING':               
            return {...state, detailsStatus:2};
            break;   
        case 'PROJECTS_LOADING':               
            return {...state, projectsStatus:2};
            break;                          
            
            
        default:
            break;
    }

    return state;
}


export default CourseReducer;