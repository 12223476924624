import { Form,Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import * as Yup from 'yup';
import { UserContext } from "../context/languageContext";
import Elements from '../Components/elements/elements';
import img from './images/laptop.svg'
import { AuthContext } from "../context/AuthContext";
import { useFormik } from "formik";

const MobileSchema = Yup.object().shape({
    mobile: Yup.string().required('mobile_required_err').matches(/^\d+$/, 'mobile_err'),
})

const {CustomInput, LoadingButton, MobileInput} = Elements;

const SurveyEndMobile = ({dispatchWizard,values,questions}) => {

    const language = useContext(UserContext);    
    const Auth = useContext(AuthContext);   
    const [response,setResponse] = useState("");
    const [loading,setLoading] = useState(0);
    let currentLang = language.getSelectedLanguage();
    const [dynamicPrefix,setDynamicPrefix] = useState('962');


    useEffect(() => {

        Auth.getCountryPrefix().then(res => {
            setDynamicPrefix(res.data.payload.prefix || '962');
        }).catch(err => {
            setDynamicPrefix('962');
        });   

    },[])


    const handleSubmit = (values) => {        
        // dispatchWizard({type:'NEXT',data:{}}); 
        let surveyJson = questions.questions.map(q => 
            ({                
                answers :   q.answers.filter(a => a?.selected == true).map(a => a.text),
                question : q.text,
                desc : q.desc,
                isMultipleAnswers:q.isMultipleAnswers
            })
        );

        setLoading(1);        
        Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'},{},'survey').post('index',{survey:surveyJson, mobile:values.mobile_prefix_tmp+''+(parseInt(values.mobile)), language:currentLang}).then( res => {
            setResponse(res.data.payload);
            if(res.data.status == 1){

                setTimeout(() => {
                    window.location.href = '/campaign?campType=8&mobile='+(parseInt(values.mobile))+'&prefix='+values.mobile_prefix_tmp+(res.data?.payload?.promocode !='' ? '&promocode='+res.data.payload.promocode : '');
                }, 8000);

            }
            setLoading(2);
        }).catch( err => {
            setLoading(2)
        });
        //console.log(values.mobile);       
    }

    const initValues = {
        mobile:"" , 
        mobile_prefix: ('+'+dynamicPrefix) , 
        mobile_prefix_tmp : dynamicPrefix,        
    };

    const formikProps = useFormik({
        initialValues:initValues,
        validationSchema:MobileSchema,
        onSubmit: handleSubmit,
        enableReinitialize:true

    })     
    const onChangePrefix = (newPrefix) => {
        setDynamicPrefix(newPrefix)
        formikProps.setFieldValue("mobile_prefix",'+'+newPrefix);
    }


    return (
        <>
        { !response &&
        <div className="survey-question-body">
        <img className="survey-form-img" src={img} alt={language.survey_end_1_mobile} />
        <h1>{language.survey_end_1_mobile}<br/>{language.survey_end_2_mobile}</h1>
        <div className="intro-int" style={{width:'100%', maxWidth:'700px', margin: '50px auto'}}>
        <Formik
             enableReinitialize
             initialValues={initValues}
             validationSchema={MobileSchema}
             onSubmit={handleSubmit} >
            <Form>
                <div className="form-group" style={{"margin": "0"}}>
                    <div className="reg-field survey-field">


                    <CustomInput autocomplete="off" id="mobile_prefix" name="mobile_prefix" type="number" placeholder=" " className="hide" />
                    {/* <CustomInput autocomplete="off" type="number" onKeyUp={(e) => {if(e.target.value.length ==3){document.getElementById('mobile').focus()};}} onChange={(e)=>{setFieldValue("mobile_prefix_tmp",e.target.value);setFieldValue("mobile_prefix",'+'+e.target.value);}} id="mobile_prefix_tmp" name="mobile_prefix_tmp" maxLength={3} style={{backgroundImage:'unset',top:'0'}} className="form-select camp-prefix" />  */}
                    <div className={`reg-field-tel `}>
                        <label htmlFor="mobile" className="placeholder-mob2">{language.mobile_number}</label>
                        <MobileInput selectedVal={values.mobile_prefix_tmp} onChangePrefix={onChangePrefix} id="mobile2" name="mobile" type="number" placeholder=" " style={{"direction":"ltr"}} className="reg-input custom-input" />
                    </div>


                    </div>
                </div>
                <LoadingButton loading={loading == 1 ? 'true' : 'false'} type="submit" className="submit-button-auto reg-btn submit-survey">{language.continue}</LoadingButton>
            </Form>            
        </Formik>
        </div>
        </div>
        }
        { loading == 2 &&
        <div className="survey-question-body">
        <h1 style={{'paddingTop':'10%'}}>{language.survey_mobile_finish_msg}</h1>
        </div>
        }        
        </>
    );
}

export default SurveyEndMobile