import React from 'react';
import { useEffect, useState, useContext, useRef } from "react";
import {UserContext} from '../../context/languageContext'
import {loadCaptionFiles} from "../../helpers/functions"
// import { AzureMP } from 'react-azure-mp'
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import config from '../config';
import BitmovinPlayer from './bitmovinPlayer';
import CustomVideoPlayer from './CustomVideoPlayer/CustomVideoPlayer';
const VideoPlayer = (props) => {
    const lang = React.useContext(UserContext);
    const isCompleted = props?.isCompleted || false;
    const autoplay = props?.autoplay == true || false;
    const moduleId = props?.moduleId || 0;
    const courseId = props?.courseId || 0;
    const markAsCompleted = props?.markAsCompleted || (()=>'');   
    const [currentTime, setCurrentTime] = useState(null);
    const videoEl = useRef(null);
    const signsVideoEl = useRef(null);
    const captionFilesEn = props?.captionEn || '';//loadCaptionFiles(props.url,'en');    
    const signsVideoSrc = props.signsVideoSrc;
    const timeoutIdRef = useRef();
    let currentLang = lang.getSelectedLanguage();

    const {id, courseId:courseIdFromParam, courseName, videoLang} = useParams([]);
    if(videoLang && videoLang !== undefined){
      currentLang = videoLang;
    }
    const navigate = useNavigate();
  
    //console.log(actionTaken)
      useEffect(() => {
        if (videoEl) {

        }
    
        return () => {};
      }, []);

    const handleLoadedMetadata = () => {
        //console.log(videoEl)
        const video = videoEl.current;
        const signsVideo = signsVideoEl.current;
        if (!video) return;
        //console.log(`The video is ${video.duration} seconds long.`);


        video.ontimeupdate = () => {
            const duration = video.duration
            const current = video.currentTime
            const fixedPercantage = 0.8;
            if(current/duration >= fixedPercantage){
                markAsCompleted(moduleId,courseId);
            }
        }

        video.onended = () => {
            props.playNext();
        }

        if(signsVideoSrc){

                video.onplay = () => {
                    signsVideo.play();
                //  alert(6);
                }
                video.onpause = () =>{
                    signsVideo.pause();
                //  alert(7);
                }
        }
        
        
      };
      const onProgress = (event,player) => {
        //

        const durationV = player.cache_.duration
        const currentV = player.currentTime()  
        onProgressShared(currentV, durationV);
        
      }

      const onProgressShared = (currentTime, duration) => {
          //console.log(currentTime)
          //console.log(duration)             
          const fixedPercantage = 0.75;
          const maxfixedPercantage = 0.87;
          if((currentTime/duration) >= fixedPercantage && (currentTime/duration) <= maxfixedPercantage){
                  //console.log(" mark course as completed "+moduleId+" 0000 "+courseId);
                  markAsCompleted(moduleId,courseId);
          }
      }

      const onEnd = () => {
        //
        //console.log("ended")
        //console.log(" mark course as completed "+moduleId+" 0000 "+courseId);
        markAsCompleted(moduleId,courseId);
        props.playNext();
        
      }      
      const onInstanceCreated = (player) => {
        if (player) {
          //{ console.log(player.cache_)}
           player.on("progress", event => onProgress(event, player));
           player.on("ended", onEnd);
        
        }
      };
      var urlsSpliter=props.url
      urlsSpliter=urlsSpliter.split('&token=');
      urlsSpliter=urlsSpliter[0]
      console.log(urlsSpliter)
    return (
        
        <>
            <div className="module-contents">
                <div className={`mark-complete-btn`}>
                    <button onClick={()=>markAsCompleted(moduleId,courseId)} className={`btn btn-success ${(isCompleted==true || isCompleted==-1) && 'hide'}`}>Mark as complete</button>
                    <button className={`btn btn-success ${(!isCompleted || isCompleted==-1) && 'hide'}`}>completed</button>
                </div>

                {/* { currentLang == 'en' ? (
                <div>
                <div class="module-lang-swicher"><span className='active-module-lang'>{lang.english}</span></div>
                <div className="module-lang-swicher" onClick={()=> window.location.href = localStorage.getItem('basename')+'/courseModules/'+moduleId+'/'+courseId+'/'+props.title+'/ar'}><span>{lang.ar}</span></div>
                </div>
                ):(
                <div>
                <div class="module-lang-swicher" onClick={()=> window.location.href = localStorage.getItem('basename')+'/courseModules/'+moduleId+'/'+courseId+'/'+props.title+'/en'}><span>{lang.english}</span></div>
                <div className="module-lang-swicher"><span className='active-module-lang'>{lang.ar}</span></div>
                </div>
                  ) 
                } */}

                { signsVideoSrc &&
                <video ref={signsVideoEl} class="signs-video"  muted>
                {/* <source src={`https://www.w3schools.com/html/mov_bbb.mp4`} type="video/mp4"  /> */}
                <source src={signsVideoSrc} type="video/mp4"  />
                </video>
                }

                {/*
                {props.url}
                 type="video/mp4"
                    https://beecellmedia-euno.streaming.media.azure.net/c57bffcb-0f08-4a62-a9e9-091d206ebe26/IntroforMawahebSFX-v2.4.ism/manifest(format=m3u8-cmaf)" type="application/vnd.ms-sstr+xml"
                */}
                {/* <video autoPlay={autoplay}  key={props.url}  width="320" height="240" controls id={props.id} ref={videoEl} onLoadedMetadata={handleLoadedMetadata} crossOrigin="use-anonymous">         
                <track   label="English"   kind="subtitles"   srcLang="en"   src={captionFilesEn}    />                
                <track   label="Arabic"   kind="subtitles"   srcLang="ar"   src={captionFilesAr}  default />                
                <source  src="https://beecellmedia-euno.streaming.media.azure.net/c57bffcb-0f08-4a62-a9e9-091d206ebe26/IntroforMawahebSFX-v2.4.ism/manifest(format=m3u8-cmaf)" type="application/vnd.ms-sstr+xml"  />
                Your browser does not support the video tag.
                </video> */}
                {/* {
                  config?.useBitmovinPlayer ? 
                    <BitmovinPlayer onFinish={onEnd} onProgress={onProgressShared} className="landing-video" src={urlsSpliter} poster={config.imgDns+"icon/intro_poster.png"}/>
                  :
                    <AzureMP  onInstanceCreated={onInstanceCreated} options={{controls: true, autoplay: false, hotKeys: {enableVolumeScroll: false}}} src={[{src: props.url, type: "application/vnd.ms-sstr+xml" }]}></AzureMP>
                } */}
                {
                    //<BitmovinPlayer onFinish={onEnd} onProgress={onProgressShared} className="landing-video" src={urlsSpliter} poster={config.imgDns+"icon/intro_poster.png"}/>
                    <CustomVideoPlayer className="landing-video" subTitleLang={'en'}  poster={props?.cover || config.imgDns+"icon/intro_poster.png"} src={urlsSpliter} subTitleFile={captionFilesEn} showTranscript={false} onProgress={onProgressShared} onFinish={onEnd}/>
                }
                {/* progress area 
                {timeoutIdRef.current=setTimeout(() => {console.log("ok video");},1000)}*/}
                
                {/* {<iframe frameBorder="0" width="640" height="400" src={config.serverDomain+"azurePlayer.php?url=https://beecellmedia-euno.streaming.media.azure.net/d69bbecb-2db5-4137-9401-f10086b1f54b/Drawing_Episode_01.ism/manifest(format=m3u8-cmaf)"}></iframe>} */}
            </div>
        </>
    )
}


export default VideoPlayer