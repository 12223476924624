import { useContext, useEffect, useMemo, useState } from "react";
import { UserContext } from "../context/languageContext";
import { AuthContext } from "../context/AuthContext";
const statuses = ['Failed','Success','','Rejected'];
const UserBillingHistory = (props) => {

    const Lang = useContext(UserContext);
    const Auth = useContext(AuthContext);

    const [data,setData] = useState([]);
    const [isLoading,setLoading] = useState(true);

    const getData = () => {
        setLoading(true);
        Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('userBillingHistory').then(res => {            
            let reponse = res.data.payload;
            console.log(reponse.data)
            setData(reponse.data)        
            setLoading(false);
        }).catch(err => {
            setLoading(false);
        })

    }
      
    useEffect(()=>{
        getData();
    },[])
        //should be memoized or stable
        const columns = useMemo(
          () => [
            {accessorKey: 'user_action',header: 'Action',},
            {accessorKey: 'user_action',header: 'Action',},
          ],
          [],
        );

    return (
        <div className="section-container">
                <div className="content-container" style={{width:'70%'}}>

                    <div className='notif-top'>
                        <img className='svg-logo2' src={process.env.PUBLIC_URL + "/assets/images/payment_plan.svg"} alt={Lang.billing_history} /> 
                        <p className='notif-title'>{Lang.billing_history}</p>
                    </div> 

                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>{Lang.action}</th>
                                <th>{Lang.plan}</th>
                                <th>{Lang.amount}</th>
                                <th>{Lang.promo_code}</th>
                                <th>{Lang.promo_value}</th>
                                <th>{Lang.status}</th>
                                <th>{Lang.date}</th>
                            </tr>
                        </thead>
                        {
                            isLoading ? <tr><td colSpan={7}>{Lang.loading}</td></tr> : (
                                data?.length ==0 ? <tr><td colSpan={7 }>{Lang.no_results}</td></tr> : (
                                    data?.map(item => (
                                        <tr>
                                            <td>{item.user_action}</td>
                                            <td>{item.plan_name}</td>
                                            <td>{(parseFloat(item.amount)/100)}$</td>
                                            <td>{item.promo_code}</td>
                                            <td>{item.promo_value}</td>
                                            <td>{item.completed==0 ? Lang.pending : (item.synced ==0 ? Lang.pending :statuses[item.status])}</td>
                                            <td>{item.inserted_date}</td>
                                        </tr>
                                    ))
                                )
                            )
                        }
                    </table>
                </div>        
        </div>
    );
}

export default UserBillingHistory;