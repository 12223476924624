import axios from "axios";
import {
    deviceType,
    osName,
    osVersion,
    browserName,
    browserVersion,
    engineName,
    engineVersion,
    mobileVendor,
    mobileModel,
    isMobile
} from 'react-device-detect';

async function getIpAddress(){
    //let res = await axios.get("https://api.ipify.org/?format=json");
    let ip = ''//res?.data?.ip || '';
    return ip;
}
export const getDeviceInfo = async(stringify=false) => {
    let ipAddress = await getIpAddress();

    let info = {deviceType, osName, osVersion, browserName, browserVersion, engineName, engineVersion, mobileModel, mobileVendor, ipAddress, isMobile }; 

    if(stringify){
        return JSON.stringify(info);
    }
    return info;
}

