import {useState, useContext, useEffect} from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {Form, Formik} from 'formik';
import CampaignJourneyStep1Schema from './schema/campaignJourneyStep1Schema';
import Elements from './elements/elements';
import {getAge} from '../helpers/functions';
import {AuthContext} from '../context/AuthContext'
import { UserContext as language } from '../context/languageContext';
import config from './config';
import '../assets/css/forms.css';
import Cookies from 'universal-cookie';

const {CustomInput, SelectInput, LoadingButton} = Elements;
var response = null; 
const cookies = new Cookies();
const UserRegistrationStep2Email = (props) => {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [totalDaysInMonth, setTotalDaysInMonth] = useState(31);
    let [selectedMonth, setSelectedMonth] = useState(0);
    let [selectedYear, setSelectedYear] = useState(0);    
    const Auth = useContext(AuthContext);
    const Lang = useContext(language);
    const adultAge = 18;    
    const noDOB = true;
    const containPassword = false;
    const currentYear = new Date().getFullYear();
   
    const daysInMonthProccess = (values) => {
        let y =  selectedYear;//values.dob_year;
        let m =  selectedMonth;//values.dob_month;
        let d = 0;
        if(y && m){
            d = new Date(y, m, 0).getDate();
        }

        d = 31;
        //console.log(d)
        setTotalDaysInMonth(d);
    }


    useEffect(()=>{
        if(Auth.isLogged){
            navigate('/');
        } 

        // Auth.logTracking('registeration_page');

    },[])
   
    const parentProps = props?.parentProps;
    const dataFromPrevStep = parentProps?.values?.values || [];
    //console.log(dataFromPrevStep);

    const onSubmitRegister = async (values, actions) => {
        setLoading(true);
        let responseData = await Auth.registerEmailNewFlow(values);        
        response = responseData.status;
        //console.log(responseData);
        //console.log(actions);
        //await new Promise((resolve) => setTimeout(resolve, 1000));
        setLoading(false);
        //console.log(response)
        if(responseData.status == 1){
            actions.resetForm();
            values.token = responseData.token;
            values.userId = responseData.userId;
            
            parentProps.dispatchWizard({type:'NEXT',data:{values}})
        }
      };   
      
      
      const initValues = {
        dob_day:noDOB ? 1: (dataFromPrevStep.dob_day || ""),
        dob_month:noDOB ? 1: (dataFromPrevStep.dob_month || ""),
        dob_year:noDOB ? (currentYear - 20): (dataFromPrevStep.dob_year || ""),
        email: (dataFromPrevStep.email || "") || (cookies.get('fb_email')) || "", 
        no_dob: noDOB ? 1: 0,
        is_password : containPassword ? 1 : 0,
        password : "",
    };
    


    return(
        <>
        <div id="userReg-overlay"></div>    
        <div className='userReg-section'>
        <NavLink className="close-userReg" to="/"><span className="fa fa-fw fa-times" style={{color:'black'}}></span></NavLink>
        <div className="reg-form" style={{maxWidth:'400px', width:'90%'}}>
        <h2 className="reg-title" style={{'textAlign':'center', 'paddingBottom':'3%'}}>{Lang.RegisterNewAccount}</h2>


        <Formik
        enableReinitialize
      initialValues={initValues}
      validationSchema={CampaignJourneyStep1Schema}
      onSubmit={onSubmitRegister}  
    >

{(props) => {
    //console.log(props.values.dob_year);
    let {values, errors, touched, setErrors, setTouched} = props;
    if(response == 3){
        //setTouched({email:true});
        touched.email = true;
        setErrors({'email':'email_registered_before'});
    }      
    response= null;

    return(
        <Form>
  
            <div className={`form-group ${noDOB ? 'hide':''}`} style={{"margin": "0", "width":"100%"}}>
            <div className="col-md-3 date-label" style={{"width":"40%"}}>
                <label>{Lang.date_of_birth+'*'}</label>                
            </div>

<div className='dob_options_container'>
            <div className="col-md-3" style={{paddingRight: '0', width: '30%'}}>
                <SelectInput onChange={(e) => {
                                props.setFieldValue("dob_year",e.target.value);
                                //props.values.dob_year=e.target.value;
                                setSelectedYear(e.target.value);
                                selectedYear = e.target.value;                                
                                daysInMonthProccess(props.values)
                }} name="dob_year" id="dob_year" className="reg-input select-date">
                    <option value={Lang.year} disabled={false}>{Lang.year}</option>
                    { (()=>{
                        let eleArr = [];
                        let currentYear = new Date().getFullYear();
                        for (var i=config.birth_year_from; i<=currentYear; i++){
                            eleArr.push(<option key={i} value={i}>{i}</option>);
                        }
                        return eleArr;
                    })()
                    }

                </SelectInput>                
            </div>            
            <div className="col-md-3" style={{paddingRight: '0', width: '30%'}}>
                <SelectInput onChange={(e) => {
                                props.setFieldValue("dob_month",e.target.value);
                                //props.values.dob_month=e.target.value;
                                setSelectedMonth(e.target.value);
                                selectedMonth = e.target.value;                                
                                daysInMonthProccess(props.values)
                }}  name="dob_month" id="dob_month" className="reg-input select-date">
                    <option value={Lang.month} disabled={false}>{Lang.month}</option>
                    {
                        config.months.map( item => {
                            return (
                                <option key={item.id} value={item.id}>{Lang[item.name]}</option>
                            )
                        })
                    }                                       
                </SelectInput>                
            </div> 
            <div className="col-md-3" style={{paddingRight: '0', width: '30%'}}>
                <SelectInput name="dob_day" id="dob_day" className="select-date reg-input">  
                   <option value={Lang.day} disabled={false}>{Lang.day}</option>
                    {
                        ( ()=>{
                            let eleArr = [];
                            for(var j=1; j<=totalDaysInMonth ;j++){
                                eleArr.push(<option key={j} value={j}>{j}</option>);
                            }
                            return eleArr;
                        })()
                    }
                </SelectInput>                
            </div> 
            </div>            
            </div>  
            <div className="form-group hide" style={{"margin": "0"}}>
            <div className={`by-mob radio-card-box selected`} >
                <div className='radio-card-img'><i className='fa fa-facebook'></i></div>
                <div className='radio-card-text'>{Lang.using_facebook}</div>
            </div> 
            </div>     

            <div className="form-group" style={{"margin": "0"}}>
            <div className={`by-mob radio-card-box selected`} >
                <div className='radio-card-img'><i className='fa fa-google'></i></div>
                <div className='radio-card-text'>{Lang.using_google}</div>
            </div> 
            </div>                      

            <div className="form-group hide" style={{"margin": "0"}}>
            <div className={`by-mob radio-card-box selected`} >
                <div className='radio-card-img'><i className='fa fa-mobile'></i></div>
                <div className='radio-card-text'>{Lang.using_mobile}</div>
             </div>    
             </div>  
                       
                   
            <div className="form-group" style={{"margin": "0"}}>
                <div className="reg-field">
                    <label htmlFor="email">{Lang.enter_your_email+''}</label>
                    <CustomInput name="email" id="email" type="text" placeholder=" " className="reg-input userReg-input" />
                    <CustomInput readOnly name="no_dob" id="no_dob" type="hidden" placeholder=" " className="reg-input" />
                </div>
                
                <button type='button'class="close-fa-times" disabled={isLoading==1 ?true:false} onClick={()=>{ parentProps.dispatchWizard({type:'PREV',data:{values:{...parentProps.dataFromPrevStep}}}) }}>
                <span className="fa fa-fw fa-times field-icon"> </span>
                </button> 
               
            </div>

            <div className={`form-group ${containPassword ? '':'hide'}`} style={{"margin": "0", "position":"relative"}}>
                <div className="reg-field">
                    <label htmlFor="password">{Lang.password+'*'}</label>
                    <CustomInput id="password" name="password" type="password" placeholder=" " className="reg-input custom-input"/>
                </div>
               
                <span toggle="#password" className="fa fa-fw fa-eye field-icon toggle-password" ></span>
            </div>            
      
            <div className='wizard-btns-container'>
                {/* <button type='button' className="submit-button-auto reg-btn wizard-btn secondary-btn" disabled={isLoading==1 ?true:false} onClick={()=>{ parentProps.dispatchWizard({type:'PREV',data:{values:{...parentProps.dataFromPrevStep}}}) }}>
                {Lang.back}
                </button>          */}
                <LoadingButton loading={isLoading.toString()} type="submit" className="submit-button-auto reg-btn" loading-text="<i class='fa fa-circle-o-notch fa-spin'></i> Processing" >{Lang.continue}</LoadingButton>
            </div>
    </Form>
          )}}
</Formik>

        </div>
        </div>
        </>
    );
}


export default UserRegistrationStep2Email;