import '../../assets/css/childCard.css'
import './style.css'
import ChildAvatar from '../../assets/images/child-avatar.png'
const ChildCardLoader = (props) => {
    const total = props.total || 1;
    const elements = [];
    for(var x=0;x<total;x++){
        elements.push(
            <div className="col-xl-6 col-lg-6 col-md-12 is-loading">
            <div className="child-card profile-header">
                <div className="body">
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-12">
                            <div className="circle">  </div>
                        </div>
                        <div className="col-lg-8 col-md-8 col-12">
                            <h4 className="m-t-0 m-b-0 shine"></h4>
                            <div className="shine half"></div>
                            <div className="shine half"></div>
                            <div className="shine half"></div>
                            <div className='m-t-5 child-btns'>
                                <button className='shine'></button>
                                <button className='shine'></button>
                            </div>

                        </div>                
                    </div>
                </div>                    
            </div>
        </div>  
        );
    }
    return (elements)
}

export default ChildCardLoader