
import {useState, useContext, useEffect, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import {Form, Formik} from 'formik';
import ParentProfileSchema from '../../Components/schema/parentProfileSchema';
import AdultProfileSchema from '../../Components/schema/adultProfileSchema';
import Elements from '../elements/elements';
import {errorFocus, getAge, scrollTo, subtractYears} from '../../helpers/functions';
import {AuthContext} from '../../context/AuthContext'
import { UserContext as language } from '../../context/languageContext';
import { ProfileContext } from '../../context/ProfileContext';
import MessageModal from '../modals/messageModal';
import FileUploadBtn from '../elements/fileUploadBtn';
import loadinImg from '../../assets/images/Loading_icon.gif'
import defaultImage from '../../assets/images/profile-default.png';
import config from '../config';
import {editProfile} from '../../helpers/functions';
import ConfirmModal from '../modals/confirmModal';

const {CustomInput, DateInput, SelectInput, RadioInput, LoadingButton} = Elements;
var responseStatus = null;
var response = null;

const AdultProfile = (props) => {

    const [removeStatus, setRemoveStatus] = useState('idle');
    const [isLoading, setLoading] = useState(false);    
    const [userData, setUserData] = useState([]);
    const [message, setMessage] = useState({title:"", body:""});
    const [startDate, setStartDate] = useState(new Date());
    const [profileImg, setProfileImg] = useState(loadinImg);
    const Auth = useContext(AuthContext);
    const Profile = useContext(ProfileContext)
    const Lang = useContext(language);
    const imgref = useRef(null)
    const adultAge = 18; 
    const [totalDaysInMonth, setTotalDaysInMonth] = useState(31);
    const dob_month = useRef(null);
    const [username, setUsername] = useState(Auth.getUserName(false));
    const [isEdit, setEdit] = useState(false);
    const applyNewPriceCardFeatures = config?.applyNewPriceCardFeatures || false;
    const [profileTitle, setProfileTitle] = useState(applyNewPriceCardFeatures ? '' : Lang.adult_profile);

    const searchParams = new URLSearchParams(document.location.search);
    const focus = searchParams.get('focus');    
    const focusOn = focus || '';    
  
    const daysInMonthProccess = (values) => {
        let y =  values.dob_year;
        let m =  values.dob_month;
        let d = 0;
        if(y && m){
            d = new Date(y, m, 0).getDate();
        }

        d = 31;
        //console.log(d)
        setTotalDaysInMonth(d);
    }

    useEffect(()=>{
        Profile.getProfileData().then((res)=>{
            if(res.data?.payload){
                setUserData(res.data.payload);
                setStartDate(new Date(res.data.payload.dob))
                setProfileImg(res.data.payload.profile_img)
                daysInMonthProccess({dob_year:res.data?.payload?.dob?.year, dob_month:res.data?.payload?.dob?.month});
                if(applyNewPriceCardFeatures){
                    if(res.data.payload?.profileTypeTitle){
                        setProfileTitle(Lang[res.data.payload.profileTypeTitle+'_profile'])
                    }
                    else{
                        setProfileTitle(Lang.profile);                    
                    }
                }                
            }

        }).catch((err)=>{

        });

        if(focus){
            editProfile(setEdit); // to make feilds in edition state
            const element = document.getElementById(focusOn);
            element.focus();              
            scrollTo(focusOn);
            element.style.borderColor= '#e62828';
        }       

    },[])
    
    
    const initValues = {
        id: userData.id || 0,
        //dob: userData?.dob || '' ,
        dob_day:userData?.dob?.day || '',
        dob_month:userData?.dob?.month || '',
        dob_year:userData?.dob?.year || '',        
        first_name: userData?.first_name != 'notSet' ? (userData?.first_name || '') : '', 
        last_name: userData?.last_name != 'notSet' ? (userData?.last_name || '') : '', 
        email:  userData?.email || '', 
        mobile: userData?.mobile_number || '', 
        mobile_prefix: userData?.mobile_prefix || '+962',
        allow_sharing_projects:userData?.allow_sharing_projects || '',
        show_sign_language_videos:userData?.show_sign_language_videos || '',
        password:'', 
        isEmailMobile:userData.isEmailMobile 
    };
    const maxDate = subtractYears(new Date(),18);

    const onSubmitForm = async (values, actions) => {

        setLoading(true);
        response = await Profile.updateUserProfile(values);
        setLoading(false);
        responseStatus = response.status;
        if(responseStatus == 1){
            //window.$('.reg-input').addClass('readonly-field');
            window.$(".reg-input").prop('readonly', true);
            //window.$(".select-date").prop('disabled', true);
            //window.$(".user-dob").prop('disabled', true);
            window.$('.eye-icon').addClass('profile-eye');
            window.$('.save-profile').addClass('hide');
            window.$('.edit-icon').removeClass('hide');
            window.$('.profile-user-name').removeClass('hide');
            window.$('.upload-img').addClass('hide');
            window.$('#usernameIcon1').removeClass('hide');
            window.$('#usernameIcon2').addClass('hide');

            //actions.resetForm();            
            actions.setFieldValue('password', "", false);
            setMessage({title:Lang.edit_profile_success, body:Lang.data_saved_success});
            setUsername(values.first_name+' '+values.last_name);
            window.$('#general-message').modal('show');
            setEdit(false);
        }
        else if(responseStatus==3 || responseStatus==5){}
        else{
            setMessage({title:"", body:"Failed Data Save"});
            window.$('#general-message').modal('show');
        }        
    }

    const handleErrorImage = () => {
        imgref.current.src=defaultImage
    }
    return (
        <>
        <Formik
      enableReinitialize
      initialValues={initValues}
      validationSchema={AdultProfileSchema}
      onSubmit={onSubmitForm}  
    >

{({ values, errors, setFieldValue, setErrors, touched }) => {
    if(responseStatus == 3){
        setErrors({'email':'email_registered_before'});
    }    
    if(responseStatus == 5){
        setErrors({'mobile':'mobile_registered_before'});
    }        
    responseStatus= null;

    errorFocus(errors);

    return(
        <Form>

<div className="child-container" style={{display: 'none'}}>
    <div className="profile-header">
        <p className='profile-type-name'>{profileTitle}</p>
        <div className="profile-image user-img float-md-right">
            <div className='change-image-icon-container p-relative' style={{width:'300px'}}>
            <img style={{'margin': '0 auto','display': 'block'}} ref={imgref} src={profileImg} onError={handleErrorImage} alt="" />
            <p class="profile-user-name">{username}</p>
                <FileUploadBtn
                functions =
                {{
                    beforeupload:() => { setProfileImg(loadinImg)},
                    onuploaderror:() => {setProfileImg(defaultImage)},
                    afterupload:(data) => {setProfileImg(data.payload.img)},
                }} 
                accept="image/*"
                id={`profile_image`} 
                url={`uploadProfileImage`} 
                data={{userId:Auth.getUserId()}} 
                title={Lang.change_photo}/>
            </div>
        </div>
    </div>
</div>

<div className="section-container">
        <div className='content-container reg-container profile-box'>
        <div className="top-titles">
            <p className="profile-icon" id="usernameIcon1"><img src={process.env.PUBLIC_URL + "/assets/images/parent_profile.svg"} alt={profileTitle} /><p>{profileTitle}</p></p>
            <p className="profile-icon hide" id="usernameIcon2"><img src={process.env.PUBLIC_URL + "/assets/images/parent_profile.svg"} alt={Lang.adult_profile} /><p>{Lang.adult_profile_edit}</p></p>
            <p className="edit-icon" onClick={()=>editProfile(setEdit)}><img className="fa-pencil" src={process.env.PUBLIC_URL + "/assets/images/editPen.svg"}/>{Lang.edit}</p>
        </div>
        {/* Start Form section */}
        <div className="reg-form" style={{'width':'90%'}}>
        <div className='change-image-icon-container p-relative profile-image-container'>
            <img className='user-image' ref={imgref} src={profileImg} onError={handleErrorImage} alt="" />
            <p class="profile-user-name">{username}</p>
            <div style={{'margin':' 0 20px'}}>
                <FileUploadBtn className="upload-img hide"
                functions =
                {{
                    beforeupload:() => { setProfileImg(loadinImg)},
                    onuploaderror:() => {setProfileImg(defaultImage)},
                    afterupload:(data) => {setProfileImg(data.payload.img)},
                }} 
                accept="image/*"
                id={`profile_image`} 
                url={`uploadProfileImage`} 
                data={{userId:Auth.getUserId()}} 
                title={Lang.change_photo}/>
            </div>
            </div>
            <CustomInput name="id" id="id" type="hidden"/>
            {/* <div className="form-group" style={{"margin": "0"}}>
            <DateInput selected={startDate} maxDate ={maxDate} name="dob" id="dob" type="text" placeholderText={Lang.date_of_birth+'*'} className="reg-input date-birth"/>
            </div> */}
            <div className="form-group" style={{"margin": "15px 0 0 0", "width":"100%"}}>
                {/* <div className="col-md-6"  style={{"padding": "0","marginRight":"10px"}} > */}
                <div className="profile-field">
                    <label htmlFor="first_name">{Lang.first_name+'*'}</label>
                    <CustomInput name="first_name" id="first_name" type="text" placeholder=" " className={`reg-input custom-input ${isEdit ? '' : 'readonly-field'}`} readOnly={isEdit ? false : true} doNotShowError="1" />                
                </div>
                {touched.first_name && errors.first_name && <div className="error">{Lang[errors.first_name]}</div>}

                {/* </div> */}
                <div className="form-group" style={{"margin": "15px 0 0 0","width":"100%"}}>
                {/* <div className="col-md-6"  style={{"padding": "0","marginLeft":"10px"}}> */}
                <div className="profile-field">
                    <label htmlFor="last_name">{Lang.last_name+'*'}</label>
                    <CustomInput name="last_name" id="last_name" type="text" placeholder=" " className={`reg-input custom-input ${isEdit ? '' : 'readonly-field'}`} readOnly={isEdit ? false : true} doNotShowError="1" />
                </div>
                {touched.last_name && errors.last_name && <div className="error">{Lang[errors.last_name]}</div>}

                {/* </div>   */}
                </div>
            </div>                      

        <div className="form-group" style={{"margin": "15px 0 0 0", "width":"100%"}}>
        <div className="profile-field">
            <label>{Lang.date_of_birth+'*'}</label>                
            <div className='dob_options_container' style={{width:'50%'}}>
            <div className="col-md-3 profile-date" style={{paddingRight: '0', width: '22%'}}>
                <SelectInput onChange={(e) => {
                                setFieldValue("dob_year",e.target.value);
                                //values.dob_year=e.target.value;
                                daysInMonthProccess(values)
                }} name="dob_year" id="dob_year" className={`reg-input select-date user-dob ${isEdit ? '' : 'readonly-field'}`} disabled={isEdit ? false : true}>
                    <option value={Lang.year} disabled={false}>{Lang.year}</option>
                    { (()=>{
                        let eleArr = [];
                        let currentYear = new Date().getFullYear();
                        for (var i=config.birth_year_from; i<=currentYear; i++){
                            eleArr.push(<option key={i} value={i}>{i}</option>);
                        }
                        return eleArr;
                    })()
                    }

                </SelectInput>     

            </div>            
            <div ref={dob_month} className="col-md-3 profile-date" style={{paddingRight: '0', width: '22%'}}>
                <SelectInput onChange={(e) => {
                                setFieldValue("dob_month",e.target.value);
                                //values.dob_month=e.target.value;
                                daysInMonthProccess(values)
                }}  name="dob_month" id="dob_month" className={`reg-input select-date user-dob ${isEdit ? '' : 'readonly-field'}`} disabled={isEdit ? false : true}>
                    <option value={Lang.month} disabled={false}>{Lang.month}</option>
                    {
                        config.months.map( item => {
                            return (
                                <option key={item.id} value={item.id}>{Lang[item.name]}</option>
                            )
                        })
                    }                                       
                </SelectInput> 
            </div> 
            <div className="col-md-3 profile-date" style={{paddingRight: '0', width: '22%'}}>
                <SelectInput name="dob_day" id="dob_day" className={`reg-input select-date user-dob ${isEdit ? '' : 'readonly-field'}`} disabled={isEdit ? false : true}>  
                   <option value={Lang.day} disabled={false}>{Lang.day}</option>
                    {
                        ( ()=>{
                            let eleArr = [];
                            for(var j=1; j<=totalDaysInMonth ;j++){
                                eleArr.push(<option key={j} value={j}>{j}</option>);
                            }
                            return eleArr;
                        })()
                    }
                </SelectInput>                
            </div>             
            </div> 
            </div>  
            </div>             
           
            <div className="form-group" style={{"margin": "0"}}>
              <div className="profile-field">
                <label htmlFor="email">{Lang.Email_username_edit_profile}{initValues.isEmailMobile ==1 ?'*':''}</label>
                {
                        initValues.isEmailMobile ==1 ?
                        <CustomInput name="email" id="email" type="text" placeholder=" " className={`reg-input-2 custom-input-2 readonly-field`} readOnly doNotShowError="1"/>
                        :
                        <CustomInput name="email" id="email" type="text" placeholder=" " className={`reg-input custom-input ${isEdit ? '' : 'readonly-field'}`} readOnly={isEdit ? false : true} doNotShowError="1"/>
                }
             </div>
             {touched.email && errors.email && <div className="error">{Lang[errors.email]}</div>}
            </div>
            <div className="form-group" style={{"margin": "0"}}>
                {/* <SelectInput  className="form-select" id="mobile_prefix" name="mobile_prefix">
                {
                        config.countries.map( item => {
                            return (
                                <option key={item.prefix.id} value={item.prefix.id}>{item.prefix.name}</option>
                            )
                        })
                    }  
                </SelectInput>   */}
                <div className="profile-field">
                    <label htmlFor="mobile">{Lang.parent_mobile}{initValues.isEmailMobile ==2 ?'*':''}</label>                      
                    {
                        initValues.isEmailMobile ==2 ?
                        <CustomInput id="mobile" name="mobile" type="tel" placeholder=" " className={`reg-input-2 custom-input-2 readonly-field`} readOnly disabled doNotShowError="1"/>
                        :
                        <CustomInput id="mobile" name="mobile" type="tel" placeholder=" " className={`reg-input custom-input ${isEdit ? '' : 'readonly-field'}`} readOnly={isEdit ? false : true} doNotShowError="1"/>
                    }  
                </div>
                {touched.mobile && errors.mobile && <div className="error">{Lang[errors.mobile]}</div>}
            </div>
            <div className="form-group" style={{"margin": "0", "position":"relative"}}>
                <div className="profile-field">
                    <label htmlFor="password">{Lang.password+'*'}</label>
                    <CustomInput id="password" name="password" type="password" placeholder="********" className={`reg-input custom-input ${isEdit ? '' : 'readonly-field'}`} readOnly={isEdit ? false : true} doNotShowError="1" />
                </div>
                {touched.password && errors.password && <div className="error">{Lang[errors.password]}</div>}
                <span toggle="#password" className="fa fa-fw fa-eye profile-eye field-icon toggle-password"></span>
            </div>  

            <div className={`form-group flex-group flex-group-start select-role`}  style={{"margin": "0"}}>
                <p className="select-label">{Lang.allow_sharing_voting_projects+"*"}</p>
                <div className='answers'>
                    <SelectInput name="allow_sharing_projects" className={`reg-input select-date ${isEdit ? '' : 'readonly-field'}`} disabled={isEdit ? false : true} >
                        <option value={0} disabled={false}>{Lang.no}</option>
                        <option value={1} disabled={false}>{Lang.yes}</option>
                    </SelectInput>                

                {/* <RadioInput labels={[Lang.yes, Lang.no]} ids={['ckb14','ckb15']} values={[1,0]} name="allow_sharing_projects" className="ckb ckb-dark"/> */}
                </div>   
            </div>  

            <div className={`form-group flex-group flex-group-start select-role`}  style={{"margin": "0"}}>
                <p className="select-label">{Lang.show_sign_language_videos+"*"}</p>
                <div className='answers'>
                    <SelectInput name="show_sign_language_videos" className={`reg-input select-date  ${isEdit ? '' : 'readonly-field'}`} disabled={isEdit ? false : true} >
                        <option value={0} disabled={false}>{Lang.no}</option>
                        <option value={1} disabled={false}>{Lang.yes}</option>
                    </SelectInput>                

                {/* <RadioInput labels={[Lang.yes, Lang.no]} ids={['ckb16','ckb17']} values={[1,0]} name="show_sign_language_videos" className="ckb ckb-dark"/> */}
                </div>   
            </div> 

            <LoadingButton loading={isLoading.toString()} type="submit" className="submit-button-auto save-profile hide" loading-text={`<i class='fa fa-circle-o-notch fa-spin'></i> ${Lang.processing}`} >{props.isNew ? Lang.register :Lang.save}</LoadingButton>
            <LoadingButton onClick={() => window.$('#remove-account-modal').modal('show')} loading={removeStatus == 'processing' ? 'true':'false'} type="button" className="submit-button-auto save-profile" loading-text={`<i class='fa fa-circle-o-notch fa-spin'></i> ${Lang.processing}`} >{Lang.remove_account}</LoadingButton>
            <ConfirmModal textAlign = {Lang.getSelectedLanguage() == 'ar' ? 'right' : 'left'} dir = {Lang.getSelectedLanguage() == 'ar' ? 'rtl' : 'ltr'} cancelBtnClass={'btn btn-warning'} okBtnClass={'btn btn-danger'} okCallback={()=>{Profile.removeAccount(setRemoveStatus); window.$('#remove-account-modal').modal('hide');}} title={Lang.remove_account} id="remove-account-modal" okLabel={Lang.yes} cancelLabel={Lang.no}>{[Lang.remove_account_msg_1, <><br/><br/></>, Lang.remove_account_msg_2]}</ConfirmModal>
            </div>
        </div>
        </div>
        </Form>
  
          )}}
</Formik>


        <MessageModal title={message.title} id="general-message" okLabel="Close">
        {message.body}
        </MessageModal> 
        
        </>
    );
}

export default AdultProfile