import { useEffect, useContext, useState, createRef, useRef } from "react";
import Langauge, {UserContext} from '../context/languageContext'
import '../assets/css/certificate.css'
import logo from '../assets/images/logo.svg' 
import signature from '../assets/images/mawaheb_sig.svg' 
import { useParams } from "react-router-dom";
import { ProfileContext } from "../context/ProfileContext";
import PageNotFound from "./pageNotFound";
import LoadingIcon from '../assets/images/Loading_icon.gif'
import { StringFormat } from "../helpers/functions";
import Pdf from "react-to-pdf";
import { AuthContext } from "../context/AuthContext";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

const ref = createRef();
const options = {
    orientation: 'landscape',
    unit: 'px',
    format: [1000,500]
    
};

const Certificate = () => {
    const lagKeywords = useContext(UserContext);
    let {courseId, moduleId, userId, download} = useParams([]);    
    const [isLoading, setLoading] = useState(0);
    const [certInfo, setCertInfo] = useState(0);
    const Profile = useContext(ProfileContext);
    const Auth = useContext(AuthContext);
    const downloadBtn = useRef(null);
    
    useEffect(()=>{
        if(!Auth.isLogged){
            window.location.href = '/';
        }        
        setLoading(1);
        const data = Profile.getModuleCertificate(courseId, moduleId, userId);
        data.then(res => {
            setCertInfo(res.data.payload);
            setLoading(2);
            console.log(res.data.payload);
        });
    },[])    


    
    return (
        
         <>
         { isLoading==2 && certInfo?.id >0 &&
            <>

            { download ==1 &&
                <Pdf targetRef={ref} filename="certificate.pdf" options={options}>
                {({toPdf}) => {

                    
                    
                    setTimeout(() => {
                        //toPdf();

                        const clone = document.createElement("div");
                        document.getElementsByTagName('BODY')[0].append(clone)
                        clone.style.width = '1200px';
                        clone.innerHTML = '<div class="cert-container" style="width:1200px;position: absolute; top:-16384px;">'+document.querySelector('.cert-container').innerHTML + '</div>';

                       const input = clone.querySelector('.cert-container');
                       const divHeight = input.clientHeight
                       const divWidth = input.clientWidth
                       const ratio = divHeight / divWidth;

                        html2canvas(input).then((canvas) => {
                            const imageDataURL = canvas.toDataURL("image/png");
                         
                            // Create a download link for the image
                            // const a = document.createElement("a");
                            // a.href = imageDataURL;
                            // a.download = "dog.png";
                            // a.click();

                            const doc = new jsPDF({
                                orientation: 'l', // landscape
                                unit: 'px', // points, pixels won't work properly
                                //format: [canvas.width,canvas.height] // set needed dimensions for any element
                          });
    
                            const width = doc.internal.pageSize.getWidth();
                            let height = doc.internal.pageSize.getHeight();
                            height = ratio * width;

                            doc.addImage(imageDataURL, 'JPEG', 0, 0, width, height);
                            doc.save("certificate.pdf");

                            
                         });



                    }, 1000);
                    
                
                }}
                </Pdf>
            }



        {download ==1 &&<center><h1>Downloading...</h1></center>    }
        <div style={{visibility:(download ==1 ? 'visible':'visible')}} ref={ref} className="cert-container cert-html-view">
          <div className="section-one">
            <div className="img-logo">
                <img src={logo} />
            </div>
            <div className="top-text">
                <p>{lagKeywords.certificate_of_competition}</p>
                <br/>
                <span>{lagKeywords.congratulations}</span>
            </div>
            
          </div>
          <div className="section-two">{lagKeywords.certificate_awarded_to}</div>
          <div className="section-three">{certInfo.user_name}</div>
          <div className="section-four">{StringFormat(lagKeywords.certificate_finishing_text, certInfo.name, certInfo.course_name)}</div>
          <div className="section-five">{certInfo.completion_date.day}/{certInfo.completion_date.month}/{certInfo.completion_date.year}</div>
          <div className="section-six">{lagKeywords.certificate_text1}<br/>{lagKeywords.certificate_text2}</div>
          <div className="section-seven">
            <div>
                <p>{lagKeywords.mawaheb_team}</p>
                {/* <p>maysam turk</p> */}
            </div>
            <div>
                <p>{lagKeywords.issue_date}</p>
                {/* <p>22-3-2020</p> */}
            </div>
            <div>
                <p>{lagKeywords.valid_certificate_id}</p>
                <img src={signature} className="signature"/>
            </div>
          </div>
        </div>
        </>
        }
        {    isLoading==2 && !certInfo?.id &&
            <div><PageNotFound/></div>
        }   
        {    isLoading ==1 && 
                <div className="loader"></div>
        }           
        </>    
        
    );
};

export default Certificate;