import { gtag } from "ga-gtag";
import { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { UserContext } from "../context/languageContext";
import googleConfig from './config/google'
import logo from '../assets/images/white_logo.svg' 

const CourseProjectPage = () => {

    const params = useParams();
    const Auth = useContext(AuthContext)
    const Lang = useContext(UserContext)
    const id = params?.id || 0;
    const [loading, setLoading] = useState(0);
    const [response, setResponse] = useState({});
    const selectedLang = Lang.getSelectedLanguage() ;

    useEffect( () => {
        setLoading(1);
        Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded',}).post('getCourseProject/'+id,{}).then( res => {
             setLoading(2);
             setResponse(res.data);
             if(res.data.status ==1){

                const displayname = 'Course Project - '+res.data.payload?.project_name
                document.querySelector('meta[name="description"]').setAttribute("content", displayname);
                document.querySelector('meta[name="ogTitle"]').setAttribute("content", displayname);
                document.querySelector('meta[name="ogDescription"]').setAttribute("content", displayname);
                document.title="Mawaheb "+displayname;
                  gtag('event', displayname, { 
                      'send_to':googleConfig.GOOGLE_TAG_AD_WORD+'/'+googleConfig.GOOGLE_TAG_CONVERSION_ID
                  })                

             }

        }).catch( err => {
             setLoading(2);

        });
    },[])


    return (
        <>
        <div className="section-container">
        <div className='content-container project-container'>

        {loading ==1 && <center>{Lang.loading}</center>}
        {(loading ==2 && response.status==2) && <center>{Lang.not_authorized}</center>}
        {(loading ==2 && response.status==0) && <center>{Lang.data_saved_faild}</center>}
        <img src={logo} alt={Lang.mawaheb_logo} className="project-logo" />
        {(loading ==2 && response.status==1) && 
        <center>
            { response.payload?.media_type == 'video' && <video controls className="project-media"><source src={response.payload.downloadLink}/></video>}
            { response.payload?.media_type == 'image' && <img className="project-media" src={response.payload.downloadLink} />}
            {<p className="project-details">
                <p>{response.payload['course_name'+(selectedLang!= 'en' ? '_'+selectedLang : '')]}</p>
                {/* <a title={Lang.download} href={response.payload.downloadLink} download={response.payload?.project_name}><i class="fa fa-download download-project" aria-hidden="true"></i></a> */}
                <p style={{color: '#F39422'}}>{response.payload?.username}</p>
            </p>}
        </center>
        }
        </div>
        </div>
        </>
    )
    
}

export default CourseProjectPage;