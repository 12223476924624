import React, { Component, useState, useEffect, useContext, useReducer ,useRef} from 'react';

import { useParams } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import Langauge,{UserContext} from '../context/languageContext'
import { ProfileContext } from '../context/ProfileContext';
import ApiHelper from "../helpers/ApiHelper";
import Config from '../Components/config';
import '../assets/css/certificate.css'
import { useNavigate } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import backBtn from '../assets/images/arrow-left-blue.svg';

const CourseCertificates =(props)=>
{
    const lagKeywords = React.useContext(UserContext);
    let {courseId,courseName, userId} = useParams([]);
    const Navigate = useNavigate();
    const [selectedCourse, setSelected] = useState(courseId);
    const [levelsStatuses,setLevelsStatuses]=useState([]);
    const [isLoading, setLoading] = useState(0);
    const Profile = useContext(ProfileContext);
    const Auth = useContext(AuthContext);

    useEffect(()=>{

        if(!Auth.isLogged){
            window.location.href = '/';
        } 

            setLoading(1);
            const levelsStatusesRes = Profile.getModulesCertificates(courseId,userId);
            levelsStatusesRes.then(res => {
                setLevelsStatuses(res.data.payload);
                setLoading(2);
                //console.log(res.data.payload);
            });
    },[])
      


    return (
        <>
        <div className="section-container">
            <div className="content-container">
                    <NavLink to="/dashboard" className="back-blue-btn">
                        <img src={backBtn} alt="back"/>
                        <p>{lagKeywords.back}</p>
                    </NavLink>
                    <div className='my-cert-title'>{lagKeywords.my_certificates}</div>
                    <table className='my-certificate-table'>
                        {/* <thead>
                            <tr>
                                <th>{lagKeywords.module_name}</th>
                                <th><center>{lagKeywords.completion_date}</center></th>
                                <th>{lagKeywords.view_certificates}</th>
                                <th>{lagKeywords.download}</th>
                            </tr>
                        </thead> */}
                        <tbody>
                        {
                            levelsStatuses.length > 0 && levelsStatuses.map( (item) => {
                                return (
                                    item.total_modules >0 &&
                                    <tr key={item.id}>
                                        <td>
                                            {item.name}
                                        </td>
                                        <td className='table-cell'>
                                            {item.is_completed ?  (item.completion_date || '-') : ''}
                                        </td>
                                        <td className='table-cell'>
                                        { item.is_completed ? <button className="cert-table-btn" onClick={()=>Navigate('/certificate/'+courseId+'/'+item.id+'/'+userId+'/0')}>{lagKeywords.view_certificates}</button> :  lagKeywords.no_certificates }
                                        </td>
                                        <td className='table-cell'>
                                            { item.is_completed ? <button className="cert-table-btn" onClick={()=>Navigate('/certificate/'+courseId+'/'+item.id+'/'+userId+'/1')}>{lagKeywords.download}</button> : '' }
                                        </td>
                                    </tr>
                                )
                            })
                        }

                        {
                            isLoading == 1 && <tr><td colSpan={4}>{lagKeywords.loading}</td></tr>
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
    
    




}

export default CourseCertificates;