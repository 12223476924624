import {ANALYTICS_QUALITY_CHANGE_COUNT_THRESHOLD} from '../utils/Settings';
import * as Settings from '../utils/Settings';

export default class QualityChangeService {
  private qualityChangeCount = 0;
  private previousVideoBitrate = 0;
  private previousAudioBitrate = 0;
  private resetIntervalId?: number;

  constructor(private qualityChangeThreshold: number = ANALYTICS_QUALITY_CHANGE_COUNT_THRESHOLD) {}

  resetValues() {
    this.qualityChangeCount = 0;
    this.previousAudioBitrate = 0;
    this.previousVideoBitrate = 0;
    this.stopResetInterval();
  }

  isQualityChangeEventEnabled(): boolean {
    return this.qualityChangeCount <= this.qualityChangeThreshold;
  }

  increaseCounter() {
    this.qualityChangeCount++;
  }

  shouldAllowVideoQualityChange(newBitrate: number | undefined): boolean {
    return (
      newBitrate != null &&
      !isNaN(newBitrate) &&
      this.previousVideoBitrate !== newBitrate &&
      this.isQualityChangeEventEnabled()
    );
  }

  setVideoBitrate(newBitrate: number): void {
    this.previousVideoBitrate = newBitrate;
  }

  shouldAllowAudioQualityChange(newBitrate: number | undefined): boolean {
    return (
      newBitrate != null &&
      !isNaN(newBitrate) &&
      this.previousAudioBitrate !== newBitrate &&
      this.isQualityChangeEventEnabled()
    );
  }

  setAudioBitrate(newBitrate: number): void {
    this.previousAudioBitrate = newBitrate;
  }

  startResetInterval() {
    if (this.resetIntervalId !== undefined) {
      return;
    }

    this.resetIntervalId = window.setInterval(() => {
      this.resetCounter();
    }, Settings.ANALYTICS_QUALITY_CHANGE_COUNT_RESET_INTERVAL);
  }

  stopResetInterval() {
    if (this.resetIntervalId === undefined) {
      return;
    }

    window.clearInterval(this.resetIntervalId);
    delete this.resetIntervalId;
  }

  private resetCounter() {
    this.qualityChangeCount = 0;
  }
}
