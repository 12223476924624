import  parse  from "html-react-parser";
import { useField } from "formik";
import { UserContext as language } from '../../context/languageContext';
import { useContext } from "react";
const SelectInput = ({ withParent,label, ...props }) => {
    const [field, meta] = useField(props);
    const Lang = useContext(language);
    return (
      <>
      {label && <label>{label}</label>}   
      { withParent  && 
        (<div className="child-num">
          <select
            {...field}
            {...props}
            className={meta.touched && meta.error ? props.className+" input-error" : props.className}
          >
          {props.children}
          </select>
        </div>)
        }                
        { !withParent  && 
        (<select
        {...field}
        {...props}
        className={meta.touched && meta.error ? props.className+" input-error" : props.className}
        >
        {props.children}
        </select>)

      }
        {meta.touched && meta.error && <div className="error">{Lang[meta.error]}</div>}
      </>
    );
  };
  export default SelectInput;