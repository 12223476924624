import type {StateMachineEventDef, StateMachineErrorCallback} from 'javascript-state-machine';

import {logger} from '../utils/Logger';

type OnReturn = {
  stayIn: (state: string) => StateMachineEventDef;
};

export function on(event: string): OnReturn {
  return {
    stayIn: (state: string) => ({name: event, from: state, to: state}),
  };
}

/**
 * By default, if you try to call an event method that is not allowed in the current state,
 * the state machine will throw an exception.
 * To handle this problem ourselves, we define a custom error handler that will log the error.
 *
 * @see https://github.com/jakesgordon/javascript-state-machine/blob/2.4.0/README.md#handling-failures
 */
export const customStateMachineErrorCallback: StateMachineErrorCallback = (
  _eventName,
  _from,
  _to,
  _args,
  _errorCode,
  errorMessage,
  _ex,
) => {
  logger.error('Error in StateMachine: ' + errorMessage);
};
