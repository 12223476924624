import { gtag, install } from "ga-gtag";
import { useEffect } from "react"
import googleConfig from './config/google.js'


const TestConversion = () => {

    useEffect(()=> {
        console.log('here');
        //install('G-KW6FK3Z4V9');
        gtag('event', 'Shareif', { 
            'send_to':googleConfig.GOOGLE_TAG_AD_WORD+'/enMuCLDYofgYEMvBntIp',
            'event_callback' : () => {
                console.log('event')
            }
        });        
    },[])
}

export default TestConversion