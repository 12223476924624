
import {useState, useContext, useEffect} from 'react';
import {AuthContext} from '../context/AuthContext'
import { UserContext as language } from '../context/languageContext';
import config from './config';
import '../assets/css/forms.css';
import { useParams } from 'react-router-dom';
import PageNotFound from './pageNotFound';
import { getDeviceInfo } from '../helpers/deviceInfo';
import { useNavigate } from 'react-router-dom';

let response = null;

const ChildAccountActivation = () => {

    const [isLoading, setLoading] = useState(0);
    const [success, setSuccess] = useState(-1);
    const Auth = useContext(AuthContext);
    const Lang = useContext(language); 
    const params = useParams();
    const token = params?.token || '';
    const id = params?.id || '';
    const navigate = useNavigate();
    

    useEffect( () => {

        async function initial(){

                setLoading(1);
                Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('checkActivationToken',{token, id, device_info:await getDeviceInfo()}).then( res => {
                    setLoading(2);
                    if(res?.data?.status ==1){
                        if(!Auth.isLogged && res?.data?.payload?.doLogin == 1){
                            Auth.successLoginProcess(res);
                        }                        

                        setSuccess(1);
                        setTimeout(() => {
                            navigate('/dashboard');
                        }, 2000);                        
                    }
                    else{
                        setSuccess(0);
                    }
                }).catch(err => {
                    setLoading(2);
                    setSuccess(0);
                })

        }

        initial();
      },[])



    return(        
        isLoading ==1 ? 
        <div style={{marginTop:'100px'}}><center><h1>{Lang.loading}</h1></center></div> 
        : 
        success == 1 ? 
        <div style={{marginTop:'100px'}}><center><h1>{Lang.child_account_activation_success}</h1></center></div> 
        :
        <PageNotFound/> 
        
      );
      



    
}

export default ChildAccountActivation;