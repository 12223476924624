import React, { Component } from 'react';
import '../index.css';
import Langauge, {UserContext} from '../context/languageContext'
import {useState} from 'react';
import {useEffect} from 'react';
import Config from './config';
import ApiHelper from "../helpers/ApiHelper";
import '../assets/css/contact-us.css'
import MessageModal from './modals/messageModal';

const ContactUs=()=>{

  const lagKeywords = React.useContext(UserContext);

  const value = React.useContext(UserContext);  
  //console.log(user)
  let [introLp, setintroLp] = useState([]); 
  const [message, setMessage] = useState({title:"", body:""});
  let introApi=Config.getGeneralData;
  let executeContactUs=Config.executeContactUs

  useEffect(()=>{
    ApiHelper.excuteApi(introApi+'contact_us','get','{}',setintroLp);
    },[]        
  ) 
  
  const handelContactUs=(event)=>
  {
    event.preventDefault();
    let fName=(event.target.firstName.value);
    let lastName=(event.target.lastName.value);
    let email=(event.target.email.value);
    let phone_number=(event.target.phone_number.value);
    let message=(event.target.message.value);
    //let urlGet='{firstName='+fName+'&lastName='+lastName+'&email='+email+'&message='+message+'}'
    let urlPost='{"firstName":"'+fName+'","lastName":"'+lastName+'","email":"'+email+'","phone_number":"'+phone_number+'","message":"'+message+'"}'
    const apiRes = ApiHelper.excuteApi3(executeContactUs,'post',urlPost).then(res => {
      console.log(res.status)
      if(res.status == 'success'){
        setMessage({title:lagKeywords.ContactUs, body:lagKeywords.message_sent_sucess});              
      }
      else if (res.status == 'empty'){
        setMessage({title:lagKeywords.ContactUs, body:lagKeywords.fill_contact_us});
      }      
      else{
        setMessage({title:lagKeywords.ContactUs, body:lagKeywords.message_sent_failed});
      }
      window.$('#general-message').modal('show');  
    });
  }
  const validateForm=()=>
  {
    return false;
  }
  //console.log(introApi+'faqs')
  return(

    <>
    {/* <div className='contact-us-container'  dangerouslySetInnerHTML={ {__html: introLp.contact_us} }> */}
    <div className='contact-us-container'>

        <div className="contact-us-intro">
        <div className="contact-us-text">
        <h1>{lagKeywords.ContactUs}</h1>
        <p>{lagKeywords.ContactUsT1} <br/>
        {lagKeywords.ContactUsT2}</p>
        <p>{lagKeywords.ContactUsT3} <br/>
       </p>
        </div>

        </div>                                                                     
        <div className="contact-us-lower-container" style={{padding:'50px 0 0 0'}}>
        <div className="white-area">
        <div className="contact-us-form">
        <form onSubmit={handelContactUs}>
        <div className="reg-field contact-field">
              <label>{lagKeywords.first_name}</label>
              <input type="text" placeholder=" " className="reg-input"  name="firstName" id="firstName"  />
              </div>
              <div className="reg-field contact-field">
              <label>{lagKeywords.last_name}</label>
              <input type="text" className="reg-input" placeholder=" " name="lastName" id="lastName"/>
              </div>
              <div className="reg-field contact-field">
              <label>{lagKeywords.email}</label>

              <input type="email" className="reg-input" placeholder=" " name="email" id="email"/>
              </div>
              <div className="reg-field contact-field">
              <label>{lagKeywords.mobile_number}</label>

              <input type="text" className="reg-input" placeholder=" " name="phone_number" id="phone_number"/>
              </div>
             
              <div className="reg-field contact-field">
                <label>{lagKeywords.message}</label>
              <textarea className="reg-input" placeholder=" " name="message" id="message"></textarea>
              </div>
              <button className="contact-us-btn" type='submit' >{lagKeywords.send}</button>
              </form>
        </div>
        </div>
        </div>

    </div>


        <MessageModal title={message.title} id="general-message" okLabel="Close">
            {message.body}
        </MessageModal> 

    </>
  )
}

export default ContactUs 