let domain = window.location.origin+'/';

let campaignJourneyTestModeTmp = 0;
if(domain == 'https://staging.masterhobbies.online/' || domain=='http://localhost:3000/' || domain=='http://localhost:4000/'){
    campaignJourneyTestModeTmp = 1;
}
console.log("mode is "+campaignJourneyTestModeTmp)
export const campaignJourneyTestMode = campaignJourneyTestModeTmp;

let pk = 'pk_live_51NhQVBEu7cuVnXjewe3JBk0DpEK577wSQYhTDOaD0Hyo6abdaiyGsRjBHcqjUn3vWXkkzkFcfR03RQrLfTbNca6j00iRNcqO2L';
if(campaignJourneyTestMode ==1){
    pk = 'pk_test_51NhQVBEu7cuVnXjetK3OsXL9JdyyaWoLoGa5R84OZsJE0u7gO0WNzNAvrgUoZO1EUNij6WZNeXaAVGQxjScOi5hC000I8RwfCW';
}


export const paymentKey = pk;