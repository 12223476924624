import * as Yup from 'yup';
import {getAge} from '../../helpers/functions';

//const professions = ['Doctor', 'Engineer', 'Programmer'];


const CampaignJourneyMobileStep1Schema = Yup.object().shape({

//     dob: Yup.string()
//             .required("dob_required_err"), 
        dob_day: Yup.number('dob_required_err').required("dob_required_err").min(1, 'dob_required_err'), 
        dob_month: Yup.number('dob_required_err').required("dob_required_err").min(1, 'dob_required_err'), 
        dob_year: Yup.number('dob_required_err').required("dob_required_err").min(1, 'dob_required_err'), 

        mobile: Yup.string()
        .required('mobile_required_err')
        .matches(/^\d+$/, 'mobile_err'),
        


  })

  export default CampaignJourneyMobileStep1Schema;