import {useState, useContext, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import {Form, Formik} from 'formik';
import RegisterSchema from '../Components/schema/registerSchema';
import Elements from './elements/elements';
import {errorFocus, getAge} from '../helpers/functions';
import fcbImg from '../assets/images/facebook-icon-share.svg';
import gooImg from '../assets/images/google-icon-share.svg';
import {AuthContext} from '../context/AuthContext'
import { UserContext as language } from '../context/languageContext';
import config from './config';
import '../assets/css/forms.css';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';
import FbLogin from './elements/facebookLogin';
import { GoogleOAuthProvider } from '@react-oauth/google';
import GoogLogin from './elements/googleLogin';
import googleConfig from './config/google.js'
const GOOGLECLIENTID = googleConfig.GOOGLE_CLIENT_ID;

const {CustomInput, DateInput, SelectInput, RadioInput, CheckboxInput, LoadingButton} = Elements;
var response = null;

const cookies = new Cookies();

// Password Toggling in Forms
window.$(".toggle-password").on('click',function() {

    window.$(this).toggleClass("fa-eye fa-eye-slash");
        var input = window.$(window.$(this).attr("toggle"));
        if (input.attr("type") == "password") {
        input.attr("type", "text");
        } else {
        input.attr("type", "password");
        }
        input.focus();
        });
    

       
         
const Register = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [totalDaysInMonth, setTotalDaysInMonth] = useState(31);
    let [selectedMonth, setSelectedMonth] = useState(0);
    let [selectedYear, setSelectedYear] = useState(0);
    const Auth = useContext(AuthContext);
    const Lang = useContext(language);
    const adultAge = 18;    
    const params = useParams();
    const token = params?.token || '';
    const email = params?.email || '';
    const refreshToken = params?.refreshToken;
    const isInvitation = (email && token) || refreshToken ;
    const [dynamicPrefix,setDynamicPrefix] = useState('962');
    const currentYear = new Date().getFullYear();

//     window.$(".reg-field").on("click", function(e) {
//         window.$(this).find('label').addClass('non-empty');
//         if (window.$('.reg-input').val() != " ") {
            
//             window.$(this).find('label').addClass('non-empty');
//         }else{
//             window.$(this).find('label').removeClass('non-empty');
//         }
//       });
//       window.$(document).ready(function(){
//         window.$( ".reg-input" ).each(function() {
  
//       var value = window.$(this).val();
//       var length_ = value.length;        
   
//       if(length_ > 0){
//         window.$(this).find('label').addClass('non-empty');
//       }else if(length_ == 0){
//         window.$(this).find('label').removeClass('non-empty');

//        }
//     });
//   });
//     window.$(".reg-input ").on('blur', function(e) {
//        // window.$('.reg-field').find('label').removeClass('non-empty');
//        var value = window.$(this).val();
//        var length_ = value.length;        
    
//        if(length_ > 0){
//          window.$(this).find('label').addClass('non-empty');
//        }else if(length_ == 0){
//         window.$('.reg-input ').find('label').removeClass('non-empty');

//        }
//     });


    const daysInMonthProccess = (values) => {
        let y =  selectedYear;//values.dob_year;
        let m =  selectedMonth;//values.dob_month;
        //console.log(y,'->',m)
        let d = 0;
        if(y && m){
            d = new Date(y, m, 0).getDate();
        }

        d = 31;
        //console.log(d)
        setTotalDaysInMonth(d);
    }

    useEffect(()=>{
        if(Auth.isLogged){
            navigate('/');
        } 

        Auth.getCountryPrefix().then(res => {
            setDynamicPrefix(res.data.payload.prefix || '962');
        }).catch(err => {
            setDynamicPrefix('962');
        });

        Auth.logTracking('registeration_page');

    },[refreshToken])
   

    const onSubmitRegister = async (values, actions) => {
        setLoading(true);

        if(getAge(values.dob_year+'-'+values.dob_month+'-'+values.dob_day)<adultAge){
            values.mobile = '';
        }
        response = await Auth.register(values,isInvitation, initValues.stk? true:false)
        //console.log(values);
        //console.log(actions);
        //await new Promise((resolve) => setTimeout(resolve, 1000));
        setLoading(false);
        //console.log(response)
        if(response == 1){
            actions.resetForm();
        }
      };   
      
      
      const initValues = {
        //dob:"" ,
        dob_day:1,
        dob_month:1,
        dob_year:currentYear -20,
        first_name: location?.state?.first_name || cookies.get('fb_fname') || "", 
        last_name: location?.state?.last_name || cookies.get('fb_lname') || "", 
        email: location?.state?.email || cookies.get('fb_email') || email, 
        token: token,
        mobile:"", 
        mobile_prefix:('+'+dynamicPrefix) , 
        mobile_prefix_tmp : dynamicPrefix,
        password:'', 
        parent_email:'', 
        behalf:isInvitation ? '':'',
        relation:'1',
        children:'1',
        newsletter_agreement:false,
        agreement:true,
        stk:location?.state?.stk || '',
        stk_type:location?.state?.stk_type || 0,
        fb_id:location?.state?.fb_id || '',
        apple_user_id:location?.state?.apple_user_id || '',
    };
    
/*
    const initValues = {
        dob:"1990-09-08" ,
        first_name: "Mohammad", 
        last_name: "Taha", 
        email: "mohammad.taha.1990@gmail.com", 
        mobile:"0569212399", 
        mobile_prefix:'+962', 
        password:'Qwe12345#@', 
        parent_email:'', 
        behalf:1,
        relation:"1",
        children:'2',
        agreement:true
    };    
*/

    return(
      <div className="section-container">
        <div className='content-container reg-container' style={{"padding": "0"}}>
        <div className="reg-form">
        <h2 className="reg-title">{Lang.RegisterNewAccount}</h2>


        <Formik
        enableReinitialize
      initialValues={initValues}
      validationSchema={RegisterSchema}
      onSubmit={onSubmitRegister}  
    >

{(props) => {
    //console.log(props.values.dob_year);
    let {values, errors, touched, handleChange, setFieldValue, setValues} = props;



    //errors.relation = Lang.required_field;
    if(response == 2){
        errors.parent_email = 'parent_email_not_exist';
    }
    else if(response == 3){
        errors.email = 'email_registered_before';
    }    
    else if(response == 4){
        errors.email = 'username_registered_before';
    }    
    else if(response == 5){
        errors.mobile = 'mobile_registered_before';
    }        
    else if(response == 6){
        errors.parent_email = 'parent_reach_max_child';
    }         
    response= null;

    errorFocus(errors);

    return(
        <Form autocomplete="off">
            {/* <div className="form-group" style={{"margin": "0"}}>
                <DateInput name="dob" id="dob" type="text" placeholderText={Lang.date_of_birth+'*'} className="reg-input date-birth"/>
            </div> */}

            <div className='account-type-container hide'>
                <div className='account-type-text'>{Lang.reg_account_type}</div>
                <div onClick={()=>{setFieldValue("behalf",''); }} className={`account-type-item adult-account ${values.behalf!=1 ? 'selected':''}`}>{Lang.myself}</div>
                <div onClick={()=>{setFieldValue("behalf",'1'); }} className={`account-type-item parent-account ${values.behalf==1 ? 'selected':''}`}>{Lang.my_children}</div>
            </div>

            <div className="form-group hide" style={{"margin": "0", "width":"100%"}}>
            <div className="col-md-3 date-label" style={{"width":"100%"}}>
                <label>{Lang.date_of_birth+'*'}</label>                
            </div>

            <div className='dob_options_container'>
            <div className="col-md-3" style={{paddingRight: '0', width: '30%'}}>
                <SelectInput onChange={(e) => {
                                setFieldValue("dob_year",e.target.value);                                
                                //handleChange('dob_year');
                                setSelectedYear(e.target.value);
                                selectedYear = e.target.value;
                                daysInMonthProccess();
                }} name="dob_year" id="dob_year" className="reg-input select-date">
                    <option value={Lang.year} disabled={false}>{Lang.year}</option>
                    { (()=>{
                        let eleArr = [];
                        let currentYear = (new Date().getFullYear())-6;
                        for (var i=config.birth_year_from; i<=currentYear; i++){
                            eleArr.push(<option key={i} value={i}>{i}</option>);
                        }
                        return eleArr;
                    })()
                    }

                </SelectInput>                
            </div>            
            <div className="col-md-3" style={{paddingRight: '0', width: '30%'}}>
                <SelectInput onChange={(e) => {
                                setFieldValue("dob_month",e.target.value);
                                //handleChange('dob_month');
                                setSelectedMonth(e.target.value);
                                selectedMonth = e.target.value;
                                daysInMonthProccess();
                }}  name="dob_month" id="dob_month" className="reg-input select-date">
                    <option value={Lang.month} disabled={false}>{Lang.month}</option>
                    {
                        config.months.map( item => {
                            return (
                                <option key={item.id} value={item.id}>{Lang[item.name]}</option>
                            )
                        })
                    }                                       
                </SelectInput>                
            </div> 
            <div className="col-md-3" style={{paddingRight: '0', width: '30%'}}>
                <SelectInput name="dob_day" id="dob_day" className="select-date reg-input">  
                   <option value={Lang.day} disabled={false}>{Lang.day}</option>
                    {
                        ( ()=>{
                            let eleArr = [];
                            for(var j=1; j<=totalDaysInMonth ;j++){
                                eleArr.push(<option key={j} value={j}>{j}</option>);
                            }
                            return eleArr;
                        })()
                    }
                </SelectInput>                
            </div> 
            </div>            
            </div>                      
            <div className="form-group" style={{"margin": "0"}}>
                <div className="reg-field">
                    <label htmlFor="email">{Lang.email_or_user+'*'}</label>
                    <CustomInput disabled={(refreshToken && initValues.email !='') || (isInvitation && initValues.email !='')? 'disabled':false} name="email" id="email" type="text" placeholder=" " className="reg-input userReg-field" />
                </div>
                <div className="reg-field">
                    <CustomInput readOnly name="token" id="token" type="hidden" placeholder=" " className="reg-input"/>
                    <CustomInput readOnly name="stk" id="stk" type="hidden" placeholder=" " className="reg-input"/>
                    <CustomInput readOnly name="stk_type" id="stk_type" type="hidden" placeholder=" " className="reg-input"/>
                    <CustomInput readOnly name="fb_id" id="fb_id" type="hidden" placeholder=" " className="reg-input"/>
                    <CustomInput readOnly name="apple_user_id" id="apple_user_id" type="hidden" placeholder=" " className="reg-input"/>
                </div>

            </div>
            <div className="form-group inline-group" style={{"margin": "0"}}>
            <div className="col-md-6">
                <div className="reg-field">
                    <label htmlFor="first_name">{Lang.first_name+'*'}</label>
                    <CustomInput name="first_name" id="first_name" type="text" placeholder=" " className="reg-input userReg-field" />                    
                </div>
            </div>
            <div className="col-md-6">
                <div className="reg-field">
                    <label htmlFor="last_name">{Lang.last_name+'*'}</label>
                    <CustomInput name="last_name" id="last_name" type="text" placeholder=" " className="reg-input userReg-field" />
                </div>
            </div>  
            </div>                      
            <div className={`form-group ${getAge(values.dob_year+'-'+values.dob_month+'-'+values.dob_day)<adultAge ? "":""}`} style={{"margin": "0", position: 'relative'}}>
                {/* <SelectInput className="form-select" id="mobile_prefix" name="mobile_prefix" >
                    {
                        config.countries.map( item => {
                            return (
                                <option key={item.prefix.id} value={item.prefix.id}>{item.prefix.name}</option>
                            )
                        })
                    }                    
                </SelectInput> */}
                <CustomInput autocomplete="off" id="mobile_prefix" name="mobile_prefix" type="number" placeholder=" " className="hide" />
                <CustomInput autocomplete="off" type="number" onKeyUp={(e) => {if(e.target.value.length ==3){document.getElementById('mobile').focus()};}} onChange={(e)=>{setFieldValue("mobile_prefix_tmp",e.target.value);setFieldValue("mobile_prefix",'+'+e.target.value);}} id="mobile_prefix_tmp" name="mobile_prefix_tmp" maxLength={3} style={{backgroundImage:'unset',top:'0'}} className="form-select mob-prefix" />
                <div className={`reg-field `}>
                    <label htmlFor="mobile" className="placeholder-mob">{Lang.mobile_number}</label>
                    <CustomInput style={{textAlign: 'left', direction: 'ltr'}} id="mobile" name="mobile" type="number" placeholder=" " className="reg-input userReg-field" />
                </div>
         
                {/* <CustomInput id="mobile" name="mobile" type="tel" className="reg-input" placeholder={Lang.mobile_number} /> */}
            </div>
            <div className="form-group" style={{"margin": "0", "position":"relative"}}>
                <div className="reg-field">
                    <label htmlFor="password">{Lang.password+'*'}</label>
                    <CustomInput id="password" name="password" type="password" placeholder=" " className="reg-input userReg-field"/>
                </div>
                <span toggle="#password" className="fa fa-fw fa-eye field-icon toggle-password"></span>
            </div>
            <div className={`form-group hide ${getAge(values.dob_year+'-'+values.dob_month+'-'+values.dob_day) >0 && getAge(values.dob_year+'-'+values.dob_month+'-'+values.dob_day)<adultAge && !isInvitation? "":"hide"}`} style={{"margin": "0"}}>
                <div className="reg-field">
                    <label htmlFor="parent_email">{Lang.parent_email}</label>
                    <CustomInput name="parent_email" id="parent_email" type="text" placeholder=" " className="reg-input userReg-field" />
                </div>
            </div>
            <div className={`form-group hide ${getAge(values.dob_year+'-'+values.dob_month+'-'+values.dob_day)>=adultAge && !isInvitation? "flex-group select-role flex-group-start":"hide"}`} style={{"margin": "0"}}>
            <p style={{"lineHeight": "27px","color": "#999999"}}>{Lang.on_behalf_child+'*'}</p>
            <div className='answers'>
            <SelectInput name="behalf" className="reg-input select-date behalf" >
                    <option value={0} disabled={false}>{Lang.no}</option>
                    <option value={1} disabled={false}>{Lang.yes}</option>
            </SelectInput>                

                {/* <RadioInput labels={[Lang.yes, Lang.no]} ids={['ckb4','ckb1']} values={[1,0]} name="behalf" className="ckb ckb-dark"/> */}
            </div>
        </div>
        <div className={`form-group ${values.behalf==1? "flex-group select-role flex-group-start":"hide"}`}  style={{"margin": "0"}}>
            <p style={{"lineHeight": "27px","color": "#999999"}}>{Lang.relation_to_child+"*"}</p>
            <div className='answers'>
            <SelectInput doNotShowError="0" name="relation" className="reg-input select-date">
                    <option value={2} disabled={false}>{Lang.mother}</option>
                    <option value={1} disabled={false}>{Lang.father}</option>
                    <option value={3} disabled={false}>{Lang.caregiver}</option>
            </SelectInput>                

            {/* <RadioInput doNotShowError="1" labels={[Lang.father, Lang.mother, Lang.caregiver]} ids={['ckb10','ckb11','ckb13']} values={[1,2,3]} name="relation" className="ckb ckb-dark"/> */}
            </div>
        </div> 
        <div className='error'>{touched.relation && errors.relation ?Lang[errors.relation] :''}</div>
        <div className={`form-group select-role hide ${values.behalf==1? "":"hide"}`} style={{"margin": "0"}}>
        <p style={{"lineHeight": "27px","color": "#999999"}}>{Lang.select_child_number}</p>
            <SelectInput withParent={true} className="reg-input select-date" id="children" name="children" style={{"width": "100px", "padding":"10px 30px"}}>
                {/* <option value=""></option> */}
                <option value="1">{Lang.one_child}</option>
                <option value="2">{Lang.two_child}</option>
                <option value="3">{Lang.three_child}</option>
            </SelectInput>
        </div>

        <div className="form-group checking" ref={(el) => el && el.style.setProperty("margin", "40px 0 0 0", "important")} >
            <CheckboxInput labels={[Lang.newsletter_agreement]} ids={['newsletter_ckb12']} checked values={[true]} name="newsletter_agreement" className="input-checkbox ckb ckb-dark" />
        </div> 

        <div className="form-group checking" ref={(el) => el && el.style.setProperty("margin", "10px 0", "important")} >
            <CheckboxInput labels={[Lang.register_agreement]} ids={['ckb12']} values={[true]} name="agreement" className="input-checkbox ckb ckb-dark" />
        </div>       
        
            <LoadingButton loading={isLoading.toString()} type="submit" className="submit-button-auto reg-btn" loading-text="<i class='fa fa-circle-o-notch fa-spin'></i> Processing" >{Lang.register}</LoadingButton>
    </Form>
          )}}
</Formik>

{ false &&
<>
<div className="horizontal-line">
              <hr style={{width:'75%', color:'#999999',margin: '0 auto'}} /><span className="or">{Lang.or} </span>
              </div>
              <div className="social-login-container">
              <FbLogin setLoading={setLoading}><a href="#"><img src={process.env.PUBLIC_URL + "/assets/images/facebook-icon-share.svg"} alt="" style={{width:'10%'}} /></a></FbLogin>
              <div className="hide">
              <GoogleOAuthProvider clientId={GOOGLECLIENTID}>
                <GoogLogin setLoading={setLoading}><a href="#"><img src={process.env.PUBLIC_URL + "/assets/images/google-icon-share.svg"} alt="" style={{width:'10%'}}  /></a></GoogLogin>
              </GoogleOAuthProvider></div>
              </div>
</>              
}

        </div>
        </div>
        </div>
    );
}


export default Register;