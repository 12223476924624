import axios from 'axios';
import { object } from 'yup';
import {EncryptData, ConvertUrlToObject, DecryptData} from '../helpers/functions.js'
var randomString = require('random-string');
const milliseconds = Math.floor(Date.now() / 1000);
const trackerGeneration=()=>{
   return randomString({
        length: 12,
        numeric: true,
        letters: true,
        special: false
      });
} 


let trackerGenerationValue='';
if(localStorage.getItem('tracker')==null)
{
    trackerGenerationValue=trackerGeneration();
    localStorage.setItem("tracker", trackerGenerationValue);
}
else
{
    trackerGenerationValue=localStorage.getItem('tracker')
    if(trackerGenerationValue==='QUxNJhnBGXuI')
    {
       // alert('hello man')
    }
}


const ApiHelper = {
    
    excuteApi: function(api,method,paramsList,stateName=null){
        let tragetAPi=api+'&tracker='+trackerGenerationValue;
        let urlToObj = ConvertUrlToObject(tragetAPi,paramsList);
        let dataEnc = EncryptData(urlToObj.payload);

        //console.log(fullApi);
        const request = axios.create({
            baseURL: urlToObj.domain,
            timeout: 20000,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
                // or whatever you want, in key/value pair
            }
        })
        if(method==='get'){
             const req =  request.get('',{params:{"dataEnc":dataEnc}});
             return req.then(res=>{
                res.data = DecryptData(res.data,true);
                if(!stateName){
                    return res.data;
                 }                
                stateName(res.data)
                //console.log(res.data);
            })             
        }  
        else{
            const req = request.post('',{"dataEnc":dataEnc});
            return req.then(res=>{
                res.data = DecryptData(res.data)
                if(!stateName){
                    return res.data
                }                
                stateName(res.data)
                
            })               
        }
            
    },
    excuteApi2: function(api,method,paramsList,dispatch){
        //console.log(pamasList);
        let tragetAPi=api+'&tracker='+trackerGenerationValue;
        let urlToObj = ConvertUrlToObject(tragetAPi,paramsList);
        let dataEnc = EncryptData(urlToObj.payload);        
        const request = axios.create({
            baseURL: urlToObj.domain,
            timeout: 20000,
            headers: {
                //'Content-Type': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded'
                // or whatever you want, in key/value pair
            }
        })
        if(method==='get')
             return request.get('',{params:{"dataEnc":dataEnc}}).then(res=>{
                res.data = DecryptData(res.data,true);
                dispatch({type:'DETAILS_RESPONSE', data:res.data})
            })               
            else
                return request.post('',{"dataEnc":dataEnc}).then(res=>{
                    res.data = DecryptData(res.data,true);
                    dispatch({type:'DETAILS_RESPONSE', data:res.data})
                })               
            
    },
    excuteApi3: function(api,method,paramsList){
        //console.log(pamasList);
        let tragetAPi=api+'&tracker='+trackerGenerationValue;
        let urlToObj = ConvertUrlToObject(tragetAPi,paramsList);
        let dataEnc = EncryptData(urlToObj.payload);        
        const request = axios.create({
            baseURL: urlToObj.domain,
            timeout: 20000,
            headers: {
                //'Content-Type': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded'
                // or whatever you want, in key/value pair
            }
        })
        if(method==='get')
             return request.get('',{params:{"dataEnc":dataEnc}}).then(res=>{
                res.data = DecryptData(res.data,true);
                
                return res.data;
            })               
            else
                return request.post('',{"dataEnc":dataEnc}).then(res=>{
                    res.data = DecryptData(res.data,true);
                   return res.data;
                })               
            
    },    
}

export default ApiHelper;