import {DownloadSpeedMeter} from '../../core/DownloadSpeedMeter';

import type {FragLoadedData} from './hlsjs/HlsJsPlayerTypes';

export class HlsSpeedMeterAdapter {
  private meter: DownloadSpeedMeter;

  constructor(hls: any, meter: DownloadSpeedMeter) {
    this.meter = meter;
    hls.on(hls.constructor.Events.FRAG_LOADED, (_eventName, data) => this.handleFragmentLoadedEvent(data));
  }

  getDownloadSpeedMeter(): DownloadSpeedMeter {
    return this.meter;
  }

  handleFragmentLoadedEvent(eventData: FragLoadedData) {
    if (eventData.frag.type !== 'main') {
      // ignore non-video fragments
      return;
    }

    const httpStatus =
      eventData.networkDetails != null ? (eventData.networkDetails as {status: number}).status : undefined;
    if (httpStatus == null) {
      // ignore fragments that https status is undefined (networkDetails are null)
      return;
    }

    const stats = eventData.frag.stats;
    const loadingDuration = (stats.loading.end - stats.loading.start) / 1000;
    const timeToFirstByte = (stats.loading.first - stats.loading.start) / 1000;

    const measurement = {
      duration: loadingDuration,
      size: stats.total,
      timeToFirstByte: timeToFirstByte,
      timestamp: new Date(),
      httpStatus,
    };

    this.meter.addMeasurement(measurement);
  }
}
