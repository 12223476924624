import {useState, useContext, useEffect} from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {Form, Formik} from 'formik';
import CampaignJourneyStep1Schema from './schema/campaignJourneyStep1Schema';
import Elements from './elements/elements';
import {getAge} from '../helpers/functions';
import {AuthContext} from '../context/AuthContext'
import { UserContext as language } from '../context/languageContext';
import config from './config';
import '../assets/css/forms.css';
import Cookies from 'universal-cookie';
import SmileyIcon from '../assets/images/smiley.svg';

const {CustomInput, SelectInput, LoadingButton} = Elements;
var response = null; 
const cookies = new Cookies();

const CampaignJourneyStep4Themes = (props) => {
    const searchParams = new URLSearchParams(document.location.search);
    const media = require('./config/campMedia.json');

    let campaignType = searchParams.get('campType');
    if(!campaignType || campaignType == undefined || campaignType==0 || campaignType==1){
      campaignType = 1
    }
    let courseSelected = searchParams.get('courseSelected');//view theme based on

    if(!courseSelected || courseSelected == undefined ){
        courseSelected = 'default'
      }    
    let titlteParam='tmplt_'+courseSelected;
    let mediaType = media.find(item=>item.key==courseSelected)['mediaType'] || 1;
    let media_src = 'src'+mediaType+'_'+courseSelected;

    const navigate = useNavigate();
    const Auth = useContext(AuthContext);
    const Lang = useContext(language);

    useEffect(()=>{
        if(Auth.isLogged){
            navigate('/');
        } 

        if(props?.values?.values?.loginData?.data?.payload?.autologin){
            setTimeout(() => {
                Auth.successLoginProcess(props?.values?.values?.loginData,props?.values?.values?.loginData?.redirect); 
              }, 2000);                    
              return;            
        }
        else if(props?.values?.loginData?.data?.payload?.autologin){
            setTimeout(() => {
                Auth.successLoginProcess(props?.values?.loginData,props?.values?.loginData?.redirect); 
              }, 2000);                    
              return;            
        }   

        Auth.logTracking('registeration_page');

    },[campaignType])
   

    return(
        <>
        <div id="userReg-overlay"></div>
        <div className="theme-section theme-step4" style={{"padding": "0"}}>
        <div className="reg-form camp-theme-form4">
        <NavLink className="close-userReg" to="/"><span className="fa fa-fw fa-times"></span></NavLink>
        <img src={SmileyIcon} className='smiley-icon' alt="" /> 
        {/* <h2 className="camp-title">{Lang.tmplt_title1}</h2> */}
        <p className="camp-text">{Lang.tmplt_step4Text_1}<br></br>{Lang.tmplt_step4Text_2}</p>

        </div>
        </div>
        </>
    );
}


export default CampaignJourneyStep4Themes;