import * as Yup from 'yup';
import {getAge} from '../../helpers/functions';

//const professions = ['Doctor', 'Engineer', 'Programmer'];


const CampaignJourneyMobileStep1PasswordSchema = Yup.object().shape({


        mobile: Yup.string()
        .required('mobile_required_err')
        .matches(/^\d+$/, 'mobile_err'),
        
        password: Yup.string()            
        .min(8, 'password_min_err')
        .matches(/^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+$/,"password_english_err")        
        


  })

  export default CampaignJourneyMobileStep1PasswordSchema;