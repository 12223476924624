import React from "react";
import {useContext, useState,useRef, useEffect} from 'react'; 
import config from '../../Components/config';
import LoadingButton from '../elements/loadingButton';
import InputValidations from '../../helpers/inputValidations';
import ApiHelper from "../../helpers/ApiHelper";
import {AuthContext} from '../../context/AuthContext'
import {NavLink, useNavigate} from 'react-router-dom';
import FileUpload from '../../helpers/fileUpload';
import UploadedFiles from '../elements/uploadedFiles';
import '../../assets/css/modals.css'; 
import Langauge, {UserContext} from '../../context/languageContext';

const UploadProject=(props)=>
{
    //console.log(props.item)
    const Auth=useContext(AuthContext);
    const lagKeywords = React.useContext(UserContext);     
    let fileInput=useRef(null);

    const [projectName, setProjectName] = useState('');

    let [uploadedFiles,setUploadedFiles] = useState([]);
    useEffect( () => {
        if(props.item){
            getUploadedFiles(props.item.id, props.userIdSelected)
        }
    },[props.item]);

    const getUploadedFiles = (course_id,user_id) => {
        let courseId = parseInt(course_id);
        let userId = parseInt(user_id);
        Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('getUploadedProjects',{courseId,userId}).then( (res) => {
            if(res.data.status==1){
                setUploadedFiles(res.data.payload);
            }
            else{
                setUploadedFiles([]);
            }
        }).catch((err)=>{
            setUploadedFiles([]);
        });        
    }
    /*
    let [myFile,setMyFile]=useState('')
    let [myFileExt,setMyFileExt]=useState('')    
    //convert file to base 64
    const onChange=(e)=>
    {
        let files=fileInput.current.files
        let fName=(files[0].name)
        let fileExt = fName.split('.').pop();
        setMyFileExt(fileExt)
        let reader=new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload=(e)=>{
            console.log('image',e.target.result)
            setMyFile(e.target.result)
        }
    }

    const saveFile=()=>{
        //console.log(myFile)
        let uploaderUser=props.userIdSelected;
        let projectCourse=props.item.id
        Auth.authorizedRequest( {
            "Content-Type":'multipart/form-data',
          }).post('saveNewProjectFile/'+myFileExt,{uploaderUser:uploaderUser,projectCourse:projectCourse,myFile:myFile,fileName:myFileExt})


    }
    */   
    return(
<div id="UploadProject" className="modal fade" role="dialog">
    <div className="modal-dialog upload-modal">
        <div className="modal-content">
            <div className="modal-body">
                <div>
                    <h1 className='upload-text'>{lagKeywords.upload_your_project+ " "}<span className="course-name">{props.item.name}</span></h1>
                    {/* <input type="file" ref={fileInput} name="file" onChange={(e)=>onChange(e)} /> */}
                    

                    <div className="form-group" style={{"margin": "0", width:'50%'}}>
                        <div className="reg-field">
                            <label htmlFor="project_name">{'Project Name *'}</label>
                            <input className="reg-input" id ="project_name" name="project_name" type="text" value={projectName} onChange={(e)=>setProjectName(e.target.value)}/>
                        </div>
                    </div>


                    <FileUpload onComplete={() => { window.$('.modal').modal('hide'); window.location.href="/dashboard"}} disabledsubmit={projectName ? 0:1} fun={{getUploadedFiles}}  id="project_file" hasbutton="true" url={`saveNewProjectFile`} data={{userId:props.userIdSelected,courseId:props.item.id, projectName}}/>                        
                    <UploadedFiles fun={{getUploadedFiles}} data={uploadedFiles} params={{userId:props.userIdSelected,courseId:props.item.id}}/>
                    {/* <button onClick={saveFile} className="btn btn-primary">Save your file</button> */}
                    <h3 className="progressing-note">{lagKeywords.progressing_note+ " "}{Math.round(props.item.progress)}%</h3>
                </div>
            </div>
        </div>
    </div>
</div>

    )
}

export default UploadProject;