import elements from './elements/elements';
import {Form, Formik} from 'formik';
import LoadingButton from './elements/loadingButton';
import { UserContext } from '../context/languageContext';
import { useContext, useEffect, useState } from 'react';
import config from './config';
import { AuthContext } from '../context/AuthContext';
import MessageModal from './modals/messageModal';

const NotificationsManagement = (props) => {

    const Lang = useContext(UserContext);
    const {CheckboxInput} = elements;

    const [data, setData] = useState([]);
    const [isLoading, setLoading] = useState(0);
    const [message, setMessage] = useState({title:'',body:''});
    const Auth = useContext(AuthContext)
    useEffect(()=> {     
        if(!Auth.isLogged){
            window.location.href = '/';
            return ;
        }
        Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('notificationSettings').then(res => {            
            setData(res.data.payload);            
            //data = res.data.payload
            //console.log(data);
        })
    },[])
    let settings = [];
    config.notification_types.forEach((item)=> {
        settings[item.keyname] = data[item.keyname] ? true : false;
    });
    

    
    const initValues = {
        ...settings
    };
    //console.log(initValues);

    const onSubmitStep = async (values, actions) => {
        let v = {'notifi_setting':values};
        setLoading(1);
        Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('saveNotificationSettings',v).then(res => {
            //console.log(res);
            if(res.data.status==1){
                setMessage({title:Lang.notifi_manage,body:Lang.data_saved_success});
            }
            else{
                setMessage({title:Lang.notifi_manage,body:Lang.data_saved_faild});
            }
            
            setLoading(2);
            window.$('#save-message').modal('show');
        }).catch((err) => {
            setLoading(2);
            setMessage({title:Lang.notifi_manage,body:Lang.data_saved_faild});
            window.$('#save-message').modal('show');
        });

      };     

return (
    <div className="section-container">
        <div className="content-container price-step">

            <div className='notif-top'>
                <img className='svg-logo2' src={process.env.PUBLIC_URL + "/assets/images/notification_m.svg"} alt={Lang.notifi_manage} /> 
                <p className='notif-title'>{Lang.notifi_manage}</p>
            </div>  

            <Formik
      initialValues={initValues}
     // validationSchema={RegisterSchema}
      onSubmit={onSubmitStep}  
      enableReinitialize
    >

{(props) => {
    //console.log(props.values.dob_year);
    let {values, errors} = props;

    return(
        <Form>

            <div className='checkboxes-container'>
                {
                    config.notification_types.map((item) =>(
                        (item.account_type.includes(parseInt(Auth.getAccountType()))) ?
                        <div key={item.id} className='rounded-checkbox'>
                            <CheckboxInput labels={[Lang[item.keyname]]} ids={['ckbox'+item.id]} values={[1]} name={`${item.keyname}`} className=""/>
                        </div>                        
                        :''
                    ))                      
                }
            </div>                              

            <LoadingButton loading={isLoading == 1 ? `true`:`false`} type="submit" className="submit-button-auto reg-btn" loading-text="<i class='fa fa-circle-o-notch fa-spin'></i> Processing" >{Lang.save}</LoadingButton>
        </Form>    
    )}}
    </Formik>                

        </div>
        <MessageModal title={message.title} id="save-message" okLabel="Close">
        {message.body}
      </MessageModal> 
    </div>
)
}

export default NotificationsManagement