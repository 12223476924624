import { useContext, useState } from "react";
import { UserContext } from "../../context/languageContext";
import { otherLanguageCourseName } from "../../helpers/functions";
import config from "../config";
import { NavLink } from "react-router-dom";

const CourseItem = ({data}) => {

    const lang = useContext(UserContext);
    const name = otherLanguageCourseName(data, lang.getSelectedLanguage());
    return (
        <>
            <NavLink className="each-course" to={'/course/'+data.moodle_course_id+'/'+name?.split(' ').join('-')}>
                <img className="course-poster-icon" src={config.imgDns+ "icon/"+data.moodle_course_id+"_large.png"} alt={name}/>

                <p className="each-course-name">{name}</p>
            </NavLink>



            {/* <div> course name : {name}</div>
            <div> course image : <img src={config.imgDns+ "icon/course_"+data.moodle_course_id+".svg"}/></div>        
            <div> course link : {'/course/'+data.moodle_course_id+'/'+name?.split(' ').join('-')}</div> */}
        </>
    );
}

export default CourseItem;