
import React, { useContext, useState, useEffect } from 'react';
import paypalConfig  from './config/paypal';
// import { PayPalButton } from "react-paypal-button-v2";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { AuthContext } from '../context/AuthContext';


const PaypalBtn= (props) => {
    
    let {price=1, successCallback= null, subscriptionType = 4, planId = 0, style = {width: '230px', margin : '10px auto', fontSize: '25px'}, promoCode, disablePaypal = false, buttonStyle={disableMaxWidth: true, shape: 'pill'} }= props;
    const amount = price;
    const Auth = useContext(AuthContext);
    const [paypalPlanId, setPaypalPlanId] = useState(planId);
    const [paypalActions, setPaypalActions] = useState(null);
    const [disabled, setDisabled] = useState(false)
    /** 
     * subscriptionType
     * 1 : yearly
     * 2 : monthly
     * 3 : daily
     * 4 : one time
     */

    useEffect( () => {
        checkPaypalDiscount();
      },[promoCode])    
    let options = {
        currency: 'USD',
        clientId : paypalConfig.ENVIRONMENT == 'production' ? paypalConfig.PRODCUTION_CLIENT_ID : paypalConfig.SANDBOX_CLIENT_ID,
        disableFunding: 'card,credit,paylater',
        vault : subscriptionType != 4 ? true : false,
        //debug : true
    }


    const onError = (err) => {
        console.log("Error!", err)
    }
    
    const onSuccess = (payment) => {
        console.log(payment);
        console.log('success function');
        //return 
        successCallback(payment,setDisabled)
        // if(payment?.status == "COMPLETED" && payment?.id){
        //     console.log('paypal success');            
        //     if(successCallback !=  null){
        //         console.log('paypal success callback');
                
        //     }
        // }
    }

    const checkPaypalDiscount = () => {

        Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('checkPaypalDiscount',{paypalPlanId : planId, promoCode: promoCode}).then( res => {
          if(res?.data?.status ==1){
            if(res.data?.payload?.planId && res.data?.payload?.planId != paypalPlanId){
              setPaypalPlanId(res.data.payload.planId);                
            }
          }
          //actions.enable();
      }).catch( err => {
        //actions.enable();          
      })      
    }    
    
    const onCancel = (details, data) => {
        console.log("The payment was cancelled!", data);
        setDisabled(false);
    }

    const createSubscription = (data, actions) => {
        setDisabled(true);
          console.log('create subscription',paypalPlanId)
          return actions.subscription.create({
              plan_id: paypalPlanId
            }).then((orderId) => {
              // Your code here after create the order
              console.log('order id => ',orderId)
              return orderId;
          });;
      }
      const onInit = (data, actions) => {   
        //console.log(props.promoCode);  
          setPaypalActions(actions);
          //actions.disable();
  
          // actions.disable();
          //actions.enable();
      }

    const catchError=(err) => {
        setDisabled(false);
        console.log('catch error');
    }    
    const onApprove = (data, actions) => {    
        console.log('order approved')    
        // Capture the funds from the transaction
        console.log(data);
        console.log(actions);
        onSuccess(data);
        // return actions.subscription.get().then(function(details) {
        //   // Show a success message to your buyer
        //   console.log("Subscription completed");
          
        // })
    }

    const createOrder = (data, actions) => {
        console.log('careate order')
        return actions.order.create({
          purchase_units: [{
            amount: {
              value: amount
            }
          }]
        });
      
    } 

    let subscriptionAttributes = {onSuccess:onSuccess};
    if(subscriptionType != 4){
        subscriptionAttributes = {createSubscription : createSubscription, onApprove : onApprove};
        options['intent'] = 'subscription';
         options['clientId'] = paypalConfig.PRODCUTION_CLIENT_ID;
        if(paypalConfig.ENVIRONMENT != 'production'){
            options['clientId'] = paypalConfig.SANDBOX_CLIENT_ID
        }
    }

    return (
        <div style={{...style, position:'relative'}}>
            {(disabled || disablePaypal) && <div className='shader' style={{position:'absolute',top:0,left:0,right:0,width:'100%', zIndex:10000,height:'100%',background:'white',opacity:'0.5'}}></div>}
            {/* <PayPalButton            
            options = {options}
            amount={amount}
            onInit = {onInit}
            onError={onError}
            catchError ={catchError}
            onCancel={onCancel}
            {...subscriptionAttributes}
            style = {{disableMaxWidth: true, shape: 'pill'}}
            /> */}

        <PayPalScriptProvider options = {options}>
            <PayPalButtons
            amount={amount}
            onInit = {onInit}
            onError={onError}
            catchError ={catchError}
            onCancel={onCancel}
            {...subscriptionAttributes}
            style = {buttonStyle}            
            />
        </PayPalScriptProvider>            
        </div>
        );

}

export default PaypalBtn;