import React, { Component } from 'react';
import '../index.css';
import {UserContext} from '../context/languageContext'
import {useState} from 'react';
import {useEffect} from 'react';
import Config from './config';
import ApiHelper from "../helpers/ApiHelper";
import '../assets/css/faqs.css'
const Faqs=()=>{

  const lagKeywords = React.useContext(UserContext);   

  const value = React.useContext(UserContext);  
  //console.log(user)
  let [introLp, setintroLp] = useState([]); 
  let introApi=Config.getGeneralData;
  useEffect(()=>{
    ApiHelper.excuteApi(introApi+'faqs&lang='+value.getSelectedLanguage(),'get','{}',setintroLp);


    window.$('body').on('change','.input-accordion',function(e){
      e.stopPropagation();
      e.stopImmediatePropagation();
      window.$('.input-accordion').not(window.$(this)).prop('checked',false);
  })

    },[]        
  )  

  document.querySelector('meta[name="description"]').setAttribute("content", lagKeywords.faqs_page_tag_desc);  
  document.querySelector('meta[name="keywords"]').setAttribute("content", lagKeywords.faqs_page_tag_keywords);          
  document.title =  lagKeywords.faqs_page_title;  

  //console.log(introApi+'faqs')
  return(

    <>
    
    <div className='faqs-container' dangerouslySetInnerHTML={ {__html: introLp.faqs} }></div>
    </>
  )
}

export default Faqs 