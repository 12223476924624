import { Form,Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import * as Yup from 'yup';
import { UserContext } from "../context/languageContext";
import Elements from '../Components/elements/elements';
import img from './images/laptop.svg'
import { AuthContext } from "../context/AuthContext";

import { GoogleOAuthProvider } from "@react-oauth/google";
import googleConfig from '../Components/config/google.js'
import GoogLogin from "../Components/elements/googleLogin";
import SurveyResult from './surveyResult.js';
import loadingIcon from './images/loader.gif'; 

const GOOGLECLIENTID = googleConfig.GOOGLE_CLIENT_ID;

const EmailSchema = Yup.object().shape({
    email: Yup.string().required('email_required_err')
    //.email('email_format_err')
    .test('validator-username-email', (value, { createError,resolve }) =>{
        //const emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        const emailRegex = /^\s*([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+\s*$/;
        if(value && !emailRegex.test(value)){
                                         
                return createError({
                        message: `email_format_err`,
                        path: 'email', // Fieldname
                })
                
        }
        return true;
    }),    
})

const {CustomInput, LoadingButton} = Elements;

const SurveyEnd = ({dispatchWizard,values,questions, fullWidth=false}) => {

    const language = useContext(UserContext);    
    const Auth = useContext(AuthContext);   
    const [response,setResponse] = useState("");
    const [loading,setLoading] = useState(0);
    const [formValues, setFormValues] = useState({email:'', stk:'',regType:0});
    // const [email,setEmail] = useState('');
    // const [stk,setStk] = useState(''); // social access token
    // const [regType, setRegType] = useState(0) // default by email
    let currentLang = language.getSelectedLanguage();

    const searchParams = new URLSearchParams(document.location.search);
    const surveyType = searchParams.get('surveyType') || 1;
    let showResultForAll = false;
    if(surveyType ==2){
        showResultForAll =true;
    }
    
    

    useEffect( () => {
        if(Auth.isLogged){
            handleSubmit({email:''});
        }
    },[])   
    
    
    const handleSubmit = (values) => {        
        // dispatchWizard({type:'NEXT',data:{}}); 
        //console.log(questions.questions)
        //return;        
        const isLong = document.location.pathname == '/survey/long' ? 1 :0;
        const englishSurvey = require('./questions/en'+(isLong ? '_long' : '')+'.json');
        let surveyJson = questions.questions.map(q => {
            let questionShared = englishSurvey.questions.find(qEn => qEn.id == q.id);// q;
            return ({                
                answers :   q.answers.filter(a => a?.selected == true).map(a =>  questionShared?.answers.find(aEn => aEn.id == a.id)?.text || ""),
                question : questionShared.text,
                desc : questionShared.desc,
                isMultipleAnswers:questionShared.isMultipleAnswers
            })
        }
        );
        // console.log(surveyJson)

        setLoading(1);        
        Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'},{},'survey').post('index',{surveyType,survey:surveyJson, email:values.email, stk:values.stk, regType: values.regType, language:currentLang}).then( res => {
            setResponse(res.data.payload);
            setLoading(2);
            if(!Auth.isLogged && !showResultForAll){

                if(res.data?.payload?.redirect){
                    window.location.href =  res.data.payload.redirect;
                }
                else{
                    setTimeout(() => {
                        window.location.href =  '/';
                    }, 2000);
                }

            }           
        }).catch( err => {
            setLoading(2)
        });
        //console.log(values.email);       
    }

    const initValues = {
        email: formValues.email, 
        stk: formValues.stk,
        regType: formValues.regType
    };

    return (
        <>
        { !Auth.isLogged && !response &&
            <div className="survey-question-body" style={fullWidth ? {maxWidth:'100%'}:{}}>
            <img className="survey-form-img" src={img} alt={language.survey_end_1} />
            <h1>{language.survey_end_1}<br/>{language.survey_end_2}</h1>
            <div className="intro-int" style={{width:'100%', maxWidth:'700px', margin: '50px auto'}}>
            <Formik
                enableReinitialize
                initialValues={initValues}
                validationSchema={EmailSchema}
                onSubmit={handleSubmit} >
                <Form>
                <div className="form-group" style={{"margin": "0"}}>
                <div className="reg-field survey-field">
                        <GoogleOAuthProvider clientId={GOOGLECLIENTID} className="social-login-container">
                            <GoogLogin getEmailOnly ={true} setLoading={setLoading} successLoginRedirect={'prices'} registerCalback={(email,accessToken)=> {setFormValues({email,stk:accessToken,regType:2});handleSubmit({email,stk:accessToken,regType:2})}}>
                                <div className="reg-button gray-bordered">                
                                    <img src={process.env.PUBLIC_URL + "/assets/images/google-icon.svg"} alt="google" style={{width:'30px'}}  />
                                    <p>{language.reg_by}</p>
                                </div>
                            </GoogLogin>
                        </GoogleOAuthProvider>
                    </div>
                    </div>
                    <div className="horizontal-line">
                       <hr style={{width:'85%', maxWidth:'400px', color:'#999999',margin: '0 auto'}} />
                       <span className="or">{language.or} </span>
                    </div>
                    <div className="form-group" style={{"margin": "0"}}>
                        <div className="reg-field survey-field">
                            <label htmlFor="email"></label>
                            <CustomInput name="email" id="email" type="text" placeholder={language.enter_your_email+''} className="reg-input survey-email" />
                            <CustomInput name="stk" id="stk" type="hidden"  />
                            <CustomInput name="regType" id="regType" type="hidden"  />
                        </div>
                    </div>
                    <LoadingButton loading={loading == 1 ? 'true' : 'false'} type="submit" className="submit-button-auto reg-btn submit-survey">{language.continue}</LoadingButton>



                </Form>            
            </Formik>
            </div>
            </div>
        }
        { loading == 2 && !Auth.isLogged && !showResultForAll &&
            <div className="survey-question-body" style={fullWidth ? {maxWidth:'100%'}:{}}>
            <h1 style={{'paddingTop':'10%'}}>{language.survey_finish_msg}</h1>
            </div>
        }
        { loading == 1 && (Auth.isLogged || showResultForAll) &&
            <div className="survey-question-body" style={fullWidth ? {maxWidth:'100%'}:{}}>
            <h1 style={{'paddingTop':'10%'}}>{language.survey_finish_msg_logged}</h1>
            <img className="loaderIcon" src={loadingIcon} alt={language.survey_finish_msg_logged} />
            </div>
        }   
        { loading == 2 && (Auth.isLogged || showResultForAll) &&
            <>
            <div className="survey-question-body" style={fullWidth ? {maxWidth:'100%'}:{}}>
                {/* <h3 style={{marginTop:'0px',paddingTop:'10px'}}>{language.assessment_result} : </h3> */}
                <br/>
                <br/>
                {/* <p style={{textAlign: currentLang=='ar'? 'right':'left',direction: currentLang=='ar'? 'rtl':'ltr',padding: '20px'}}>{htmlBrToReacBr(response?.message || '')}</p> */}
                <div style={{textAlign: currentLang=='ar'? 'right':'left',direction: currentLang=='ar'? 'rtl':'ltr'}}>{response?.message && <SurveyResult redirect={response?.redirect} promoCodeData={response?.promoCodeData} data={response?.message} isSubscriber={response?.is_subscriber || 0} />}</div>
                <center>
                {/* <LoadingButton onClick={()=> window.location.href= response?.redirect || localStorage.getItem('basename') + '/courses'} type="button" className="submit-button-auto reg-btn submit-survey">{language.continue}</LoadingButton> */}
                </center>
            </div>
            </>
        }      
        </>
    );
}

export default SurveyEnd