import * as Yup from 'yup';
import {getAge, usernameSuggestions} from '../../helpers/functions';

//const professions = ['Doctor', 'Engineer', 'Programmer'];


const RegisterChildSchema = Yup.object().shape({

//     dob: Yup.string()
//             .required('dob_required_err'), 
dob_day: Yup.number('dob_required_err').required("dob_required_err").min(1, 'dob_required_err'), 
dob_month: Yup.number('dob_required_err').required("dob_required_err").min(1, 'dob_required_err'), 
dob_year: Yup.number('dob_required_err').required("dob_required_err").min(1, 'dob_required_err'), 
    first_name: Yup.string()
            .label('Fisrt Name')
            .required('fname_required_err')
            .matches(/^[aA-zZ\s\u0600-\u06FF]+$/, "fname_pattern_err"),
    last_name: Yup.string()
            .label('Last Name')
            .required('lname_required_err')
            .matches(/^[aA-zZ\s\u0600-\u06FF]+$/, "lname_pattern_err"),
    email: Yup.string()
            .required('email_required_err')
            //.email('email_format_err'),  
            .test('validator-username-email', (value, { createError,resolve }) =>{
                //const emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
                const emailRegex = /^\s*([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+\s*$/;
                //const nameRegex = /^[a-zA-Z]+$/;
                const nameRegex = /^[A-Za-z]+[A-Za-z0-9]*$/;
                const y = resolve(Yup.ref('dob_year'));
                const m = resolve(Yup.ref('dob_month'));
                const d = resolve(Yup.ref('dob_day'));                
                const age = getAge(y+'-'+m+'-'+d);
                if(!emailRegex.test(value)){
                        //if(age < 18 && !nameRegex.test(value)){
                        if(!nameRegex.test(value)){
                                return createError({
                                        message: `email_format_or_username`,
                                        path: 'email', // Fieldname
                                      })
                        }
                        // else if(age >= 18){
                        //         return createError({
                        //                 message: `email_format_err`,
                        //                 path: 'email', // Fieldname
                        //         })
                        // }                        
                }
                return true;
            })

            .test('validator-parentemail-email', async (value, { createError,resolve }) =>{
                const parent_email = resolve(Yup.ref('parent_email'));                
                const same = parent_email == value ;
                if(same && (value!='' && value !=undefined)){
        
                        const fname = resolve(Yup.ref('first_name')); 
                        const lname = resolve(Yup.ref('last_name')); 
                        const email = resolve(Yup.ref('email')); 
                        const dob_year = resolve(Yup.ref('dob_year')); 
                        const dob_month = resolve(Yup.ref('dob_month')); 
                        const dob_day = resolve(Yup.ref('dob_day'));

                        let dobConcat = '';
                        if(dob_year) dobConcat += '_'+dob_year;
                        //if(dob_month) dobConcat += ''+dob_month;
                        //if(dob_day) dobConcat += ''+dob_day;
                        const sugg = await usernameSuggestions('son'+dobConcat,email.split('@')[0]);
                        if(sugg){
                                return createError({
                                        message: `parent_same_email_child_sugg,(${sugg})`,
                                        path: 'email', // Fieldname
                                })
                        }
                        else{
                                return createError({
                                        message: `parent_same_email_child`,
                                        path: 'email', // Fieldname
                                })                                
                        }

                                
                }
                return true;
            }),            

    mobile: Yup.string().matches(/^\d+$/, 'mobile_err'),
    password: Yup.string()
                .when('id', (val, schema) => {
                        if(val == 0){
                                return schema.required('password_required_err'); 
                        }
                                return schema;
                })
            .min(8, 'password_min_err')
            .matches(/^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+$/,"password_english_err"),
            //.matches(/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!-#_+])[a-zA-Z0-9!-#_+]+$/,"password_pattern_err"),
    gender: Yup.number().required().moreThan(0,'required_field'),

  })

  export default RegisterChildSchema;