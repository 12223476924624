import { Button, Spinner } from 'react-bootstrap';
import parse from 'html-react-parser'
import { UserContext as language } from '../../context/languageContext';
import { useContext } from 'react';

const LoadingButton = (props) => {
    //let attributes = Object.keys(props).filter((key)=>{ return key != 'children'}).map( (key) => { return `${key} = "${props[key]}"`}).join(' ');
    //console.log(props.loading);
    const Lang = useContext(language);
    let loadingText = "Processing...";
    if(props['loading-text']){
        loadingText = props['loading-text'];
    }
    if(props.loading == 'true')    
        return (<button disabled {...props} >
            <Spinner
                as="span"
                variant="warning"
                size="sm"
                role="status"
                aria-hidden="true"
                animation="grow"/>
                {parse(loadingText)}
        </button>)        
    else
        return (<button {...props} >{props.children}</button>)
        
}

export default LoadingButton;