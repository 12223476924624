import React, {useState,useEffect,useContext,useRef} from 'react';
import '../index.css';
import {UserContext} from '../context/languageContext'
import Config from './config';
import ApiHelper from "../helpers/ApiHelper";
import '../assets/css/cards.css';
import '../assets/css/contact-us.css';
import {AuthContext} from '../context/AuthContext';
import { NavLink } from 'react-router-dom';
import { ReplaceStringWithElement, ReplaceTagStringWithElement } from '../helpers/functions';
import MessageModal from './modals/messageModal';
import PlanItem from './sections/planItemUpgradeDowngrade';
import { useNavigate } from 'react-router-dom';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import {UserContext as language} from '../context/languageContext';
import {getDeviceInfo} from '../helpers/deviceInfo';
const Prices=(props)=>{


  const Auth = useContext(AuthContext);
  //console.log(Auth.getUserName())
  let checkPromoResult='';
  const promoCodeValue = useRef(null);
  const keywords = useContext(UserContext);  
  const navigate = useNavigate();
  //console.log(user)
  const lang = useContext(language);

  const langDetect = lang.getSelectedLanguage();

  let [plans, setPlans] = useState([]); 
  let [isLoading, setLoading] = useState(0); 
  let [isActiveAccount, setActiveAccount] = useState(0); 
  const [unsubReqloading, setUnsubReqloading] = useState(0); 
  const [isYearly, setIsYearly] = useState(1);
  const [disabled, setDisabled] = useState(false);
  const [cardSelected, setCardSelected] = useState(1);
  const [message, setMessage] = useState({title:'', body:''}); 
  let [promoDiscount, setpromoDiscount] = useState([]); 
  let plansApi=Config.plansApi;
  let [filedPromoCode,setFilledPromoCode]=useState([]);
  let promoCodeDetails=Config.promoCodeDetailsApi;
  let accType = 0; 
  let numChildren = 0
  if(props?.values?.values){
    accType = props.values.values.accType || 0;
    numChildren = props.values.values.numChildren || 0;
  }

  const [numChild, setNumChild] = useState(numChildren);
  const [isMobile, setIsMobile] = useState(false);
  let totalSteps = 3;
  if(props?.values?.total_steps){
    totalSteps = props.values.total_steps;
  }
  let currentStep = 3;
  if(props?.step){
    currentStep = props.step;
  }  
  useEffect(()=>{
      getSubscriptionPlans(accType, numChild);

      getDeviceInfo().then(res => {
        setIsMobile(res?.isMobile);
    });  

    },[]        
  )  
 

  const setSubFrequency = (ev) => {
    let isChecked = ev.target.checked;
    if(isChecked == true){
      setIsYearly(1);
    }
    else{
      setIsYearly(0);
    }
  }


  const getSubscriptionPlans = (accType, numChildren) => {
    setNumChild(numChildren)
    setLoading(1);
    //console.log(accType, '->' , numChildren)
    Auth.isActiveAccount().then(res => {
        if(res == true){
          setActiveAccount(1);
        }
            Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('getPriceCards', {accType,numChildren, new_logic:1}).then(res => {
              setPlans(res.data);
              setLoading(2);
              if(res.data?.payload?.subscription_type ==1){
                setIsYearly(0);
              }
              if(res.data?.payload?.card_id_sub_in !=0){
                setDisabled(true);
              }
            });

        //}
        //else{
        //  setLoading(2);
        //}
    });
        
  }

  const showGewneralMessage = (title='',message='') => {

    setMessage({title:title, body:message});
    window.$('#general-modal').modal('show');   

  }
  const unsubscribePlan = () => {

    setUnsubReqloading(1);
    const userLanguage = keywords.getSelectedLanguage()
    Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('unsubscribePlan',{language:userLanguage}).then(res => {    
      setUnsubReqloading(2);
      if(res.data.status ==1 ){
        setMessage({title:keywords.unsub, body:keywords.success_unsub});
        plans.payload.can_sub = 1;
      }
      else{
        setMessage({title:keywords.unsub, body:keywords.failed_unsub});
      }
      window.$('#general-modal2').modal('show'); 
      setTimeout(() => {
          window.location.reload();
      }, 1300);   
    })
    .catch(err => {
      setUnsubReqloading(2);
      setMessage({title:keywords.unsub, body:keywords.failed_unsub});
      window.$('#general-modal2').modal('show');    
    })
  }

  const showLoginMessage = () => {
    localStorage.setItem('redAfterLogin','prices');
    window.$('#nonLogged-price-modal').modal('show');   
    return; 
  }

  const sendActivationLink = () => {
      showGewneralMessage('', 
          ReplaceTagStringWithElement(keywords.account_not_activated_click, 'link', 
              <NavLink onClick={
                ()=>{
                  showGewneralMessage('',keywords.processingDots);
                    Auth.sendActivationEmail().then(res =>  
                    res==true ? 
                    showGewneralMessage('',keywords.success_send_activation) :
                    showGewneralMessage('',keywords.failed_send_activation))
                }
              }/>
          )
      )    

  }

  const CheckPromo=(packageId,currentPrice)=>
  {
    
    var promoValue=window.$("#promoCode_"+packageId).val();
    //console.log(promoValue);
    var PromoResult=ApiHelper.excuteApi(promoCodeDetails+'packageId='+packageId+'&promoValue='+promoValue,'get','{}').then((res) => {
          //setpromoDiscount(res);
          promoDiscount = res;
          //console.log(res)


          if(promoDiscount.valid)
          {
            var newPrice = document.getElementById("promoCodeResult_"+packageId).innerHTML=((currentPrice-(currentPrice*promoDiscount.discount)));
            window.$("#promoCodeResult_"+packageId).html('<span style="color:#0ea502; font-size: 13px;">' + keywords.valid_promocode+'<br>' + keywords.price_after_discount +'<strong style="font-size: 16px;">'+ newPrice.toFixed(2) +'$' +'</strong>' + '</span>' );
            setFilledPromoCode('?prefilled_promo_code='+promoValue);
          }
          else
          {
            document.getElementById("promoCodeResult_"+packageId).innerHTML= currentPrice;
            document.getElementById("promoCodeResult_"+packageId).innerHTML= '<span style="color:red; font-size: 13px;text-align:right;direction:rtl;">' + keywords.sorry+'<br>'+keywords.not_valid_promocode+'</span>';
          }

    });    


    return false;
    
  }

  //console.log(isLoading,isActiveAccount)
  //if(isLoading ==2 && isActiveAccount ==0){
  //  return <div style={{marginTop:'100px'}}><center><h1>{keywords.account_not_activated}</h1></center></div> 
  //}
  
  //nsole.log(isMobile+' mob')
  return(


      

    <>    
  
    {   
    isLoading == 2 && (
    <>
    {
          !Auth?.isLogged &&
        <button className='back-btn' onClick={()=>props.dispatchWizard({type:'RESET',data:{goTo:localStorage.getItem('basename')+'/courses'}})  }></button>
    }

    {
      <>
       <h1 className='prices-title' style={{marginBottom:'30px'}}>
          <span className='price-logo'>{keywords.plans_title}<img className='svg-logo2' src={process.env.PUBLIC_URL + "/assets/images/mobNav_prices.svg"} alt={keywords.prices} /></span>
          <span className='hide' style={{color: '#d9d9d9'}}>{currentStep}/{totalSteps}</span>
        </h1>         
       </>
    }

    <center className='price_desc' style={{marginBottom:'30px', fontSize:'22px', direction:keywords.getSelectedLanguage()=='ar' ?'rtl':'ltr'}}>
      {keywords.plans_desc1}
      {/* {keywords.plans_desc2} */}
    </center>
    {    
      Auth.isLogged && accType == 2 && 
      <div className='hide' style={{textAlign : 'center',padding: '10px 0 40px 0'}}>
        <div style={{fontSize:'18px'}}>{keywords.number_of_children}</div>
        <select className='reg-input' style={{width:'auto',marginTop:'10px'}} onChange={(e)=>getSubscriptionPlans(accType, e.target.value)}>
        <option value={0}>{keywords.number_of_children}</option>
          <option selected={parseInt(numChild)==1 ? true:false} value={1}>1</option>
          <option selected={parseInt(numChild)==2 ? true:false} value={2}>2</option>
          <option selected={parseInt(numChild)==3 ? true:false} value={3}>3</option>
        </select>
      </div>
    }
    {/* Cards - plans Swicher */}
    <div className='p-card-swicher'>
    <div className="checkbox-wrapper-64">
      <div>{keywords.quarterly}</div>
      <label className="switch">
        {/* <input disabled = {disabled} checked={isYearly ? true : false} value={1} type="checkbox" onChange={(e)=> setSubFrequency(e)} /> */}
        <input checked={isYearly ? true : false} value={1} type="checkbox" onChange={(e)=> setSubFrequency(e)} />
        <span className="slider"></span>
        {
          // disabled && <div className='disableSwaitcher' style={{position:'absolute',top:'0',bottom:'0',right:'0',left:'0',borderRadius:'30px',background:'rgba(253 ,253 ,253 ,0.7)'}}></div>
        }        
      </label>
      <div>{keywords.yearly}</div>
    </div>
    {/* <div className='p-card-offer'><strong>{keywords.card_offer_30_1} </strong>{keywords.card_offer_30_2}</div> */}

  {isYearly==1 && !isMobile && <div className='p-price-discount'>
  {/* <div className='p-discount-left'>
    <div className='p-label-circle'></div>
    <div className='p-per-offer'>{keywords.card_offer_30_1}</div>
  </div>
  <div className='p-discount-right'>{keywords.card_offer_30_2}</div> */}
  <img src={`${Config.serverDomain}icon/Pricing-37${langDetect!='en' ? '_'+langDetect : ''}.png`}/>
</div>}

{isYearly==1 && isMobile && <div className='p-price-discount-full'>
   <img src={`${Config.serverDomain}icon/Pricing-37${langDetect!='en' ? '_'+langDetect : ''}.png`} />
</div>
}

    </div>

      <div className="cards">
      <Tabs
   defaultActiveKey={plans.payload?.show_family ==0 ? 1 : 2}
  transition={false}
  id="fill-tab-example"
  className="mb-3"
  fill> 
          {
            plans.payload?.cards?.map( (item) => {
              //let planTypeText = Config.planTypes.find( typeItem => typeItem.id == item.plan_type );
              //let subTypeText = Config.subTypes.find( subItem => subItem.id == item.subscription_type );
              if(item?.type == 2 && plans.payload?.show_individual == 0){
                  return '';
              }
              else if(item?.type == 1 && plans.payload?.show_family == 0){
                return '';
              }              
              else
                return (
                          
//  (!plans.payload?.card_id_sub_in || (plans.payload?.card_id_sub_in !=0 && item.id == plans.payload?.card_id_sub_in)) &&
    <Tab eventKey={item.id} title={
    <div className='p-card-tab' onClick={() => setTimeout(() => {
      //document.getElementsByClassName("p-card-check-container")[0].scrollIntoView();
      //document.getElementsByClassName("p-card-check-container")[1].scrollIntoView();
    }, 200)}>
      <div className='p-card-tab-icon'><img src={item?.logo} alt={item['name_'+langDetect]} /></div>
      <p style={{color:item?.text_color || 'black'}}>{item['name_'+langDetect]}</p>
    </div>
    }>

                  <PlanItem 
                    item={item} 
                    keywords={keywords} 
                    //planTypeText={planTypeText} 
                    //subTypeText={subTypeText} 
                    //canSub={plans.payload?.can_sub}
                    canSub={true}
                    showLoginMessage={showLoginMessage} 
                    sendActivationLink={sendActivationLink}
                    CheckPromo = {CheckPromo}
                    hasPromoCode = {false}
                    isLogged = {Auth.isLogged }
                    isActiveAccount ={isActiveAccount}
                    showButtons = {(accType!=3 ? true : (plans.payload?.has_parent ? false : true))}
                    cardIdSubIn={plans.payload?.card_id_sub_in}
                    planIdSubIn={plans.payload?.plan_id_sub_in}
                    isYearly={isYearly}
                    //subscriptionType = {item.subscription_type}
                    />
                     </Tab>
  
                  
                )
            })
          }
            </Tabs>
        </div>


        {
          plans.payload?.can_sub == 0 && (
          
          // <h3><center>To be able to subscribe new plan, Go to settings to unsubscribe from current plan before.</center></h3>
          
            unsubReqloading ==1 ? 
            <h3><center>{keywords.waiting}</center></h3>
            :
            (
                plans.payload?.is_active ? 
                ''// <h3><center>{ReplaceStringWithElement(keywords.unsub_before_sub, '{0}', <a onClick={unsubscribePlan} href="javascript:" >{keywords.unsub}</a> )}</center></h3>
                :
                <h3><center>{ReplaceStringWithElement(keywords.recharge_or_change_payment_method, '{0}', <a onClick={() => navigate('/changePaymentMethod')} href="javascript:" >{keywords.here}</a> )}</center></h3>
            )
          
          
          )
         
        }

        {
            plans.payload?.can_sub == 1 && (
              <><center onClick={()=> navigate('/courses')} style={{'marginTop':'20px','color':'gray','cursor':'pointer'}}>{keywords.skip}</center></>
            )           
        }



                <MessageModal title={message.title} id="general-modal2" okLabel="Close">{message.body}</MessageModal>
                </>  
    )}
    </>
  )
}

export default Prices 
