import { useContext, useEffect } from "react"
import { AuthContext } from "../context/AuthContext"
import { UserContext as language } from '../context/languageContext';

const ChildDashboard = (props) => {
    const Lang = useContext(language);

    const Auth = useContext(AuthContext)
    useEffect( ()=> {
        let userId = Auth.getUserId()
        props.printUserCoursesSummary(userId)
    },[])
    return (
        <p className="hello-title">{Lang.hello} <span style={{textTransform: 'capitalize'}}>{Auth.getUserName()}</span></p>
    )
}

export default ChildDashboard