
import {useState, useContext, useEffect} from 'react';
import {AuthContext} from '../context/AuthContext'
import { UserContext as language } from '../context/languageContext';
import '../assets/css/forms.css';
import { useParams } from 'react-router-dom';
import PageNotFound from './pageNotFound';
import { getDeviceInfo } from '../helpers/deviceInfo';

const TokenLogin = () => {

    const [isLoading, setLoading] = useState(1);
    const [success, setSuccess] = useState(-1);
    const Auth = useContext(AuthContext);
    const Lang = useContext(language); 
    const params = useParams();
    const token = params?.token || '';

    useEffect( () => {


        async function initial(){

                setLoading(1);
                Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('checkLoginToken',{token, device_info:await getDeviceInfo()}).then( res => {
                    setLoading(2);
                    if(res?.data?.status ==1){
                        //console.log(res?.data?.payload);
                        setSuccess(1);

                        if(!Auth.isLogged && res?.data?.payload?.doLogin == 1){
                            let redirect = res?.data?.payload?.redirect || '';
                            Auth.successLoginProcess(res,redirect);
                        }
                        else{
                            setTimeout(() => {
                                let redirect = res?.data?.payload?.redirect || 'prices';
                                window.location.href = localStorage.getItem('basename') +'/'+redirect;
                            }, 2000);
                        }
                                                

                    }
                    else{
                        setSuccess(0);
                    }
                }).catch(err => {
                    setLoading(2);
                    setSuccess(0);
                })
        }

        initial();
      },[])



    return(        
        isLoading ==1 ? 
        <div style={{marginTop:'100px'}}><center><h1>{Lang.loading}</h1></center></div> 
        : 
        success == 1 ? 
        <div style={{marginTop:'100px'}}><center><h1>{Lang.loading}</h1></center></div> 
        :
        <PageNotFound/> 
        
      );
      



    
}

export default TokenLogin;