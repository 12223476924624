
import { useField } from "formik";
import { useContext } from "react";
import { UserContext as language } from '../../context/languageContext';

const MultiboxesInput = (props) => {

    const classes = props.className ?  props.className + ' hide' : ' hide';
    const [field, meta, helpers] = useField(props);
    const { setValue } = helpers;
    const Lang = useContext(language);  

    let errorMessage = '';
    if(meta.touched && meta.error){
      let errorArr =[];
      if(typeof meta.error===  "string")
      {
         errorArr = meta.error.split(",");
      }
      
      if(errorArr.length >1){
        errorMessage = Lang[errorArr[0]] 
        for(let x=1;x< errorArr.length;x++){
          let comma = ','
          if(x == errorArr.length-1){
            comma = ''
          }
          errorMessage += ' ' + errorArr[x]+comma;
        }
      }
      else{
        errorMessage = Lang[meta.error];
      }
    }

    const auto_tab_input = () => {
        window.$(".code-inputs .otp-input").keyup(function () {
          if (this.value.length == this.maxLength) {
            window.$(this).nextAll(".code-inputs .otp-input:enabled:first").focus();
          }
        });
      }

    const auto_backspace = () => {
        window.$(".code-inputs .otp-input").keyup(function (e) {
          if (e.keyCode == 8) {
            if (window.$(this).prev().length > 0) {
                window.$(this).prev("input").focus();
            }
          }
        });
      }
      
      // window.$(document).ready(function () {
      //   auto_tab_input();
      //   auto_backspace();
      // });
      

      const copyToMainInput = (e) => {
        const boxesName = e.target.name;
        const currentBox = e.target;
        const currentIndex = currentBox.getAttribute('index');
        const nextIndex = parseInt(currentIndex)+1;
        const prevIndex = parseInt(currentIndex)-1;
        
        const enteredChar = Number(e.key)
        if (!isNaN(enteredChar) && e.key !== null && e.key !== ' ') {
          currentBox.value = '';
          currentBox.value = enteredChar
          //console.log(enteredChar)
          if(document.getElementsByName(boxesName)[nextIndex]){
              document.getElementsByName(boxesName)[nextIndex].focus();
          }
        }
        else if (e.keyCode ==	8){
          if(document.getElementsByName(boxesName)[prevIndex]){
              document.getElementsByName(boxesName)[prevIndex].focus();
          }
        }
        const boxesObj = document.getElementsByName(boxesName);
        let accumulator = '';
        for(let i=0;i<boxesObj.length;i++){  
          // if(!boxesObj[i].value){
          //   boxesObj[i].value = 0;
          // }  
          accumulator += boxesObj[i].value;
        }

        setValue(accumulator)
        
      }
    return (
        <div class="otp-form-wrapper">
          <div class="code-inputs">
            <input type="number" index="0" name={props.name+'_char[]'} onKeyUp={copyToMainInput} maxLength="1" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" className={`otp-input ${meta.touched && meta.error ? props.className+" input-error" : ''}`}/>
            <input type="number" index="1" name={props.name+'_char[]'} onKeyUp={copyToMainInput} maxLength="1" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" className={`otp-input ${meta.touched && meta.error ? props.className+" input-error" : ''}`}/>
            <input type="number" index="2" name={props.name+'_char[]'} onKeyUp={copyToMainInput} maxLength="1" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" className={`otp-input ${meta.touched && meta.error ? props.className+" input-error" : ''}`}/>
            <input type="number" index="3" name={props.name+'_char[]'} onKeyUp={copyToMainInput} maxLength="1" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" className={`otp-input ${meta.touched && meta.error ? props.className+" input-error" : ''}`}/>
            <input type="number" index="4" name={props.name+'_char[]'} onKeyUp={copyToMainInput} maxLength="1" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" className={`otp-input ${meta.touched && meta.error ? props.className+" input-error" : ''}`}/>
            <input type="number" index="5" name={props.name+'_char[]'} onKeyUp={copyToMainInput} maxLength="1" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" className={`otp-input ${meta.touched && meta.error ? props.className+" input-error" : ''}`}/>            
          </div>


          <input
          {...field}
          {...props}
          className={classes}
        />
        <center>{meta.touched && meta.error && <div style={{textAlign:'center'}} className="error">{errorMessage}</div>}</center>
    

      </div>
      
    );
  };
  export default MultiboxesInput;