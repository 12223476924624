import { useField } from "formik";
import parse from 'html-react-parser';
import { useContext } from "react";
import { UserContext as language } from '../../context/languageContext';
const RadioInput = ({ values, ids ,label, labels, ...props }) => {
    const [field, meta] = useField(props);
    const Lang = useContext(language);

    return (
      <>   
        {ids.map( (id,index) => { return(
        <div key={index}>         
      <input
      {...field}
      {...props}
      type="radio"
      id={id}       
      key={id} 
      value={values[index]}  
      checked={values[index] == field.value} 
    />
    <label htmlFor={id}>{labels[index]}</label>
    </div>
    )
          })
          }
        {meta.touched && meta.error && props.doNotShowError !=="1" && <div className="error" style={{"width":"100%"}}>{Lang[meta.error]}</div>}
      </>
    );
  };
  export default RadioInput;