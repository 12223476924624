import { useEffect, useState, useContext } from "react"
import ChildCard from "./sections/childCard"
import { ProfileContext } from "../context/ProfileContext"
import ChildCardLoader from "./loaders/childCardLoader";
import { UserContext as language } from '../context/languageContext';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation } from "swiper/modules";
import { NavLink } from "react-router-dom";

const ParentDashboard = (props) => {
    const [currentChild, setcurrentChild] = useState(0);
    const Profile = useContext(ProfileContext);
    const Lang = useContext(language);
    const [children, setChildren] = useState([]);
    const [childrenLoding, setChildrenLoading] = useState(0);
    const breakpoints={
        0: {
            slidesPerView: 1,
        },
        450: {
            slidesPerView: 1,
        },
        600: {
            slidesPerView: 1,
        },
        700: {
            slidesPerView: 1,
        },
        1000: {
            slidesPerView:3,
        },
        1500:{
            slidesPerView: 3,
        },
        2000:{
            slidesPerView: 3,
        }
    }
    useEffect(()=>{
        setChildrenLoading(1);
        let children = Profile.getUserChildren().then( (res)=>{
            setChildrenLoading(2);
            let data = res?.data?.payload || {};
            let status = res?.data?.status || 0;
            if(status && data.children.length >0){
                setChildren(data.children)
            }
        })
    },[])

    return (
        <>
            <div className="top-titles" style={{'margin':'0 auto', 'width':'85%'}}>
                 <p className="profile-icon"><img src={process.env.PUBLIC_URL + "/assets/images/added_children.svg"} alt={Lang.my_children} /><p>{Lang.my_children}</p></p>
            </div>

            <div className="container child-container" style={{'display': 'flex','justifyContent': 'space-evenly'}}>
                <div className="row-p">
                <Swiper
             breakpoints={breakpoints}
             modules={[Navigation]}
            //  spaceBetween={50}
            //  slidesPerView={2}
            onSlideChange={(swiper) => {
                let childIndex = swiper.realIndex;
                let childId = children[childIndex].uid;

                props.printUserCoursesSummary(childId);
                setcurrentChild(childId); 
            }}
             navigation={{
                 nextEl: '.nextSwipeCard',
                 prevEl: '.prevSwipeCard',
               }}            
         >
                <div className="swiper-button-prev prevSwipeCard"></div>
                <div className="swiper-button-next nextSwipeCard"></div>

                {

                    children.map( (child,index) => {
                        return <SwiperSlide key={child.uid} className="card-swiper"><ChildCard 
                        key={child.uid}
                        index={index}
                        user-id={child.uid}
                        last-login={child.last_login} 
                        gender={child.gender} 
                        completed-courses={child.completed} 
                        progress-courses={child.progress} 
                        fname={child.first_name} 
                        lname={child.last_name}
                        img={child.img}
                        printUserCoursesSummary = {props.printUserCoursesSummary}
                        activeChild = {child.uid == currentChild ? 1: 0}
                        setActiveChild = {setcurrentChild}
                        activityBtn = {true}
                        showBasicInfo="1"
                        /></SwiperSlide>
                    })
                }

                {childrenLoding==1 && <ChildCardLoader total={4}/>}                
                </Swiper>        
                </div>              
            </div>
            {
                childrenLoding==2 && children.length==0 &&
                <center className="">
                    <NavLink to={'/added-children'} className="add-child">
                        <img src={process.env.PUBLIC_URL + "/assets/images/add-child.svg"} />
                        <p>{Lang.add_children}</p>
                    </NavLink>
                </center>
                }               
        </>
    )
}

export default ParentDashboard