const content = [
  {
    course: "coding" ,
    testimonials : [
      {"id" :1, 
      "age_ar":"العمر: 12",
      "age_en":"Age: 12",
      "name_ar": "الاسم: خالد",
      "name_en": "Name: John",
      "testimonial_ar": "حبيت بكورس البرمجة انو فيو التفاصيل اللي بحتاجها لاتعلم البرمجة من الصفر، كان عندي معلومات ناقصة خاصة بمجال ال python، كورس البرمجة بمواهب جاوب على كل اسئلتي", 
      "testimonial_en": "I liked the programming course because it provides the details I needed to learn programming from scratch. I had some missing information, especially in the field of Python. MasterHobbies programming course answered all my questions.", 
      "img": "https://masterhobbies.online/assets/images/theme_images/testimonial_1.png"},

      {"id" :2, 
      "age_ar":"العمر: 41",
      "age_en":"Age: 41",
      "name_ar": "الاسم: أحلام",
      "name_en": "Name: Anna",
      "testimonial_ar": "الحلو بكورس البرمجة اللي ب MasterHobbies انو في اساسيات الرمجة المهمة وممكن أي عمر يقدرو يتابعوه ويفهموه خاصة انو ببلش بشرح برنامج ال scratch اللي بعلِّم طريقة التفكير المنطقي للبرمجة ", 
      "testimonial_en": "The great thing about the programming course at MasterHobbies is that it covers important programming fundamentals, and anyone of any age can follow and understand it. Especially since it starts with explaining the Scratch program, which teaches logical thinking for programming.", 
      "img": "https://masterhobbies.online/assets/images/theme_images/testimonial_2.png"},
     
      {"id" :3, 
      "age_ar":"العمر: 16",
      "age_en":"Age: 16",
      "name_ar": "الاسم: زينة",
      "name_en": "Name: Elizabeth",
      "testimonial_ar": "كنت حابب اتعلم عن البرمجة خاصة انها صارت من المهارات الاساسية اللي لازم كل حدا يتعلمها، عجبني بمواهب انهم بقدمو كورس برمجة للمبتدئين اللي ما عندهم اي خلفية عن البرمجة ولأول مرة بتابع كورس بالبرمجة بقدر اطبق المعلومات اللي تعلمتها بهالسهولة، طريقة الشرح كانت سلسة وممتازة.", 
      "testimonial_en": "I wanted to learn programming, especially since it has become one of the essential skills that everyone should learn. I liked MasterHobbies because they offer a programming course for beginners who have no background in programming. It's my first time taking a programming course, and I can easily apply the information I learned. The explanation was smooth and excellent.", 
      "img": "https://masterhobbies.online/assets/images/theme_images/testimonial-3.png"},
  ],
    content_titles : [
      {"id" :1, "title_ar":"محتوى کورس البرمجة:", "title_en" : "Course Content:"},
      // {"id" :2, "title_ar":"سنقدم لك نظرة شاملة على عالم البرمجة", "title_en" : "Online Programming Course Content:"},
],
  content_desc : [
    {"id" :1, "desc_ar":"شرح لمفاهيم البرمجة المتنوعة ولغات البرمجة المختلفة واستخداماتها.", "desc_en" : "Explanation of various coding concepts and different coding languages and their uses."},
    {"id" :2, "desc_ar":"دورات في برمجة Scratch وPython.", "desc_en" : "Scratch and Python coding courses available."},
    {"id" :3, "desc_ar":"تعلم بناء تطبيقات وألعاب بسهولة.", "desc_en" : "Build applications and games easily."},
    {"id" :4, "desc_ar":"مصممة خصيصًا للأطفال من سن 7 إلى 18 عاماً.", "desc_en" : "Designed specifically for children ages 7 to 18."},
    {"id" :5, "desc_ar":"القدرة على متابعة تقدم طفلك.", "desc_en" : "Ability to monitor your kid’s progress."},
],
},
  
];

export default content;

