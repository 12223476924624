import * as Yup from 'yup';
import {getAge, usernameSuggestions} from '../../helpers/functions';

//const professions = ['Doctor', 'Engineer', 'Programmer'];


const RegisterSchema = Yup.object().shape({

//     dob: Yup.string()
//             .required("dob_required_err"), 
        dob_day: Yup.number('dob_required_err').required("dob_required_err").min(1, 'dob_required_err'), 
        dob_month: Yup.number('dob_required_err').required("dob_required_err").min(1, 'dob_required_err'), 
        dob_year: Yup.number('dob_required_err').required("dob_required_err").min(1, 'dob_required_err'), 
    first_name: Yup.string()
            //.label('Fisrt Name')
            .required("fname_required_err")
            .matches(/^[aA-zZ\s\u0600-\u06FF]+$/, "fname_pattern_err"),
    last_name: Yup.string()
            //.label('Last Name')
            .required("lname_required_err")
            .matches(/^[aA-zZ\s\u0600-\u06FF]+$/, "lname_pattern_err"),
    email: Yup.string()
            .required("email_required_err")
            //.email("email_format_err"),     
            
            .test('validator-username-email', (value, { createError,resolve }) =>{
                //const emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
                const emailRegex = /^\s*([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+\s*$/;
                //const nameRegex = /^[a-zA-Z]+$/;
                const nameRegex = /^[A-Za-z]+[A-Za-z0-9]*$/;
                const y = resolve(Yup.ref('dob_year'));
                const m = resolve(Yup.ref('dob_month'));
                const d = resolve(Yup.ref('dob_day'));                
                const age = getAge(y+'-'+m+'-'+d);
                if(!emailRegex.test(value)){

                                if(age < 18 && !nameRegex.test(value)){
                                        return createError({
                                                message: `email_format_or_username`,
                                                path: 'email', // Fieldname
                                        })
                                }
                                else if(age >= 18){
                                        return createError({
                                                message: `email_format_err`,
                                                path: 'email', // Fieldname
                                        })
                                }
                }
                return true;
            }),

    mobile: Yup.string()
    .when(['dob_year', 'dob_month', 'dob_day','email'], {
        is: (y, m, d, email) => {
                if(email){
                        return false;
                }
                return true;//y && m && d && getAge(y+'-'+m+'-'+d) >= 18
        },
        then: ()=> Yup.string().required('mobile_required_err'),
      })
    .matches(/^\d+$/, 'mobile_err'),
    password: Yup.string()
            .required("password_required_err")
            .min(8, 'password_min_err')
            .matches(/^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+$/,"password_english_err"),
    parent_email: Yup.string()
//     .when('dob', (val, schema) => {
//         if(getAge(val) < 18){
//             return schema.required('parent_email_required_err').email('parent_email_format_err'); 
//         }
//         return schema;
//       }),
    .when(['dob_year', 'dob_month', 'dob_day'], {
        is: (y, m, d) => {
                return !y || !m || !d || getAge(y+'-'+m+'-'+d) < 18
        },
        then: () => Yup.string().email('parent_email_format_err')
      })
      
      
      
      .test('validator-parentemail-email', async (value, { createError,resolve }) =>{
        const email = resolve(Yup.ref('email'));
        const same = email == value ;
        if(same && (email!='' && email !=undefined)){

                const fname = resolve(Yup.ref('first_name')); 
                const lname = resolve(Yup.ref('last_name')); 
                const email = resolve(Yup.ref('email')); 
                const dob_year = resolve(Yup.ref('dob_year')); 
                const dob_month = resolve(Yup.ref('dob_month')); 
                const dob_day = resolve(Yup.ref('dob_day'));

                let dobConcat = '';
                if(dob_year) dobConcat += '_'+dob_year;
                //if(dob_month) dobConcat += ''+dob_month;
                //if(dob_day) dobConcat += ''+dob_day;
                const sugg = await usernameSuggestions('son'+dobConcat,email.split('@')[0]);
                if(sugg){
                        return createError({
                                message: `parent_same_email_sugg,(${sugg})`,
                                path: 'email', // Fieldname
                        })
                }
                else{
                        return createError({
                                message: `parent_same_email`,
                                path: 'email', // Fieldname
                        })                                
                }
                
                        
        }
        return true;
    }),



      
      






      behalf: Yup.string()
      //.label('Behalf of children')
//       .when('dob', (val, schema) => {
//         if(getAge(val) >= 18){
//             return schema.required("behalf_required_err"); 
//         }
//         return schema;
//       }),

      .when(['dob_year', 'dob_month', 'dob_day'], {
        is: (y, m, d) => {
                return y && m && d && getAge(y+'-'+m+'-'+d) > 18
        },
        //then: () => Yup.string().required('behalf_required_err'),
        then: () => Yup.string(),
      }),

      relation: Yup.string()
      //.label('Relationship')
      .when('behalf', (val, schema) => {
        if(val == 1){
            return schema.required("relation_required_err"); 
        }
        return schema;
      }),      
      agreement: Yup.string()
      //.label('Agreement')
      .test('isChecked','agremeent_required_err',(val)=>{return val=='true'}),
      children: Yup.string()
      .when('behalf', (val, schema) => {
        if(val == 1){
            return schema.required("agremeent_required_err"); 
        }
        return schema;
      }),
    
      
      
      
            //.email(),             
            //.test('strongPassword', 'message',(value)=>InputValidations.strongPassword(value).isValid),    


            
    //profession: Yup.string()
    //            .oneOf(professions, 'The profession you chose does not exist'),
    //age: Yup.number()
    //      .min(15, 'You need to be older than 15 to register')
    //      .required()
  })

  export default RegisterSchema;