import { useContext, useEffect, useState } from "react"
import { AuthContext } from "../context/AuthContext";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation, Pagination} from 'swiper/modules';
import 'swiper/css/navigation';
import { UserContext } from "../context/languageContext";
import '../assets/css/testimonials.css';

const Testimonials = () => {
      
    const Auth = useContext(AuthContext);
    const Lang = useContext(UserContext);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
   
    useEffect(() => {
        let language = Lang.getSelectedLanguage();
        
        Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded',}).post('getTestimonials',{language})
        .then((res) => {
            if(res.data.status == 1){
                setData(res.data.payload);
            }
            setLoading(false); // Set loading state to false after data is loaded
        })
        .catch(err => { 
            setData([]);
            setLoading(false); // Set loading state to false even if there's an error
        });
    }, []);

    const breakpoints={
        0: {
            slidesPerView: 1,
        },
        450: {
            slidesPerView: 1,
        },
        600: {
            slidesPerView: 1,
        },
        700: {
            slidesPerView: 1,
        },
        1000: {
            slidesPerView: 3,
        },
        1500:{
            slidesPerView: 3,
        },
        2000:{
            slidesPerView: 3,
        }
    }

    return (
        <div className="testimonials-wrapper">
        <div className="sectionTitle">{Lang.testimonials_title}</div>
        <div class="bk-slider">
        <div class="swiper">
        <div class="swiper-wrapper testimonial-swiper">
        { !loading &&
            <Swiper 
            modules={[Navigation, Pagination]}
            breakpoints={breakpoints}
            navigation 
            spaceBetween={10}
            initialSlide={1}
            // slidesPerView={1.25}
            slidesPerGroup={1}
            observer
            observeParents
            parallax
            className="slider-items"
            loop={true}
            grabCursor={true}
            slidesPerView={3}
            centeredSlides={true}
            simulateTouch={false}
            speed= {500}
            pagination={{clickable: true }}>            
            {
            data.map( item => {
                return (
                    <SwiperSlide key={item.id}>
                        <div style={{height:'100%'}} dangerouslySetInnerHTML={{__html: item.contents}}></div>
                    </SwiperSlide>
                    )
                    })
                }
                </Swiper>
            }
            </div>            
            </div>
            </div>
            </div>
    )
}

export default Testimonials