import * as Yup from 'yup';
import {getAge} from '../../helpers/functions';

//const professions = ['Doctor', 'Engineer', 'Programmer'];


const ParentProfileSchema = Yup.object().shape({

//     dob: Yup.string()
//             .required('dob_required_err'), 
dob_day: Yup.number('dob_required_err').required("dob_required_err").min(1, 'dob_required_err'), 
dob_month: Yup.number('dob_required_err').required("dob_required_err").min(1, 'dob_required_err'), 
dob_year: Yup.number('dob_required_err').required("dob_required_err").min(1, 'dob_required_err'), 
    first_name: Yup.string()
            .label('Fisrt Name')
            .required('fname_required_err')
            .matches(/^[aA-zZ\s\u0600-\u06FF]+$/, "fname_pattern_err"),
    last_name: Yup.string()
            .label('Last Name')
            .required('lname_required_err')
            .matches(/^[aA-zZ\s\u0600-\u06FF]+$/, "lname_pattern_err"),
    email: Yup.string()
            //.required('email_required_err')
            .when('isEmailMobile', (val, schema) => {
                if(val == 1){
                        return schema.required('email_required_err'); 
                }
                        return schema;
        })            
            //.email('email_format_err'),  
            .test('validator-username-email', (value, { createError,resolve }) =>{
                //const emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
                const emailRegex = /^\s*([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+\s*$/;
                if(value && !emailRegex.test(value)){
                                                 
                        return createError({
                                message: `email_format_err`,
                                path: 'email', // Fieldname
                        })
                        
                }
                return true;
            }),
            
    mobile: Yup.string()
    //.required('mobile_required_err')
    .when('isEmailMobile', (val, schema) => {
        if(val == 2){
                return schema.required('email_required_err'); 
        }
                return schema;
})
    .matches(/^\d+$/, 'mobile_err'),
    password: Yup.string()
                .when('id', (val, schema) => {
                        if(val == 0){
                                return schema.required('password_required_err'); 
                        }
                                return schema;
                })
            .min(8, 'password_min_err')
            .matches(/^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+$/,"password_english_err"),
            //.matches(/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!-#_+])[a-zA-Z0-9!-#_+]+$/,"password_pattern_err"),
    relation: Yup.number().required().moreThan(0,'required_field'),
    allow_sharing_projects: Yup.number().required('required_field'),

  })

  export default ParentProfileSchema;