import { useEffect, useState } from 'react';
 import '../../assets/css/onOffToggle.css'

const OnOffToggle = ({id='a', onLabel = 'On', offLabel='Off', label='', initial='off',...props}) => {

    const[isChecked, setIsChecked] = useState(initial == 'on' ? true : false)

    const checkboxArea = () => {
        return(
            <>
            <div className='on-off-wrapper'>
                <div>{label !='' ? label : ''}</div>
                <div className={`on-off-toggle ${props?.lang == 'ar' ? 'on-off-toggle-rtl' : ''}`}>
                    <input id={id} type="checkbox" checked={isChecked} onChange={() => {setIsChecked(!isChecked)}}/>
                    <label htmlFor={id} class={``} style={{flexDirection: props?.lang == 'ar' ? 'row' : 'row'}}>
                        <div className={`switch-on ${!isChecked ? 'off-color':''}`}>{onLabel}</div>
                        <div className={`switch-off ${isChecked ? 'off-color':''}`}>{offLabel}</div>
                    </label>
                </div> 
            </div>
            
            </>
        )
    }

    return (typeof props?.children === 'function') ? props.children(isChecked,checkboxArea)  : checkboxArea()
}

export default OnOffToggle