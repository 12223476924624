import {AdapterAPI} from '../api/AdapterAPI';
import {Analytics} from '../core/Analytics';
import {AnalyticsConfig} from '../types/AnalyticsConfig';
import {AnalyticsStateMachineOptions} from '../types/AnalyticsStateMachineOptions';
import {logger} from '../utils/Logger';

import {Adapter} from './Adapter';
import {CAFv3InternalAdapter} from './internal/CAFv3InternalAdapter';

export class CAFv3Adapter extends Adapter implements AdapterAPI {
  constructor(config: AnalyticsConfig, player: any, opts?: AnalyticsStateMachineOptions) {
    super();
    if (this.hasPlayerAlreadyBeenAugmented(player)) {
      logger.errorMessageToUser('Bitmovin Analytics is already hooked up to this player instance');
      return;
    }
    this.markPlayerInstanceAsAugmented(player);
    this.internalAdapter = new CAFv3InternalAdapter(player, opts);
    this.analytics = new Analytics(config, this.internalAdapter);
  }
}
