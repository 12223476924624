import { useContext, useEffect, useState } from "react";
import {NavLink, useParams} from 'react-router-dom';
import { UserContext } from "../context/languageContext";
import '../assets/css/blogs.css';
import arrow from '../assets/images/arrow-orange.png';
import { AuthContext } from "../context/AuthContext";

const Blog = () => {
    
    const Auth = useContext(AuthContext);
    const params = useParams();
    const blogId = params.id;
    const lagKeywords = useContext(UserContext);
    const selectedLang = lagKeywords.getSelectedLanguage();
    const [isLoading, setIsLoading] = useState(0);
    const [blog, setBlog] = useState({});

    useEffect(()=>{
        setIsLoading(1)
        Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded',}).post('getBlog',{id:blogId,language:selectedLang}).then((res) => {
            if(res?.data?.payload?.blog?.blog_language != selectedLang){
                window.location.href = (localStorage.getItem('basename') ? localStorage.getItem('basename') : '') + '/blogs';
                return;
            }            
            setBlog(res?.data?.payload?.blog || {});
            setIsLoading(2);
        }).catch((err)=> {
            setIsLoading(2);
        });
    },[])
    return(
        <>


        <section className={`blog-section ${selectedLang == 'ar' ? 'rtl':''}`}>
        {
            isLoading == 2  && 
                
                    <div key={blog.id} className="blog-container">
                        <div className="blog-inner-body" dangerouslySetInnerHTML={ {__html: blog.blog_desc} }>{}</div>
                        <NavLink to={`/blogs`} className="back-arrow"><img src={arrow}/></NavLink>
                    </div>
                
            
        }
        </section>
        </>
    )    
}

export default Blog;