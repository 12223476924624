import elements from './elements/elements';
import {Form, Formik} from 'formik';
import LoadingButton from './elements/loadingButton';
import SelectInput from './elements/selectInput';
import config from './config';
import CustomInput from './elements/customInput';
import { UserContext as language } from '../context/languageContext';
import { useContext, useEffect, useState } from 'react';
import { firebaseAuth,  RecaptchaVerifier,signInWithPhoneNumber } from './config/firebase';
import { trim } from '../helpers/functions';
import { AuthContext } from '../context/AuthContext';
import MobileWizardStep1Schema from './schema/mobileWizardStep1Schema';

const MsisdnRegisterStep1 = (props) => {

    const {RadioInput} = elements;
    const Lang = useContext(language);
    const [isLoading, setLoading] = useState(0);
    const Auth = useContext(AuthContext);
    const [dynamicPrefix,setDynamicPrefix] = useState('962');
    const resetPurpose = props.reset || false;

    useEffect(()=>{
        Auth.getCountryPrefix().then(res => {
            setDynamicPrefix(res.data.payload.prefix || '962');
        }).catch(err => {
            setDynamicPrefix('962');
        });        
    })


    const initValues = {
        mobile_prefix:('+'+dynamicPrefix) , 
        mobile_prefix_tmp : dynamicPrefix,
        mobile:"",
    };

    const generateRecaptch = () => {

        let verifier = new RecaptchaVerifier('recaptcha-container', {
            'size':'invisible',
            'callback': (res) => {
                
            }
        },firebaseAuth);

        return verifier;

    }
    const onSubmit = async (values, actions) => {
        setLoading(1);
        const mobile_prefix = values.mobile_prefix;
        const msisdn  =  mobile_prefix +''+ values.mobile;

        if(msisdn && msisdn.length >=12){
            const verifier = generateRecaptch();

            Auth.authorizedRequest({'Content-Type':'application/x-www-form-urlencoded'}).post('checkMsisdnRequest',{msisdn}).then(res=> {
                if(res.data.status ==1){
                    
                        signInWithPhoneNumber(firebaseAuth, msisdn, verifier).then( res => {
                            //console.log(res);
                            setLoading(2);
                            values.confirmationResult = res;
                            values.mobile = parseInt(values.mobile+'',10);
                            //console.log(values);
                            props.dispatchWizard({type:'NEXT',data:{values}})
                        }).catch(err => {
                            if(err.code='auth/too-many-requests'){
                                props.showMessage(Lang.error, Lang.too_many_request);
                            }
                            setLoading(2);
                        });

                }
                else{
                    props.showMessage(Lang.error, Lang.data_saved_faild_auth);
                    if(res.data?.payload?.campaign_url){
                        setTimeout(() => {
                            window.location.href = res.data.payload.campaign_url;
                        }, 2000);
                    }
                    setLoading(2);
                }
            }).catch(err => {
                props.showMessage(Lang.error, Lang.data_saved_faild_auth);
                setLoading(2);
            })


        }
        else{
            setLoading(2);
        }
        
        //props.dispatchWizard({type:'NEXT',data:{values}})
 
       };     

    return (
        <div className="section-container">
            <div className='content-container' style={{"padding": "0"}}>
                <div className="reg-form">
                    <h2 className="reg-title">{resetPurpose? Lang.reset_password: Lang.RegisterNewAccount}</h2>


                <Formik
                enableReinitialize
          initialValues={initValues}
          validationSchema={MobileWizardStep1Schema}
          onSubmit={onSubmit}  
        >
    
    {(props) => {
        //console.log(props.values.dob_year);
        let {values, errors, setFieldValue} = props;
    
        return(
            <Form autocomplete="off" >




            <div className="form-group" style={{"margin": "0", position: 'relative'}}>
                {/* <SelectInput className="form-select" id="mobile_prefix" name="mobile_prefix" >
                    {
                        config.countries.map( item => {
                            return (
                                <option key={item.prefix.id} value={item.prefix.id}>{item.prefix.name}</option>
                            )
                        })
                    }                    
                </SelectInput> */}
                <CustomInput autocomplete="off"  id="mobile_prefix" name="mobile_prefix" type="number" placeholder=" " className="hide" />
                <CustomInput autocomplete="off" type="number" onKeyUp={(e) => {if(e.target.value.length ==3){document.getElementById('mobile').focus()};}} onChange={(e)=>{setFieldValue("mobile_prefix_tmp",e.target.value);setFieldValue("mobile_prefix",'+'+e.target.value);}} id="mobile_prefix_tmp" name="mobile_prefix_tmp" maxLength={3} style={{backgroundImage:'unset',top:'0'}} className="form-select mob-prefix" />                
                <div className="reg-field-tel">
                    <label htmlFor="mobile" className="placeholder-mob">{Lang.mobile_number}</label>
                    <CustomInput id="mobile" name="mobile" type="number" placeholder=" " className="reg-input" style={{textAlign: 'left', direction: 'ltr'}}/>
                </div>
         
                {/* <CustomInput id="mobile" name="mobile" type="tel" className="reg-input" placeholder={Lang.mobile_number} /> */}
            </div>

    
                <LoadingButton loading={isLoading==1 ?`true`:`false`} type="submit" className="submit-button-auto reg-btn" loading-text="<i class='fa fa-circle-o-notch fa-spin'></i> Processing" >{`continue`}</LoadingButton>
            </Form>    
        )}}
        </Formik>                
    
                </div>
            </div>
        </div>
    )
}

export default MsisdnRegisterStep1;