import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../context/languageContext';
import { AuthContext } from '../context/AuthContext';
import '../assets/css/notifications.css'
import user1Img from '../assets/images/user1.jpg'
import profileAvatar from '../assets/images/child-avatar.png'
import { NavLink } from 'react-router-dom';
import LoadingButton from './elements/loadingButton';

import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import ar from 'javascript-time-ago/locale/ar.json'
import ReactTimeAgo from 'react-time-ago'
import { ReplaceTagStringWithElement, DownloadAuthResponse } from '../helpers/functions';

const Notifications = (props) => {


    const Auth = useContext(AuthContext);
    const Lang = useContext(UserContext);
    const [isLoading, setLoading] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [pageNum, setPageNum] = useState(0);
    const [notifications, setNotifications] = useState([])
    const notificationsPerReq = 5;


    const getNotifications = () => {
        let newPageNum = pageNum + 1;
        setPageNum(newPageNum);
        setLoading(1);
        //console.log(pageNum);
        const dataToSend = {
            pageNum:newPageNum, notificationsPerReq
        }
        //console.log(dataToSend);
        Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('notifications', dataToSend).then(res => {            
            setLoading(2);            
            let newState = [...notifications, ...res.data.payload.notifications]
            setNotifications(newState); 
            //console.log(res.data.payload.notifications);
            if(newState.length < res.data.payload.total_rec){
                setHasMore(true);
            }           
            else{
                setHasMore(false);
            }
            //data = res.data.payload
            //console.log(data);
        }).catch((err)=>{
            setLoading(2);
            setHasMore(false);
        });
    }

    const updateDescription = (item) => {

        if(item.type == 'notify_child_finish_course'){
            const wrapper = <NavLink to={'/course/'+item.extra_info.course_id+'/'+item.extra_info.course_name?.split(' ').join('-')}/>
            return ReplaceTagStringWithElement(item?.desc, 'courseLink', wrapper )
        }
        else if(item.type == 'notify_child_submit_project'){
            const wrapper1 = <NavLink onClick={()=> DownloadAuthResponse(Auth,item.extra_info.project_url,item.extra_info.file_name)}/>
            const replace1 = ReplaceTagStringWithElement(item?.desc, 'projectLink', wrapper1 );
            const wrapper2 = <NavLink to={'/course/'+item.extra_info.course_id+'/'+item.extra_info.course_name?.split(' ').join('-')}/>
            replace1[2] = ReplaceTagStringWithElement(replace1[2], 'courseLink', wrapper2 );
            //console.log(replace1)
            return replace1;
        }        
        else if(item.type == 'notify_new_course_uploaded'){
            const wrapper = <NavLink to={'/course/'+item.extra_info.course_id+'/'+item.extra_info.course_name?.split(' ').join('-')}/>
            return ReplaceTagStringWithElement(item?.desc, 'courseLink', wrapper )
        }        

        return item.desc
    }

    const updateSeenStatus = (id,updateTo) => {
        Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('updateNotificationSeen', {id,status:updateTo}).then(res => {
            if(res.data.status ==1){
                let newArr = notifications.filter( item =>{
                    if(item.id == id){
                        item.seen = updateTo;
                    }
                    return item;
                })

                setNotifications(newArr);
            }
        })
    }


    useEffect(()=>{
        TimeAgo.addDefaultLocale(en);
        TimeAgo.addLocale(ar);        
        getNotifications();
    },[])

    return (
        <div className="section-container">
            <div className="content-container price-step">
            <div className='notif-top'>
                <img className='svg-logo2' src={process.env.PUBLIC_URL + "/assets/images/notification_m.svg"} alt={Lang.notifications} /> 
                <p className='notif-title'>{Lang.notifications}</p>
            </div>  




                <section className="section-50">
                    <div className="container noti-cont">

                        <div className="notification-ui_dd-content">

                            {
                                notifications.map(item => {
                                    let seen = true;
                                    if(item.seen == '0'){
                                        seen = false;
                                    }
                                    return(
                                        <div key={item.id} className={`notification-list ${!seen ? 'notification-list--unread':''}`}>
                                            <div className="notification-list_content">
                                                <div className="notification-list_img">
                                                    <img src={item?.fromUser?.image || profileAvatar} alt="user"/>
                                                </div>
                                                <div className="notification-list_detail">
                                                    <p><span>{item?.fromUser?.name}</span> {item?.title}</p>
                                                    <p className="text-muted">{updateDescription(item)}</p>
                                                    <p className="text-muted"><small><ReactTimeAgo date={new Date(item.date)}  locale="en-US"/></small></p>
                                                </div>
                                            </div>
                                            <div className="notification-list_feature-img">
                                                {/* <img src={user1Img} alt="Feature image"/> */}
                                                {!seen ? 
                                                <i className='fa fa-eye' onClick={()=>updateSeenStatus(item.id,1)}></i> : 
                                                <i className='fa fa-eye-slash' onClick={()=>updateSeenStatus(item.id,0)}></i>}
                                            </div>
                                        </div>                                    
                                    )
                                })
                            }

     
                        
                        </div>

                        <div className="text-center">
                        {    hasMore && notifications.length >0 &&
                                <LoadingButton onClick={getNotifications} loading={isLoading == 1 ? `true`:`false`} type="submit" className="submit-button-auto reg-btn" loading-text="<i class='fa fa-circle-o-notch fa-spin'></i> Processing" >{Lang.load_more}</LoadingButton>
                        }

                        {    isLoading==2 && notifications.length ==0 &&
                                <div>{Lang.no_results_notification}</div>
                        }   
                        {    isLoading ==1 && notifications.length ==0 &&
                                <div>{Lang.loading}</div>
                        }                                                


                        </div>

                    </div>
                    </section>




            </div>
        </div>            
    );
}

export default Notifications