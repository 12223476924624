import type {PlayerAPI} from 'bitmovin-player';

import {DownloadSpeedMeter} from '../../core/DownloadSpeedMeter';

export class Bitmovin8SpeedMeterAdapter {
  private meter: DownloadSpeedMeter;

  constructor(player: PlayerAPI, meter: DownloadSpeedMeter) {
    this.meter = meter;
    player.on(player.exports.PlayerEvent.DownloadFinished, (event) => this.handleOnDownloadFinishEvent(event));
  }

  getDownloadSpeedMeter(): DownloadSpeedMeter {
    return this.meter;
  }

  handleOnDownloadFinishEvent(event: any) {
    if (!event.success) {
      return;
    }
    if (event.downloadType.indexOf('media/video') === 0) {
      this.meter.addMeasurement({
        duration: event.downloadTime,
        size: event.size,
        timeToFirstByte: event.timeToFirstByte,
        timestamp: new Date(),
        httpStatus: event.httpStatus,
      });
    }
  }
}
