import { useContext } from "react";
import {AuthContext} from "../../context/AuthContext";

const UploadedFiles = (props) => {
    //console.log(props.data)

    const Auth =useContext(AuthContext);
    const removeProject = (id) => {

        Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('removeProject/'+id).then( (res) => {
            if(res.data.status==1){
                props.fun.getUploadedFiles(props.params.courseId,props.params.userId);
            }
            else{
                
            }
        }).catch((err)=>{
            
        });  

    }
    return (
        <div className="uploaded-area-wrapper">
            <ul className="uploaded-area">
            {
                props.data && props.data.map( (item) => {
                    return (
                        <li key={item.id} className="row-area">
                        <div className="content upload">
                            <i className="fa fa-file"></i>
                            <div className="details">
                            <span className="name">{item.project_name}</span>
                            {/* <span className="name">{item.original_file_name}.{item.extension}</span> */}
                            <span className="size">{(item.filesize/1048576).toFixed(3)} MB</span>
                            </div>
                        </div>
                    
                    
                        <div className={`progress-bar${item.uploaded==1?'hide':''}`}>
                            <div className="progress" style={{width: item.progress+"%"}}></div>
                        </div>
    
                        <i className="fa fa-close" onClick={()=>removeProject(item.id)}></i>
                    </li>
                    )
                })
            }    



            </ul>
      </div>
                
    )
}

export default UploadedFiles;