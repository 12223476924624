import { useContext, useRef, useState } from 'react';
import '../assets/css/newsletter.css';
import { AuthContext } from '../context/AuthContext';
import LoadingButton from './elements/loadingButton';
import { UserContext as language } from '../context/languageContext';
//import image from '../assets/images/newsletter_part.svg';
import image from '../assets/images/newsletter_icon.svg';
import '../assets/css/newsletter.css';
import '../assets/css/forms.css';
import Image from './elements/image';
const Newsletter = () => {


    let closeBtn = true;
    const [open,setOpen] = useState(true);
    const [isSuccess,setSuccess] = useState(0);
    const [isLoading,setLoading] = useState(0);
    const emailRef = useRef(null);
    const Auth = useContext(AuthContext);
    const Lang = useContext(language);

    const formSubmit = (e) => {
        e.preventDefault();
        let emailVal = emailRef.current.value;
        if(emailVal){
            setLoading(1);
            Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('subscribe_newsletter',{email:emailVal}).then(res => {
                if(res.data?.status == 1){
                    setSuccess(1);
                }
                setLoading(2);
            }).catch(err => {


                setLoading(2);
            })
        }
    }

    const onClose = () => {
        setOpen(false);
    }

    return (
        <section className='newsletter-section'>
        <div>
        <div className='img-box'><Image src={image} alt="" /></div>
        <p className='newsletter-text'>{Lang.newsletter_text2}</p>
        <div className='form-box'> 
        <div className='whole-form'>  
{
            isLoading==0 && (
                        <>

                            <form onSubmit={formSubmit} method="post">

                            <div className="reg-field">
                                {/* <label htmlFor="email">{Lang.enter_your_email}</label> */}
                                <input ref={emailRef} placeholder={Lang.enter_your_email}  id="email" name="email" type="email" className="reg-input"/>
                            </div>
                                <LoadingButton className="submit-button-auto reg-btn"  loading={isLoading == 1 ? `true`:`false`} loading-text="<i class='fa fa-circle-o-notch fa-spin'></i> Processing" type="submit">{Lang.send}</LoadingButton>
                            </form>
                        </>

            )
            
            }
            {
                isLoading ==2 && isSuccess==1 && (
                    <p class="after-sub">{Lang.success_newsletter_sub}</p>
                )            
            }
            {
                isLoading ==2 && isSuccess==0 && (
                    <p class="after-sub">{Lang.unsuccess_newsletter_sub}</p>
                )            
            }  
        </div>
        </div>
        </div>
        </section>

            // <div className={`newsletter-popup ${open && 'open'}`}>
            
            //     <div className={`newsletter-popup-container ${open && 'open'}`}>

            //         { closeBtn &&  <a href="#" onClick={onClose} className="newsletter-popup-close-btn">&times;</a> }                    

            //         <h3><i className="fa fa-envelope"></i>Subscribe To Our Newsletter</h3>

            //             {
            //             isLoading==0 && (
            //                         <>
            //                             <p>Join our subscribers list to get the latest news, updates, and special offers directly in your inbox.</p>

            //                             <form onSubmit={formSubmit} method="post">
            //                                 <input ref={emailRef} type="email" name="email" placeholder="Your Email" required/>
            //                                 <LoadingButton loading={isLoading == 1 ? `true`:`false`} loading-text="<i class='fa fa-circle-o-notch fa-spin'></i> Processing" type="submit">Subscribe</LoadingButton>
            //                             </form>
            //                         </>

            //             )
                        
            //             }
            //             {
            //                 isLoading ==2 && isSuccess==1 && (
            //                     <p>Subscription in newsletter has been successfully</p>
            //                 )            
            //             }
            //             {
            //                 isLoading ==2 && isSuccess==0 && (
            //                     <p>Faild to Subscribe in newsletter</p>
            //                 )            
            //             }  


            //         <p className="newsletter-msg"></p>

            //     </div>
            // </div>               
        
         
         

         
        
    )
}

export default Newsletter;