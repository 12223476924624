import { useState } from "react";
import { DownloadAuthResponse } from '../../helpers/functions';
import LoadingIcon from '../../assets/images/Loading_icon.gif'

const CourseDownloadBtn = ({Auth, courseId, itemId, subItemId, subItemName}) => {

    let  [isLoading, setLoading] = useState(0);

    return (
            isLoading 
            ? 
            <img height={80} src={LoadingIcon} />
            :
            <span onClick={ (e)=> { DownloadAuthResponse(Auth,'download_course_content/'+courseId+'/'+itemId+'/'+subItemId, subItemName+'.zip', ()=> setLoading(1), () => setLoading(0));}} className='download-icon'><i className="fa fa-download" aria-hidden="true"></i></span>                                                                                                                                     
               
    )
}

export default CourseDownloadBtn