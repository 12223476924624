import React, { createContext, useContext, useEffect, useState } from 'react';
import {loadStripe} from '@stripe/stripe-js';
import '../assets/css/paymentForm.css'
import '../assets/css/creditCard.css';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
  ElementsConsumer
} from '@stripe/react-stripe-js';
import {UserContext} from '../context/languageContext'
import { AuthContext } from '../context/AuthContext';
import uuid from 'react-uuid';
import axios from 'axios';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import ApiHelper from "../helpers/ApiHelper";
import Config from './config';
import {campaignJourneyTestMode, paymentKey} from './config/payment'

const PK = paymentKey;

//const PRODUCT_ID = 'prod_MiLod1b8EIkwWu';//prod_MdnJ952hJ4CTk8
//const GENERATE_SECRET_URL = 'https://beepay.mobi/index.php?r=Stripe/VisaCreateCustomer';
//const GENERATE_SECRET_URL = 'VisaCreateCustomer';

//const SELLER_ID = 20;
//const PRICE = '1';

var CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
      base: {
        iconColor: "#6dbba5",
        color: "#501d93",
        fontWeight: 'bold',
        fontFamily: "STC",
        fontSize: "17px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": {
          color: "#fce883"
        },
        "::placeholder": {
          color: "#F39422"
        }
      },
      invalid: {
        iconColor: "#ffc7ee",
        color: "#ffc7ee"
      }
    }
  };
  
  const stripePromise = loadStripe(PK);

  const CardField = ({ onChange, clientSecret }) => {
    CARD_OPTIONS['clientSecret'] = clientSecret;
    console.log(CARD_OPTIONS)
    return (
      <div className="FormRow">
        <CardElement options={CARD_OPTIONS} onChange={onChange} />
      </div>
    );

  }
  
  const Field = ({
    label,
    id,
    type,
    placeholder,
    required,
    autoComplete,
    value,
    onChange,
    readOnly
  }) => (
    <div className="FormRow">
      <label htmlFor={id} className="FormRowLabel">
        {label}
      </label>
      <input
        className="FormRowInput"
        id={id}
        type={type}
        placeholder={placeholder}
        required={required}
        autoComplete={autoComplete}
        value={value}
        onChange={onChange}
        readOnly = {readOnly}
      />
    </div>
  );
  
  const SubmitButton = ({ processing, error, children, disabled, className }) => (
    <button
      className={className ? className : (`SubmitButton ${error ? "SubmitButton--error" : ""}`)}
      type="submit"
      disabled={processing || disabled}
    >
      {processing ? "Processing..." : children}
    </button>
  );
  
  const ErrorMessage = ({ children , style}) => (
    <div className="ErrorMessage" style={style} role="alert">
      <svg width="16" height="16" viewBox="0 0 17 17">
        <path
          fill="#FFF"
          d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
        />
        <path
          fill="#6772e5"
          d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
        />
      </svg>
      {children}
    </div>
  );
  
  const ResetButton = ({ onClick }) => (
    <button type="button" className="ResetButton" onClick={onClick}>
      <svg width="32px" height="32px" viewBox="0 0 32 32">
        <path
          fill="#000"
          d="M15,7.05492878 C10.5000495,7.55237307 7,11.3674463 7,16 C7,20.9705627 11.0294373,25 16,25 C20.9705627,25 25,20.9705627 25,16 C25,15.3627484 24.4834055,14.8461538 23.8461538,14.8461538 C23.2089022,14.8461538 22.6923077,15.3627484 22.6923077,16 C22.6923077,19.6960595 19.6960595,22.6923077 16,22.6923077 C12.3039405,22.6923077 9.30769231,19.6960595 9.30769231,16 C9.30769231,12.3039405 12.3039405,9.30769231 16,9.30769231 L16,12.0841673 C16,12.1800431 16.0275652,12.2738974 16.0794108,12.354546 C16.2287368,12.5868311 16.5380938,12.6540826 16.7703788,12.5047565 L22.3457501,8.92058924 L22.3457501,8.92058924 C22.4060014,8.88185624 22.4572275,8.83063012 22.4959605,8.7703788 C22.6452866,8.53809377 22.5780351,8.22873685 22.3457501,8.07941076 L22.3457501,8.07941076 L16.7703788,4.49524351 C16.6897301,4.44339794 16.5958758,4.41583275 16.5,4.41583275 C16.2238576,4.41583275 16,4.63969037 16,4.91583275 L16,7 L15,7 L15,7.05492878 Z M16,32 C7.163444,32 0,24.836556 0,16 C0,7.163444 7.163444,0 16,0 C24.836556,0 32,7.163444 32,16 C32,24.836556 24.836556,32 16,32 Z"
        />
      </svg>
    </button>
  );




const StripPaymenForm = (props) => {

  const [val,setVal] = useState("");

  const lagKeywords = React.useContext(UserContext);

    const { elements, stripe } = props;
    const [completeProcees,setcompleteProcees]=useState(false)

    const [error, setError] = useState(null);
    const [cardComplete, setCardComplete] = useState(false);
    const [processing, setProcessing] = useState(false);
    let   [paymentMethod, setPaymentMethod] = useState(null);
    const [billingDetails, setBillingDetails] = useState({
      email: props.basicInfo.email || "",
      phone: props.basicInfo.phone || "",
      name: props.basicInfo.name || ""
    });

    const Auth = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(()=>{
      if(!Auth.isLogged){
        window.location.href= '/';
        return;
      }
    },[])


  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    //console.log("one")
    if (error) {
      elements.getElement("card").focus();
      return;
    }

    if (cardComplete) {
      setProcessing(true);
    }

    /** create payment  method */
   // console.log("hi")
    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: billingDetails,
    });
    //console.log(payload)
    //console.log("bye")
    //return
    
    if (payload.error) {
      setError(payload.error);
    } else {
      setPaymentMethod(payload.paymentMethod);
      paymentMethod = payload.paymentMethod

      //console.log(paymentMethod)
      const dataToSend = {
        paymentMethod: paymentMethod?.id,
        isTestMode : campaignJourneyTestMode,
        //requested_price : PRICE,
        //seller_id: SELLER_ID,
        billingTracker:uuid(),
        //secret_only: 1,
        //testMode:1,
        ...billingDetails
      }


      /**
       * get secret
       */
        //const config = { headers:{'Content-Type': 'application/x-www-form-urlencoded'} };
        //const url = GENERATE_SECRET_URL;  
        //const url = 'http://localhost/stripeTest/index.php'
        //Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post(url, dataToSend).then( async(res) => {
            let clientSecret = props?.clientSecret
            if(clientSecret){

              /**
               * attach payment method with customer api
               */
        
               const lastStep = await Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('changePaymentMethod',dataToSend);
                if (lastStep?.data?.status != 1) {
                  //console.log(lastStep);
                  setError(lagKeywords.data_saved_faild);
                } 
                else {
                    navigate('/prices');
                  //callback function after success
                  //let values = {token:props.token};
                  //props.dispatchWizard({type:'NEXT',data:{dataToSend}})
                  // setTimeout(() => {
                  //   navigate('/dashboard');
                  // }, 2000);                  
                    //alert("Success! Check your email for the invoice.");
                }

                
               
            }
            else{
              setError('no secret')
            }
            setProcessing(false);
            setcompleteProcees(true);
        // })
        // .catch((err)=> {
        //     setProcessing(false);
        //     setcompleteProcees(true);
        //     console.log(err);
        // })
       




    }
  };



  const reset = () => {
    console.log('reset')
    setError(null);
    setProcessing(false);
    setcompleteProcees(false);
    setPaymentMethod(null);
    // setBillingDetails({
    //   email: "",
    //   phone: "",
    //   name: ""
    // });
  };

        return completeProcees ? (
            <div className="Result">
              <div className="ResultTitle" role="alert" style={{'color':'black'}}>
                {error ? lagKeywords.failed_change_payment_mthd:lagKeywords.payment_mthd_changed}
              </div>
              <div className="ResultMessage">
              {error ? error:
                lagKeywords.success_sub
                
              }
              </div>
              {
                error &&
              <ResetButton onClick={reset} />
              }
            </div>
          ) : (
            <form className="Form stripe-form" onSubmit={handleSubmit}>
              <fieldset className="FormGroup">
                <Field
                  label={lagKeywords.name}
                  id="name"
                  type="text"
                  placeholder="MasterHobbies"
                  required
                  autoComplete="name"
                  readOnly
                  value={billingDetails.name}
                  onChange={(e) => {
                    setBillingDetails({ ...billingDetails, name: e.target.value });
                  }}
                />
                <Field
                  label={lagKeywords.email}
                  id="email"
                  type="email"
                  placeholder="xxxxxxx@xxxx.xxx"
                  required
                  autoComplete="email"
                  readOnly
                  value={billingDetails.email}
                  onChange={(e) => {
                    setBillingDetails({ ...billingDetails, email: e.target.value });
                  }}
                />
                <Field
                  label={lagKeywords.mobile_number}
                  id="phone"
                  type="tel"
                  placeholder="(xxx) xx-xxx-xxxx"
                  required
                  readOnly
                  autoComplete="tel"
                  value={billingDetails.phone}
                  onChange={(e) => {
                    setBillingDetails({ ...billingDetails, phone: e.target.value });
                  }}
                />
              </fieldset>
              <fieldset className="FormGroup">
                <CardField
                  clientSecret = {props?.clientSecret}
                  onChange={(e) => {
                    setError(e.error);
                    setCardComplete(e.complete);                    
                  }}
                />
              </fieldset>
              {error && <ErrorMessage style={{'position':'relative'}}>{error.message}</ErrorMessage>}




              <SubmitButton className="submit-button-auto reg-btn" processing={processing} error={error} disabled={!stripe}>
               {lagKeywords.add}
              </SubmitButton>              
            </form>
            
          );
  


}


const ChangePaymentMethod = (props) => {

  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(0);
  const [paymentError, setPaymentError] = useState('');
  const [processing, setProcessing] = useState(false); //pay clicked
  const [completeProcees, setCompleteProcees] = useState(false); //paying process completed
  const [basicInfo, setInfo] = useState({});
  const [paymentMethodInfo, setPaymentMethodInfo] = useState({});
  const [clientSecret, setSecret] = useState('');

  const Auth = useContext(AuthContext);
  const lagKeywords = React.useContext(UserContext);


  const SubmitButton = ({ processing, error, children, disabled, className }) => (
    <button
      className={className ? className : (`SubmitButton ${error ? "SubmitButton--error" : ""}`)}
      type="submit"
      disabled={processing || disabled}
    >
      {processing ? "Processing..." : children}
    </button>
  );

  
  useEffect(()=>{
      Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('getUserBasicInfo').then(res => {
        //console.log(res);
        if(res.data.status ==1){
            setSecret(res.data.payload.secret);    
            setInfo(res.data.payload.basicInfo);        
        }
        else{
          setIsError(1);
        }
        setLoading(false);
      }).catch(err => {
        setLoading(false);
        setIsError(1);
      });
  },[])



  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientSecret,
  };
//console.log(clientSecret)
if(loading){
  return (<center style={{'marginTop':'50px'}}><h3>{lagKeywords.loading}</h3></center>)
}
else if(isError){
  return (<center style={{'marginTop':'50px'}}><h3>{lagKeywords.data_saved_faild}</h3></center>)
}

  return (     
        <div className='stripe-container'>
            <Elements stripe={stripePromise} options={options}>
                <ElementsConsumer>
                    {(ctx) => <StripPaymenForm clientSecret={clientSecret} basicInfo={basicInfo} {...ctx} />}
                </ElementsConsumer>
            </Elements>        
        </div>    
  );
};


export default ChangePaymentMethod