import {Analytics} from '../../core/Analytics';
import {Event} from '../../enums/Event';
import {MIMETypes} from '../../enums/MIMETypes';
import {Player} from '../../enums/Player';
import {Feature} from '../../features/Feature';
import {FeatureConfig} from '../../features/FeatureConfig';
import {ErrorDetailBackend} from '../../features/errordetails/ErrorDetailBackend';
import {ErrorDetailTracking} from '../../features/errordetails/ErrorDetailTracking';
import {AnalyticsStateMachineOptions} from '../../types/AnalyticsStateMachineOptions';
import {FeatureConfigContainer} from '../../types/FeatureConfigContainer';
import {QualityLevelInfo} from '../../types/QualityLevelInfo';
import {logger} from '../../utils/Logger';

import {HTML5InternalAdapter} from './HTML5InternalAdapter';
import {InternalAdapterAPI} from './InternalAdapterAPI';

export class ShakaInternalAdapter extends HTML5InternalAdapter implements InternalAdapterAPI {
  constructor(
    private player: any,
    opts?: AnalyticsStateMachineOptions,
  ) {
    super(player.getMediaElement(), opts);
  }

  override initialize(analytics: Analytics): Array<Feature<FeatureConfigContainer, FeatureConfig>> {
    super.initialize(analytics);
    let videoElement: HTMLVideoElement | undefined;
    try {
      videoElement = this.player.getMediaElement();
    } catch (e) {
      // empty
    }

    if (videoElement == null) {
      const onStateChangeEventListener = (state) => {
        if (state.state === 'media-source') {
          try {
            this.player.removeEventListener('onstatechange', onStateChangeEventListener);
            videoElement = this.player.getMediaElement();
            this.setMediaElement(videoElement);
          } catch (e) {
            logger.errorMessageToUser(
              'Something went wrong while getting underlying HTMLVideoElement. Not possible to attach adapter and initialize Bitmovin Analytics. Error: ',
              e,
            );
            this.release();
          }
        }
      };
      this.player.addEventListener('onstatechange', onStateChangeEventListener);
      this.player.addEventListener('unloading', this.onUnloading.bind(this));
    } else {
      this.setMediaElement(videoElement);
    }
    const errorDetailTracking = new ErrorDetailTracking(
      analytics.errorDetailTrackingSettingsProvider,
      new ErrorDetailBackend(analytics.errorDetailTrackingSettingsProvider.collectorConfig),
      [analytics.errorDetailSubscribable],
      undefined,
    );
    return [errorDetailTracking];
  }

  override getPlayerName = () => Player.SHAKA;

  getPlayerVersion = () => this.player.constructor.version;

  override isLive = () => {
    return this.player ? this.player.isLive() : false;
  };

  override getMIMEType() {
    // FIXME: Could be HLS too, Shaka probably has a method to find out
    return MIMETypes.DASH;
  }

  override getStreamURL() {
    return this.player ? this.player.getAssetUri() : null;
  }

  /**
   * Implemented by sub-class to deliver current quality-level info
   * specific to media-engine.
   * @override
   * @returns {QualityLevelInfo}
   */
  getCurrentQualityLevelInfo(): QualityLevelInfo | null {
    const variantTracks = this.player.getVariantTracks();

    const activeVideoTrack = variantTracks
      .filter((track: any) => track.active)
      .filter((track: any) => track.videoCodec || track.videoId !== undefined)[0];

    if (!activeVideoTrack) {
      // can only happen for audio-only streams
      return null;
    }

    const bitrate = activeVideoTrack.videoBandwidth || activeVideoTrack.bandwidth;
    const width = activeVideoTrack.width;
    const height = activeVideoTrack.height;

    return {
      bitrate,
      width,
      height,
    };
  }

  private onUnloading = () => {
    this.eventCallback(Event.SOURCE_UNLOADED, {});
    this.release();
  };
}
