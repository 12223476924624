import {Wizard, WizardStep} from './elements/wizard/wizard';
import { useState,useEffect } from 'react';
import MessageModal from './modals/messageModal';
import Step2 from './campaignJourneyPaymentStep1'
import Step3 from './campaignJourneyStep3'



import facebookConfig from './config/facebook';
import ReactPixel from 'react-facebook-pixel';
import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';


const CampaignJourney4 = () => {


    const searchParams = new URLSearchParams(document.location.search);
    let emailFromUrl = searchParams.get('email');
    let promocode = searchParams.get('promocode');
    let surveyToken = searchParams.get('token') || '';

    const [promoCodesInfo, setPromoCodesInfo] = useState([]);

    const Auth = useContext(AuthContext);

        
    const [messageDetails,setMessageDetails] = useState({title:'',message:''});
    const showMessage = (title='no_title', message='') => {
        title = title || 'no_title';
        setMessageDetails({title,message});
        window.$('#campaign-message').modal('show');
    }    

    const advancedMatching = {};    
    const options = {
        autoConfig: true, 
        debug: false, 
      };
       
    useEffect(()=>{

            if(surveyToken && emailFromUrl){
                Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded',}).post('checkSurveyEmailToken',{email:emailFromUrl, surveyToken});
            }        
            ReactPixel.init(facebookConfig.FACEBOOK_PIXEL_ID, advancedMatching, options);
            ReactPixel.track(facebookConfig.FACEBOOK_PIXEL_LEAD_EVENT);
        },[]
        
        ) 

    return (
        <>
        
            <div className="prices-section">
                {
                    <Wizard>
                        <WizardStep showMessage={showMessage} step={1} > <Step2 promoCodesInfo={promoCodesInfo} setPromoCodesInfo={setPromoCodesInfo} promocode={promocode} email={emailFromUrl}/> </WizardStep>
                        <WizardStep setMessage={setMessageDetails} showMessage={showMessage} step={2} > <Step3/> </WizardStep>
                    </Wizard>
                }     
            </div>
                
            <MessageModal title={messageDetails.title} id="otp-message" okLabel="Close">
            {messageDetails.message}
            </MessageModal>  
            <MessageModal title={messageDetails.title} id="general-modal" okLabel="Close">{messageDetails.message}</MessageModal>                            
        </>
    )    

}

export default CampaignJourney4