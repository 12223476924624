const config = {
    SANDBOX_CLIENT_ID : 'AQ2cQKAkPR4ERUVDGZ7eTZOyOp8zyh_ePAGp2-gx9ANjKDnoE1ZxvFd5jGftb5ObmPFa0KqFcGwFYaMM',
    PRODCUTION_CLIENT_ID : 'Af4UJ0-o020cQOO5pB10aG-0iMWCYTbI7W9gt4x50CFFdkqpj_yt5D19M9n_r77yZaaOfHsw8TnJJj8z',
    // local testing
    // SANDBOX_CLIENT_ID :'AXgtlBsYX8vJbeaqMR8NvbBF8yz8KlK5-mAE8Z6vrltJ8aL8NHaJx8yGeicVZY4P1K5PXlZbI19NnUAo',//'AWRaf0jyvYLraN9Wp5W14WSz8qW-0s2QunTrdIb-ARAwdTXpyaSY_7pRBC6teJmwN5xClA62NFGn22HF',// 'AQ2cQKAkPR4ERUVDGZ7eTZOyOp8zyh_ePAGp2-gx9ANjKDnoE1ZxvFd5jGftb5ObmPFa0KqFcGwFYaMM',
    // PRODCUTION_CLIENT_ID : 'AWRaf0jyvYLraN9Wp5W14WSz8qW-0s2QunTrdIb-ARAwdTXpyaSY_7pRBC6teJmwN5xClA62NFGn22HF',    
    ENVIRONMENT : 'production' // [sandbox, production]
}

export default config

