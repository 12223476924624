import { useContext, useEffect, useState } from "react";
import {NavLink} from 'react-router-dom';
import { UserContext } from "../context/languageContext";
import '../assets/css/landingPage.css';
import '../assets/css/blogs.css';
import { AuthContext } from "../context/AuthContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube } from '@fortawesome/free-brands-svg-icons'

const Blogs = () => {
    
    const Auth = useContext(AuthContext);
    const lagKeywords = useContext(UserContext);
    const selectedLang = lagKeywords.getSelectedLanguage();
    const [isLoading, setIsLoading] = useState(0);
    const [blogs, setBlogs] = useState([]);
    const [videoInfo, setVideoInfo] = useState({src:'',title:'', show:false});
    
    const showVideoBlog = (title, src) => {
        setVideoInfo({src,title, show:true});
    }

    const closeVideoBlog = () => {
        setVideoInfo({src:'',title:'', show:false});
    }    

    useEffect(()=>{
        setIsLoading(1)
        Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded',}).post('getBlogs',{language:selectedLang}).then((res) => {
            setBlogs(res?.data?.payload?.blogs || []);
            setIsLoading(2);
            setTimeout(() => {
                let ele = document.getElementById("blogs-section");
                ele.scrollIntoView({ behavior: "smooth", inline: "nearest" });
            }, 700);            
        }).catch((err)=> {
            setIsLoading(2);
        });
    },[])
    return(
        <>
        <section className="lp-intro-container web-intro" style={{backgroundColor: 'rgb(233, 233, 233)'}}>
            <h1 className="lp-intro-title">{lagKeywords.mawaheb_intro_title}</h1>
            <p className="lp-intro-p">{lagKeywords.mawaheb_intro_text}</p>
            {!Auth.isLogged  ? 
            <NavLink to="/userRegistration" className="lp-btn login-trigger call-to-action-btn">{lagKeywords.get_started}</NavLink>
           : '' }
            </section>
        <section className="lp-intro-container mob-intro" style={{backgroundColor: 'rgb(233, 233, 233)'}}></section>
            <h1 className="lp-intro-title mob-intro">{lagKeywords.mawaheb_intro_title}</h1>
            <p className="lp-intro-p mob-intro">{lagKeywords.mawaheb_intro_text}</p>
            {!Auth.isLogged  ? 
            <NavLink to="/userRegistration" className="lp-btn mob-intro login-trigger call-to-action-btn" >{lagKeywords.get_started}</NavLink>     
            : '' }


        <section id="blogs-section" className={`blogs-section ${selectedLang == 'ar' ? 'rtl':''}`}>
        {
            isLoading == 2 && blogs.length > 0 && blogs.map((blog) => {
                return (
                    <div key={blog.id} className="blog-item">
                        <img className="blog-image" src={blog.blog_image}/>
                        <div className="blog-title-wrapper">
                            <div className="blog-title">{blog.blog_title}</div>
                            <div className="blog-more">
                                {
                                    blog.blog_type == 2 ? <NavLink onClick={()=> showVideoBlog(blog.blog_title, blog.blog_video)}>{ lagKeywords.watch_video+' >'}</NavLink> :
                                    <NavLink to={`/blog/${blog.id}`}>{ lagKeywords.more+' >'}</NavLink>
                                }
                            </div>
                            <div className="blog-extra">{
                                blog.blog_type == 2 ? <FontAwesomeIcon icon={faYoutube} />  : ''
                            }</div>
                        </div>
                    </div>
                )
            })
        }
        </section>

        <div className={`blog-video-section ${videoInfo.show == false && 'hide'} ${selectedLang == 'ar' ? 'rtl':''}`}>
            <div className="video-wrapper">
            {videoInfo?.src !='' && <video preload="yes" style={{height:'100%'}} controls   playsInline webkit-playsInline>
                    <source src={videoInfo.src} type="video/mp4"  preload="auto"></source>
                </video> 
                }
                <div className="v-title">{videoInfo.title}</div>
            </div>

            <div onClick={closeVideoBlog} className="close-video-blog">X</div>

        </div>
        </>
    )    
}

export default Blogs;