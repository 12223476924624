import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { UserContext as language } from '../context/languageContext';
import { getDeviceInfo } from "../helpers/deviceInfo";
import PageNotFound from "./pageNotFound";

const Login2 = (props)=> {

    const [isLoading, setLoading] = useState(0);
    const [success, setSuccess] = useState(-1);
    const Auth = useContext(AuthContext);
    const Lang = useContext(language); 
    const params = useParams();
    const token = params?.rtk || '';
    const id = params?.id || '';
    const navigate = useNavigate();

    useEffect( () => {


        async function initial(){

                setLoading(1);
                Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('checkRtk',{token, id, device_info:await getDeviceInfo()}).then( res => {
                    setLoading(2);
                    if(res?.data?.status ==1){
                        //console.log(res?.data?.payload);
                        setSuccess(1);
                        if(!Auth.isLogged ){
                            let redirect = res?.data?.payload?.redirect || '';
                            Auth.successLoginProcess(res,redirect);
                        }
                        else{
                            setSuccess(0);
                        }
                                                

                    }
                    else{
                        setSuccess(0);
                    }
                }).catch(err => {
                    setLoading(2);
                    setSuccess(0);
                })
        }

        initial();
      },[]);


      return(        
        isLoading ==1 ? 
        <div style={{marginTop:'100px'}}><center><h1>{Lang.loading}</h1></center></div> 
        : 
        success == 1 ? 
        <div style={{marginTop:'100px'}}><center><h1></h1></center></div> 
        :
        (isLoading==2 && <PageNotFound/> )
        
      );      

}

export default Login2;