import { useContext, useState } from 'react';
import '../../assets/css/mobileInput.css'
import { UserContext } from '../../context/languageContext';
import countries from '../config/countries';
import CustomInput from "./customInput";

const MobileInput = ({selectedVal='962', ...props}) => {

    const [selected, setSelected] = useState(selectedVal);
    const lang = useContext(UserContext)

    const selectOption = (countryCode) => {

        setSelected(countryCode);
        if(props.onChangePrefix){
            props.onChangePrefix(countryCode);
        }
        const selected_option = document.querySelector('.selected-option div');
        const select_box = document.querySelector('.options');
        const search_box = document.querySelector('.search-box-country');

        select_box.classList.remove('active'); // close the list after selection
        selected_option.classList.remove('active'); // to change thearrow up/bottom
    
        search_box.value = '';
        select_box.querySelectorAll('.hide').forEach(el => el.classList.remove('hide'));
    }


    const searchCountry = () => {

        const search_box = document.querySelector('.search-box-country');
        const options = document.querySelectorAll('.option');
        let search_query = search_box.value.toLowerCase();
        for (let option of options) {
            let is_matched = option.querySelector('.country-name').innerText.toLowerCase().includes(search_query);
            option.classList.toggle('hide', !is_matched)
        }
    }    

    const activate = () => {
        const selected_option = document.querySelector('.selected-option div');
        const select_box = document.querySelector('.options');     
        const search_box = document.querySelector('.search-box-country');   
        search_box.focus();
        select_box.classList.toggle('active');
        selected_option.classList.toggle('active');        
    }

    return (
        <div className="select-box">
        <div className="selected-option">
            <div onClick={activate}>
                <span className="iconify"> <img src={`assets/images/flags/${countries.find(c => c.phone == selected).code.toLowerCase()}.svg`} /></span>
                <strong>{selected}</strong>
            </div>            
        </div>
        <CustomInput style={props.inputStyle} id={props.id} name={props.name} placeholder={props.placeholder} className={props.className}/>
        <div className="options">
            <input type="text" name="tel" placeholder={lang.search} className="search-box-country" id="search-box-country" onKeyUp={searchCountry}/>
            <ol>
            {
                countries.map( country => (
                    <li id={'country_'+country.phone} className="option" onClick={() => selectOption(country.phone)}>
                        <div>
                        <span className="iconify"> <img src={`assets/images/flags/${country.code.toLowerCase()}.svg`} /></span>
                            <span className="country-name">{country.name}</span>
                        </div>
                        <strong>+{country.phone}</strong>
                    </li>
                ))
            }
            </ol>
        </div>
    </div>        
    )

}

export default MobileInput;