import { useEffect, useState } from "react";

const Timer = ({hours=0, minutes=0, seconds=0, showHours=true, showMinutes=true, showSeconds=true,onTimeEnd=null}) => {

    let [hoursNow, updateHours] = useState(hours);
    let [minutesNow, updateMinutes] = useState(minutes);
    let [secondsNow, updateSeconds] = useState(seconds);
    let timeInterval = null

    useEffect(()=> {

        timeInterval = setInterval(() => {
            //console.log(`${hoursNow}:${minutesNow}:${secondsNow}`)
            if(secondsNow == 0){
                if(minutesNow == 0){
                    if(hoursNow == 0){
                        clearInterval(timeInterval);
                        if(onTimeEnd !=null){
                            onTimeEnd();
                        }
                    }
                    else{
                        updateHours(prevHour => prevHour-1);
                        updateMinutes(59);
                        updateSeconds(59);
                        secondsNow = 59;
                        minutesNow = 59;
                        hoursNow -= 1;
                    }
                }
                else{
                    updateMinutes(prevMinute => prevMinute-1);
                    updateSeconds(59);
                    secondsNow = 59;
                    minutesNow -= 1
                }                
            }
            else{
                updateSeconds(prevSecond => prevSecond-1);
                secondsNow -= 1;
            }              
        }, 1000);
            

        return () => clearInterval(timeInterval);

    },[]);

    return (
        <>
        {showHours && <span>{hoursNow>=10?hoursNow:'0'+hoursNow}:</span>}
        {showMinutes && <span>{minutesNow>=10?minutesNow:'0'+minutesNow}:</span>}
        {showSeconds && <span>{secondsNow>=10?secondsNow:'0'+secondsNow}</span>}
        </>
    )
    
}

export default Timer