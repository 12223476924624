import { useContext, useEffect, useMemo, useState } from "react";
import { UserContext } from "../context/languageContext";
import { AuthContext } from "../context/AuthContext";
import '../assets/css/devices.css'
const statuses = ['Failed','Success'];
const Devices = (props) => {

    const Lang = useContext(UserContext);
    const Auth = useContext(AuthContext);

    const [data,setData] = useState([]);
    const [isLoading,setLoading] = useState(true);

    const getData = () => {
        setLoading(true);
        Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('userDevices').then(res => {            
            let reponse = res.data.payload;
            console.log(reponse.data)
            setData(reponse.data)        
            setLoading(false);
        }).catch(err => {
            setLoading(false);
        })

    }
      
    useEffect(()=>{
        getData();
    },[])


    const removeDevice = (e, id) => {
        Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('removeUserDevice/'+id);    
        e.target.closest('tr').remove();
    }

    return (
        <div className="section-container">
                <div className="content-container devices-container">

                    <div className='notif-top'>
                    <img className='svg-logo2' src={process.env.PUBLIC_URL + "/assets/images/device.svg"} alt={Lang.saved_devices} /> 
                        <p className='notif-title'>{Lang.saved_devices}</p>
                    </div> 

                    <table className="table table-striped cell-centered devices-table">
                        <thead>
                            <tr>
                                <th>{Lang.device}</th>
                                <th>{Lang.os}</th>
                                <th>{Lang.browser}</th>
                                <th>{Lang.last_login}</th>
                                <th>{Lang.action}</th>
                            </tr>
                        </thead>
                        {
                            isLoading ? <tr><td colSpan={7}>{Lang.loading}</td></tr> : (
                                data?.length ==0 ? <tr><td colSpan={7 }>{Lang.no_results}</td></tr> : (
                                    data?.map(item => (
                                        <tr>
                                            <td>{item.device_type}</td>
                                            <td>{item.os_name}</td>
                                            <td>{item.browser_name}</td>
                                            <td>{item.last_logged_in}</td>
                                            { item.logged_in == 0 ?
                                            <td><i onClick={ event =>removeDevice(event,item.id)} style={{color:'red', cursor:'pointer'}} className="fa fa-trash"></i></td>
                                            :
                                            <td> - </td>
                                            }       
                                        </tr>
                                    ))
                                )
                            )
                        }
                    </table>
                </div>        
        </div>
    );
}

export default Devices;