import * as Yup from 'yup';

const MobileWizardStep1Schema = Yup.object().shape({

    mobile: Yup.string()
            .required("required_field")
            .matches(/^\d+$/, 'mobile_err')
            .min(8, 'mobile_min_err')

  })

  export default MobileWizardStep1Schema;