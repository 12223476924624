import './style.css'
const CourseModuleLoader = (props) => {

    const total = props?.total || 1;
    const elements = [];
    for(var x=0;x<total;x++){
        elements.push(
            <div key={x} className="is-loading"  {...props}>
                <div className="image img-thumb" style={{'height':'250px'}} ></div>
                <div className="content">
                    <p></p>
                </div>
            </div>   
        );
    }    
    return (elements)
}

export default CourseModuleLoader;