import React,{memo, useContext} from "react" ;
import '../assets/css/landingPage.css'
import Config from '../Components/config';
import Langauge, {UserContext} from '../context/languageContext'
import {useState} from 'react';
import {useEffect} from 'react';
import ApiHelper from "../helpers/ApiHelper";
import { IframeHTMLAttributes } from "react";
import parse from 'html-react-parser';
import {useNavigate,NavLink} from 'react-router-dom';
import playIcon from '../assets/images/parts_play_icon.svg' 
import MessageModal from "./modals/messageModal";
import { AuthContext } from "../context/AuthContext"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import Newsletter from "./newsletter";
import Testimonials from "./testimonials";
import { Navigation } from "swiper/modules";
import { otherLanguageCourseName, ReplaceTagStringWithElement, splitStringByTag } from "../helpers/functions";
import Image from "./elements/image";
import BitmovinPlayer from "./elements/bitmovinPlayer";


const coursesrBreakpoints={
    0: {
        slidesPerView: 3,
        // spaceBetween: 10,
    },
    400: {
        slidesPerView: 3,
    },
    450: {
        slidesPerView: 3,
    },
    600: {
        slidesPerView: 3,
    },
    700: {
        slidesPerView: 3,
    },
    1000: {
        slidesPerView: 9,
    },
    1500:{
        slidesPerView: 9,
    },
    // 2000:{
    //     slidesPerView:7,
    // }
}

let coursecats=''
let categoryIdSelected=''
export const GetCoursesForSelectedCat=(props)=>{
  
const lagKeywords = React.useContext(UserContext);   
  //categoryIdSelected=2
  const categoryId=(props.categoryId)
  const introCourses=props.introCourses
      return (
        <Swiper
         breakpoints={coursesrBreakpoints}
         modules={[Navigation]}
        //   spaceBetween={30}
        //  slidesPerView={2}
   
         navigation={{
             nextEl: '.nextSwipeCourse'+categoryId,
             prevEl: '.prevSwipeCourse'+categoryId,
           }}            
     >
            <div className={`swiper-button-prev prevSwipeCard prevSwipeCourse${categoryId}`} style={{width:'60px'}}></div>
            <div className={`swiper-button-next nextSwipeCard nextSwipeCourse${categoryId}`}></div>
{

        introCourses.length >0 && introCourses.map((introCoursesObj, index) => {                 
           
           //console.log(introCoursesObj);
           if(introCoursesObj.visible !=1){
                return '';
            }           
            introCoursesObj.displayname = otherLanguageCourseName(introCoursesObj, lagKeywords.getSelectedLanguage());
          return ( 
            <>
            
            
            {categoryId && categoryId==introCoursesObj.categoryid &&(
                <>
                <SwiperSlide className="card-swiper">
                  <div className="single-course" key={introCoursesObj.id}>   
                    <NavLink className="each-course" to={'/course/'+introCoursesObj.id+'/'+introCoursesObj.displayname?.split(' ').join('-')}>
                      <Image src={Config.imgDns+ "icon/course_"+introCoursesObj.id+".svg"} alt={introCoursesObj.displayname} />
                      <p>{introCoursesObj.displayname}</p>
                    </NavLink>
                  </div>
                  </SwiperSlide>
                  </>)}
                  </>
                  )
                    
           })   

     }
     {
              props.categoriesList && props.categoriesList.length > 0 && props.categoriesList.map((categoriesListObj,index)=>{
                if(props.categoryId==categoriesListObj.parent)
                {
                    return (
                        <SwiperSlide>
                        <div className="single-course" id={"cat_"+categoriesListObj.id} key={categoriesListObj.id} >   
                        <NavLink className="each-course" to={'categoryCourses/'+categoriesListObj.id+'/'+categoriesListObj.name}>
                            {/* <div className="each-cat"  id={"cat_"+categoriesListObj.id} key={categoriesListObj.id} data-toggle="collapse">    */}
                            <img src={Config.imgDns+ "icon/cat_"+categoriesListObj.id+".svg"} alt={props.lagKeywords['cat_name_'+categoriesListObj.id]} />
                            <p>{props.lagKeywords['cat_name_'+categoriesListObj.id]}</p>
                        {/* </div>  */}
                        </NavLink>                    
                        </div> 
                        </SwiperSlide>

    
                    )
                }
            })
     }
           </Swiper>     
  )
}

export const GetCoursesFormSubCat=(props)=>{
  
  const lagKeywords = React.useContext(UserContext);   
  //categoryIdSelected=2
  const categoryId=(props.categoryId)
  const introCourses=props.introCourses
      return (


        introCourses.length >0 && introCourses.map((introCoursesObj, index) => {                 
           
           //console.log(introCoursesObj);
           if(introCoursesObj.visible !=1){
                return '';
            }           
            introCoursesObj.displayname = otherLanguageCourseName(introCoursesObj, lagKeywords.getSelectedLanguage());
          return ( 
            <>
            
            
            {categoryId && categoryId==introCoursesObj.categoryid &&(
                <>
                  <div className="single-course" key={introCoursesObj.id}>   
                    <NavLink className="each-course" to={'/course/'+introCoursesObj.id+'/'+introCoursesObj.displayname?.split(' ').join('-')}>
                      <img src={Config.imgDns+ "icon/course_"+introCoursesObj.id+".svg"} alt={introCoursesObj.displayname} />
                      <p>{introCoursesObj.displayname}</p>
                    </NavLink>
                  </div>
                  </>)}
                  </>
                  )
                    
           })   
  )
}

// the below component not used
export const GetSubCats=(props)=>
{
    return(
        props.categoriesList && props.categoriesList.length > 0 && props.categoriesList.map((categoriesListObj,index)=>{
            if(props.categoryId==categoriesListObj.parent)
            {
                return (
                    <div className="single-course" id={"cat_"+categoriesListObj.id} key={categoriesListObj.id} >   
                    <NavLink className="each-course" to={'categoryCourses/'+categoriesListObj.id+'/'+categoriesListObj.name}>
                        {/* <div className="each-cat"  id={"cat_"+categoriesListObj.id} key={categoriesListObj.id} data-toggle="collapse">    */}
                        <img src={Config.imgDns+ "icon/cat_"+categoriesListObj.id+".svg"} alt={props.lagKeywords['cat_name_'+categoriesListObj.id]} />
                        <p>{props.lagKeywords['cat_name_'+categoriesListObj.id]}</p>
                    {/* </div>  */}
                    </NavLink>                    
                    </div> 

                )
            }
        })
    )

        
}


const LandingPage=(props)=>{

/*
    apis calling
*/
    const value = React.useContext(UserContext);  
    const Auth = useContext(AuthContext);
    let [videosLp, setvideosLp] = useState([]); 
    let [introLp, setintroLp] = useState([]); 
    //let [introCourses, setCourses] = useState([]); 
    const introCourses = props.sharedCourses;
    let [categoriesList,setCategoriesList]=useState([])
    let [categoryIdFilter,setcategoryIdFilter]=useState('')
    let [categorySelectedImage,setcategorySelectedImage]=useState('')
    let [categorySelectedName,setcategorySelectedName]=useState('')
    let [popup, setPopup] = useState({title:'',content:''});
    if(typeof(introCourses) == 'string'){
        introCourses = JSON.parse(introCourses);
        }

    if(typeof(categoriesList) == 'string'){
        categoriesList = JSON.parse(categoriesList);
        }        
    let api=Config.videoLp;
    let introApi=Config.introApi;
    const navigate = useNavigate();

    useEffect(()=>{
        ApiHelper.excuteApi(api,'get','{}',setvideosLp);
        ApiHelper.excuteApi(introApi+'+homepage_content&lang='+value.getSelectedLanguage(),'get','{}').then( res => {
            
            //res.homepage_content = res.homepage_content.replace('#iframe#','<iframe frameBorder="0" width="640" height="400" src="'+Config.serverDomain+'azurePlayer.html"></iframe>');
            res.homepage_content = res.homepage_content.replace('#iframe#','');
            res.homepage_content = parse(res.homepage_content);

            setintroLp(res);
        });
        //ApiHelper.excuteApi(Config.coursesApi,'get','{}',setCourses);
        ApiHelper.excuteApi(Config.categoriesApi,'get','{}',setCategoriesList);
    },[]        
    )
    
      const breakpoints={
        0: {
            slidesPerView: 2.5,
        },
        450: {
            slidesPerView: 2.5,
        },
        600: {
            slidesPerView: 2.5,
        },
        700: {
            slidesPerView: 3,
        },
        1000: {
            slidesPerView: 5.5,
        },
        1500:{
            slidesPerView: 6,
        },
        2000:{
            slidesPerView: 6,
        }
    }
   
  const openRelatedCourses = (categoryId,name) => {
        setcategoryIdFilter(categoryId)
        setcategorySelectedImage(Config.imgDns+ "icon/cat_"+categoryId+".svg")
        setcategorySelectedName(name)
        window.$(".single-category").show(300);
        window.$(".each-cat#cat_"+categoryId).hide(300);
        window.$(".each-cat").on("click", function(){
            window.$('.each-cat').show();
        });
      
        window.$('.close-cat').on('click', function() {
            window.$(".single-category").hide(500);
            window.$('.each-cat').show();
        })

        if(window.$(window).width() <= 650){
            setcategorySelectedImage(Config.imgDns+ "icon/mob_cat_"+categoryId+".png");
        }
      }
    //   window.$( document ).ready(function() {
    //     var slideWidth = window.$('.swiper-slide').width();
    //     var slideHt = window.$('.swiper-slide').height();
    //     window.$('.thumbnail-container').css("width", slideWidth);
    //     window.$('.swiper-item').css("width", slideWidth);
    //     window.$('.thumbnail-container img').css("width", slideWidth);
    //     var newHt = slideHt + 150;
    //     // window.$('.thumbnail-container').css("height", newHt);
    //     // window.$('.thumbnail-container img').css("height", newHt);
    //     window.$('.thumbnail-container img').attr({ width: slideWidth});

    //     });

    window.$("body").on('click',".call-to-action-btn",function(event) {
        event.stopImmediatePropagation();
        event.stopPropagation();
        //console.log('clicked')
        if(Auth.isLogged)
        {
            navigate('/dashboard');
        }
        else
        {
            //alert('not l;ogged');
            window.$('#login').modal('show');
        }
    })
    
    const lagKeywords = React.useContext(UserContext);
    let videoString='';
    if(videosLp && videosLp.length > 0)
    {
        videoString=
            videosLp.map((videosLpObj, index) => {
            return ( 
                     `<div class="item" key="${videosLpObj.id}">
                      <iframe class="iframe-video-carousel " src="${videosLpObj.URL}" title='tetsing' frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            <p class="item-title">${videosLpObj.name}</p>
                      </div>`
                    )
            
        }).join(' ')
    


    }
    document.querySelector('meta[name="description"]').setAttribute("content", lagKeywords.landing_page_tag_desc);  
    document.querySelector('meta[name="keywords"]').setAttribute("content", lagKeywords.landing_page_tag_keywords);          
    document.title =  lagKeywords.landing_page_title;  

//console.log(videoString);

const handleYouTubeVideo=(videoId,videoUrl)=>{
    //console.log(videoUrl)
    setPopup({title:'no_title',content: <iframe className="iframe-video-carousel2" style={{"height":"256px"}} src={videoUrl} title="tetsing" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    })
    window.$('#content-video').modal({backdrop: 'static', keyboard: false}, 'show');
    return;
    window.$("#"+videoId).parent().html(
        '<iframe class="iframe-video-carousel " style="height:256px" src="'+videoUrl+'" title="tetsing" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>'
    )

}
var path = process.env.PUBLIC_URL;
//console.log(path);
let selectedLang = lagKeywords.getSelectedLanguage();
let poster = Config.imgDns+"icon/intro_poster.png";
let introVidSrc = "https://beecellmedia-euno.streaming.media.azure.net/2864d85e-eb74-4e86-a86e-e9507ccdc1f3/Mawaheb_Intro_presenter_Arabic_C.ism/manifest(format=m3u8-cmaf)";
//let introVidSrc = "https://streams.bitmovin.com/cldi22p872vgg0q11ct0/manifest.m3u8";
if(selectedLang !='ar'){
    poster = Config.imgDns+"icon/intro_poster_"+selectedLang+".png";
    if(selectedLang == 'en'){
        introVidSrc = "https://beecellmedia-euno.streaming.media.azure.net/8a44a3a7-98e1-4c40-a426-8e5fbfa669f3/Mawaheb_Intro_presenter_Jena.ism/manifest(format=m3u8-cmaf)";        
        /*introVidSrc = "https://streams.bitmovin.com/cldiqv4apk7jn1is18u0/manifest.m3u8";*/
    }
}

    return(
        <>
    <div className="green-bg">
    <div className="intro-container">
    <div className="intro-section">
    {introLp.homepage_content}
        <div className="video-intro-section">
            {<BitmovinPlayer className="landing-video" src={introVidSrc} poster={poster}/>}
            {/* <AzureMP className="landing-video" options={{controls: true, autoplay: false, poster: Config.imgDns+"icon/intro_poster_hobbies.png" }} src={[{src:"https://beecellmedia-euno.streaming.media.azure.net/50ada359-3f6e-4a5d-9e07-eafcca2b4b4f/MasterHobbies_Intro_presenter_Je.ism/manifest(format=m3u8-cmaf)", type: "application/vnd.ms-sstr+xml" }]}></AzureMP> */}
        </div>
    </div>
    </div>
    <div className="categories-container">
        <h2 className="intro-title">{lagKeywords.courses_catefories}</h2>
        <div className="single-category">
        <button className="close-cat">×</button>
        <div className="cat-image-container">
            {/* <img className="cat-image" src={categorySelectedImage} alt={categoryIdFilter} /> */}
            { categorySelectedImage &&
            <Image className="cat-image" src={categorySelectedImage} alt={categoryIdFilter} />
            }
            </div>
        <p className="cat-name">{categorySelectedName}</p>
        <div className="all-related-courses">
            {/* <OwlCarousel className="slider-items owl-carousel" {...options}> */}
                <GetCoursesForSelectedCat categoryId={categoryIdFilter} introCourses={introCourses} categoriesList={categoriesList} lagKeywords={lagKeywords} />
                {/* <GetSubCats categoryId={categoryIdFilter} categoriesList={categoriesList} lagKeywords={lagKeywords} /> */}
                     
            {/* </OwlCarousel> */}
        </div>
    </div>
    <section className="courses-section"> 
   
    {categoriesList && categoriesList.length > 0 && categoriesList.map((categoriesListObj,index)=>{
        if(categoriesListObj.id > 0 && categoriesListObj.parent==0)
        {
            return (
                <div className="each-cat"  id={"cat_"+categoriesListObj.id} key={categoriesListObj.id} data-toggle="collapse" onClick={()=> openRelatedCourses(categoriesListObj.id,lagKeywords['cat_name_'+categoriesListObj.id])}>   
                    {/* <img src={Config.imgDns+ "icon/cat_"+categoriesListObj.id+".svg"} alt={lagKeywords['cat_name_'+categoriesListObj.id]} /> */}
                    <Image src={Config.imgDns+ "icon/cat_"+categoriesListObj.id+".svg"} alt={lagKeywords['cat_name_'+categoriesListObj.id]} />
                    <p>{lagKeywords['cat_name_'+categoriesListObj.id]}</p>   
                </div> 
            )
        }
    })}
    </section>
    </div>
    </div>
    {/* Video Carousel section */}
    
    <section className="videos-carousel-container">
        <h2 className="intro-title">{lagKeywords.content_talents}</h2>
        {/* <OwlCarousel  className="slider-items owl-carousel" {...options}>{parse(videoString)}</OwlCarousel> */}
         {/* <OwlCarousel  className="slider-items owl-carousel" {...options}>  */}
         <Swiper
            modules={[Navigation]}
            navigation 
            spaceBetween={30}
            slidesPerView={4}
            slidesPerGroup={1}
            observer
            observeParents
            parallax
            className="slider-items"
            breakpoints={breakpoints}
            >         
        {(videosLp && videosLp.length > 0 ) && videosLp.map((videosLpObj, index) => {
            var Language = localStorage.getItem('lang');
                return ( 
                     <SwiperSlide key={videosLpObj.id} >

                        <div className="swiper-item" key={videosLpObj.id} onClick={()=>handleYouTubeVideo(videosLpObj.id,videosLpObj.URL)}>
                        <div className="thumbnail-container">
                        {/* <img className="video-poster thumbnail-img" id={videosLpObj.id} src={videosLpObj.thumbinal} alt={videosLpObj.name} /> */}
                        <Image className="video-poster thumbnail-img" id={videosLpObj.id} src={videosLpObj.thumbinal} alt={videosLpObj.name} />
                        <img src={playIcon} alt={lagKeywords.play_mawaheb_icon} className='playIcon2' style={{ top : 'unset'}} />

                        {/* <img id={videosLpObj.id} src={process.env.PUBLIC_URL + "/assets/images/parts_thumbnail5.png"} /> */}
                         
                        </div> 
                        {( Language == 'ar' ) ? <p className="item-title-slider"><span>{videosLpObj.name_ar}</span></p> : <p className="item-title-slider"><span>{videosLpObj.name}</span></p> }
                        </div>
                     </SwiperSlide>
                     )
            })
    }
        </Swiper>
     {/* </OwlCarousel>  */}
    <MessageModal title={popup.title} id="content-video" modal-class-size={`modal-lg`} onclose={()=>{setPopup({title:'',content:''})}} okLabel={'Close'}>
        {popup.content}
    </MessageModal>     
    </section>
    <Testimonials/>
    <Newsletter/>

   
    {/* <section className="certificate-container">  
        <h2 className="intro-title-certificate">{lagKeywords.accreditations_ceriﬁcations}</h2>
        <div className="certificates-section">
            <div className="single-certificate"><img src={Config.imgDns+"images/drawing_icon1.svg"} alt={lagKeywords.drawing} /><p>{lagKeywords.drawing}</p></div>
            <div className="single-certificate"><img src={Config.imgDns+"images/animation.svg"} alt={lagKeywords.animation} /><p>{lagKeywords.animation}</p></div> 
            <div className="single-certificate"><img src={Config.imgDns+"images/digitalPainting.svg"} alt={lagKeywords.digitalPainting} /><p>{lagKeywords.digitalPainting}</p></div> 
            <div className="single-certificate"><img src={Config.imgDns+"images/drawing_icon2.svg"} alt={lagKeywords.fashionDesign} /><p>{lagKeywords.fashionDesign}</p></div> 
        </div>
    </section>  */}
        </>
    )
}

export default memo(LandingPage)