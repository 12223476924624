import React from "react";
import '../assets/css/notFound.css'
import Langauge, {UserContext} from '../context/languageContext'


const PageNotFound = () => {
    const lagKeywords = React.useContext(UserContext);     

    return (
    <div className='c notFoundContainer'>
        <div className='_404'>{lagKeywords._404}</div>
        <hr/>
        <div className='_2'>{lagKeywords.pageNotFound}</div>
    </div>
    );
};

export default PageNotFound;