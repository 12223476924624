import {useState, useContext} from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import {formatDate} from '../../helpers/functions'
import { useField, useFormikContext } from "formik";
import { UserContext as language } from '../../context/languageContext';


const DateInput = ({label, ...props }) => {
    const [dateVal,setDate] = useState("");
    const [field, meta] = useField(props);
    const { setFieldValue } = useFormikContext();
    //console.log(field);
    const Lang = useContext(language);
    return (
        <>
        {label && <label>{label}</label>}         
        <DatePicker withPortal showMonthDropdown showYearDropdown dropdownMode="select"  {...field} {...props} isClearable onChange={ (value) => { setFieldValue(field.name, formatDate(value)); } }  dateFormat="MMMM yyyy" className={meta.touched && meta.error ? props.className+" input-error" : props.className} />
        {meta.touched && meta.error && <div className="error">{Lang[meta.error]}</div>}
      </>        
    );
}

export default DateInput;