import {useState, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import {UserContext as language} from '../../context/languageContext';
import { AuthContext } from '../../context/AuthContext';
import DiscountIcon from '../../assets/images/discount-icon.png';
import ConfirmModal from '../modals/confirmModal';
import { useEffect } from 'react';

const PlanItem = ({setCardSelected=null, isCardSelected = false, buttonTitle='',setSelectedPlan=null, isYearly=false, hasPromoCode, showButtons ,keywords, item, planTypeText, subTypeText, canSub, showLoginMessage, sendActivationLink, CheckPromo, isLogged, isActiveAccount, subscriptionType,cardIdSubIn}) => {

    const yearlyPlan = item?.plans?.find(plan => plan.subscription_type == 1);
    const quarterlyPlan = item?.plans?.find(plan => plan.subscription_type == 5);
    let defaultPlanId = yearlyPlan?.has_subscription ? (quarterlyPlan?.id || '') : (yearlyPlan?.id || '');
    let defaultPlan = yearlyPlan?.has_subscription ? (quarterlyPlan) : (yearlyPlan);
    
    if(isYearly){
      defaultPlanId =  (yearlyPlan?.id || '');
      defaultPlan =  (yearlyPlan);
    }
    else{
      defaultPlanId =  (quarterlyPlan?.id || '');
      defaultPlan =  (quarterlyPlan);
    }
    

    const lang = useContext(language);
    const Auth = useContext(AuthContext);
    const langDetect = lang.getSelectedLanguage();
    const [val,setVal] = useState(defaultPlanId);
    const [price,setPrice] = useState(item?.price);
    const Navigate = useNavigate();
    //alert(hasPromoCode)
    if(hasPromoCode == undefined){
      hasPromoCode = true;
    }

    useEffect(()=> {
      setVal(defaultPlanId);
      setPrice((defaultPlan?.price/(defaultPlan.id == yearlyPlan.id ? 12 : 3)).toFixed(0))
      if(setSelectedPlan && isCardSelected){
        setSelectedPlan(defaultPlan);
      }
    },[defaultPlanId])



    // window.$(".card").click(function(){
    //   if(window.$(this).hasClass("clicked")){
    //     window.$(this).removeClass("clicked");
    //   }
    //   else{
    //     window.$(".clicked").removeClass("clicked");
    //     window.$(this).addClass("clicked");
    //   }
    // })


    // var cards = document.getElementsByClassName('card');

    // for (var i = 0; i < cards.length; i++) {
      
    //   cards[i].addEventListener('click', pickACard);
    // }
    
    // function pickACard(e) {
    //   resetCards();
    //   const hasClass = e.target.classList.contains('card');
    //   if (hasClass) {
    //     // e.target.parentElement.className = 'card picked';
    //     hasClass.classList.add('picked');
    //   } else {
    //     console.log('Event.target does NOT have the specified class');
    //   }
    // }
    
    // function resetCards() {
    //   for (var i = 0; i < cards.length; i++) {
    //     cards[i].className = 'card';
    //   }
    // }
    const checkPaymentInfo = () =>  {
      Navigate('/paymentInfo');
    }

    const handleCheckoutRedirect = () => {

      if(setSelectedPlan){
        setSelectedPlan(defaultPlan);
        if(setCardSelected){
          setCardSelected(item.id);
        }
        document.querySelector('.stripe-container').scrollIntoView({ behavior: "smooth", block: "center" });
        return;
      }
      // if(showLoginMessage){
      //   showLoginMessage();
      // }
      if(Auth.isLogged && val){
        //console.log(item);
        if(item.type ==1 && cardIdSubIn && cardIdSubIn !=item.id){
          window.$('#switching-plan-modal').modal('show');
        }
        else{
          Navigate('/checkout/'+val);
        }
        
      }
      else if(Auth.isLogged && !val){

      }
      else{
        localStorage.setItem('forceRedAfterLogin','checkout/'+val);
        window.$('#login').modal('show');
      }
      
    }
    return (
<>
  
    <div className="p-card-container ">
      {setCardSelected && !isCardSelected && 
      <div style={{zIndex: 1000,background: 'rgb(225,224,224,0.45)', borderRadius: '20px'}} className='shader'></div>
      }
      {setCardSelected && !isCardSelected ?
      <div className='p-card-top-icon' style={{zIndex: 1100,opacity:'0.4'}}>
        <img src={item?.logo} alt={item['name_'+langDetect]} />
      </div>
      :
      <div className='p-card-top-icon'>
      <img src={item?.logo} alt={item['name_'+langDetect]} />
    </div>
      }
        <p className='p-card-plan' style={{color:item?.text_color || 'black'}}>{item['name_'+langDetect]}</p>
          <div className="p-card-filled" style={{background:item?.bg_color || '', direction:langDetect=='ar'? 'rtl':'ltr'}}>
            <p>{lang.starting_at}:</p>
            <p><span>{langDetect=='ar'?price+'$' : '$'+price}</span> {lang.usd_month}</p>
          </div>
        <ul>
        <div className='p-card-check-container'>
        {
          item?.features?.map( feature => (
            <li className='p-card-check-items'>
              <span>
                <span className='check-mark' style={{border: '2px solid '+item?.text_color, borderLeft: '0', borderTop: '0' || ''}}></span>
              </span>
              <p>{feature['feature_text_'+langDetect]}</p>
            </li>    
          ))
        }
      </div>

      
          <div class="p-card-choosing hide">
          <p>{lang.select_payment_period}:</p>
            <div>
              {
                yearlyPlan && (
                  <>
                  <div className={`p-card-ckb ${yearlyPlan.has_subscription && 'diabled-option'}`}>
                    <div className={`shader ${!yearlyPlan.has_subscription && 'hide'}`}></div>
                    <div className='p-card-price'><strong>{langDetect=='ar'?(yearlyPlan.price/12).toFixed(2) : (yearlyPlan.price/12).toFixed(2)}</strong> {lang.usd_month}</div>
                    <div class="p-card-radios">                    
                    <input checked={yearlyPlan?.id == val} name={"agreement_"+item.id} class="input-checkbox ckb p-card-radio ckb-dark" type="radio" id={yearlyPlan.id} value={yearlyPlan.id}/>
                    <label onClick={()=> setVal(yearlyPlan.id)} for={yearlyPlan.id} class="p-card-labeling">{lang.yearly} 
                    { yearlyPlan['discount_label_'+langDetect] &&
                      <span>
                        <img width="60" src={DiscountIcon} alt="" />
                        <p>{yearlyPlan['discount_label_'+langDetect]}</p>
                      </span>
                    }
                    </label>
                    <span>({lang.rec_once_year})</span>
                  </div>
                  </div>
                  </>
                )
              }

              {
                quarterlyPlan && (
                  <>
                  <div className={`p-card-ckb ${quarterlyPlan.has_subscription && 'diabled-option'}`}>
                  <div className={`shader ${!quarterlyPlan.has_subscription && 'hide'}`}></div>
                    <div className='p-card-price'><strong>{langDetect=='ar'?(quarterlyPlan.price/3).toFixed(2) : (quarterlyPlan.price/3).toFixed(2)}</strong> {lang.usd_month}</div>
                    <div class="p-card-radios">
                    <input checked={quarterlyPlan?.id == val} name={"agreement_"+item.id} class="input-checkbox ckb p-card-radio ckb-dark" type="radio" id={quarterlyPlan.id} value={quarterlyPlan.id}  />
                    <label  onClick={()=> setVal(quarterlyPlan.id)} for={quarterlyPlan.id} class="p-card-labeling">{lang.quarterly}
                    { quarterlyPlan['discount_label_'+langDetect] &&
                      <span>
                        <img width="60" src={DiscountIcon} alt="" />
                        <p>{quarterlyPlan['discount_label_'+langDetect]}</p>
                      </span>
                    }                    
                    </label>
                    <span>({lang.rec_three_months})</span>
                  </div>
                  </div>
                  </>
                )
              }

            </div>
          </div> 
          {
            
          canSub>0 && (
            <>
              <button style={{zIndex: 1005,position: 'relative'}} onClick={handleCheckoutRedirect} className="p-card-view-btn">{buttonTitle ? buttonTitle : lang.continue_to_payment}</button>
              </>
                    )
          }
          
          {
            !canSub &&(
              
              <button style={{zIndex: 1005,position: 'relative'}} onClick={checkPaymentInfo} className="p-card-view-btn">{lang.payment_info}</button>

              
            )

          }

          
        </ul>
    </div> 
 

    <ConfirmModal dir = {langDetect == 'ar' ? 'rtl' : 'ltr'} okBtnClass={'btn btn-primary'} okCallback={()=>{Navigate('/checkout/'+val); window.$('#switching-plan-modal').modal('hide');}} title={lang.switch_plan_title} id="switching-plan-modal" okLabel={lang.yes} cancelLabel={lang.no}>{lang.switch_plan_msg}</ConfirmModal>

</>
    )
}

export default PlanItem;