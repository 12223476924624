import type {PlayerError, Quality} from 'amazon-ivs-player';

import QualityChangeService from '../../../../core/QualityChangeService';
import {logger} from '../../../../utils/Logger';
import {EventCallbackDispatcher} from '../../EventCallbackDispatcher';
import {ErrorService} from '../playback/ErrorService';
import {PlaybackService} from '../playback/PlaybackService';
import {VideoStartupService} from '../playback/VideoStartupService';

import {AmazonIVSPlayerContext} from './AmazonIVSPlayerContext';

export class AmazonIVSPlayerEventHandler {
  constructor(
    private videoStartupService: VideoStartupService,
    private playbackService: PlaybackService,
    private errorService: ErrorService,
  ) {}

  onRebuffering() {
    logger.log('onRebuffering');
    this.playbackService.onRebuffering();
  }

  onDurationChanged(payload: number) {
    logger.log('onDurationChanged: ' + payload);
  }

  onError(payload: PlayerError) {
    logger.log('onError: ' + payload);
    this.errorService.onError(payload);
  }

  onInitialized() {
    logger.log('onInitialized');
    if (!this.videoStartupService.finishedStartup) {
      this.videoStartupService.onInitialized();
    }
  }

  onQualityChanged(payload: Quality) {
    logger.log('onQualityChanged: ' + payload);
    if (this.videoStartupService.finishedStartup) {
      this.playbackService.onQualityChange(payload);
    }
  }

  onTimeUpdate(payload: number) {
    logger.log('onTimeUpdate: ' + payload);
  }

  // HTMLVideoElement Events

  onPlay(event: Event) {
    if (!this.videoStartupService.finishedStartup) {
      this.videoStartupService.onPlay();
    } else {
      logger.log('onPlay:', event);
    }
  }

  onPause(event: Event) {
    if (this.videoStartupService.finishedStartup) {
      logger.log('onPause:', event);
      this.playbackService.onPause();
    }
  }

  onPlaying(event: Event) {
    logger.log('onPlaying:', event);
    if (!this.videoStartupService.finishedStartup) {
      this.videoStartupService.onPlaying();
      this.playbackService.onStartupPlaying();
    } else {
      this.playbackService.onPlaying();
    }
  }

  onSeek() {
    if (this.videoStartupService.finishedStartup) {
      this.playbackService.onSeek();
    }
  }

  onSeeked() {
    if (this.videoStartupService.finishedStartup) {
      this.playbackService.onSeeked();
    }
  }

  static create(
    dispatcher: EventCallbackDispatcher,
    playerContext: AmazonIVSPlayerContext,
    qualityChangeService: QualityChangeService,
  ): AmazonIVSPlayerEventHandler {
    const videoStartupService = new VideoStartupService(dispatcher, playerContext);
    const playbackService = PlaybackService.create(dispatcher, playerContext, qualityChangeService);
    const errorService = new ErrorService(dispatcher, playerContext);
    return new AmazonIVSPlayerEventHandler(videoStartupService, playbackService, errorService);
  }

  // for now we will keep those in here
  // once we have finished the implementation of IVS we can remove them

  // private onAudioBlocked() {}
  // private onBufferUpdate() {}
  // private onMutedChanged() {}
  // private onNetworkUnavailable() {}
  // private onPlaybackBlocked() {}
  // private onPlaybackRateChanged(payload: number) {}
  // private onTextCue(payload: TextCue) {}
  // private onTextMetadataCue(payload: TextMetadataCue) {}
  // private onVolumeChanged(payload: number) {}
}
