import elements from './elements/elements';
import {Form, Formik} from 'formik';
import LoadingButton from './elements/loadingButton';
import { UserContext } from '../context/languageContext';
import { useContext } from 'react';

const PricesStep2 = (props) => {

    const Lang = useContext(UserContext);
    const {RadioInput} = elements;

    const initValues = {
        numChildren:"",
    };

    const onSubmitStep = async (values, actions) => {

        //console.log('submitted');
        //console.log(props.values.values)
        if(values.numChildren>0){
            props.dispatchWizard({type:'NEXT',data:{values}})    
        }

      };     

return (
    <div className="contact-us-lower-container" style={{padding:'0'}}>
        <div className="white-area price-step" style={{marginBottom:"0"}}>

            <h1 className='price-step-title'>                
                <span style={{color: '#d9d9d9'}}>{props.step}/{props.values.total_steps}</span>
            </h1>              

            <Formik
      initialValues={initValues}
     // validationSchema={RegisterSchema}
      onSubmit={onSubmitStep}  
    >

{(props2) => {
    //console.log(props.values.dob_year);
    let {values, errors} = props2;

    return(
        <Form>
            <p className="prices-text">{Lang.pre_price_q1}</p>

            <div className='answers flex-center'>
                <RadioInput style={{'width':'150px'}} labels={['1', '2', '3']} ids={['ckb11','ckb22','ckb33']} values={[1,2,3]} name="numChildren"  class="ckbox-prices"/>
            </div>

            <center>
                <button type='button' className='submit-button-auto prices-btn  reg-btn inline-block btn-margin' onClick={()=>props.dispatchWizard({type:'RESET'})  }>{Lang.back}</button>
                <LoadingButton loading={`false`} type="submit" className="submit-button-auto prices-btn reg-btn inline-block" loading-text="<i class='fa fa-circle-o-notch fa-spin'></i> Processing" >{Lang.continue}</LoadingButton>
            </center>
        </Form>    
    )}}
    </Formik>                

        </div>
    </div>
)
}

export default PricesStep2