import { useContext, useEffect, useState } from "react";
import { UserContext } from "../context/languageContext";
// import chartsAr from './images/chartsAr.png';
// import chartsEn from './images/chartsEn.png';
import { ReplaceTagStringWithElement } from "../helpers/functions";
import DoughnutChart from "../Components/elements/charts/doughnutChart";
import config from "../Components/config";

const SurveyResult = ({data, promoCodeData, redirect, isSubscriber}) => {
 const language = useContext(UserContext);   
 const selectedLang = language.getSelectedLanguage(); 

 let leftImage = config.serverDomain+'images/client/surveyLeft'+(selectedLang == 'ar' ? 'Ar':'En')+'.png';

 let promoHeading1 = promoCodeData.length > 0 ? language.survey_result_heading7_1.replace('%s',(promoCodeData['discount_value']*100)+'%') : '';
 let promoHeading2 = promoCodeData.length > 0 ? language.survey_result_heading7_2.replace('%s',promoCodeData['promo_name']) : '';
 
 promoHeading1 = promoHeading1 && ReplaceTagStringWithElement(promoHeading1, 'span1', <span style={{color:"#f49422"}} />);
 promoHeading2 = promoHeading2 && ReplaceTagStringWithElement(promoHeading2, 'span2', <span style={{color:"#f49422"}} />);
    
    return (
        <>
         <div className="surveyResult-container">
            {/* <div className="dear-name">
                <span>عزيزي/عزيزتي</span>
                <span>فرح ابو يوسف</span>
            </div> */}
            <div className="textOfResult">
                {language.survey_result_heading2}
            </div>
            <div className="progressCircles">
              <div className="resultText" style={{margin: '0 15px'}}>{language.survey_result_heading3}</div>
              <div className="circlesContainer">
                {
                    data?.recommendations?.map( item => (
                        <div className="progressCircle">
                        <div>
                            <DoughnutChart 
                                            
                                centerText={Math.round(item.percentage,2)+'%'} 
                                data={[item.percentage, (100-item.percentage)]} 
                                options={{cutout: 28, plugins:{legend:{display:false}, 
                                tooltip:{enabled:false}}}} 
                                width={80} 
                                height={80} 
                                backgroundColor={[item.color,'#bfbcbc']}
                                lang={selectedLang}
                            />                            
                        </div>
                        <div style={{textAlign:'center'}}>{selectedLang == 'en' ? item?.course_name : item['course_name_'+selectedLang]}</div>
                        </div>                        

                    ))
                }

                </div>
              <div className="surveyReultBtn" style={{textAlign:'center'}} onClick={()=> window.location.href= redirect ||  '/course/'+data['top']['id']+'/'+(selectedLang == 'en' ? data['top']['name'] : data['top']['name_'+selectedLang])}>{language.survey_result_button1.replace('%s',selectedLang == 'en' ? data['top']['name'] : data['top']['name_'+selectedLang])}</div>
            </div>
            <div className="textOfResult">{language.survey_result_heading4}</div>
              
              <section className="thePartsSection">
                <div className="squaresContainer">
                    <div className="resultText">
                    {
                        ReplaceTagStringWithElement(language.survey_result_heading6.replace('%s',selectedLang == 'en' ? data['top']['name'] : data['top']['name_'+selectedLang]),'span1',<span style={{fontWeight:'bold'}}/>)
                    }
                    </div>
                    <div className="three-squares" style={{flexDirection : selectedLang=='ar' ? 'row' : 'row-reverse'}}>
                        <div className="square first-square" style={{textAlign:'center'}}> 
                        <img src={config.serverDomain+'images/client/surveyRightCircle2.png'}/>
                            <div className="square-inside">                                
                                <div>{selectedLang == 'en' ? data?.top?.modules[0]['name'] : data?.top?.modules[0]['name_'+selectedLang] }</div> 
                            </div>                        
                        </div>
                        <div className="square second-square" style={{textAlign:'center'}}>
                            <img src={config.serverDomain+'images/client/surveyRightCircle1.png'}/>
                            <div className="square-inside">
                                <div>{selectedLang == 'en' ? data?.top?.modules[1]['name'] : data?.top?.modules[1]['name_'+selectedLang] } </div>
                            </div>                         
                        </div>
                    </div>
                    <div className="three-squares">                        
                        <div className="square third-square" style={{textAlign:'center'}}>
                            <img src={config.serverDomain+'images/client/surveyRightCircle3.png'}/>
                            <div className="square-inside">
                                <div>{selectedLang == 'en' ? data?.top?.modules[2]['name'] : data?.top?.modules[2]['name_'+selectedLang] } </div>
                            </div>                             
                        </div>
                    </div>                    
                </div>
            <div className="chartContainer" style={{justifyContent:"unset", padding: "30px 10px"}}>
                <div className="resultText">{language.survey_result_heading5}</div>
                <div style={{"textAlign": "center"}}>
                    <img style={{width:"250px"}} className="" src={leftImage} alt={language.survey_result_heading5} />
                </div>
            </div>
            </section>
            { !isSubscriber ?  
            <div className="resultText" style={{color: "#808080", marginTop: "3rem"}}>{promoHeading1}</div>
            : ''}
            <div className="surveyReultBtn" style={{textAlign:'center'}} onClick={()=> window.location.href= redirect ||  '/course/'+data['top']['id']+'/'+(selectedLang == 'en' ? data['top']['name'] : data['top']['name_'+selectedLang])}>{language.survey_result_button2}</div>
            {/* <a href={ '/courses'} style={{textAlign: 'center',display: 'block',fontSize: '20px',color: 'cornflowerblue'}}>{language.check_all_courses}</a> */}
            { !isSubscriber && promoCodeData['end_date'] && promoCodeData['end_date'] != undefined && promoCodeData['end_date'] != 'undefined' ?  
            <>
            <div className="validityText">
                {
                    ReplaceTagStringWithElement(language.survey_result_heading8.replace('%s',promoCodeData['end_date']),'span1',<span style={{color:"#f49422"}}/>)
                }
            </div>
            <div className="validityText">{language.survey_result_heading9}</div>
            </>
            : ''}
        </div>    
        </>
    );
}

export default SurveyResult