import * as Yup from 'yup';
import {getAge} from '../../helpers/functions';

//const professions = ['Doctor', 'Engineer', 'Programmer'];


const UserRegistrationSchema = Yup.object().shape({

//     dob: Yup.string()
//             .required("dob_required_err"), 
        dob_day: Yup.number('dob_required_err').required("dob_required_err").min(1, 'dob_required_err'), 
        dob_month: Yup.number('dob_required_err').required("dob_required_err").min(1, 'dob_required_err'), 
        dob_year: Yup.number('dob_required_err').required("dob_required_err").min(1, 'dob_required_err'), 
    first_name: Yup.string()
            //.label('Fisrt Name')
            .required("fname_required_err")
            .matches(/^[aA-zZ\s\u0600-\u06FF]+$/, "fname_pattern_err"),
    last_name: Yup.string()
            //.label('Last Name')
            .required("lname_required_err")
            .matches(/^[aA-zZ\s\u0600-\u06FF]+$/, "lname_pattern_err"),
    email: Yup.string()

    .when(['is_mobile'], {
        is: (is_mobile) => {
                return parseInt(is_mobile) !=1
        },
        then: () => Yup.string().required('email_required_err'),
      })
            //.required("email_required_err")
            //.email("email_format_err"),     
            
            .test('validator-username-email', (value, { createError,resolve }) =>{
                //const emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
                const emailRegex = /^\s*([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+\s*$/;
                //const nameRegex = /^[a-zA-Z]+$/;
                const nameRegex = /^[A-Za-z]+[A-Za-z0-9]*$/;
                const y = resolve(Yup.ref('dob_year'));
                const m = resolve(Yup.ref('dob_month'));
                const d = resolve(Yup.ref('dob_day'));                
                const age = getAge(y+'-'+m+'-'+d);
                if(value && !emailRegex.test(value)){
                                                 
                        return createError({
                                message: `email_format_err`,
                                path: 'email', // Fieldname
                        })
                        
                }
                return true;
            }),

        mobile: Yup.string()
        .when(['dob_year', 'dob_month', 'dob_day', 'is_mobile'], {
        is: (y, m, d, is_mobile) => {
                if(!is_mobile){
                        return false;
                }
                return true;//y && m && d && getAge(y+'-'+m+'-'+d) >= 18
        },
        then: () =>  Yup.string().required('mobile_required_err'),
        })
        .matches(/^\d+$/, 'mobile_err'),

    password: Yup.string()
            .required("password_required_err")
            .min(8, 'password_min_err')
            .matches(/^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+$/,"password_english_err"),
            //.matches(/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!-#_+])[a-zA-Z0-9!-#_+]+$/,"password_pattern_err"),
    parent_email: Yup.string()


      .test('validator-parentemail-email', (value, { createError,resolve }) =>{
        const email = resolve(Yup.ref('email'));
        const same = email == value ;
        if(same && (email!='' && email !=undefined)){


                return createError({
                        message: `parent_same_email`,
                        path: 'parent_email', // Fieldname
                })
                        
        }
        return true;
    }),






      behalf: Yup.string()

      .when(['dob_year', 'dob_month', 'dob_day'], {
        is: (y, m, d) => {
                return y && m && d && getAge(y+'-'+m+'-'+d) > 18
        },
        //then: Yup.string().required('behalf_required_err'),
        then: () => Yup.string(),
      }),

      relation: Yup.string()
      //.label('Relationship')
      .when('behalf', (val, schema) => {
        if(val == 1){
            return schema.required("relation_required_err"); 
        }
        return schema;
      }),      
      agreement: Yup.string()
      //.label('Agreement')
      .test('isChecked','agremeent_required_err',(val)=>{return val=='true'}),
      children: Yup.string()
      .when('behalf', (val, schema) => {
        if(val == 1){
            return schema.required("agremeent_required_err"); 
        }
        return schema;
      }),
    
      
 
  })

  export default UserRegistrationSchema;