import type {PlayerAPI} from 'bitmovin-player';

import {SegmentTracker} from '../../core/SegmentTracker';
import {SegmentInfo} from '../../types/SegmentInfo';
import * as Utils from '../../utils/Utils';

interface DownloadFinishedEvent {
  attempt?: number;
  downloadType?: string;
  httpStatus: number;
  mimeType?: string;
  success: boolean;
  timestamp: number;
  type: string;
  url: string;
}

interface SegmentPlaybackEvent {
  duration?: number;
  mediaInfo?: any;
  mimeType?: string;
  playbackTime?: number;
  representationId?: string;
  timestamp?: number;
  type?: string;
  uid?: string;
  url?: string;
}

export class Bitmovin8SegmentTrackerAdapter {
  private segmentTracker: SegmentTracker;

  constructor(player: PlayerAPI, segmentTracker: SegmentTracker) {
    this.segmentTracker = segmentTracker;

    player.on(player.exports.PlayerEvent.DownloadFinished, (event) => this.handleOnDownloadFinishEvent(event));
    player.on(player.exports.PlayerEvent.SegmentPlayback, (event) => this.handleSegmentPlaybackEvent(event));
  }

  getSegmentTracker(): SegmentTracker {
    return this.segmentTracker;
  }

  handleOnDownloadFinishEvent(event: DownloadFinishedEvent & any) {
    if (event.downloadType.indexOf('media/video') === 0) {
      const url = event.url;
      const segment: SegmentInfo = {
        name: Utils.getURLResourceName(url),
        url,
        timestamp: event.timestamp,
      };
      this.segmentTracker.addSegment(segment);
    }
  }

  handleSegmentPlaybackEvent = (event: SegmentPlaybackEvent & any) => {
    this.segmentTracker.removeSegment(event.url);
  };
}
