import Config from '../../Components/config';
import { useContext, useEffect, useState } from "react";
import { UserContext } from '../../context/languageContext';
import ApiHelper from '../../helpers/ApiHelper';
import {NavLink} from 'react-router-dom';
import '../../assets/css/courses.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation} from 'swiper/modules';
import 'swiper/css';
import { getCourseNameBasedOnLanguage } from '../../helpers/functions';


const AllCourses = () => {

      const breakpoints={
        0: {
            slidesPerView: 3,
        },
        450: {
            slidesPerView: 3,
        },
        600: {
            slidesPerView: 3,
        },
        700: {
            slidesPerView: 3,
        },
        1000: {
            slidesPerView: 5,
        },
        1500:{
            slidesPerView: 6,
        },
        2000:{
            slidesPerView: 6,
        }
      }

    let [coursesList, setCourses] = useState([]); 
    if(typeof(coursesList) == 'string'){
        coursesList = JSON.parse(coursesList);
    }    
    const lagKeywords = useContext(UserContext);
    useEffect(()=>{
        ApiHelper.excuteApi(Config.coursesApi,'get','{}',setCourses);
    },[])    
    return(
        <>            
        <section className="all-courses-container">
        {/* <OwlCarousel  className="slider-items owl-carousel" {...options}> */}
        
       <div className="swiper-button-prev previous-swipe"></div>
       <div className="swiper-button-next next-swipe"></div>
        <Swiper
            breakpoints={breakpoints}
            modules={[Navigation]}
            spaceBetween={17}
            //navigation 
            navigation={{
                nextEl: '.next-swipe',
                prevEl: '.previous-swipe',
              }}            
            slidesPerView={6}
            slidesPerGroup={1}
            // onSlideChange={() => console.log('slide change')}
            //onSwiper={(swiper) => console.log(swiper)}
        >
            {
            coursesList && coursesList.length > 0 && coursesList.map((coursesListObj, index) => {
                if(coursesListObj.categoryid>0)
                {

                    let courseName = getCourseNameBasedOnLanguage(coursesListObj,lagKeywords.getSelectedLanguage());
                    return (     

                        <SwiperSlide key={index}>
                        <div key={coursesListObj.id}>   
                            <img className='course-icon' src={Config.imgDns+"icon/course_"+coursesListObj.id+".svg"} alt={courseName} />
                            <NavLink className="menu-link" to={'/course/'+coursesListObj.id+'/'+courseName?.split(' ').join('-')}>{courseName}</NavLink>
                            <button className="course-btn"><NavLink to={'/course/'+coursesListObj.id+'/'+courseName?.split(' ').join('-')}>{lagKeywords.watch_now}</NavLink></button>                               
                        </div>        
                        </SwiperSlide>
                                   

                    )
                   
                }
                  
                })
            }    
            </Swiper>            
             {/* </OwlCarousel> */}
        </section>

        </>        
    )
}

export default AllCourses;