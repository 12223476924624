import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { AuthContext } from "../context/AuthContext";

const ScrollToTop =() => {
  const { pathname } = useLocation();

  const cookies = new Cookies();
  const navigate = useNavigate();
  const Auth = useContext(AuthContext);
  useEffect(()=>{
    var intervalHandle = setInterval(() => {
      let isLoggedCookie = cookies.get('isLogged') ? cookies.get('isLogged') : false;   
      if(!isLoggedCookie){
        clearInterval(intervalHandle);
        Auth.storageLogout(true);
        if(Auth.isLogged){
          navigate('/');
        }
      }
    }, 10000);
  },[])

  useEffect(() => {
    window.scrollTo(0, 0);
    saveTrafficSource();
  }, [pathname]);

  const saveTrafficSource = () => {
    const trafficSrcParams = ['gclid','fclid','clickid'];
    const searchParams = new URLSearchParams(document.location.search);
    let sourceKey, sourceVal = '';
    for(let i=0;i<trafficSrcParams.length;i++){
      let paramName = trafficSrcParams[i];
      if(searchParams.get(paramName)){
        sourceKey = paramName;
        sourceVal = searchParams.get(paramName);        
        break;
      }
    }
    ///-------get third party
    const trafficSrcParamsThirdParty = ['dm'];
    const searchParamsThirdParty = new URLSearchParams(document.location.search);
    let sourceKeyThirdParty, sourceValThirdParty = '';
    for(let i=0;i<trafficSrcParamsThirdParty.length;i++){
      let paramNameThirdParty = trafficSrcParamsThirdParty[i];
      if(searchParamsThirdParty.get(paramNameThirdParty)){
        sourceKeyThirdParty = paramNameThirdParty;
        sourceValThirdParty = searchParamsThirdParty.get(paramNameThirdParty);        
        break;
      }
    }    

    if(sourceKey && sourceVal){
      let maxAge = 86400 // 24 hours
      cookies.set('trafficSrcKey', sourceKey, { path: '/' , maxAge});
      cookies.set('trafficSrcVal', sourceVal, { path: '/' , maxAge});
    }
    if(sourceKeyThirdParty && sourceValThirdParty){
      let maxAge = 86400 // 24 hours
      cookies.set('thirdPartySrcKey', sourceKeyThirdParty, { path: '/' , maxAge});
      cookies.set('thirdPartySrcVal', sourceValThirdParty, { path: '/' , maxAge});
    }


  }  
  return null;
}

export default ScrollToTop;