export default class HTMLVideoElementStatisticsProvider {
  private previousTotalDroppedFrames = 0;

  constructor(private videoElement: HTMLVideoElement | undefined) {}
  getDroppedFrames(): number {
    if (this.videoElement == undefined) {
      return 0;
    }
    const droppedFrames = this.videoElement.getVideoPlaybackQuality().droppedVideoFrames;
    const currentDroppedFrames = droppedFrames - this.previousTotalDroppedFrames;
    this.previousTotalDroppedFrames = droppedFrames;
    return currentDroppedFrames;
  }
  reset() {
    this.previousTotalDroppedFrames = 0;
  }
}
