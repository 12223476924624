import { useContext, useEffect, useState } from "react";
import  { AuthContext } from "../context/AuthContext";
import { UserContext } from "../context/languageContext";
import CategoryItem from "./sections/categoryItem";
import { NavLink } from "react-router-dom";
import backBtn from '../assets/images/arrow-left-blue.svg';
import config from "./config";

const Courses = () => {

    const [categorizedCourses, setCategorizedCourses] = useState([]);
    const [categories, setCategories] = useState([]);
    const Auth = useContext(AuthContext);
    const lang = useContext(UserContext);
    let looped = [];
  
    useEffect(()=> {
        Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('categorizedCourses').then(res => {            
            if(res?.data?.status ==1){                
                setCategories(res.data?.payload?.categories || []);
                setCategorizedCourses(res.data?.payload?.courses || []);                
            }
        })
    },[])

    document.querySelector('meta[name="description"]').setAttribute("content", lang.courses_tag_desc);
    document.querySelector('meta[name="keywords"]').setAttribute("content", lang.courses_tag_keywords);        
    document.title = lang.courses_page_title;

    return (

        <div className="section-container1">
        <div className="content-container1" style={{backgroundColor:'#F5F5F5'}}>
            {/* <NavLink to="/" className="back-blue-btn"><img src={backBtn} alt="back"/><p>{lang.back}</p></NavLink> */}
            <div className='courses-from-cat'>
            <p className="courses-statement">
              {lang.courses_statement}
            </p>
        <>
        {
            
            categories?.map( item => {

                if(looped.includes(item.id)){
                    return '';
                }
                let catId = item.id;
                let couresesUnder = categorizedCourses[item.id];
                looped.push(item.id);
                if(item.parent_category !=0){
                    catId = item.parent_category;
                    if(looped.includes(catId)){
                        return '';
                    }           
                    looped.push(catId);         
                    couresesUnder =[...couresesUnder, ...categorizedCourses[catId]];;
                }
             
                return  <>
               
                <div className="single-course swiper-course1">
                            <div className="cat-item">
                                {/* <img src={config.imgDns+ "icon/cat_"+catId+".svg"} width={70}/> */}
                               <p className="single-cat-name">{lang['cat_name_'+catId]}</p>
                            </div> 
                            <CategoryItem key={item.id} id={catId} courses={couresesUnder}/>
                       </div>
               </>
            })
        }
        </>
            </div>
        </div>
        </div>

    );
}

export default Courses;