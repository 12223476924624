

export const ChildsInitState = {
    isNew : true,
    userId : 0,
    showForm : false,
    children:[],
    selectedChild:{},
    messageBody:'',
    messageTitle:'',
    isLoading:{initial:false, remove:false, add:false, check_add_ability:true},
    totalLoaders:4,
    can_add_child:false,
    total_childs_can_add:0,
    showCardLoader: true
};



const ChildsReducer = (state=  ChildsInitState, action) => {
    switch (action.type) {
        case 'PREPARE_NEW_USER':               
            return {...state, isNew:true, userId:0, selectedChild:{}, showForm:true};
            //break;
        case 'PREPARE_EDIT_USER':           
            //console.log(action.data)    
            return {...state, isNew:false, userId:action.data.uid, selectedChild:action.data, showForm:true};
            //break;            
        case 'SET_CHILDREN':               
            return {...state, children:action.data, isLoading:{...state.isLoading, initial:false}};
            //break;   
        case 'UPDATE_CHILDREN':    
            state.children.find((item, index) => {
                if(action.data.uid == state.selectedChild.uid){
                    state.children[index]['first_name'] = action.data.first_name;
                    state.children[index]['last_name'] = action.data.last_name;
                    return item;
                }
            });           
            return {...state};
            //break;               
        case 'ADD_CHILDREN':               
            return {...state, children:[...state.children, action.data], isNew:false, userId:action.data.uid, showForm:false, selectedChild:action.data, isLoading:{...state.isLoading, add:false}};
            //break
        case 'REMOVE_CHILDREN':               
            return {...state, children:[...state.children, action.data], isLoading:{...state.isLoading, remove:false} };            
            //break;                  
        case 'SELECT_CHILD':               
            return {...state, selectedChild:action.data, showForm:false};
            //break;     
        case 'SET_MESSAGE':               
            return {...state, messageBody:action.data.body, messageTitle:action.data.title};
            //break;   
        case 'START_INITIAL_LOADING':               
            return {...state, isLoading:{...state.isLoading, initial:true}, totalLoaders:ChildsInitState.totalLoaders};
            //break;     
        case 'END_INITIAL_LOADING':               
            return {...state, isLoading:{...state.isLoading, initial:false}};
            //break
        case 'START_REMOVE_LOADING':               
            return {...state, isLoading:{...state.isLoading, remove:true}};
            //break;     
        case 'END_REMOVE_LOADING':               
            if(action.data == true){
                state.children = state.children.filter(item => item.uid != state.selectedChild.uid)
            }
            return {...state, isLoading:{...state.isLoading, remove:false}};            
            //break;      
        case 'START_ADD_LOADING':               
            return {...state, isLoading:{...state.isLoading, add:true}, totalLoaders:1};
            //break;     
        case 'END_ADD_LOADING':               
            return {...state, isLoading:{...state.isLoading, add:false}};  
            //break
        case 'SET_CAN_ADD_CHILD':
            return {...state, can_add_child:action.data.value, total_childs_can_add:action.data.max_child_to_add, isLoading:{...state.isLoading, check_add_ability:false}}                                                        
        case 'CLOSE_FORM':               
            return {...state, showForm:false, showCardLoader:false};            
        case 'RESET_DATA':               
            return {...ChildsInitState};
            //break;     
            
        default:
            break;
    }

    return state;
}


export default ChildsReducer;