import React, {useContext, useEffect, useState} from 'react';
import '../../assets/css/campTheme.css';
import content from './campThemeData';
import ApiHelper from '../../helpers/ApiHelper';
import {UserContext} from '../../context/languageContext'
import Config from '../../Components/config';
import { AuthContext } from '../../context/AuthContext';

const SpecialFooter=()=>{
  const Auth = useContext(AuthContext);
  const searchParams = new URLSearchParams(document.location.search);
  let courseSelected = searchParams.get('courseSelected'); //view theme based on
  const Language = localStorage.getItem('lang');
  const value = React.useContext(UserContext);  
  let [faqs, setfaqs] = useState([]); 
  let faqsApi=Config.campFaqApi;

  useEffect(()=>{
    // ApiHelper.excuteApi(faqsApi+'&courseName=coding','get','{}',setfaqs);
    // console.log(faqs);
   
    Auth.authorizedRequest({'Content-Type':'application/x-www-form-urlencoded'}).post('getCampFaq',{courseName:courseSelected}).then(res => {
      if(res?.data?.status ==1){
        setfaqs(res.data.payload);
        // console.log(res.data.payload)
       
      }
    })
    },[]        
  )  

  return(
    <>
       <div className='camp-cards'>
       {content.map(({ course, testimonials }, index) => (
         courseSelected == course ? 
        testimonials.map(({ id, age_ar, age_en, name_ar, name_en, testimonial_ar, testimonial_en, img }, index) => (

        <div className='camp-card'>
          <img src={img} alt="" />
          <p>
            <strong>{Language == 'ar' ? name_ar : name_en }</strong>
            <strong>{Language == 'ar' ? age_ar : age_en }</strong><br></br>
            {Language == 'ar' ? testimonial_ar : testimonial_en } 
          </p>
        </div>
        ))
        : ''
        ))}
       </div>


<section className="camp-accordion">
{faqs?.questions?.map( question => 
           {   
            return(  <>
              {( courseSelected == question.course ? 
       
<div class="tab">
  <input type="checkbox" name="accordion-1" id={question.id} />
  <label for={question.id} className="tab__label">{Language == 'ar' ? question.question_ar : question.question_en }</label>
  {Language == 'ar' ? 

  <div className="tab__content" dangerouslySetInnerHTML={{__html:question.answer_ar}}></div>
  : 
  <div className="tab__content" dangerouslySetInnerHTML={{__html:question.answer_en}}></div>

  
}
</div>
   : '' )} 
   </>
    )  
}       )}
</section>
    </>
  )
}

export default SpecialFooter