import {saveTrafficParams, getThirdPartySource} from '../../helpers/functions';
saveTrafficParams('thirdParty');

let config={
    GOOGLE_CLIENT_ID:'887017407630-kical4mfal36rbg132tkmglb2jg8qt1e.apps.googleusercontent.com',
    GOOGLE_TAG_AD_WORD: 'AW-11368082204',
    GOOGLE_TAG_CONVERSION_ID: 'Qx1HCNyE8e0YEJzW3Kwq',
    GOODLE_TAG_SUBSCRIBE_EVENT_SUCCESS:'Subscribe New',
    GOODLE_TAG_SUBSCRIBE_EVENT_FAILED:'SignUp',
    GOODLE_TAG_SUBSCRIBE_EVENT_UNDER_PROCESSING:'SignUp',
    GOOGLE_TAG_PAGE_VIEW_ID: '2GEhCNipz9gYEMvBntIp',
    GOOGLE_TAG_CONVERSION_SigupID: 'Qx1HCNyE8e0YEJzW3Kwq',
    GOOGLE_TAG_CONVERSION_purchases: '7xgaCOLj9OoYEJzW3Kwq',

}

// console.log('->->->->->->->->->->->->->->->->->->->->->'+getThirdPartySource())
if(getThirdPartySource() == 'dm'){
    console.log('google updated');
    config={
        GOOGLE_CLIENT_ID:'887017407630-kical4mfal36rbg132tkmglb2jg8qt1e.apps.googleusercontent.com',
        GOOGLE_TAG_AD_WORD: 'AW-11178320075',
        GOOGLE_TAG_CONVERSION_ID: 'Xdw1COCk7p8YEMvBntIp',
        GOODLE_TAG_SUBSCRIBE_EVENT_SUCCESS:'Subscribe New',
        GOODLE_TAG_SUBSCRIBE_EVENT_FAILED:'SignUp',
        GOODLE_TAG_SUBSCRIBE_EVENT_UNDER_PROCESSING:'SignUp',
        GOOGLE_TAG_PAGE_VIEW_ID: '2GEhCNipz9gYEMvBntIp',
        GOOGLE_TAG_CONVERSION_SigupID: 'Hz3yCMLPmKAYEMvBntIp',    
    }
}


export default config