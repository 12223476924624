import React, { Component, useState, useEffect, useContext, useReducer ,useRef} from 'react';
import { useParams } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import Langauge,{UserContext} from '../context/languageContext';
import { ProfileContext } from '../context/ProfileContext';
import ApiHelper from "../helpers/ApiHelper";
import Config from '../Components/config';
import '../assets/css/certificate.css';
import { useNavigate } from 'react-router-dom';
import { GetCoursesFormSubCat } from './landingpage';
import { NavLink } from "react-router-dom";
import backBtn from '../assets/images/arrow-left-blue.svg';

const CategoryCourses =(props)=>
{
    let [introCourses, setCourses] = useState([]); 
    let {catId,catName} = useParams([]);
    const lagKeywords = React.useContext(UserContext);
    useEffect(()=>{
        ApiHelper.excuteApi(Config.coursesApi,'get','{}',setCourses);//.then(res=>console.log(res));

    },[]        
    )
      


    return (
        <>
        <div className="section-container">
            <div className="content-container">
                <NavLink to="/" className="back-blue-btn"><img src={backBtn} alt="back"/><p>{lagKeywords.back}</p></NavLink>
                <div className='courses-form-subcat'>
                    {/* <div className='my-cert-title'>{lagKeywords.courses_under_category+ " "+catName}</div> */}
                    <GetCoursesFormSubCat categoryId={catId} introCourses={introCourses} />
                </div>
                </div>
            </div>
        </>
    )


}

export default CategoryCourses;