import { useEffect, useState } from "react";

const Answer = ({data, question, selectAnswer, selected, changeSize, submitFunction}) => {

        const [clicked, setClicked] = useState(false);
        let ansItemStyle = {};
        let ansTextStyle = {};
        let ansImgStyle = {};

        if (changeSize == true){
                ansItemStyle = {height: "45px", margin : "5px 0px", padding : "8px"};
                ansTextStyle = {fontSize: "15px"};
                ansImgStyle = {width: "47px"};
               }

               
        useEffect(()=> {
                if(question.isMultipleAnswers == false && selected==true && clicked ==true){
                        submitFunction(0.1);
                        //console.log('do automatic next');
                }
        }, [question, selected])               
    return (
            <div key={'ans_'+question.id+'_'+data.id} selected style={ansItemStyle} className={`answer-item ${selected && 'selected'}`} onClick={() =>{setClicked(true);selectAnswer(question.id, data.id)}}>
                    <div className="anwser-text" style={ansTextStyle}>{data.text}</div>
                    {data.img &&<img className="anwser-img" style={ansImgStyle} src={data.img}/>}
            </div>        
    )
}

export default Answer;