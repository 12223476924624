import {useState, useContext, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import {Form, Formik} from 'formik';
import CampaignJourneyStep1Schema from './schema/campaignJourneyStep1Schema';
import Elements from './elements/elements';
import {getAge, ReplaceTagStringWithElement} from '../helpers/functions';
import {AuthContext} from '../context/AuthContext'
import { UserContext as language } from '../context/languageContext';
import config from './config';
import '../assets/css/forms.css';
import { gtag, install } from 'ga-gtag';
import ReactPixel from 'react-facebook-pixel';
import googleConfig from './config/google'
import facebookConfig from './config/facebook';
import { NavLink } from 'react-router-dom';
import Timer from './elements/timer';
import CustomInput from './elements/customInput';
import LoadingButton from './elements/loadingButton';
import CodeSchema from './schema/codeSchemaCampaign';
import { firebaseAuth,  RecaptchaVerifier,signInWithPhoneNumber } from './config/firebase';

const CampaignJourneyMobileOtpStep3 = (props) => {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(0);   
    let [response, setResponse] = useState(null);
    const Auth = useContext(AuthContext);
    const Lang = useContext(language);    
    const dataFromPrevStep = props?.values?.values || [];
    const [waitingToResend, setWaitingToResend] = useState(0)
    const [verifier, setVerifier] = useState(null);
    const [confirmationResult, setConfirmationResult] = useState(null);
    const [mobileNumber,setMobileNumber] = useState(dataFromPrevStep?.mobile_prefix && dataFromPrevStep?.mobile ? dataFromPrevStep.mobile_prefix+''+dataFromPrevStep.mobile:'')
    //const [mobileNumber,setMobileNumber] = useState('+972569212399')
    




    useEffect(()=>{
        if(Auth.isLogged){
            navigate('/');
        } 

                
        const advancedMatching = {};    
        const options = {
            autoConfig: true, 
            debug: false, 
          };

        const payment_status = dataFromPrevStep.payment_status;
        if(payment_status == 0){// failed billing
            gtag('event', googleConfig.GOODLE_TAG_SUBSCRIBE_EVENT_FAILED, { 
                'send_to':googleConfig.GOOGLE_TAG_AD_WORD+'/'+googleConfig.GOOGLE_TAG_CONVERSION_ID
            });
            ReactPixel.init(facebookConfig.FACEBOOK_PIXEL_ID, advancedMatching, options);
            ReactPixel.track(facebookConfig.FACEBOOK_PIXEL_SUBSCRIBE_EVENT_FAILED);
        }
        else if(payment_status == 1){//success billing
            gtag('event', googleConfig.GOODLE_TAG_SUBSCRIBE_EVENT_SUCCESS, { 
                'send_to':googleConfig.GOOGLE_TAG_AD_WORD+'/'+googleConfig.GOOGLE_TAG_CONVERSION_ID
            });
            ReactPixel.init(facebookConfig.FACEBOOK_PIXEL_ID, advancedMatching, options);
            ReactPixel.track(facebookConfig.FACEBOOK_PIXEL_SUBSCRIBE_EVENT_SUCCESS);
        }
        else if(payment_status == 2){//billing under processing//
            gtag('event', googleConfig.GOODLE_TAG_SUBSCRIBE_EVENT_UNDER_PROCESSING, { 
                'send_to':googleConfig.GOOGLE_TAG_AD_WORD+'/'+googleConfig.GOOGLE_TAG_CONVERSION_SigupID
            });
            ReactPixel.init(facebookConfig.FACEBOOK_PIXEL_ID, advancedMatching, options);
            ReactPixel.track(facebookConfig.FACEBOOK_PIXEL_SUBSCRIBE_EVENT_UNDER_PROCESSING);
        }    
                               
    },[])
   

    //console.log(props);
    //console.log(dataFromPrevStep);

      const initValues = {
        code:"",
    };

    const onSubmit = (values) => {

          setLoading(1);
          setResponse(null);
          values.code = '12345'
        console.log(dataFromPrevStep)
          /** get url for complete registeration */
          Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded',}).post('getCompleteRegUrl',{...values,token:dataFromPrevStep?.token}).then(res => {
            //console.log(res);
            if(res?.data?.status ===1){ //success
                if(res?.data?.payload?.url){
                  setResponse(1);
                  navigate(res.data.payload.url);
                }
            }
            else if(res?.data?.status ===0){
              setResponse(0);
            }
            setLoading(2);
          }).catch(err => {
              //console.log(err);
              //setResponse(-1);
              setLoading(2);
          });



    }
    return(
    <>
      <div className="section-container" style={{"padding": "0"}}> 
        <div className='content-container'>
        <div className="reg-form">
        <h2 className="reg-title" style={{'textAlign':'center', 'paddigBottom': '3%'}}>{Lang.RegisterNewAccount}</h2>

        {dataFromPrevStep?.message && <center>{dataFromPrevStep.message}</center>}
        <center>

              <Formik initialValues={initValues} onSubmit={onSubmit} >
              {(props) => {
                  let {values, errors, touched, handleChange, setErrors, setFieldValue, setValues} = props;
                                  
                  return(
                    <Form>
                          <LoadingButton loading={isLoading==1 ?`true`:`false`} type="submit" className="submit-button-auto reg-btn" loading-text="<i class='fa fa-circle-o-notch fa-spin'></i> Processing" >{Lang.complete_your_reg}</LoadingButton>
                    </Form>
                  )
              }}
              </Formik>

        </center>


        </div>
        </div>
        
        </div>        
    </>
    );
}


export default CampaignJourneyMobileOtpStep3;