import React, { Component } from 'react';
import './index.css';


const About=()=>{
  return(
    <>
    <div className="intro-section">
            {/* <div className="opacity"></div> */}
            <div className="intro-video">
                <video controls>
                    <source type="video/mp4" src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4" />
                </video>
                <h2 className="intro-title">Intro About Mawaheb</h2>
                <p className="intro-paragraph">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, 
                   sed diam nonummy nibh euismod tincidunt ut laoreet dolore
                   magna aliquam erat volutpat. Ut wisi enim ad minim veniam, 
                   quis nostrud exerci tation ullamcorper suscipit lobortis 
                   nisl ut aliquip ex ea commodo consequat. Duis autem vel 
                   eum iriure dolor in hendrerit in vulputate velit esse molestie 
                   consequat, vel illum dolore eu feugiat nulla facilisis at vero 
                   eros et accumsan et iusto odio dignissim qui blandit praesent 
                   luptatum zzril delenit augue duis dolore te feugait nulla facilisi.</p>
            </div>
        </div>
        </>
  )
}



function IsLogged(props)
{
  if(props.isLoggedIn)
    return <h1>u are in</h1>;
  else
    return <h1>u are out</h1>;

}


const listItemsI=['Mohammad','shareif'];
const listItems= listItemsI.map((listItemsI=>{return <li>{listItemsI}</li>}));

const numberList=[10,20,30,40]
const numberListp=numberList.map((numberList=>{return <li>{numberList*5}</li>}))

class Abouts extends Component
{
    constructor(props)
    {
      super(props);
      this.state={
        companyName:'',
        isLoggedIn:false
      }
    
    }
  
  
    changeText(event)
    {
      console.log('ok')
      this.setState({companyName:event.target.value})
    }
    render()
    {
       return ( <>




        <div>
       
          <IsLogged isLoggedIn={this.state.isLoggedIn} />
          {
            (this.state.isLoggedIn)?(<h1>Logout</h1>):(<h1>login</h1>)
          }
          <HeaderMenu/>
          <h1>oks ya man</h1>Ok
          <input type="text" id="companyName" onChange={this.changeText.bind(this)} />
          <h2>You entered {this.state.companyName}</h2>
          <ul>{listItems}</ul>
          <ul>{numberListp}</ul>
          </div>
          
          </>)
    }
}
class HeaderMenu extends Component
{
  render()
  {
    return (
      <div>Main Menu ff</div>
    )
  }
}
export default About 