import {useState, useContext, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import {Form, Formik} from 'formik';
import CampaignJourneyStep1Schema from './schema/campaignJourneyStep1Schema';
import Elements from './elements/elements';
import {getAge, ReplaceTagStringWithElement} from '../helpers/functions';
import {AuthContext} from '../context/AuthContext'
import { UserContext as language } from '../context/languageContext';
import config from './config';
import '../assets/css/forms.css';
import { gtag, install } from 'ga-gtag';
import ReactPixel from 'react-facebook-pixel';
import googleConfig from '../Components/config/google'
import facebookConfig from '../Components/config/facebook';
import { NavLink } from 'react-router-dom';
import Timer from './elements/timer';
import CustomInput from './elements/customInput';
import LoadingButton from './elements/loadingButton';
import CodeSchema from './schema/codeSchemaCampaign';
import MultiboxesInput from './elements/multiboxesInput';
import SmileyIcon from '../assets/images/smiley.svg';
import Logo from '../assets/images/logo.svg';



const CampaignJourneyStep3 = (props) => {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(0);   
    let [response, setResponse] = useState(null);
    const Auth = useContext(AuthContext);
    const Lang = useContext(language);
    let checkEmailMsg = Lang.checkEmail;

    const searchParams = new URLSearchParams(document.location.search);
    let emailFromUrl = searchParams.get('email');
    let surveyToken = searchParams.get('token') || '';    
    
    if(props?.values?.values?.payment_status == 0 ){ //insufficient
      checkEmailMsg = Lang.checkEmail2
      props.values.values.message = (
        <>
        <div class="_1-sentence">{Lang.insufficient_credit2_1}</div><br></br>
        <div class="_2-sentence">{Lang.insufficient_credit2_2}</div>
        <div class="_3-sentence">{Lang.insufficient_credit2_3}</div><br></br>
        {(!emailFromUrl || !surveyToken) && <><div class="_4-sentence">{Lang.insufficient_credit2_4}</div><br></br></>}
        </>
        )
    }      
    else{ // success response
      checkEmailMsg = Lang.checkEmail2
      props.values.values.message = (
        <>
        <div class="_1-sentence">{Lang.insufficient_credit2_1}</div><br></br>
        {(!emailFromUrl || !surveyToken) && <><div class="_2-sentence">{Lang.insufficient_credit2_4}</div></>}
        </>
        )      
    } 
            
    
    const dataFromPrevStep = props?.values?.values || [];
    const [waitingToResend, setWaitingToResend] = useState(0)
    const componentProps = props;


    useEffect(()=>{
        if(Auth.isLogged){
            navigate('/');
        } 


        if(emailFromUrl && surveyToken && dataFromPrevStep.redirectToLogin && !dataFromPrevStep?.autologin){
          setTimeout(() => {
            window.$('#login').modal('show');
          }, 3000);          
                    
        }        
        else if(emailFromUrl && surveyToken && dataFromPrevStep.completionLink && !dataFromPrevStep?.autologin){
          //console.log(dataFromPrevStep.completionLink)
          setTimeout(() => {
            window.location.href = dataFromPrevStep.completionLink;
          }, 2000);
        }
        const advancedMatching = {};    
        const options = {
            autoConfig: true, 
            debug: false, 
          };

        const payment_status = dataFromPrevStep.payment_status;        
        if(payment_status == 0){// failed billing
            gtag('event', googleConfig.GOODLE_TAG_SUBSCRIBE_EVENT_FAILED, { 
                'send_to':googleConfig.GOOGLE_TAG_AD_WORD+'/'+googleConfig.GOOGLE_TAG_CONVERSION_ID
            });
            ReactPixel.init(facebookConfig.FACEBOOK_PIXEL_ID, advancedMatching, options);
            ReactPixel.track(facebookConfig.FACEBOOK_PIXEL_SUBSCRIBE_EVENT_FAILED);
        }
        else if(payment_status == 1){//success billing
            gtag('event', googleConfig.GOODLE_TAG_SUBSCRIBE_EVENT_SUCCESS, { 
                'send_to':googleConfig.GOOGLE_TAG_AD_WORD+'/'+googleConfig.GOOGLE_TAG_CONVERSION_ID
            });
            ReactPixel.init(facebookConfig.FACEBOOK_PIXEL_ID, advancedMatching, options);
            ReactPixel.track(facebookConfig.FACEBOOK_PIXEL_SUBSCRIBE_EVENT_SUCCESS);
        }
        else if(payment_status == 2){//billing under processing
            gtag('event', googleConfig.GOODLE_TAG_SUBSCRIBE_EVENT_UNDER_PROCESSING, { 
                'send_to':googleConfig.GOOGLE_TAG_AD_WORD+'/'+googleConfig.GOOGLE_TAG_CONVERSION_SigupID
            });
            ReactPixel.init(facebookConfig.FACEBOOK_PIXEL_ID, advancedMatching, options);
            ReactPixel.track(facebookConfig.FACEBOOK_PIXEL_SUBSCRIBE_EVENT_UNDER_PROCESSING);
        }    
                               
    },[])
   
    
    //console.log(props);
    //console.log(dataFromPrevStep);

    const showGewneralMessage = (title='no_title',message='') => {
        title = title || 'no_title';
        props.setMessage({title:title, message:message});
        window.$('#otp-message').modal('show');   
    
      }

      const initValues = {
        code:"",
    };
 

    const onSubmit = (values) => {
          const code = ''+values.code;
          if(code.length <6){
            return;
          }
          setLoading(1);
          setResponse(null);
          Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded',}).post('verifyEmailOtp',{...values,token:dataFromPrevStep?.token}).then(res => {
              //console.log(res);
              if(res?.data?.status ===1){ //success
                  if(res?.data?.payload?.url){
                    navigate(res.data.payload.url);
                    setResponse(1);
                  }
              }
              else if(res?.data?.status ===0){
                setResponse(0);
              }
              setLoading(2);
          }).catch(err => {
              //console.log(err);
              setResponse(-1);
              setLoading(2);
          })
    }
    return(
    <>
      <div className="section-container" style={{"padding": "0"}}> 
        <div className='content-container'>
        <div className="otp-form">
        {/* <h2 className="reg-title" style={{'textAlign':'center', 'paddigBottom': '3%','fontSize':'4rem','marginBottom':'50px'}}>{Lang.RegisterNewAccount}</h2> */}
         <img src={SmileyIcon} className='smiley-icon' alt="" /> 
         <div className='middle-text'>
        {dataFromPrevStep?.message && <center>{dataFromPrevStep.message}</center>}

        { (!emailFromUrl || !surveyToken) &&
        <center class="otp-text" style={{direction: Lang.getSelectedLanguage() =='ar' ? 'rtl' :'ltr'}}>
        {          
            ReplaceTagStringWithElement(checkEmailMsg, 'nav', 
              <a className={waitingToResend==1?'disabled-text':''} onClick={
                ()=>{
                  setWaitingToResend(1);
                  showGewneralMessage('',Lang.processingDots);
                    Auth.sendCompleteRegistrationEmail(dataFromPrevStep?.token).then(res =>  
                    res==true ? 
                    showGewneralMessage('',Lang.success_send_email) :
                    showGewneralMessage('',Lang.failed_send_email))
                }
              }/>, waitingToResend==1 ? <Timer onTimeEnd={()=>setWaitingToResend(0)} showHours={false} minutes={1} seconds={0}/> :''
          )
        }

              <Formik initialValues={initValues} onSubmit={onSubmit} validationSchema={CodeSchema}>
              {(props) => {
                  let {values, errors, touched, handleChange, setFieldValue, setValues} = props;
                  if(response == 0){
                    errors.code = 'invalid_code';
                  }
                  else if(response == -1){
                    errors.code = 'data_saved_faild';
                  }
                  else if(response == -2){
                    errors.code = 'code_length_error';
                  }
                  else if(response ==1){
                    errors.code = '';
                  }
                  response = null

                  return(
                    <Form>
                      <MultiboxesInput id="code" name="code"/>
                          <div className="form-group hide" style={{"margin": "0", position: 'relative'}}>
                              <div className="reg-field">
                                  <label htmlFor="mobile" className="placeholder-mob">{Lang.pin_code}</label>
                                  {/* <CustomInput id="code" name="code" type="text" placeholder=" " className="reg-input" /> */}
                              </div>
                          </div>

                        <div className='wizard-btns-container otp-btns'>
                          <LoadingButton loading={isLoading==1 ?`true`:`false`} type="submit" className="submit-button-auto reg-btn wizard-btn" loading-text="<i class='fa fa-circle-o-notch fa-spin'></i> Processing" >{Lang.verify}</LoadingButton>
                          <button type='button' className="submit-button-auto reg-btn wizard-btn secondary-btn" disabled={isLoading==1 ?true:false} onClick={()=>{ componentProps.dispatchWizard({type:'PREV',data:{values:{...componentProps.dataFromPrevStep}}}) }}>
                            {Lang.back}
                          </button> 

                        </div>
                    </Form>
                  )
              }}
              </Formik>

        </center>
        }

        </div>
        <img src={Logo} alt="" style={{width: '130px'}}/>

        </div>
        </div>
        
        </div>        
    </>
    );
}


export default CampaignJourneyStep3;