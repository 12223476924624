import React, { Component } from 'react';
import '../index.css';
import {UserContext} from '../context/languageContext'
import {useState} from 'react';
import {useEffect} from 'react';
import Config from './config';
import ApiHelper from "../helpers/ApiHelper";
import '../assets/css/terms-policy.css'

const TermsConditions=()=>{

  
  const value = React.useContext(UserContext);  
  //console.log(user)
  let [introLp, setintroLp] = useState([]); 
  let introApi=Config.getGeneralData;
  useEffect(()=>{
    ApiHelper.excuteApi(introApi+'terms_condition&lang='+value.getSelectedLanguage(),'get','{}',setintroLp);
    },[]        
  )  
  return(

    <>
    <div className="section-container" dangerouslySetInnerHTML={ {__html: introLp.terms_condition} }></div>
    </>
  )
}

export default TermsConditions 