import { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import '../assets/css/modules_menu.css'
import '../assets/js/modules_menu.js'
import CourseDownloadBtn from './sections/CourseDownloadBtn';

const CourseModulesMenu = (props) => {
    let {courseId} = useParams([]);

    const Auth = useContext(AuthContext);
    const data = props.data;
    const selected = props.selected || null;
    const isCompleted = props.isCompleted || null;

    const[opened, setOpen] = useState(true);    
    const navigate = useNavigate();
    //console.log(data?.items);
    //console.log(isCompleted)
    // if(!data?.items){
    //     return '';
    // }


    return (
        data?.items &&
        <>
            {/* <div className={`course-modules-menu ${opened ? '':'opened'}`}> */}
            <div className='course-modules-menu'>
                {/* <i onClick={()=>setOpen(!opened)} className='menu-handler fa fa-bars'></i>
                <div className={`menu-shader ${opened ? 'hide':''}`}></div> */}

                <div className="menu-groups">
                {
                    data.items.map( item => {
                        
                        let subIds = item.sub.map(a => a.id);
                        let collapse = false;                        
                        if(subIds.includes(parseInt(selected)) == true){
                            collapse = true;
                        }
                        if(item.sub.length==0)
                        {
                            return;
                        }
                        return(
                            
                        <div className="panel-group" key={item.id}>
                            {/* <div className="panel panel-default"> */}
                                <div className="level-accordion">
                                    {/* <h4 className="panel-title"> */}
                                        <input defaultChecked={1} type="checkbox" id={item.id} className="episode-input-accordion" />
                                        <label for={item.id} href={`#collapse${item.id}`}  className="level-acc-label">{props.lang=='ar'&&item.name_ar? item.name_ar : item.name}</label>
                                    {/* </h4> */}
                                    <div className='acc-level-content'>
                                    <div id={`collapse${item.id}`} className="panel-collapse collapse in" aria-expanded="true">
                                        <ul className="list-group">

                            
                                        {
                                            item.sub.map(subItem => {
                                                return(

                                                        <li id={subItem.id} key={subItem.id} className={`list-group-item ${selected==subItem.id ? 'selected':''}`}>
                                                            <span className='episode-container'>         
                                                                <input checked={subItem.isCompleted==1 || (subItem.id==selected && isCompleted==true) ?true:false} disabled className='episode-input-accordion ckbox ckb2 ckb-dark' id={'chk'+subItem.id} type="checkbox" />
                                                                <label htmlFor={'chk'+subItem.id}>
                                                                </label>
                                                                <p onClick={()=> navigate('/courseModules/'+subItem.id+'/'+courseId+'/'+subItem.name)} className='episode-name'>{props.lang=='ar'&&subItem.name_ar? subItem.name_ar : subItem.name}</p>
                                                            </span> 

                                                            {   subItem.downloadable ==1 && subItem.hasFiles ==1 && <CourseDownloadBtn Auth={Auth} courseId={courseId} itemId={item.id} subItemId={subItem.id} subItemName={subItem.name}/> }

                                                        </li>
                                                   
                                                )                    
                                            })

                                        }
                                        </ul>
                                    </div>
                                    </div>
                               </div>
                        </div>
                        )        
                        
                    })  
                    }
                </div>
            </div>
        </>
    )
}


export default CourseModulesMenu;