
import {initializeApp} from 'firebase/app'
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyCai5_ECJ_d6lyuWJejK1SoZZAs1KSYDgU",
    authDomain: "masterhobbies-952df.firebaseapp.com",
    projectId: "masterhobbies-952df",
    storageBucket: "masterhobbies-952df.appspot.com",
    messagingSenderId: "887017407630",
    appId: "1:887017407630:web:15323ed468d6b5cacaf5df",
    measurementId: "G-3H33C4J2XL"
};




const app = initializeApp(firebaseConfig);
const firebaseAuth = getAuth(app);
export {firebaseAuth,  RecaptchaVerifier,signInWithPhoneNumber};