import elements from './elements/elements';
import {Form, Formik} from 'formik';
import LoadingButton from './elements/loadingButton';
import { UserContext } from '../context/languageContext';
import { useContext } from 'react';

const PricesStep1 = (props) => {

    const Lang = useContext(UserContext);
    const {RadioInput} = elements;

    const initValues = {
        accType:"",
    };

    const onSubmitStep = async (values, actions) => {

       //console.log('submitted');
       if(values.accType==1){
        props.dispatchWizard({type:'GOTO',data:{step:3,values}})
       }
       else if(values.accType==2){
        props.dispatchWizard({type:'NEXT',data:{values}})
       }

      };     

return (
    <div className="contact-us-lower-container" style={{padding:'0'}}>
        <div className="white-area price-step" style={{marginBottom:"0"}}>
            <h1 className='price-step-title'><span style={{color: '#d9d9d9'}}>{props.step}/{props.values.total_steps}</span></h1>  
            <Formik
      initialValues={initValues}
     // validationSchema={RegisterSchema}
      onSubmit={onSubmitStep}  
    >

{(props) => {
    //console.log(props.values.dob_year);
    let {values, errors} = props;

    return(
        <Form>
            <p className="prices-text">{Lang.pre_price_q2}</p>

            <div className='answers flex-center'>
                <RadioInput labels={[Lang.pre_price_a11, Lang.pre_price_a12]} ids={['ckb4','ckb1']} values={[1,2]} name="accType" class="ckbox-prices"/>
            </div>

            <LoadingButton loading={`false`} type="submit" className="submit-button-auto prices reg-btn" loading-text="<i class='fa fa-circle-o-notch fa-spin'></i> Processing" >{Lang.continue}</LoadingButton>
        </Form>    
    )}}
    </Formik>                

        </div>
    </div>
)
}

export default PricesStep1