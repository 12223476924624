import {useState,useContext, useReducer, useEffect} from 'react';
import Step1 from './pricesStep1'
import Step2 from './pricesStep2'
import Step3 from './pricesStep3'
//import Step3New from './pricesStep3New'
import Step3New from './pricesStep3UpgradeDowngrade'
import {UserContext} from '../context/languageContext'
import { AuthContext } from '../context/AuthContext';
import {Wizard, WizardStep} from './elements/wizard/wizard';
import uuid from 'react-uuid';
import Cookies from 'universal-cookie';
import PageNotFound from './pageNotFound';
import config from './config';

const PricesWizard = () => {

    const searchParams = new URLSearchParams(document.location.search);
    const keywords = useContext(UserContext); 
    const Auth = useContext(AuthContext);
    let accType = searchParams.get('accType');
    let step3Direct = accType ? true : false;
    if(!step3Direct){
        accType = Auth.getAccountType();
    }

    const cookies= new Cookies();
    const isMena = cookies.get('isMena');
    const isVas = cookies.get('isVas');
    const showNewDesign = config?.applyNewPriceCardFeatures || false;

    const showLoginModal = () => {
        window.$('.modal').modal('hide'); 
        window.$('#login').modal('show');    
      }    

    return (
        <>
        { (isMena !=1 && isVas !=1) &&
            <div className="prices-section" style={{'backgroundColor': 'rgb(233, 233, 233)'}}>
                {
                    (Auth.isLogged || step3Direct || showNewDesign) ? <Wizard><WizardStep step={1} >{showNewDesign ? <Step3New values={{values:{accType:accType, numChildren:1}}}/> : <Step3 values={{values:{accType:accType, numChildren:1}}}/>}</WizardStep></Wizard>  :
                    ( !showNewDesign &&
                    <Wizard>
                        <WizardStep step={1} > <Step1/> </WizardStep>
                        <WizardStep step={2} > <Step2/> </WizardStep>
                        <WizardStep step={3} > <Step3/> </WizardStep>              
                    </Wizard>
                    )
                }     
            </div>
        }
        {
            (isMena ==1 || isVas ==1) && <PageNotFound/>
        }
        </>
    )
}

export default PricesWizard