import { NavLink } from 'react-router-dom'
import Config from '../../Components/config';
import '../../assets/css/dashboard.css'
import React,{useContext ,useEffect, useState } from "react" ;
import DoughnutChart from '../elements/charts/doughnutChart'
import Langauge, {UserContext} from '../../context/languageContext'
import UploadProject from '../modals/uploadProject';
import iconCourse from '../../assets/images/parts_Drawing.svg'
import { DownloadAuthResponse, ReplaceTagStringWithElement } from '../../helpers/functions';
import { AuthContext } from '../../context/AuthContext';
import ApiHelper from '../../helpers/ApiHelper';
import MessageModal from '../modals/messageModal';
import { useNavigate } from 'react-router-dom';

const CoursesSummary = (props) => {
    const navigate = useNavigate();
    const Auth = useContext(AuthContext);
    const lagKeywords = React.useContext(UserContext);
    const [currentProjectItem,setCurrentProjectItem]=useState('');
    let [coursesList, setCourses] = useState([]); 
    const [message, setMessage] = useState('')
    const [title, setTitle] = useState('')

    useEffect(()=>{
        ApiHelper.excuteApi(Config.coursesApi,'get','{}',setCourses);
    },[])   
    let isLoading = props.isLoading;
    //if(props.data.length > 0){
        //console.log(props.data)


        const navigatePlans = () => {        
            window.$('.modal').modal('hide');
            // redirect to plans page
            navigate('/prices');
        }        
        const showMessage = (isActive) =>{
            if(isActive == -2){
                setMessage( () =>(<>{lagKeywords.your_sub_expired}</>));           
            }
            else{
                setMessage( () =>(<>{ReplaceTagStringWithElement(lagKeywords.go_to_pans, 'link',<a onClick={navigatePlans}/>)}</>));           
            }
            window.$('#enroll-message').modal('show');             
        }

        const getCourseName = (data) => {
            let selectedLang = lagKeywords.getSelectedLanguage();
            if(selectedLang == 'ar'){
                return data.namear || data.name;
            }
            return data.name;
        } 
            return (
                <>
                {
                    isLoading !=0 &&
                    <table className={`summary-table`}>
                        <thead>
                            <tr>
                                <th>{lagKeywords.course_name}</th>
                                <th><center>{lagKeywords.progress}</center></th>
                                <th>{lagKeywords.submitted_projects}</th>
                                <th>{lagKeywords.certificates}</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            props.data && isLoading==2 && props.data.map( (item) => {
                                //item.isActiveSubscriber =-2;
                                return (
                                    <tr key={item.id}>
                                        <td className='table-d-cell'>
                                        <NavLink to={'/course/'+item.id+'/'+getCourseName(item).split(' ').join('-')} className='course-dashboard'>
                                            <img className='course-img-dashboard' src={Config.imgDns+"icon/course_"+item.id+".svg"} />
                                            <p className='course-name-dashboard'>{getCourseName(item)}</p>
                                        </NavLink>
                                        { item.is_logged_user_enrolled == 1 &&
                                        <>
                                        {
                                            item.isActiveSubscriber == 1 ?
                                            <>
                                            <NavLink to={'/courseModules/0/'+item.id+'/'+getCourseName(item)}>
                                                <p className='table-btn'>{lagKeywords.watch_from_beginning}</p>
                                            </NavLink>
                                            {item.progress > 0 && 
                                            <NavLink to={'/courseModules/'+item.current_module+'/'+item.id+'/'+getCourseName(item)}>
                                                <p className='table-btn'>{lagKeywords.continue}</p>
                                            </NavLink>
                                            }
                                            </>
                                            :
                                            <>
                                            <NavLink onClick={()=>showMessage(item.isActiveSubscriber)}><p className='table-btn'>{lagKeywords.watch_from_beginning}</p></NavLink>
                                            {item.progress > 0 && <NavLink onClick={()=>showMessage(item.isActiveSubscriber)} ><p className='table-btn'>{lagKeywords.continue}</p></NavLink>}
                                            </>                                                                                        
                                        }

                                        </>
                                        }
                                        </td>
                                        <td className='progress-chart'><div className='doughnutchart'><DoughnutChart 
                                         
                                        centerText={Math.round(item.progress,2)+'%'} 
                                              data={[item.progress, (100-item.progress)]} 
                                           options={{cutout: 28, plugins:{legend:{display:false}, 
                                           tooltip:{enabled:false}}}} 
                                             width={80} 
                                            height={80} 
                                   backgroundColor={["#ffa034","#f6f6f6"]}/></div></td>

                                   
                                        <td className='table-d-cell'>
                                            {
                                                item.projects && item.projects.length>0 ? (
                                                    <>
                                                    <ul className='uploaded-area'>
                                                        {
                                                            item.projects.map( (project) => {
                                                                return(
                                                                    <li key={project.id}  className="table-btn table-anchor"><a href={`javscript:`} title={project.original_file_name+'.'+project.extension} onClick={ (e)=> { DownloadAuthResponse(Auth,'download_course_project/'+project.id, project.original_file_name+'.'+project.extension);}}>{project.project_name || project.original_file_name+'.'+project.extension }</a></li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                    { props.accType !=2 && <button className="table-btn" data-target="#UploadProject" data-toggle="modal" onClick={()=>{setCurrentProjectItem(item)}}>{lagKeywords.upload_project}</button> }
                                                    </>
                                                ) : (item.progress>0 && props.accType !=2 ?<button className="table-btn"  data-target="#UploadProject" data-toggle="modal" onClick={()=>{setCurrentProjectItem(item)}}>{lagKeywords.upload_project}</button>:'-')
                                            }
                                        </td>
                                        <td className='table-d-cell'>
                                            {
                                                (item.progress > (1/item.total_modules)) ? <button className="table-btn"><NavLink to={'/courseCertificates/'+item.id+'/'+getCourseName(item)+'/'+props.userIdSelected}>{lagKeywords.view_certificate}</NavLink></button> : lagKeywords.no_completed_modules
                                            }
                                         </td>
                                    </tr>
                                )
                            })
                        }

                        {                       
                            isLoading == 1 && <tr><td colSpan={4}>{lagKeywords.loading}</td></tr>
                        }              
                        {                       
                            isLoading == 2 && props.data.length ==0 && <tr><td colSpan={4}>{
                                
                                ReplaceTagStringWithElement(lagKeywords.no_courses_started_yet, 'navlink',<NavLink style={{color:'orange'}} to={'/courses'}/>)
                                                
                                }</td></tr>
                        }                                   
                        </tbody>
                    </table>
                }
                    <UploadProject item={currentProjectItem} userIdSelected={props.userIdSelected} />

                    <MessageModal title={title} id="enroll-message" okLabel="Close">
                        {message}
                    </MessageModal>                     
                </>
            )
    //}
    //else{
    //    return '';
   // }
}

export default CoursesSummary;