import * as Yup from 'yup';
import {getAge} from '../../helpers/functions';

//const professions = ['Doctor', 'Engineer', 'Programmer'];


const ChildProfileSchema = Yup.object().shape({


    first_name: Yup.string()
            .label('Fisrt Name')
            .required('fname_required_err')
            .matches(/^[aA-zZ\s\u0600-\u06FF]+$/, "fname_pattern_err"),
    last_name: Yup.string()
            .label('Last Name')
            .required('lname_required_err')
            .matches(/^[aA-zZ\s\u0600-\u06FF]+$/, "lname_pattern_err"),
    /*        
    email: Yup.string()
            .required('email_required_err')
            //.email('email_format_err'),  
            .test('validator-username-email', (value, { createError,resolve }) =>{
                const emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
                const nameRegex = /^[a-zA-Z]+$/;
                if(!emailRegex.test(value)){
                        if(!nameRegex.test(value)){
                                return createError({
                                        message: `email_format_or_username`,
                                        path: 'email', // Fieldname
                                      })
                        }                      
                }
                return true;
            }),
*/
    //mobile: Yup.string().matches(/^\d+$/, 'mobile_err'),
    parent_email: Yup.string()
    .required('parent_email_required_err')
    .email('parent_email_format_err')


      .test('validator-parentemail-email', (value, { createError,resolve }) =>{
        const email = resolve(Yup.ref('email'));
        const same = email == value ;
        if(same && (email!='' && email !=undefined)){


                return createError({
                        message: `parent_same_email`,
                        path: 'parent_email', // Fieldname
                })
                        
        }
        return true;
    }),

    password: Yup.string()
                .when('id', (val, schema) => {
                        if(val == 0){
                                return schema.required('password_required_err'); 
                        }
                                return schema;
                })
            .min(8, 'password_min_err')
            .matches(/^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+$/,"password_english_err"),
            //.matches(/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!-#_+])[a-zA-Z0-9!-#_+]+$/,"password_pattern_err"),
    //gender: Yup.number().required().moreThan(0,'required_field'),
    allow_sharing_projects: Yup.number().required(),

  })

  export default ChildProfileSchema;