const config={
APPID:'1287917615421270',
FACEBOOK_PIXEL_ID : '628588785983328',//1540982699728838
FACEBOOK_PIXEL_SUBSCRIBE_EVENT_SUCCESS : 'Subscribe New',
FACEBOOK_PIXEL_SUBSCRIBE_EVENT_FAILED : 'SignUp',
FACEBOOK_PIXEL_SUBSCRIBE_EVENT_UNDER_PROCESSING : 'SignUp',
FACEBOOK_PIXEL_LEAD_EVENT:'Lead'

}


export default config