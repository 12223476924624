import {MediaPlayer, PlayerEventType} from 'amazon-ivs-player';

import QualityChangeService from '../../../../core/QualityChangeService';
import {EventCallbackDispatcher} from '../../EventCallbackDispatcher';

import {AmazonIVSPlayerContext} from './AmazonIVSPlayerContext';
import {AmazonIVSPlayerEventHandler} from './AmazonIVSPlayerEventHandler';

/**
 * Map of IVS Player events that we listen to, each should be type of {@link PlayerEventType}.
 *
 * Why: This way, we don't need to import string enum {@link PlayerEventType} from 'amazon-ivs-player' directly,
 * aka. compile/include it (as value) in our distribution code
 */
export const IVS_PLAYER_EVENT = {
  DURATION_CHANGED: 'PlayerDurationChanged' as PlayerEventType.DURATION_CHANGED,
  ERROR: 'PlayerError' as PlayerEventType.ERROR,
  INITIALIZED: 'PlayerInitialized' as PlayerEventType.INITIALIZED,
  QUALITY_CHANGED: 'PlayerQualityChanged' as PlayerEventType.QUALITY_CHANGED,
  REBUFFERING: 'PlayerRebuffering' as PlayerEventType.REBUFFERING,
  SEEK_COMPLETED: 'PlayerSeekCompleted' as PlayerEventType.SEEK_COMPLETED,
  TIME_UPDATE: 'PlayerTimeUpdate' as PlayerEventType.TIME_UPDATE,
  PLAYBACK_RATE_CHANGED: 'PlayerPlaybackRateChanged' as PlayerEventType.PLAYBACK_RATE_CHANGED,
  PLAYBACK_BLOCKED: 'PlayerPlaybackBlocked' as PlayerEventType.PLAYBACK_BLOCKED,
} as const;

export class AmazonIVSPlayerEventListener {
  constructor(private eventHandler: AmazonIVSPlayerEventHandler) {}

  startMonitoring(player: MediaPlayer) {
    this.startMonitoringIVSPlayer(player);
    this.startMonitoringHTMLVideoElement(player.getHTMLVideoElement());
  }

  private startMonitoringHTMLVideoElement(video: HTMLVideoElement) {
    video.addEventListener('play', this.eventHandler.onPlay.bind(this.eventHandler));
    video.addEventListener('playing', this.eventHandler.onPlaying.bind(this.eventHandler));
    video.addEventListener('pause', this.eventHandler.onPause.bind(this.eventHandler));
    video.addEventListener('seeking', this.eventHandler.onSeek.bind(this.eventHandler));
    video.addEventListener('seeked', this.eventHandler.onSeeked.bind(this.eventHandler));
  }

  //https://aws.github.io/amazon-ivs-player-docs/1.18.0/web/interfaces/playereventtypemap.html
  private startMonitoringIVSPlayer(player: MediaPlayer) {
    player.addEventListener(IVS_PLAYER_EVENT.REBUFFERING, this.eventHandler.onRebuffering.bind(this.eventHandler));
    player.addEventListener(
      IVS_PLAYER_EVENT.DURATION_CHANGED,
      this.eventHandler.onDurationChanged.bind(this.eventHandler),
    );
    player.addEventListener(IVS_PLAYER_EVENT.ERROR, this.eventHandler.onError.bind(this.eventHandler));
    player.addEventListener(IVS_PLAYER_EVENT.INITIALIZED, this.eventHandler.onInitialized.bind(this.eventHandler));
    player.addEventListener(
      IVS_PLAYER_EVENT.QUALITY_CHANGED,
      this.eventHandler.onQualityChanged.bind(this.eventHandler),
    );
    player.addEventListener(IVS_PLAYER_EVENT.TIME_UPDATE, this.eventHandler.onTimeUpdate.bind(this.eventHandler));

    // player.addEventListener(PlayerEventType.AUDIO_BLOCKED, this.onAudioBlocked);
    // player.addEventListener(PlayerEventType.BUFFER_UPDATE, this.onBufferUpdate);
    // player.addEventListener(PlayerEventType.MUTED_CHANGED, this.onMutedChanged);
    // player.addEventListener(PlayerEventType.NETWORK_UNAVAILABLE, this.onNetworkUnavailable);
    // player.addEventListener(PlayerEventType.PLAYBACK_BLOCKED, this.onPlaybackBlocked);
    // player.addEventListener(PlayerEventType.PLAYBACK_RATE_CHANGED, this.onPlaybackRateChanged);
    // player.addEventListener(PlayerEventType.TEXT_CUE, this.onTextCue);
    // player.addEventListener(PlayerEventType.TEXT_METADATA_CUE, this.onTextMetadataCue);
    // player.addEventListener(PlayerEventType.VOLUME_CHANGED, this.onVolumeChanged);
  }

  static create(
    dispatcher: EventCallbackDispatcher,
    playerContext: AmazonIVSPlayerContext,
    qualityChangeService: QualityChangeService,
  ): AmazonIVSPlayerEventListener {
    const handler = AmazonIVSPlayerEventHandler.create(dispatcher, playerContext, qualityChangeService);
    return new AmazonIVSPlayerEventListener(handler);
  }
}
