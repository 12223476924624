import Config from '../../Components/config';
import { useContext, useEffect, useState } from "react";
import { UserContext } from '../../context/languageContext';
import ApiHelper from '../../helpers/ApiHelper';
import {NavLink} from 'react-router-dom';
import '../../assets/css/courses.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import { getCourseNameBasedOnLanguage } from '../../helpers/functions';

const LatestCourses = () => {

    const options = {
        margin: 5,
        responsiveClass: true,
        nav: true,
        autoplay: false,
        loop:false,
        autoplaySpeed:1500,
        smartSpeed: 1000,
        navText: ['', ''],
        responsive: {
            0: {
                items: 3,
            },
            450: {
                items: 3,
            },
            600: {
                items: 3,
            },
            700: {
                items: 3,
            },
            1000: {
                items: 5,
            },
        },
      };  

      const breakpoints={
        0: {
            slidesPerView: 1,
        },
        450: {
            slidesPerView: 2,
        },
        600: {
            slidesPerView: 2,
        },
        700: {
            slidesPerView: 3,
        },
        1000: {
            slidesPerView: 5,
        },
        1500:{
            slidesPerView: 6,
        },
        2000:{
            slidesPerView: 6,
        }
    };

    let [coursesList, setCourses] = useState([]); 
    if(typeof(coursesList) == 'string'){
        coursesList = JSON.parse(coursesList);
       
    }    
    const lagKeywords = useContext(UserContext);
    useEffect(()=>{
        ApiHelper.excuteApi(Config.coursesApi,'get','{}').then(res => {
            let courseList = res;
            courseList.sort((a, b) => {
                return b.id - a.id;
            });
            setCourses(courseList)
        });
    },[])    
    return(
        <>            
        <section className="all-courses-container">
        {/* <OwlCarousel  className="slider-items owl-carousel" {...options}> */}
        <div class="swiper-button-prev previous-swipe"></div>
        <div class="swiper-button-next next-swipe"></div>
        <Swiper
            breakpoints={breakpoints}
            modules={[Navigation]}
            spaceBetween={17}
            navigation={{
                nextEl: '.next-swipe',
                prevEl: '.previous-swipe',
              }} 
            slidesPerView={6}
            slidesPerGroup={1}
            
            //onSwiper={(swiper) => console.log(swiper)}
        >
            {
            coursesList && coursesList.length > 0 && coursesList.map((coursesListObj, index) => {
            // console.log(introCoursesObj);
                if(coursesListObj.categoryid>0)
                {
                    let courseName = getCourseNameBasedOnLanguage(coursesListObj,lagKeywords.getSelectedLanguage());
                    return (     
                        <SwiperSlide key={index}>
                        <div key={coursesListObj.id}>   
                        <img className='course-icon' src={Config.imgDns+"icon/course_"+coursesListObj.id+".svg"} alt={courseName} />
                            <NavLink className="menu-link" to={'/course/'+coursesListObj.id+'/'+courseName?.split(' ').join('-')}>{courseName}</NavLink>
                            <NavLink className="course-btn" to={'/course/'+coursesListObj.id+'/'+courseName?.split(' ').join('-')}>{lagKeywords.watch_now}</NavLink>                                                             
                        </div>     
                        </SwiperSlide>   
                                   

                    )
                   
                }
                  
                })
            }    
            </Swiper>
             {/* </OwlCarousel> */}
        </section>

        </>        
    )
}

export default LatestCourses;