import {Wizard, WizardStep} from './elements/wizard/wizard';
import { useState } from 'react';
import MessageModal from './modals/messageModal';
import Step1 from './userRegistrationStep1'
import Step2 from './userRegistrationStep2'
import Step3 from './userRegistrationStep3'

const UserRegistration = () => {

    const [messageDetails,setMessageDetails] = useState({title:'',message:''});
    const showMessage = (title='', message='') => {
        setMessageDetails({title,message});
        window.$('#otp-message').modal('show');
    }    

    return (
        <>
            <div className="wizard-section" >
                {
                    <Wizard>
                        <WizardStep showMessage={showMessage} step={1} noAnimate={true}> <Step1/> </WizardStep>
                        <WizardStep showMessage={showMessage} step={2} noAnimate={true}> <Step2/> </WizardStep>
                        <WizardStep showMessage={showMessage} step={3} noAnimate={true}> <Step3/> </WizardStep>
                        {/* <WizardStep showMessage={showMessage} step={3} > <Step2/> </WizardStep> */}
                        {/* <WizardStep setMessage={setMessageDetails} showMessage={showMessage} step={4} > <Step3/> </WizardStep> */}
                    </Wizard>
                }     
                <div id='recaptcha-container'></div> 
            </div>
                
            <MessageModal title={messageDetails.title} id="otp-message" okLabel="Close">
            {messageDetails.message}
            </MessageModal>  
            {/* <MessageModal title={messageDetails.title} id="general-modal" okLabel="Close">{messageDetails.body}</MessageModal>                             */}
        </>
    )    

}

export default UserRegistration