import {useState, useContext, useEffect, forwardRef, useRef} from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {Form, Formik} from 'formik';
import RegisterChildSchema from '../../Components/schema/registerChildSchema';
import Elements from '../elements/elements';
import {errorFocus, getAge, subtractYears} from '../../helpers/functions';
import {AuthContext} from '../../context/AuthContext'
import { UserContext as language } from '../../context/languageContext';
import { ProfileContext } from '../../context/ProfileContext';
import config from '../config';
import uuid from 'react-uuid';

const {CustomInput, DateInput, SelectInput, RadioInput, LoadingButton} = Elements;
var responseStatus = null;
var response = null;
const ChildForm = (props) => {

    const formRef = useRef(null)
    const data = props.data
    const dispatchChildReucer = props.dispatch;
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const Auth = useContext(AuthContext);
    const Lang = useContext(language);
    const Profile = useContext(ProfileContext);
    const adultAge = 18;  
    let [selectedMonth, setSelectedMonth] = useState(0);
    let [selectedYear, setSelectedYear] = useState(0);    
    const [totalDaysInMonth, setTotalDaysInMonth] = useState(31);
    const dob_month = useRef(null);      


    const daysInMonthProccess = () => {
        let y =   selectedYear;//values.dob_year;
        let m =  selectedMonth;//values.dob_month;
        let d = 0;
        if(y && m){
            d = new Date(y, m, 0).getDate();
        }

        d = 31;
        //console.log(selectedYear+'->'+selectedMonth+'->'+d)
        setTotalDaysInMonth(d);
    }

    const removeChild = (id) =>{
        dispatchChildReucer({type:'SELECT_CHILD', data:props.data});
        dispatchChildReucer({type:'START_REMOVE_LOADING', data:id});
        Profile.removeChild(false, {id}).then((res)=>{
            dispatchChildReucer({type:'END_REMOVE_LOADING', data:true});
        }).catch((err)=>{
            dispatchChildReucer({type:'END_REMOVE_LOADING', data:false});
        })
    }    

    useEffect(()=>{
        formRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        selectedYear = data?.dob?.year;
        setSelectedYear(data?.dob?.year);
        selectedMonth = data?.dob?.month;
        setSelectedMonth(data?.dob?.month);
        daysInMonthProccess();
    },[data])
   

    const onSubmitForm = async (values, actions) => {
        setLoading(true);

        response = await Auth.registerChild(values)
        //console.log(values);
        //console.log(actions);
        //await new Promise((resolve) => setTimeout(resolve, 1000));
        setLoading(false);
        //console.log(response)
        responseStatus = response.status;
        if(response.status == 1){
            //actions.resetForm();            
            if(props.isNew){
                dispatchChildReucer({type:'ADD_CHILDREN', data:response.payload});
                data = response.payload
            }
            else{
                dispatchChildReucer({type:'UPDATE_CHILDREN', data:response.payload});
            }
            actions.setFieldValue('password', "", false);
            dispatchChildReucer({type:"SET_MESSAGE", data:{title:"", body:Lang.data_saved_success}})
            window.$('#general-message').modal('show');
            dispatchChildReucer({type:'CLOSE_FORM', data:[]});
            navigate('/added-children/'+uuid(),{ state: {rand:uuid()}}); 
            //dispatchChildReucer({type:'RESET_DATA',data:{}});   
            //console.log('here');
        }
        else if(response.status==3 || response.status==4 || response.status==5){}
        else{
            dispatchChildReucer({type:"SET_MESSAGE", data:{title:"", body:"Failed Data Save"}})
            window.$('#general-message').modal('show');
        }
        
      };   
      
      //console.log(data);
      const initValues = {
        id: props.userId,
        //dob: data?.dob || '' ,
        dob_day:data?.dob?.day || '',
        dob_month:data?.dob?.month || '',
        dob_year:data?.dob?.year || '',         
        first_name: data?.first_name || '', 
        last_name: data?.last_name || '', 
        email:  data?.email || '', 
        mobile: data?.mobile_number || '', 
        mobile_prefix: data?.mobile_prefix || '+962',
        password:'', 
        gender:data?.gender || 1,
        parent_email:props.parentEmail
    };

    const minDate = subtractYears(new Date(),18);
    return(
      <div className="section-container edit-child-form">
        <div className='content-container reg-container'>
        <div className="top-titles edit-form">
            <p className="edit-icon" style={{'justifyContent': 'flexEnd'}}><img className="fa-pencil" src={process.env.PUBLIC_URL + "/assets/images/editPen.svg"}/> {props.isNew ? Lang.new_child:Lang.edit}</p>
        </div>
        <div className="reg-form" ref={formRef} style={{"width":"90%"}}>
        <Formik
      enableReinitialize
      initialValues={initValues}
      validationSchema={RegisterChildSchema}
      onSubmit={onSubmitForm}  
    >

{({ values, errors, touched, setFieldValue, setErrors }) => {
    
    if(responseStatus == 3){
        //errors.email = 'Email Not Available!';
        setErrors({'email':'email_registered_before'});
    }    
    else if(responseStatus == 4){
        //errors.email = 'username_registered_before';
        setErrors({'email':'username_registered_before'});
    }    
    else if(responseStatus == 5){
        setErrors({'mobile':'mobile_registered_before'})
        //errors.mobile = 'mobile_registered_before';
        
    }      
    else{
        //touched.mobile = false;
        
    }    
    responseStatus= null;

    errorFocus(errors);

    return(
        <Form>
            <CustomInput name="id" id="id" type="hidden"/>
            {/* <div className="form-group" style={{"margin": "0"}}>
                
            <DateInput minDate ={minDate} name="dob" id="dob" type="text" placeholderText={Lang.date_of_birth+'*'} className="reg-input date-birth"/>
            </div> */}


            <input type='hidden' name="parent_email"/>


        <div className="form-group" style={{"margin": "0", "width":"100%"}}>
            <div className="col-md-3 date-label" style={{"width":"100%"}}>
                <label>{Lang.date_of_birth+'*'}</label>                
            </div>
            <div className='dob_options_container'>

            <div className="col-md-3" style={{paddingRight: '0', width: '30%'}}>
                <SelectInput onChange={(e) => {
                                setFieldValue("dob_year",e.target.value);
                                //values.dob_year=e.target.value;
                                setSelectedYear(e.target.value);
                                selectedYear = e.target.value;
                                daysInMonthProccess()
                }} name="dob_year" id="dob_year" className="reg-input select-date">
                    <option value={Lang.year} disabled={false}>{Lang.year}</option>
                    { (()=>{
                        let eleArr = [];
                        let currentYear = new Date().getFullYear();
                        for (var i=config.birth_year_from; i<=currentYear; i++){
                            eleArr.push(<option key={i} value={i}>{i}</option>);
                        }
                        return eleArr;
                    })()
                    }
                </SelectInput>     
            </div>            
            <div  ref={dob_month} className="col-md-3" style={{paddingRight: '0', width: '30%'}}>
                <SelectInput onChange={(e) => {
                                setFieldValue("dob_month",e.target.value);
                                //values.dob_month=e.target.value;
                                setSelectedMonth(e.target.value);
                                selectedMonth = e.target.value;
                                daysInMonthProccess()
                }}  name="dob_month" id="dob_month" className="reg-input select-date">
                    <option value={Lang.month} disabled={false}>{Lang.month}</option>
                    {
                        config.months.map( item => {
                            return (
                                <option key={item.id} value={item.id}>{Lang[item.name]}</option>
                            )
                        })
                    }                                       
                </SelectInput> 
            </div> 
            <div className="col-md-3" style={{paddingRight: '0', width: '30%'}}>
                <SelectInput name="dob_day" id="dob_day" className="select-date reg-input">  
                   <option value={Lang.day} disabled={false}>{Lang.day}</option>
                    {
                        ( ()=>{
                            let eleArr = [];
                            for(var j=1; j<=totalDaysInMonth ;j++){
                                eleArr.push(<option key={j} value={j}>{j}</option>);
                            }
                            return eleArr;
                        })()
                    }
                </SelectInput>                
            </div>             
        </div> 
        </div>
        <div className="form-group" style={{"margin": "0"}}>
                <div className="reg-field">
                    <label htmlFor="email">{Lang.EmailOremail}</label>
                    <CustomInput name="email" id="email" type="text" className="reg-input" placeholder=" "/>
                </div>
        </div>
        <div className="form-group inline-group" style={{"margin": "0"}}>
            <div className="col-md-6"  style={{"padding": "0","marginRight":"10px"}} >
                <div className="reg-field">
                    <label htmlFor="first_name">{Lang.first_name+'*'}</label>
                    <CustomInput name="first_name" id="first_name" type="text" className="reg-input" placeholder=" "/>                
                </div>
            </div>
            <div className="col-md-6"  style={{"padding": "0","marginLeft":"10px"}}>
                <div className="reg-field">
                    <label htmlFor="last_name">{Lang.last_name+'*'}</label>
                    <CustomInput name="last_name" id="last_name" type="text" className="reg-input" placeholder=" "/>
                </div>  
            </div>
        </div>                      
        <div className="form-group" style={{"margin": "0"}}>
            <SelectInput  className="form-select" id="mobile_prefix" name="mobile_prefix">
            {
                config.countries.map( item => {
                    return (
                        <option key={item.prefix.id} value={item.prefix.id}>{item.prefix.name}</option>
                    )
                })
            }  
            </SelectInput>    
            <div className="reg-field-tel child-mob">
            <label htmlFor="mobile">{Lang.mobile_number}</label>           
            <CustomInput id="mobile" name="mobile" type="tel" className="reg-input" placeholder=" " />
            </div>
        </div>
        <div className="form-group" style={{"margin": "0", "position":"relative"}}>
            <div className="reg-field">
                <label htmlFor="password">{Lang.password+'*'}</label>
                <CustomInput id="password" name="password" type="password" className="reg-input" placeholder=" " />
                <span toggle="#password" className="fa fa-fw fa-eye field-icon toggle-password"></span>
            </div>
        </div>
        <div className={`form-group flex-inner-group flex-group-start select-role`} style={{"margin": "0"}}>
            <p className='ckb-text'>{Lang.child_gender}</p>
            <div className='answers'>
            <SelectInput doNotShowError="1" name="gender" className="reg-input select-date" >
                        <option value={1} disabled={false}>{Lang.male}</option>
                        <option value={2} disabled={false}>{Lang.female}</option>
            </SelectInput>                


                {/* <RadioInput labels={[Lang.male, Lang.female]} ids={['ckb1','ckb2']} values={[1,2]} name="gender" className="ckb ckb-dark" doNotShowError="1" /> */}
            </div>   
            {touched.gender && errors.gender && <div className="error" style={{"width":"100%"}}>{Lang[errors.gender]}</div>}
        </div>   
        <div className="form-group remove-child" style={{"margin": "0"}}>
            {   !props.isNew &&
            <a onClick={()=>removeChild(initValues.id)} className="remove-child">{Lang.remove_child}</a>
            }
        </div>         
            <LoadingButton loading={isLoading.toString()} type="submit" className="submit-button-auto" loading-text={`<i class='fa fa-circle-o-notch fa-spin'></i> ${Lang.processing}`} >{props.isNew ? Lang.add :Lang.save}</LoadingButton>
        </Form>
          )}}
</Formik>

        </div>
        </div>
        </div>
    );
};


export default ChildForm;