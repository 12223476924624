import {Wizard, WizardStep} from './elements/wizard/wizard';
import { useEffect, useState } from 'react';
import MessageModal from './modals/messageModal';
import Step1 from './campaignJourneyMobileStep1'
import Step2 from './campaignJourneyMobileOtpStep2'
import Step3 from './campaignJourneyMobileStep2'
import Step4 from './campaignJourneyMobileOtpStep3'
import Step3Survey from './campaignJourneyMobileOtpStep3Survey'

import facebookConfig from './config/facebook';
import ReactPixel from 'react-facebook-pixel';

const CampaignJourney8 = () => {

    const [messageDetails,setMessageDetails] = useState({title:'',message:''});
    const showMessage = (title='no_title', message='') => {
        title = title || 'no_title';
        setMessageDetails({title,message});
        window.$('#campaign-message').modal('show');
    }    

    const searchParams = new URLSearchParams(document.location.search);
   
    let queryMobile = searchParams.get('mobile');
    let promocode = searchParams.get('promocode');

    const advancedMatching = {};    
    const options = {
        autoConfig: true, 
        debug: false, 
      };
       
    useEffect(()=>{
            ReactPixel.init(facebookConfig.FACEBOOK_PIXEL_ID, advancedMatching, options);
            ReactPixel.track(facebookConfig.FACEBOOK_PIXEL_LEAD_EVENT);
        },[]
        
        )   


    return (
        <>
        
            <div className="prices-section">
            { 
                queryMobile ? 

                    <Wizard>
                        <WizardStep showMessage={showMessage} step={1} > <Step1  noDOB={true}/> </WizardStep>
                        <WizardStep setMessage={setMessageDetails} showMessage={showMessage} step={2} > <Step2/> </WizardStep>
                        <WizardStep setMessage={setMessageDetails} showMessage={showMessage} step={3} > <Step3Survey/> </WizardStep>
                        <WizardStep setMessage={setMessageDetails} showMessage={showMessage} step={4} > <Step3 promocode={promocode}/> </WizardStep>
                        <WizardStep setMessage={setMessageDetails} showMessage={showMessage} step={5} > <Step4/> </WizardStep>
                    </Wizard>

                    :

                    <Wizard>
                        <WizardStep showMessage={showMessage} step={1} > <Step1  noDOB={true}/> </WizardStep>
                        <WizardStep setMessage={setMessageDetails} showMessage={showMessage} step={2} > <Step2/> </WizardStep>
                        <WizardStep setMessage={setMessageDetails} showMessage={showMessage} step={3} > <Step3/> </WizardStep>
                        <WizardStep setMessage={setMessageDetails} showMessage={showMessage} step={4} > <Step4/> </WizardStep>
                    </Wizard>  
                            
            }    
                <div id='recaptcha-container'></div>   
            </div>
                
            <MessageModal title={messageDetails.title} id="otp-message" okLabel="Close">
            {messageDetails.message}
            </MessageModal>  
            <MessageModal title={messageDetails.title} id="general-modal" okLabel="Close">{messageDetails.message}</MessageModal>                            
        </>
    )    

}

export default CampaignJourney8