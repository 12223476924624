import {Wizard, WizardStep} from './elements/wizard/wizard';
import { useState } from 'react';
import MessageModal from './modals/messageModal';
import Step1 from './campaignJourneyMobileStep1'
import Step2 from './campaignJourneyMobileStep2'
import Step3 from './campaignJourneyMobileStep3'

const CampaignJourneyMobile2 = () => {

    const [messageDetails,setMessageDetails] = useState({title:'',message:''});
    const showMessage = (title='no_title', message='') => {
        title = title || 'no_title';
        setMessageDetails({title,message});
        window.$('#campaign-message').modal('show');
    }    

    return (
        <>
        
            <div className="prices-section">
                {
                    <Wizard>
                        <WizardStep showMessage={showMessage} step={1} > <Step1 noDOB={true}/> </WizardStep>
                        <WizardStep showMessage={showMessage} step={2} > <Step2/> </WizardStep>
                        <WizardStep setMessage={setMessageDetails} showMessage={showMessage} step={3} > <Step3/> </WizardStep>
                    </Wizard>
                }    
                <div id='recaptcha-container'></div>   
            </div>
                
            <MessageModal title={messageDetails.title} id="otp-message" okLabel="Close">
            {messageDetails.message}
            </MessageModal>  
            <MessageModal title={messageDetails.title} id="general-modal" okLabel="Close">{messageDetails.message}</MessageModal>                            
        </>
    )    

}

export default CampaignJourneyMobile2