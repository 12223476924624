import '../../assets/css/projectItem.css'
import {getFileIcon, ReplaceTagStringWithElement} from '../../helpers/functions'
import { AuthContext } from '../../context/AuthContext'
import React, { useContext, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import playIcon from '../../assets/images/parts_play_icon.svg' 
import { UserContext } from '../../context/languageContext'
import config from '../config'

const ProjectItem = (props) => {
    const lagKeywords = React.useContext(UserContext);
    const Auth = useContext(AuthContext);
    const navigate = useNavigate();
    let [item,updateItem] = useState(props.data)
    const showShareUrl = (link) =>{
        props.setMessageContent(
        // <h4><a href={link}>{link}</a></h4>
        <center>
            <input value={config.domain+'course/project/'+item.id} readonly={true} type='text' style={{'width':'90%'}}/>
            <button class="btn btn-primary" style={{margin:'10px'}} onClick={()=>{navigator.clipboard.writeText(config.domain+'course/project/'+item.id);}}>{lagKeywords.copy_link}</button>
            <button class="btn btn-primary" style={{margin:'10px'}} onClick={()=>{window.$('#enroll-message').modal('hide');navigate('/course/project/'+item.id)}}>{lagKeywords.view_project}</button>
        </center>
        );
        props.setMessageTitle(lagKeywords.project_link);
        window.$('#enroll-message').modal('show');
    }

    const do_like =(id) => {
        if(!Auth.getUserId()){
            props.setMessageContent(<>{ReplaceTagStringWithElement(lagKeywords.login_to_review_project,'navlink',<NavLink onClick={props.showLoginModal}/>)}</>);
            props.setMessageTitle(lagKeywords.alert);
            window.$('#enroll-message').modal('show');  
        }
        else{
            let isLike = item.is_like
            if(item.is_like==1){
                isLike = 0;
            }
            else{
                isLike = 1;
            }
            Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('likeProject/'+item.id,{isLike}).then( (res) => {
                if(res.data.status==1){
                    item.is_like =isLike;
                    updateItem({...item})
                }
            });

        }
    } 
    return (
	<div className="project-card">
		<div className="card-img">
            <div style={{padding:'50px'}}>
            {/* {getFileIcon(item?.extension, false)} */} 
            {/* {console.log(item?.extension)} */}
            
            {((item?.extension == 'png') || (item?.extension == 'jpg') || 
              (item?.extension == 'jpeg') || (item?.extension == 'svg') )? <img src={item.downloadLink} className="img-thumb" />: <video className="item-project" src={item.downloadLink} /> }

            {/* <img src={item.downloadLink} /> */}
            </div>
			<h1>{/*`Title`*/}</h1>
		</div>
        <img src={playIcon} alt="play icon" className='playIconProject' />

		<div className="card-details">
            <span>
                {item?.username}
                {/*`AUG 4`*/}
            </span>
            <span>

                { item.allow_sharing_projects == 1 && 
                <>
                {/* <i className="fa fa-heart" onClick={()=>do_like(item.id)} style={{color:parseInt(item.is_like)==1?'red':'#9c9b9b'}}></i> */}
                <i className="fa fa-share" onClick={()=>showShareUrl(item.downloadLink)}></i>
                {/* <i className="fa fa-share" onClick={()=>navigate('/course/project/'+item.id)}></i> */}
                </>
                }
                {/*`102`*/}
            </span>
        </div>
		{/* <div className="card-text">
            <p></p>
        </div>
		<div className="read-more">Read More</div> */}
	</div>

    )
}

export default ProjectItem