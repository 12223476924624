import { useContext, useEffect } from "react";
import LoadingButton from "../Components/elements/loadingButton";
import { UserContext } from "../context/languageContext";
import introImage from './images/intro.svg'

const SurveyIntro = ({dispatchWizard,values, fullWidth=false}) => {

    const language = useContext(UserContext);    
    const handleSubmit = () => {        
        dispatchWizard({type:'NEXT',data:{}});        
    }


    useEffect(()=>{
          const keyDownHandler = event => {
      
            if (event.key === 'Enter' ) {
              event.preventDefault();
                document.getElementById('submit')?.click();
              //handleSubmit();
            }
          };

          document.addEventListener('keydown', keyDownHandler);

          return () => {
            document.removeEventListener('keydown', keyDownHandler);
          };

    },[])

    return (
        <>
        <div className="survey-container">
        <div className="survey-question-body" style={fullWidth ? {maxWidth:'100%'}:{}}>
            <h1>{language.survey_intro}</h1>
            <img className="intro-image" src={introImage}/>
            <h4 className="intro-int">{language.intro_hint}</h4>

            <LoadingButton id={'submit'} onClick={handleSubmit} className="submit-button-auto reg-btn">{language.start_now}</LoadingButton>
        </div>
        </div>
        </>
    );
}

export default SurveyIntro