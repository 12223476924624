import { useContext, useRef, useState } from "react"
import { AuthContext } from "../../context/AuthContext";
import { DecryptData } from "../../helpers/functions";
import LoadingButton from "./loadingButton";

const FileUploadBtn = (props) => {
    //console.log(props)
    const inputRef = useRef(null);
    const Auth = useContext(AuthContext);
    const initalState = {
        selectedFiles: [],
        currentFile : {name:''},
        progress : 0,
        message : '',
        currentlyUploading:false
    }

    let [filesState, setFiles] = useState(initalState);

    const setSelectedFiles = (files=[]) => {
        filesState.currentFile = files[0];
        setFiles({...initalState, selectedFiles:files, currentFile:files[0]})
    }

    const upload = () => {
        let currentFile = filesState.currentFile;
        //console.log(filesState);
        let formData = new FormData();
        if(props.mutiple ==true){
            for(var x=0; x<filesState.selectedFiles; x++){
                formData.append("file["+x+"]", filesState.selectedFiles[x]);    
            }
        }
        else{
            formData.append("file", currentFile);
        }        
        if(props.data){
            Object.keys(props.data).map(k => formData.append(k, props.data[k]) );
        }

        filesState.currentlyUploading = true;
        setFiles({...filesState});
        if(props.functions.beforeupload){
            props.functions.beforeupload();
        }           
        Auth.authorizedRequest({"Content-Type": "multipart/form-data"}).post(props.url, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress,
          }).then( (res) => {
            if(res.data.error==1){
                    filesState.currentlyUploading = false;              
                    setFiles({...initalState,message:'upload failed'})  
                    if(props.functions.onuploaderror){
                        props.functions.onuploaderror();
                    }                                                    
            }
            else{
                filesState.currentlyUploading = false;
                setFiles({...initalState, message:'upload success'});
                if(props.functions.afterupload){
                    props.functions.afterupload(res.data);
                }
            }      
            //filesState.currentlyUploading = false;   
               
          }).catch( (err) => {
            //console.log(err);
            filesState.currentlyUploading = false;
            setFiles({...initalState,message:'upload failed'})
            if(props.functions.onuploaderror){
                props.functions.onuploaderror();
            }               
            //filesState.currentlyUploading = false; 
          });
    }

    const onUploadProgress = (event) => {
        let progress = Math.round((100 * event.loaded) / event.total)
        //console.log(filesState);
        setFiles({...filesState, progress})
    }

    const fileUploadChange = () => {
        let files=inputRef.current.files
        setSelectedFiles(files)
        upload();
        //console.log(files)
    }


    if(props?.iconbtn === "true"){
        return (
            <i className={props.className} onClick={()=> inputRef.current.click()}>
                <input type="file" ref={inputRef} {...props} onChange={fileUploadChange} style={{position: "absolute", clip: "rect(0px, 0px, 0px, 0px)"}}/>
            </i>            
        )
    }

    else if(!props.iconbtn){
    return (
        <span className={props.className}>
            <input type="file" ref={inputRef} {...props} onChange={fileUploadChange} style={{position: "absolute", clip: "rect(0px, 0px, 0px, 0px)"}}/>
            <LoadingButton type="button" loading={filesState.currentlyUploading.toString()} className={`orange-btn btn-round`} onClick={()=> inputRef.current.click()} loading-text={`<i class='fa fa-circle-o-notch fa-spin'></i>${props.loadingText || 'Uploading'}`}>{props.title}</LoadingButton>                                 
        </span>                
      )
    }
    
    
    
}

export default FileUploadBtn