import * as Yup from 'yup';

const CodeSchemaCampaign = Yup.object().shape({

    code: Yup.string()
            .required("code_required_err")
            //.typeError("numbers_only")
            .test('len', 'code_length_error_campaign', val => val?.length >= 6),
            
  })

  export default CodeSchemaCampaign;