import { useField } from "formik";
import { useContext } from "react";
import { UserContext as language } from '../../context/languageContext';
const CustomInput = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    const Lang = useContext(language);

    let errorMessage = '';
    if(meta.touched && meta.error){
      let errorArr =[];
      if(typeof meta.error===  "string")
      {
         errorArr = meta.error.split(",");
      }
      
      if(errorArr.length >1){
        errorMessage = Lang[errorArr[0]] 
        for(let x=1;x< errorArr.length;x++){
          let comma = ','
          if(x == errorArr.length-1){
            comma = ''
          }
          errorMessage += ' ' + errorArr[x]+comma;
        }
      }
      else{
        errorMessage = Lang[meta.error];
      }
    }

    return (
      <>
      {label && <label>{label}</label>}        
        <input
          {...field}
          {...props}
          className={meta.touched && meta.error ? props.className+" input-error" : props.className}
        />
        {meta.touched && meta.error && props.doNotShowError !=="1" && <div className="error">{errorMessage}</div>}
      </>
    );
  };
  export default CustomInput;