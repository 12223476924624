/** react core libraries */
import React, { useEffect, useState } from 'react';
import { Route,  BrowserRouter as Router,Routes } from 'react-router-dom' 

/** shared components */
import Header from './Components/header_new';
import Footer from './Components/footer_new';
import LoginModal from './Components/modals/loginModal';
import ForgetPasswordModal from './Components/modals/forgetPasswordModal';
import Chatbot from './Components/chatbot';
import SharedComponent from './Components/sharedComponent';
import ScrollToTop from './helpers/scrollToTop';
 
/** contexts components */
import Langauge from './context/languageContext';
import Auth from './context/AuthContext';
import Profile from './context/ProfileContext';


/** inside router components */
import About from './Components/about'
//import LandingPage from './Components/landingpage.js'
import LandingPage from './Components/landingPage_new.js'
import CourseDetails from './Components/courseDetails';
import TermsConditions from "./Components/termsConditions";
import PrivacyPolicy from "./Components/privacyPolicy";
import PaymentPolicy from "./Components/paymentPolicy";
import Faqs from './Components/faqs';
import ContactUs from './Components/contact_us';
import Register from './Components/register';
import CourseModules from './Components/courseModules.js'
import Dashboard from './Components/dashboard.js';
import AddedChildren from './Components/addedChildren';
import UserProfile from './Components/userProfile';
import PageNotFound from './Components/pageNotFound';
import CourseCertificates from './Components/courseCertificates.js'
import PricesWizard from './Components/pricesWizard';
import CheckoutForm from './Components/checkoutForm3';
import NotificationsManagement from './Components/notificationsManage';
import Notifications from './Components/notifications';
import Certificate from './Components/certificate';
import Newsletter from './Components/newsletter';
import ResetPassword from './Components/resetPassword';
import TokenLogin from './Components/tokenLogin.js';
import AccountActivation from './Components/accountActivation';
import ChildAccountActivation from './Components/childAccountActivation';
import MsisdnRegisterWizard from './Components/msisdnRegisterWizard';
import MsisdnAutoLogin from './Components/msisdnAutoLogin';
import JoinOurTeam from './Components/JoinOurTeam';
import CategoryCourses from './Components/categoryCourses'
import PaymentInfo from './Components/paymentInfo';
import MenaRoute from './Components/menaRoute';
import CampaignJourney from './Components/campaignJourney';
import CampaignCompleteRegistration from './Components/campaignCompleteRegistration';
import UserBillingHistory from './Components/userBillingHistory';
import Devices from './Components/devices';
import Courses from './Components/courses';
import CampaignJourneyMobile from './Components/campaignJourneyMobile';
import ChangePaymentMethod from './Components/changePaymentMethod';
import CampaignJourney2 from './Components/campaignJourney2';
import CampaignJourneyMobile2 from './Components/campaignJourneyMobile2';
import CampaignJourney3 from './Components/campaignJourney3';
import CampaignJourney4 from './Components/campaignJourney4';
import CampaignJourney7 from './Components/campaignJourney7';
import Testimonials from './Components/testimonials';
import CampaignJourneyThemes from './Components/campaignJourneyThemes';
/** functions and helpers */
import config from './Components/config';
import ApiHelper from './helpers/ApiHelper';
import CampaignJourney8 from './Components/campaignJourney8';
import UserRegistration from './Components/userRegistration';
import CompleteUserRegistration from './Components/completeUserRegistration';
import Login2 from './Components/login2';
import Survey from './survey/survey';
import SurveyModal from './Components/modals/surveyModal';

import PaypalSimulation from './Components/paypalSimulation';
import CampaignJourneyThemesMobile from './Components/campaignJourneyThemesMobile';

import CourseProjectPage from './Components/courseProjectPage';
import TestConversion from './Components/testConversion';
import AppleCallback from './Components/appleCallback';

import Blogs from './Components/blogs';
import Blog from './Components/blog';

// import ReactGA from 'react-ga';
// const TRACKING_ID = "UA-114526787-1"; // OUR_TRACKING_ID
// ReactGA.initialize(TRACKING_ID);

const App = () =>
{    
  const [sharedCourses, setSharedCourses] = useState([]);
  const searchParams = new URLSearchParams(document.location.search);
  const isLogin = searchParams.get('login');
  let campaignType = searchParams.get('campType');
  if(!campaignType || campaignType == undefined || campaignType==0 || campaignType==1){
    campaignType = 1
  }
  let isSurvey = document.location.pathname == '/survey' ? 1 :0;
  isSurvey = isSurvey || document.location.pathname == '/survey/long' ? 1 :0;
  const lang = searchParams.get('lang') || '';

  useEffect(()=> {
    ApiHelper.excuteApi(config.coursesApi,'get','{}',setSharedCourses);
  },[])
  
  document.querySelector('meta[name="description"]').setAttribute("content", 'discover talent, hobbies. education, online education, Python course, AI course, online courses, kids talents, Kids hobbies, adults hobbies, crafts courses');
  document.querySelector('meta[name="ogTitle"]').setAttribute("content", 'discover talent, hobbies. education, online education, Python course, AI course, online courses, kids talents, Kids hobbies, adults hobbies, crafts courses');
  document.querySelector('meta[name="ogDescription"]').setAttribute("content", 'Mawaheb'); 
  
  document.title="MasterHobbies";


    return (
      <>   
   
        {isSurvey ? 
        <Router>
          <Auth>
            <Langauge> 
                <ScrollToTop/>
                <Routes>
                  <Route exact={true} path="/survey" element={<Survey isLong={false} lang={lang}/>}/>
                  <Route  path="/survey/long" element={<Survey isLong={true} lang={lang}/>}/>
                </Routes> 
            </Langauge>  
          </Auth>
        </Router>              
              
        :
        <>

        <Chatbot/>
        <Router>  
          <Auth>   
            <Langauge>   
              <ScrollToTop/>
              <SharedComponent/>
              {!lang && 
              <Profile>
                <Header sharedCourses={sharedCourses}/>
                <Routes>               
                  <Route  exact path="/" element={<LandingPage  sharedCourses={sharedCourses}/>} />
                  <Route  path="/about" element={<About />} />
                  <Route  path="/prices" element={<PricesWizard/>} />
                  <Route  path="/courseDetails/:id/:name" element={<CourseDetails />} />
                  <Route  path="/course/:id/:name" element={<CourseDetails />} />
                  <Route  path="/TermsConditions" element={<TermsConditions />} />
                  <Route  path="/PrivacyPolicy" element={<PrivacyPolicy />} />
                  <Route  path="/PaymentPolicy" element={<PaymentPolicy />} />
                  <Route  path="/Faqs" element={<Faqs />} />
                  <Route  path="/Blogs" element={<Blogs />} />
                  <Route  path="/Blog/:id" element={<Blog />} />
                  <Route  path="/ContactUs" element={<ContactUs />} />
                  <Route  path="/register" element={<Register />} />
                  <Route  path="/register/:refreshToken" element={<Register />} />
                  <Route  path="/register/:token/:email" element={<Register />} />

                  <Route  path="/courseModules/:id/:courseId/:courseName" element={<CourseModules/>}>
                      <Route  path=":videoLang" element={<CourseModules/>}/>
                  </Route>

                  <Route  path="/courseCertificates/:courseId/:courseName/:userId" element={<CourseCertificates/>} />
                  <Route  path="/dashboard" element={<Dashboard />} />
                  <Route  path="/added-children" element={<AddedChildren />} />
                  <Route  path="/added-children/:refresh" element={<AddedChildren />} />
                  <Route  path="/profile" element={<UserProfile />} />
                  <Route  path="/notifications-management" element={<NotificationsManagement />} />
                  <Route  path="/notifications" element={<Notifications/>} />
                  <Route  path="*" element={<PageNotFound/>} />
                  {/* <Route  path="/checkout/:productId/:price/" element={<CheckoutForm/>} /> */}
                  <Route  path="/checkout/:planId/:promoCode/" element={<CheckoutForm/>} />
                  <Route  path="/checkout/:planId" element={<CheckoutForm/>} />
                  <Route  path="/certificate/:courseId/:moduleId/:userId/:download" element={<Certificate/>} />
                  <Route  path="/newsletter" element={<Newsletter/>} />
                  <Route  path="/resetPassword/:token/:email" element={<ResetPassword/>} />
                  <Route  path="/tokenLogin/:token/" element={<TokenLogin/>} />
                  <Route  path="/accountActivation/:token/:id" element={<AccountActivation/>} />
                  <Route  path="/accountActivation/:token/:id/:platform" element={<AccountActivation/>} />
                  <Route  path="/childAccountActivation/:token/:id" element={<ChildAccountActivation/>} />
                  <Route  path="/login" element={<LandingPage  sharedCourses={sharedCourses}/>} />
                  <Route  path="/authMobile" element={<MsisdnRegisterWizard/>} />
                  <Route  path="/resetMobile" element={<MsisdnRegisterWizard reset={true}/>} />
                  
                  <Route  path="/login2/:rtk/:id" element={<Login2/>} />
                  <Route  path="/autoLogin" element={<MsisdnAutoLogin/>} />
                  <Route  path="/join-our-team" element={<JoinOurTeam/>} />
                  <Route  path="/categoryCourses/:catId/:catName" element={<CategoryCourses/>} />
                  <Route  path="/paymentInfo" element={<PaymentInfo/>} />
                  <Route  path="/userBillingHistory" element={<UserBillingHistory/>} />
                  { campaignType ==1 && <Route  path="/campaign" element={<CampaignJourney/>} />}
                  { campaignType ==2 && <Route  path="/campaign" element={<CampaignJourney2/>} />}
                  { campaignType ==3 && <Route  path="/campaign" element={<CampaignJourneyMobile/>} />}
                  { campaignType ==4 && <Route  path="/campaign" element={<CampaignJourneyMobile2/>} />}
                  { campaignType ==5 && <Route  path="/campaign" element={<CampaignJourney3/>} />}
                  { campaignType ==6 && <Route  path="/campaign" element={<CampaignJourney4/>} />}
                  { campaignType ==7 && <Route  path="/campaign" element={<CampaignJourney7/>} />}
                  { campaignType ==8 && <Route  path="/campaign" element={<CampaignJourney8/>} />}

                   <Route  path="/userRegistration" element={<UserRegistration/>} />
                   <Route  path="/completeUserRegistration/:token/:userId" element={<CompleteUserRegistration/>} />
                   
                  <Route path="/campaignTheme/"  element={<CampaignJourneyThemes/>} />
                  <Route path="/campaignThemeMobile/"  element={<CampaignJourneyThemesMobile/>} />
                  <Route  path="/campaignCompleteReg/:token/:userId" element={<CampaignCompleteRegistration />} />
                  <Route  path="/devices" element={<Devices/>} />
                  <Route  path="/courses" element={<Courses/>} />
                  <Route  path="/course" element={<Courses/>} />
                  <Route  path="/changePaymentMethod" element={<ChangePaymentMethod/>} />

                  <Route path="/MENA" element={<MenaRoute/>}/>
                  <Route path="/testimonials" element={<Testimonials/>}/>
                  <Route  path="/paypalSimulation" element={<PaypalSimulation/>} />
                  <Route  path="/course/project/:id" element={<CourseProjectPage/>} />
                  <Route  path="/testConversion" element={<TestConversion/>} />
                  <Route  path="/appleCallback" element={<AppleCallback/>} />
                  

                </Routes>
                <Footer/>
                <LoginModal/>
                <SurveyModal/> 
                <ForgetPasswordModal/> 
              </Profile>
            }

            </Langauge> 
          </Auth>
        </Router> 

        </>
        } 

        </>
    )
  }


export default App;
