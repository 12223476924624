import elements from './elements/elements';
import {Form, Formik} from 'formik';
import LoadingButton from './elements/loadingButton';
import { UserContext } from '../context/languageContext';
import { useContext, useEffect, useState } from 'react';
import config from './config';
import { AuthContext } from '../context/AuthContext';
import MessageModal from './modals/messageModal';
import Elements from './elements/elements';
import { ReplaceStringWithElement } from '../helpers/functions';
import Cookies from 'universal-cookie';
import '../assets/css/paymentInfo.css'

const {CustomInput, DateInput, SelectInput, RadioInput, CheckboxInput} = Elements;

const PaymentInfo = (props) => {

    const Lang = useContext(UserContext);
    const {CheckboxInput} = elements;

    const [data, setData] = useState({});
    const [isLoading, setLoading] = useState(0);
    const [unsubReqloading, setUnsubReqloading] = useState(0); 
    const [canUnsub,setCanUnsub] = useState(1);
    const [message, setMessage] = useState({title:'',body:''});
    const Auth = useContext(AuthContext);
    const [nextMonthDays, setNextMonthDays] = useState(0);
    const [startMonthDays, setStartMonthDays] = useState(0);
    const cookies= new Cookies();
    let isVas = cookies.get('isVas') || 0;
    useEffect(()=> {     
        if(!Auth.isLogged){
            window.location.href = '/';
            return ;
        }

        // let reponse = {
        //     start_year:2023,
        //     start_month:2,
        //     start_day:24,
        //     next_year:2023,
        //     next_month:3,
        //     next_day:26,
        //     children:3,
        //     amount:12.5,
        //     subscription_type: 1
        // };
               
        setLoading(1);
        Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('subscriptionDetails').then(res => {            
            let reponse = res.data.payload;
            setData(reponse)        
            if(reponse.start_year && reponse.start_month){
                setStartMonthDays( new Date(reponse.start_year, reponse.start_month, 0).getDate());
            }   
            if(reponse.next_year && reponse.next_month){
                setNextMonthDays( new Date(reponse.next_year, reponse.next_month, 0).getDate());
            } 
            if(reponse.cancelled_subscription == 1){
                setCanUnsub(0);
            }
            setLoading(2);
        }).catch(err => {
            setLoading(2);
        })
    },[])

    

    
    const initValues = {
        start_year:data.start_year,
        start_month:data.start_month,
        start_day:data.start_day,
        next_year:data.next_year,
        next_month:data.next_month,
        next_day:data.next_day,    
        children:data.children,
        amount: data.amount,
        subscription_type: data.subscription_type
    };
    //console.log(initValues);

    const onSubmitForm = async (values, actions) => {


      };     



      const unsubscribePlan = () => {
        setUnsubReqloading(1);
        const userLanguage = Lang.getSelectedLanguage()
        Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('unsubscribePlan',{language:userLanguage}).then(res => {    
          setUnsubReqloading(2);
          if(res.data.status ==1 ){
            setMessage({title:Lang.unsub, body:Lang.success_unsub});
            setCanUnsub(0);
            //setData({amount:'',subscription_type:'',children:'',next_day:'',next_month:'',next_year:'',start_day:'',start_month:'',start_year:'', have_payment_method:data.have_payment_method}) 
          }
          else{
            setMessage({title:Lang.unsub, body:Lang.failed_unsub});
          }
          window.$('#confirm-modal').modal('show');    
        })
        .catch(err => {
          setUnsubReqloading(2);
          setMessage({title:Lang.unsub, body:Lang.failed_unsub});
          window.$('#confirm-modal').modal('show');    
        })
      }


      const historyTable = (data) => {

        return(
        <>
        <table className="table table-striped cell-centered devices-table wide-screen-history-payment">
        <thead>
            <tr>
                <th>{Lang.transaction_date}</th>
                <th>{Lang.amount}</th>
                <th>{Lang.renewal_period}</th>
                <th>{Lang.transaction_id}</th>
            </tr>
        </thead>
        {
            
                data?.length ==0 ? <tr><td colSpan={7 }>{Lang.no_results}</td></tr> : (
                    data?.map(item => (
                        <tr>
                            <td>{item.transaction_date}</td>
                            <td>{(Lang.getSelectedLanguage() != 'ar' ? '$ ':'') + item.amount + (Lang.getSelectedLanguage() == 'ar' ? ' $':'')  }</td>
                            <td>{Lang[item.period]}</td>
                            <td>{item.transaction_id}</td>      
                        </tr>
                    ))
                )
            
        }
        </table>


        <div className='mobile-history-payment' style={{direction: Lang.getSelectedLanguage() == 'ar' ? 'rtl' : 'ltr'}}>
        {
                    data?.length ==0 ? <table className="table table-striped cell-centered devices-table"><tr><td colSpan={7 }>{Lang.no_results}</td></tr></table> : (
                        data?.map((item,index) => (
                            <table className="table table-striped cell-centered devices-table" style={{borderBottom: data?.length == (index+1) ? '':'1px solid'}}>
                                <tr><th>{Lang.transaction_date}</th><td>{item.transaction_date}</td></tr>
                                <tr><th>{Lang.amount}</th><td>{(Lang.getSelectedLanguage() != 'ar' ? '$ ':'') + item.amount + (Lang.getSelectedLanguage() == 'ar' ? ' $':'')  }</td></tr>
                                <tr><th>{Lang.renewal_period}</th><td>{Lang[item.period]}</td></tr>
                                <tr><th>{Lang.transaction_id}</th><td>{item.transaction_id}</td></tr>   
                            </table>
                        ))
                    )
        }            
        </div>

        </>
        )

      }

      const removePaymentMethod = () => {
        setUnsubReqloading(1);

        Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('removePaymentMethod',{}).then(res => {    
            setUnsubReqloading(2);
            if(res.data.status ==1 ){
              setMessage({title:Lang.payment_method, body:Lang.remove_payment_method_success});
              setData({...data, have_payment_method:0}) 
              //setCanUnsub(0);
            }
            else{
              setMessage({title:Lang.payment_method, body:Lang.remove_payment_method_failed});
            }
            window.$('#confirm-modal').modal('show');    
          })
          .catch(err => {
            setUnsubReqloading(2);
            setMessage({title:Lang.payment_method, body:Lang.remove_payment_method_failed});
            window.$('#confirm-modal').modal('show');    
          });

      }

      const printButtons = (values) => {

        let jsxArray =[]
        
        if(values.subscription_type && canUnsub){
            jsxArray.push(<div><button onClick={unsubscribePlan} className={`pay-button`}>{Lang.unsub_from_plan}</button></div>);
        } 
       
        if(data?.have_payment_method==1){
            jsxArray.push(<div><button onClick={removePaymentMethod} className={`pay-button`} >{Lang.remove_payment_method}</button></div>);
        }

        return jsxArray;
        
      }
return (
    <div className="section-container">
    <div className='content-container reg-container' style={{"padding": "0"}}>
    <div className="reg-form pay-form">
        <div className='pay-title'>
                <img className='svg-logo2' src={process.env.PUBLIC_URL + "/assets/images/payment_plan.svg"} alt={Lang.payment_info} /> 
                <p className='notif-title'>{Lang.payment_info}</p>
            </div>  
{ isLoading==1 &&<center>{Lang.loading}</center>}            
{ isLoading==2 && !data.subscription_type && 
    <>
    

    { data.has_history > 0 ?
    <div className="form-group inline-group pay-info" style={{"margin": "20px 0 0 0", "width":"100%"}}>
        <div className="col-md-3 date-label" style={{"width":"40%"}}>
            <label class="pay-label">{Lang.payment_history}</label>                
        </div>
        <div class="pay-info-field">
            <LoadingButton onClick={ () => window.$('#history-modal').modal('show')} type="button" className="submit-button-auto reg-btn">{Lang.view}</LoadingButton>
        </div>
    </div>
    :
    <center>{Lang.no_payment_info}</center>
    }

</>
} 
{ isLoading==2 && data.using_mobile ==1 && data.cancelled_subscription==0 && canUnsub==1 && <center><button onClick={unsubscribePlan} className={`general-btn`}>{Lang.unsub}</button></center>} 
{ isLoading==2 && data.using_mobile ==1 && (data.cancelled_subscription ==1 || canUnsub!=1) && 

<>
{ data.has_history > 0 && !isVas?
    <div className="form-group inline-group pay-info" style={{"margin": "20px 0 0 0", "width":"100%"}}>
        <div className="col-md-3 date-label" style={{"width":"40%"}}>
            <label class="pay-label">{Lang.payment_history}</label>                
        </div>
        <div class="pay-info-field">
            <LoadingButton onClick={ () => window.$('#history-modal').modal('show')} type="button" className="submit-button-auto reg-btn">{Lang.view}</LoadingButton>
        </div>
    </div>
    :
    <center>{Lang.no_payment_info}</center>
}
</>
} 
{ isLoading==2 && (isVas==1 || (data.using_mobile !=1 &&  data.start_year && data.start_month)) &&
            <Formik
      initialValues={initValues}
     // validationSchema={RegisterSchema}
      onSubmit={onSubmitForm}  
      enableReinitialize
    >

{(props) => {
    //console.log(props.values.dob_year);
    let {values, errors} = props;

    return(
        <Form>

{ !isVas &&
<div className="form-group inline-group pay-info" style={{"margin": "20px 0 0 0", "width":"100%"}}>
            <div className="col-md-3 date-label" style={{"width":"40%"}}>
                <label class="pay-label">{Lang.payment_paln}</label>                
            </div>
            
            <div class="wrapper1 pay-info-field">
                {Lang[config.subTypes.find(item => item.id == data.subscription_type).key]}
            </div>
            {/* <SelectInput disabled="disabled" withParent={true} className="mwaheb-select" id="subscription_type" name="subscription_type" >
                <option value=""></option>
                { 
                    config.subTypes.map( item => <option key={item.id} value={item.id}>{Lang[item.key]}</option>)                                    
                }                
            </SelectInput> */}
        </div>
}
{ !isVas &&
<div className="form-group inline-group pay-info" style={{"margin": "20px 0 0 0", "width":"100%"}}>
            <div className="col-md-3 date-label" style={{"width":"40%"}}>
                <label class="pay-label">{Lang.payment_amount}</label>                
            </div>
            <div class="wrapper1 pay-info-field">{data.amount}</div>
            {/* <CustomInput disabled="disabled" name="amount" id="amount" type="text" placeholder=" " className="reg-input" />                     */}
        </div>
}
{ Auth.getAccountType() == 2 &&
<div className="form-group inline-group pay-info" style={{"margin": "20px 0 0 0", "width":"100%"}}>
            <div className="col-md-3 date-label" style={{"width":"40%"}}>
                <label class="pay-label">{Lang.number_of_children}</label>                
            </div>
            <div class="wrapper1 pay-info-field">{data.children? (data.children==1 ? Lang.one_child :(data.children==2 ? Lang.tow_child : (data.children==3 ? Lang.three_child:'-'))) :'-'}</div>
            {/* <SelectInput disabled="disabled" withParent={true} className="mwaheb-select" id="children" name="children" >
                <option value=""></option>
                <option value="1">{Lang.one_child}</option>
                <option value="2">{Lang.two_child}</option>
                <option value="3">{Lang.three_child}</option>
            </SelectInput> */}
        </div>
}

<div className="form-group inline-group pay-info" style={{"margin": "20px 0 0 0", "width":"100%"}}>
            <div className="col-md-3 date-label" style={{"width":"40%"}}>
                <label class="pay-label">{Lang.last_payment_date}</label>                
            </div>

<div className='dob_options_container'>
<div className="col-md-3" style={{paddingRight: '0', width: '31%'}}>
                <div class="wrapper1">{data.start_day}</div>
                {/* <SelectInput disabled="disabled" name="start_day" id="start_day" className="select-date reg-input">  
                   <option value={Lang.day} >{Lang.day}</option>
                    {
                        ( ()=>{
                            let eleArr = [];
                            for(var j=1; j<=startMonthDays ;j++){
                                eleArr.push(<option key={j} value={j}>{j}</option>);
                            }
                            return eleArr;
                        })()
                    }
                </SelectInput>                 */}
            </div>
            <div className="col-md-3" style={{paddingRight: '0', width: '31%'}}>
                <div class="wrapper1">{Lang[config.months.find(item => item.id==data.start_month).name]}</div>
                {/* <SelectInput disabled="disabled" name="start_month" id="start_month" className="reg-input select-date">
                    <option value={Lang.month} >{Lang.month}</option>
                    {
                        config.months.map( item => {
                            return (
                                <option key={item.id} value={item.id}>{Lang[item.name]}</option>
                            )
                        })
                    }                                       
                </SelectInput>                 */}
            </div> 
            <div className="col-md-3" style={{paddingRight: '0', width: '31%'}}>
            <div class="wrapper1">{data.start_year}</div>
                {/* <SelectInput  name="start_year" id="start_year" disabled="disabled" className="reg-input select-date">
                    <option value={Lang.year} >{Lang.year}</option>
                    { (()=>{
                        let eleArr = [];
                        let currentYear = (new Date().getFullYear());
                        for (var i=2022; i<=currentYear; i++){
                            eleArr.push(<option key={i} value={i}>{i}</option>);
                        }
                        return eleArr;
                    })()
                    }

                </SelectInput>                 */}
            </div>            
 
            </div>            
            </div>                 






            {canUnsub==1 && (
            <div className="form-group inline-group pay-info" style={{"margin": "20px 0 0 0", "width":"100%"}}>
            <div className="col-md-3 date-label" style={{"width":"40%"}}>
                <label class="pay-label">{Lang.next_due_payment_date}</label>                
            </div>

            <div className='dob_options_container'>
            <div className="col-md-3" style={{paddingRight: '0', width: '31%'}}>
                <div class="wrapper1">{data.next_day}</div>
                {/* <SelectInput disabled="disabled" name="next_day" id="next_day" className="select-date reg-input">  
                   <option value={Lang.day} >{Lang.day}</option>
                    {
                        ( ()=>{
                            let eleArr = [];
                            for(var j=1; j<=nextMonthDays ;j++){
                                eleArr.push(<option key={j} value={j}>{j}</option>);
                            }
                            return eleArr;
                        })()
                    }
                </SelectInput>                 */}
            </div> 
            <div className="col-md-3" style={{paddingRight: '0', width: '31%'}}>
                <div class="wrapper1">{Lang[config.months.find(item => item.id==data.next_month).name]}</div>                
                {/* <SelectInput disabled="disabled" name="next_month" id="next_month" className="reg-input select-date">
                    <option value={Lang.month} >{Lang.month}</option>
                    {
                        config.months.map( item => {
                            return (
                                <option key={item.id} value={item.id}>{Lang[item.name]}</option>
                            )
                        })
                    }                                       
                </SelectInput>                 */}
            </div> 
            
            <div className="col-md-3" style={{paddingRight: '0', width: '31%'}}>
                <div class="wrapper1">{data.next_year}</div>
                {/* <SelectInput disabled="disabled" name="next_year" id="next_year" className="reg-input select-date">
                    <option value={Lang.year} >{Lang.year}</option>
                    { (()=>{
                        let eleArr = [];
                        let currentYear = (new Date().getFullYear())+10;
                        for (var i=2022; i<=currentYear; i++){
                            eleArr.push(<option key={i} value={i}>{i}</option>);
                        }
                        return eleArr;
                    })()
                    }

                </SelectInput>                 */}
            </div>            

            </div>            
            </div> 
            ) 
            }


        { data.has_history > 0 &&
        <div className="form-group inline-group pay-info" style={{"margin": "20px 0 0 0", "width":"100%"}}>
            <div className="col-md-3 date-label" style={{"width":"40%"}}>
                <label class="pay-label">{Lang.payment_history}</label>                
            </div>
            <div class="pay-info-field">
                <LoadingButton onClick={ () => window.$('#history-modal').modal('show')} type="button" className="submit-button-auto reg-btn">{Lang.view}</LoadingButton>
            </div>
        </div>
        }

          <h3 className={data?.active_free_period!=1 ? 'hide' : ''}><center class="paymentPlan-note">{Lang.free_period}{data.next_day<10?'0'+data.next_day:data.next_day}/{data.next_month<10?'0'+data.next_month:data.next_month}/{data.next_year}</center></h3>

{
            values.subscription_type && unsubReqloading ==1 ? 
            <h3><center class="paymentPlan-btns">{Lang.waiting}</center></h3>
            : (<h3><center class="paymentPlan-btns">{printButtons(values)}</center></h3>)   

}
            {data?.cancelled_subscription !=1 && <h3><center class="paymentPlan-note">{Lang.upgrade_downgrade}</center></h3> }

            {/* <LoadingButton loading={isLoading == 1 ? `true`:`false`} type="submit" className="submit-button-auto reg-btn" loading-text="<i class='fa fa-circle-o-notch fa-spin'></i> Processing" >{Lang.save}</LoadingButton> */}
        </Form>    
    )}}
    </Formik>                
}
        </div>
        <MessageModal title={message.title} id="confirm-modal" okLabel="Close">
        {message.body}
      </MessageModal> 

      <MessageModal title={Lang.payment_history} id="history-modal" okLabel="Close" modal-class-size="modal-lg">
        {historyTable(data?.history || [])}
      </MessageModal>
            
    </div>
    </div>
)
}

export default PaymentInfo