import { useContext, useState, useEffect } from 'react';
import Config from '../../Components/config';
import {UserContext} from '../../context/languageContext'

import ApiHelper from "../../helpers/ApiHelper";
import playIcon from '../../assets/images/parts_play_icon.svg' 
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation } from "swiper/modules";
import MessageModal from '../modals/messageModal';

const TipsForParent = () => {

    const lagKeywords = useContext(UserContext);
    let [videosLp, setvideosLp] = useState([]);
    let [popup, setPopup] = useState({title:'',content:''}); 
    let api=Config.videoLp;
    useEffect(()=>{
        ApiHelper.excuteApi(api,'get','{}',setvideosLp);
    },[]        
    )
    const handleYouTubeVideo=(videoId,videoUrl)=>{
        //console.log(videoUrl)
        setPopup({title:'no_title',content: <iframe className="iframe-video-carousel2" style={{"height":"256px"}} src={videoUrl} title="tetsing" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        })
        window.$('#content-video').modal({backdrop: 'static', keyboard: false}, 'show');
        return;
        window.$("#"+videoId).parent().html(
            '<iframe class="iframe-video-carousel " style="height:256px" src="'+videoUrl+'" title="tetsing" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>'
        )
    
    }
    
    // const options = {
    //     margin: 5,
    //     responsiveClass: true,
    //     nav: true,
    //     autoplay: false,
    //     loop:false,
    //     autoplaySpeed:1500,
    //     smartSpeed: 1000,
    //     navText: ['', ''],
    //     responsive: {
    //         0: {
    //             items: 1,
    //         },
    //         400: {
    //             items: 1,
    //         },
    //         600: {
    //             items: 2,
    //         },
    //         700: {
    //             items: 3,
    //         },
    //         1000: {
    //             items: 5,
    //         }
    //     },
    //   };   

      const breakpoints={
        0: {
            slidesPerView: 2.5,
        },
        450: {
            slidesPerView: 2.5,
        },
        600: {
            slidesPerView: 2.5,
        },
        700: {
            slidesPerView: 3,
        },
        1000: {
            slidesPerView: 5,
        },
        1500:{
            slidesPerView: 7,
        },
        2000:{
            slidesPerView: 8,
        }
    }

    return (
        <section className="videos-carousel-container" style={{padding:'60px 0px', backgroundColor: '#fff'}}>

        <h2 className="tips-title">{lagKeywords.tips_for_parents}</h2>
        {/* <OwlCarousel  className="slider-items owl-carousel" {...options}>{parse(videoString)}</OwlCarousel> */}
        {/* <OwlCarousel  className="slider-items owl-carousel" {...options}> */}
        <Swiper
            breakpoints={breakpoints}
            modules={[Navigation]}
            navigation           
            spaceBetween={30}
            slidesPerView={4.5}
            slidesPerGroup={1}
            observer
            observeParents
            parallax
            className="slider-items"
            > 
        {/* <div className="swiper-button-prev">Previous</div>
        <div className="swiper-button-next">next</div> */}

        {(videosLp && videosLp.length > 0 ) && videosLp.map((videosLpObj, index) => {
            var Language = localStorage.getItem('lang');
                return ( 
                    <SwiperSlide key={videosLpObj.id} >

                        <div className="swiper-item" key={videosLpObj.id} onClick={()=>handleYouTubeVideo(videosLpObj.id,videosLpObj.URL)}>
                        <div className="thumbnail-container">
                        <img style={{width:'100%',height:'100%'}} className="video-poster thumbnail-img" id={videosLpObj.id} src={videosLpObj.thumbinal}  />
                        {/* <img id={videosLpObj.id} src={process.env.PUBLIC_URL + "/assets/images/parts_thumbnail5.png"} /> */}
                        <img src={playIcon} alt={lagKeywords.play_mawaheb_icon} className='playIcon2' />
                        </div>
                        {( Language == 'ar' ) ? <p className="item-title-slider"><span>{videosLpObj.name_ar}</span></p> : <p className="item-title-slider"><span>{videosLpObj.name}</span></p> }
                        </div>
                    </SwiperSlide>
                     )
            })
    }
    {/* </OwlCarousel>      */}
    </Swiper>

    <MessageModal title={popup.title} id="content-video" modal-class-size={`modal-lg`} onclose={()=>{setPopup({title:'',content:''})}} okLabel={'Close'}>
        {popup.content}
    </MessageModal> 

    </section>
    

      
    )
}

export default TipsForParent