import { useContext } from "react";
import { UserContext } from "../../context/languageContext";
import Survey from "../../survey/survey";
import '../../assets/css/modals.css'; 


const SurveyModal = ({lang='', isLong=false}) => {

  const language = useContext(UserContext);
  if(!lang){
    lang = language.getSelectedLanguage();
  }
  return (
    <div id="survey_modal" className="modal fade" role="dialog">
        <div className="modal-dialog modal-lg-custom">
          <div className="modal-content">
            <div className="modal-body">
              <button data-dismiss="modal" className="close">&times;</button>

                <Survey fullWidth={true} isLong={isLong} lang={lang} isPopup={true}/>

            </div>
          </div>
        </div>  
    </div> 
  
        
  )    


}


export default SurveyModal;