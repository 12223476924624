import * as Yup from 'yup';
import {getAge} from '../../helpers/functions';

//const professions = ['Doctor', 'Engineer', 'Programmer'];


const CampaignJourneyStep1Schema = Yup.object().shape({

//     dob: Yup.string()
//             .required("dob_required_err"), 
        dob_day: Yup.number('dob_required_err').required("dob_required_err").min(1, 'dob_required_err'), 
        dob_month: Yup.number('dob_required_err').required("dob_required_err").min(1, 'dob_required_err'), 
        dob_year: Yup.number('dob_required_err').required("dob_required_err").min(1, 'dob_required_err'), 

    email: Yup.string().required('email_required_err')
            //.required("email_required_err")
            //.email("email_format_err"),     
            
            .test('validator-username-email', (value, { createError,resolve }) =>{
                 
                // if(!value){
                //         return true;//not mandatory
                // }
                //const emailRegex = /^\s*([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+\s*$/;
                const emailRegex = /^\s*([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+\s*$/;
                //const nameRegex = /^[a-zA-Z]+$/;
                const nameRegex = /^[A-Za-z]+[A-Za-z0-9]*$/;
                const y = resolve(Yup.ref('dob_year'));
                const m = resolve(Yup.ref('dob_month'));
                const d = resolve(Yup.ref('dob_day'));                
                const age = getAge(y+'-'+m+'-'+d);
                if(!emailRegex.test(value)){
                        return createError({
                                message: `email_format_err`,
                                path: 'email', // Fieldname
                        })
                }
                return true;
            }),
       password: Yup.string()            
       .min(8, 'password_min_err')
       .matches(/^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+$/,"password_english_err")
       .when('is_password', (val, schema) => {
        if(val == 1){
            return schema.required("password_required_err"); 
        }
        return schema;
      }),       


  })

  export default CampaignJourneyStep1Schema;