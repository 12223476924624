import elements from './elements/elements';
import {Form, Formik} from 'formik';
import LoadingButton from './elements/loadingButton';
import SelectInput from './elements/selectInput';
import config from './config';
import CustomInput from './elements/customInput';
import { UserContext as language } from '../context/languageContext';
import { useContext, useState } from 'react';
import Auth, { AuthContext } from '../context/AuthContext';

const MsisdnRegisterStep2 = (props) => {

    const {RadioInput} = elements;
    const Lang = useContext(language);
    const Auth = useContext(AuthContext);
    const [isLoading, setLoading] = useState(0);

    const dataFromPrevStep = props?.values?.values || [];
    const confirmationResult = props?.values?.values?.confirmationResult || null;
    const resetPurpose = props.reset || false;
    //console.log(confirmationResult)
    const initValues = {
        code:"",
    };

    const onSubmit = async (values, actions) => {        
        const code = ''+values.code;
        if(code.length ===6){
            setLoading(1);
            confirmationResult.confirm(code).then(res => {
                //console.log(res);
                if(res.user){ //success
                    const uid = res.user.uid;
                }
                setLoading(2);

                if(resetPurpose == true){
                    Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded',}).post('resetMobile',dataFromPrevStep).then((res)=>{  
                        if(res.data.status ==1){
                            Auth.successLoginProcess(res,'profile?focus=password');
                        }
                        
                    })
                    .catch( err => {

                    });
                    return;
                }
                else{

                    Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded',}).post('resetMobile',{...dataFromPrevStep, forActive:1}).then((res)=>{  
                        if(res.data.status ==1){
                            Auth.successLoginProcess(res,'profile');
                        }
                        
                    })
                    .catch( err => {

                    });
                    return;                    

                }
                props.dispatchWizard({type:'NEXT',data:{values,...dataFromPrevStep}})
            }).catch(err => {
                //console.log(err);
                if(err.code== 'auth/invalid-verification-code'){
                    props.showMessage(Lang.error, Lang.invalid_code);
                }
                setLoading(2);
            })
        }
        else{
            setLoading(2);
        }
 
       };     

    return (
        <div className="section-container">
            <div className='content-container' style={{"padding": "0"}}>
                <div className="reg-form">
                    <h2 className="reg-title">{resetPurpose ? Lang.enter_reset_pin+' '+dataFromPrevStep.mobile_prefix+dataFromPrevStep.mobile : Lang.RegisterNewAccount}</h2>


                <Formik
          initialValues={initValues}
         // validationSchema={RegisterSchema}
          onSubmit={onSubmit}  
        >
    
    {(props) => {
        //console.log(props.values.dob_year);
        let {values, errors} = props;
    
        return(
            <Form>




            <div className="form-group" style={{"margin": "0", position: 'relative'}}>

                <div className="reg-field">
                    <label htmlFor="mobile" className="placeholder-mob">{Lang.pin_code}</label>
                    <CustomInput id="code" name="code" type="text" placeholder=" " className="reg-input" />
                </div>
         
                {/* <CustomInput id="mobile" name="mobile" type="tel" className="reg-input" placeholder={Lang.mobile_number} /> */}
            </div>

    
                <LoadingButton loading={isLoading==1 ?`true`:`false`} type="submit" className="submit-button-auto reg-btn" loading-text="<i class='fa fa-circle-o-notch fa-spin'></i> Processing" >{`continue`}</LoadingButton>
            </Form>    
        )}}
        </Formik>                
    
                </div>
            </div>
        </div>
    )
}

export default MsisdnRegisterStep2;