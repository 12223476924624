import React,{ useContext, useState } from 'react';
import { AuthContext } from "../context/AuthContext";
import PaypalBtn from "./paypalBtn";

const PaypalSimulation = () => {
    const Auth = useContext(AuthContext)
    const [paypalPlan, setPaypalPlan] = useState("");
    const onSuccess = (paymentDetails) => {
        Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded',}).post('paypalSimulation',{details : {...paymentDetails,paypalPlan}}).then((res)=>{            
            if(res.data.status == 1){ // success
                let response = res.data;
            }
            else{
                // 3 => email used before
            }
        });
    }
    return (
    <>    
    <div>
        <h1>Plans</h1>
        <ul>
            <li style={{margin: '5px',border:' solid 2px '+(paypalPlan =='P-1BX378303Y850424NMUAFP2I' ? 'red':'black')}} onClick={() => setPaypalPlan('P-1BX378303Y850424NMUAFP2I')}>Monthly P-1BX378303Y850424NMUAFP2I</li>
            <li style={{margin: '5px',border:' solid 2px '+(paypalPlan =='P-4L626453YL3937748MUAGA4Y' ? 'red':'black')}} onClick={() => setPaypalPlan('P-4L626453YL3937748MUAGA4Y')}>Yearly P-4L626453YL3937748MUAGA4Y</li>
        </ul>
    </div>
    <PaypalBtn subscriptionType={1} planId={paypalPlan} successCallback={onSuccess}/>
    </>
    )
}

export default PaypalSimulation;