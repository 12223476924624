
import {useState, useContext, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import {Form, Formik} from 'formik';
import ResetPasswordSchema from '../Components/schema/resetPasswordSchema';
import Elements from './elements/elements';
import {AuthContext} from '../context/AuthContext'
import { UserContext as language } from '../context/languageContext';
import config from './config';
import '../assets/css/forms.css';
import { useParams } from 'react-router-dom';
import PageNotFound from './pageNotFound';

const {CustomInput, LoadingButton} = Elements;
let response = null;
// Password Toggling in Forms
window.$(".toggle-password").on('click',function() {

    window.$(this).toggleClass("fa-eye fa-eye-slash");
        var input = window.$(window.$(this).attr("toggle"));
        if (input.attr("type") == "password") {
        input.attr("type", "text");
        } else {
        input.attr("type", "password");
        }
        input.focus();
    });

const ResetPassword = () => {

    const [isLoading, setLoading] = useState(0);
    const [notFound, setNotFound] = useState(-1);
    const [success, setSuccess] = useState(-1);
    const Auth = useContext(AuthContext);
    const Lang = useContext(language); 
    const params = useParams();
    const token = params?.token || '';
    const email = params?.email || '';
    
    const showHidePassword = (targetInput) => {
        const ele = document.querySelector(targetInput);
        if(ele.type == 'password'){
            ele.type = 'text';
        }
        else{
            ele.type = 'password';
        }
    }    

    const onSubmitForm = async (values, actions) => {
        setLoading(1);
        const currentLanguage = Lang.getSelectedLanguage();
        response = Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('resetPasswordConfirm',{token, email, language:currentLanguage, ...values}).then(res => {
            setLoading(2);
            if(res?.data?.status == 1){
                actions.resetForm();
                setSuccess(1);
            }
            else{
                setSuccess(0);
            }
        }).catch(err => {
            setLoading(2);
            setSuccess(0);
        })
        

      };  


      useEffect( () => {
        Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('checkResetPasswordToken',{token, email}).then( res => {
            if(res?.data?.status ==1){
                setNotFound(0);
            }
            else{
                setNotFound(1);
            }
        })
      },[])

    const initValues = {
        password:'', 
        confirm_password:''
    };    





    return(
        
         notFound ==0 ? (
        <div className="section-container">
            {
                success == 1 && <div><center><h1>{Lang['reset_password_success']}</h1></center></div>
            }

            {
                success == 0 && <div><center><h1>{Lang['reset_password_failed']}</h1></center></div>
            }            
  
            { success == -1 &&             
          <div className='content-container' style={{"padding": "0"}}>
          <div className="reg-form">
          <h2 className="reg-title">{Lang.reset_password}</h2>
          <Formik
        initialValues={initValues}
        validationSchema={ResetPasswordSchema}
        onSubmit={onSubmitForm}  
      >
  
  {(props) => {
      //console.log(props.values.dob_year);
      let {values, errors, touched} = props;
      //errors.relation = Lang.required_field;

  
      return(
          <Form>
                     
              <div className="form-group" style={{"margin": "0", "position":"relative"}}>
                  <div className="reg-field">
                      <label htmlFor="password">{Lang.password+'*'}</label>
                      <CustomInput id="password" name="password" type="password" placeholder=" " className="reg-input"/>
                  </div>
                  <span toggle="#password" onClick={()=>showHidePassword('#password')} className="fa fa-fw fa-eye field-icon toggle-password"></span>
              </div>
                     
              <div className="form-group" style={{"margin": "0", "position":"relative"}}>
                  <div className="reg-field">
                      <label htmlFor="password-confirm">{Lang.confirm_password+'*'}</label>
                      <CustomInput id="password-confirm" name="confirm_password" type="password" placeholder=" " className="reg-input"/>
                  </div>
                  <span toggle="#password-confirm" onClick={()=>showHidePassword('#password-confirm')} className="fa fa-fw fa-eye field-icon toggle-password"></span>
              </div>              
     
   
              <LoadingButton loading={isLoading==1 ? 'true':'false'} type="submit" className="submit-button-auto reg-btn" loading-text="<i class='fa fa-circle-o-notch fa-spin'></i> Processing" >{Lang.save_password}</LoadingButton>
      </Form>
            )}}
  </Formik>
  

          </div>
          </div>

        }
          </div>
         )
        : notFound==1 ? <PageNotFound/> :<div style={{marginTop:'100px'}}><center><h1>{Lang.loading}</h1></center></div>
      
        
          
        
      );
      



    
}

export default ResetPassword;