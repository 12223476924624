import { useContext, useEffect, useState } from "react";
import LoadingButton from "../Components/elements/loadingButton";
import { UserContext } from "../context/languageContext";
import Answer from "./answer";

const Question = ({data={},dispatchWizard,values, setParent,questions, fullWidth=false}) => {    
    //console.log(values);
    const language = useContext(UserContext);
    //let selected = [];
    const [changeSize,setChangeSize] = useState(false);

    const handleSubmit = (delay=0) => {

        delay = delay * 1000; // convert to miliseconds
        const isParent = data?.isParent || false;

        let totalSteps = values?.values?.total_steps;
        if(isParent){
            let currentAnswer = questions.questions.find(q => q.id == data.id)?.answers?.find(a => a.selected == true);
            setParent([data.id,currentAnswer.id]);
            
            // console.log(data.id+"-"+currentAnswer.id);
            totalSteps = questions.questions.length + 1;
        }
        setTimeout(() => {
            dispatchWizard({type:'NEXT',data:{values:{questions:questions.questions,total_steps:(totalSteps)}}});            
        }, delay);                  

    }

    const isDisabled = !(values?.values?.questions.find(q => q.id == data.id)?.answers?.some(a => a.selected == true));
    const [disabled,setDisabled] = useState(isDisabled);

    useEffect(()=>{
        if (data.answers.length > 5) {
            setChangeSize(true);
          }
    

          const keyDownHandler = event => {
      
            if (event.key === 'Enter' ) {
              event.preventDefault();
                document.getElementById('submit')?.click();
              //handleSubmit();
            }
          };

          document.addEventListener('keydown', keyDownHandler);

          return () => {
            document.removeEventListener('keydown', keyDownHandler);
          };

    },[])    

    const selectAnswer = (questionId, answerId, setSelected) => {
        // console.log(values?.values?.questions)
        if(questionId && answerId){
            let newValues = values?.values?.questions.map( item => {
                if(item.id == questionId){
                    let selectedAnswers = item.answers.map((answer) =>{
                        if(answer.id == answerId){                            
                            if(isSelected(questionId,answerId)){
                                //console.log(answerId,questionId,'selected before')
                                answer.selected = false;
                                //selected[questionId+'_'+answerId] = false;                                
                            }
                            else{
                                //setSelected(true);
                                answer.selected = true;
                                //selected[questionId+'_'+answerId] = true;
                            }
                        }
                        else{
                            if(data.isMultipleAnswers != true){
                                answer.selected = false;
                                //selected[questionId+'_'+answerId] = false;
                            }                            
                        }


                        return answer;
                    }).filter(ans => ans.selected == true);

                    if(selectedAnswers.length >0){
                        setDisabled(false);
                    }
                    else{
                        setDisabled(true); // no answer has been selected
                    }
                }
                return item;
            })

           // console.log(newValues);
            dispatchWizard({type:'SET_VALUES',data:{values:{...values.values,questions:newValues}}})
        }
        
    }

    const isSelected = (questionId, answerId) => {
        //return selected[questionId+'_'+answerId] === true
        return values?.values?.questions.find( q => q.id == questionId).answers.find(a => a.id== answerId).selected === true;
    }

    return (
        <>
        <div className="survey-container">
        <div className="survey-question-body" style={fullWidth ? {maxWidth:'100%'}:{}}>
        <h3 className={`question-title ${changeSize ? '' : ''}`}>{data.text}
       
        {data.isMultipleAnswers ? <p className="question-title2">{language.multipleAnswers}</p> : ''}</h3>
        <div className="answers-container">
            {
                data?.answers.map( ans => (
                    <Answer changeSize={changeSize} data={ans} question={data} selectAnswer={selectAnswer} submitFunction={handleSubmit} selected={ans?.selected==true ? true:false}/>
                ))
            }
            
        </div>
        {data.isMultipleAnswers && <LoadingButton id={'submit'} disabled={disabled} onClick={handleSubmit} className="submit-button-auto reg-btn" style={{margin:' 2rem auto 2rem'}}>{language.continue}</LoadingButton>}
        </div>
        </div>

        </>
    );
}

export default Question;