import React, {useState, useContext, useEffect, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import {Form, Formik} from 'formik';
import CampaignJourneyStep1Schema from './schema/campaignJourneyStep1Schema';
import HTMLELEMENTS from './elements/elements';
import {getAge} from '../helpers/functions';
import {AuthContext} from '../context/AuthContext'
import { UserContext as language } from '../context/languageContext';
import '../assets/css/forms.css';
import CheckoutForm from './checkoutForm2';
import {campaignJourneyTestMode} from './config/payment'
import Config from './config';
import ApiHelper from "../helpers/ApiHelper";
import {UserContext} from '../context/languageContext'
import PriceCard from './sections/priceCard';
import { useSearchParams } from 'react-router-dom';
import { Tab, Tabs } from 'react-bootstrap';
import PlanItem from './sections/planItemNew';


const {CustomInput, SelectInput, LoadingButton} = HTMLELEMENTS;
var response = null; 

const CampaignJourneyStep2 = (props) => {
    const navigate = useNavigate();
    //const [isLoading, setLoading] = useState(false);
    //const [price, setPrice] = useState(0);
    const Auth = useContext(AuthContext);
    const Lang = useContext(language);  
    //const [val,setVal] = useState("");
    const [selectedPlan, setSelectedPlan] = useState({});
    let promoCodeDetails=Config.promoCodeDetailsApi;
    let [promoDiscount, setpromoDiscount] = useState([]); 
    let [filedPromoCode,setFilledPromoCode]=useState([]);
    const lagKeywords = React.useContext(UserContext);
    const [plans, setPlans] = useState([]);
    const [codes, setCodes] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();
    const [isYearly, setIsYearly] = useState(1);
    const [cardSelected, setCardSelected] = useState(1);
    const currentLanguage = Lang.getSelectedLanguage();
    const setSubFrequency = (ev) => {
      let isChecked = ev.target.checked;
      if(isChecked == true){
        setIsYearly(1);
      }
      else{
        setIsYearly(0);
      }
    }


    useEffect(()=>{
        if(Auth.isLogged){
            navigate('/');
        } 
        const sub_type = searchParams.get('type');
        
        // Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded',}).post('getCampaignPrice',{'testMode':campaignJourneyTestMode,'subType':sub_type}).then(res => {
        //   //res?.data?.payload?.price ? setPrice(res.data.payload.price) : setPrice(0) 
        //   if(res?.data?.status ==1 && res?.data?.payload?.plans?.length > 0){
        //       let selected = res.data.payload.plans.find(item => item.subscription_type==1) || {...res.data.payload.plans[0]};
        //       setSelectedPlan(selected);
        //       setPlans(res.data.payload.plans);
        //   }
        // });
        Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('getPriceCards', {accType:0,numChildren:0}).then(res => {
          setPlans(res.data);
          //setLoading(2);
        });


    },[])
   
    const dataFromPrevStep = props?.values?.values || [];
    //console.log(dataFromPrevStep);
    const CheckPromo=(packageId,currentPrice)=>
    {
      var promoValue=window.$("#promoCode_"+packageId).val();
      //console.log(promoValue);
      var PromoResult=ApiHelper.excuteApi(promoCodeDetails+'packageId='+packageId+'&promoValue='+promoValue,'get','{}').then((res) => {
            //setpromoDiscount(res);
            promoDiscount = res;
            //console.log(res)
  
  
            if(promoDiscount.valid)
            {
              var newPrice = document.getElementById("promoCodeResult_"+packageId).innerHTML=((currentPrice-(currentPrice*promoDiscount.discount)));
              window.$("#promoCodeResult_"+packageId).html('<span style="color:#0ea502; font-size: 13px;">' + lagKeywords.valid_promocode+'<br>' + lagKeywords.price_after_discount +'<strong style="font-size: 16px;">'+ newPrice.toFixed(2) +'$' +'</strong>' + '</span>' );
              //setFilledPromoCode('?prefilled_promo_code='+promoValue);
              setFilledPromoCode(promoValue);
            }
            else
            {
              document.getElementById("promoCodeResult_"+packageId).innerHTML= currentPrice;
              document.getElementById("promoCodeResult_"+packageId).innerHTML= '<span style="color:red; font-size: 13px;text-align:right;direction:rtl;">' + lagKeywords.sorry+'<br>'+lagKeywords.not_valid_promocode+'</span>';
            }
  
      });    
  
  
      return false;
      
    }
  
   


    return(
      <div className="section-container">
        <div className='content-container' style={{"padding": "0"}}>
        <div>
        <h2 className="reg-title" style={{'textAlign':'center','paddingBottom':'3%'}}>{Lang.RegisterNewAccount}</h2>
                 {/* price card */}
      <center className='price_desc' style={{margin:'40px 0', fontSize:'22px', direction:Lang.getSelectedLanguage()=='ar' ?'rtl':'ltr'}}>
      {Lang.plans_desc1}
      <br/>
      {Lang.plans_desc2}
      </center>

    {/* <div className="cards">
      {
        plans.map( (item, index) => {
          let selected = selectedPlan.id == item.id || false;
          return <PriceCard onClick={()=>setSelectedPlan(item)}selected={selected} data={item} CheckPromo={CheckPromo}/>
        })
      }
      
    </div> */}



<div className="cards camp-theme-cards">    

    {/* Cards - plans Swicher */}
    <div className='p-card-swicher'>
    <div className="checkbox-wrapper-64">
      <div>{Lang.quarterly}</div>
      <label className="switch">
        <input checked={isYearly ? true : false} value={1} type="checkbox" onChange={(e)=> setSubFrequency(e)} />
        <span className="slider"></span>
      </label>
      <div>{Lang.yearly}</div>
    </div>
    <div className='p-card-offer'><strong>{Lang.card_offer_30_1} </strong>{Lang.card_offer_30_2}</div>
    </div>

<div className="cards">
      <Tabs
  // defaultActiveKey={1}
  transition={false}
  id="fill-tab-example"
  className="mb-3"
  fill> 
        {plans.payload?.cards?.map( (item) => {



return (
                          

     <Tab eventKey={item.id} title={      
     <div className='p-card-tab'>      
       <div className='p-card-tab-icon' onClick={() => setTimeout(() => {
      //document.getElementsByClassName("p-card-check-container")[0].scrollIntoView();
      //document.getElementsByClassName("p-card-check-container")[1].scrollIntoView();
    }, 200)}><img src={item?.logo} alt={item['name_'+currentLanguage]} /></div>
       <p style={{color:item?.text_color || 'black'}}>{item['name_'+currentLanguage]}</p>
     </div>
     }>      
 
                   <PlanItem 
                     isCardSelected = {cardSelected == item.id}
                     setCardSelected = {setCardSelected}
                     item={item} 
                     setSelectedPlan={setSelectedPlan}
                     keywords={Lang} 
                     //planTypeText={planTypeText} 
                     //subTypeText={subTypeText} 
                     canSub={plans.payload?.can_sub}
                     showLoginMessage={null} 
                     //sendActivationLink={sendActivationLink}
                     CheckPromo = {CheckPromo}
                     hasPromoCode = {false}
                     isLogged = {false }
                     isActiveAccount ={false}
                     showButtons = {false}
                     cardIdSubIn={false}
                     isYearly={isYearly}
                     buttonTitle={Lang.select}
                     //subscriptionType = {item.subscription_type}
                     />
                      </Tab>
   
                   
                 )
             })




        }
            </Tabs>
        </div>







    </div>



            <CheckoutForm selectedPlan={selectedPlan} className="stripe-through-campaign" price={selectedPlan?.price || ''} dataFromPrevStep={dataFromPrevStep} token={dataFromPrevStep.token} dispatchWizard= {props.dispatchWizard} userId={dataFromPrevStep.userId} clientSecret={dataFromPrevStep.clientSecret} basicInfo={{email:dataFromPrevStep.email}} promocodeArea={

<div style={{'margin':'70px 15px 20px'}}>
<div className="" style={{'width':'100%'}}>
  <div className="reg-field">
  <label className="form-control-placeholder promo-label" style={{"fontSize":"13px"}} htmlFor="promo"><i className='fa fa-tag'></i> {Lang.checkPromoCode}</label>
  <input style={{borderStyle: 'solid'}} value={filedPromoCode} onChange={(e)=>setFilledPromoCode(e.target.value)} placeholder=" "   type="text" name={"promoCode_"+selectedPlan.id} id={"promoCode_"+selectedPlan.id}  className="reg-input promo-input"/>
  <button type='button' onClick={()=>CheckPromo(selectedPlan.id,selectedPlan?.price)} className="apply-promo ">{Lang.applyPromo}</button>

  </div>

</div>
<p name={"promoCodeResult_"+selectedPlan.id} id={"promoCodeResult_"+selectedPlan.id}></p>
</div> 


} />
       
        </div>
        </div>
        </div>
    );
}


export default CampaignJourneyStep2;