import './style.css'
const TextLineLoader = (props) => {
    const totalLines = props?.totalLines || 1;
    const paragraphs = [];
    for (var x=0;x<totalLines;x++){
        paragraphs.push(<p key={x}/>);
    }
    return (
            <div className="is-loading">
                <div className="content">
                    {paragraphs}                    
                </div>
            </div>       
    )
}

export default TextLineLoader;