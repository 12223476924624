import { useRef, useState, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import {UserContext as language} from '../../context/languageContext';
import { AuthContext } from '../../context/AuthContext';

const PlanItem = ({hasPromoCode, showButtons ,keywords, item, planTypeText, subTypeText, canSub, showLoginMessage, sendActivationLink, CheckPromo, isLogged, isActiveAccount, subscriptionType}) => {
    const lang = useContext(language);
    const Auth = useContext(AuthContext);
    const langDetect = lang.getSelectedLanguage();
    const [val,setVal] = useState("");
    const Navigate = useNavigate();
    //alert(hasPromoCode)
    if(hasPromoCode == undefined){
      hasPromoCode = true;
    }
    // window.$(".card").click(function(){
    //   if(window.$(this).hasClass("clicked")){
    //     window.$(this).removeClass("clicked");
    //   }
    //   else{
    //     window.$(".clicked").removeClass("clicked");
    //     window.$(this).addClass("clicked");
    //   }
    // })


    // var cards = document.getElementsByClassName('card');

    // for (var i = 0; i < cards.length; i++) {
      
    //   cards[i].addEventListener('click', pickACard);
    // }
    
    // function pickACard(e) {
    //   resetCards();
    //   const hasClass = e.target.classList.contains('card');
    //   if (hasClass) {
    //     // e.target.parentElement.className = 'card picked';
    //     hasClass.classList.add('picked');
    //   } else {
    //     console.log('Event.target does NOT have the specified class');
    //   }
    // }
    
    // function resetCards() {
    //   for (var i = 0; i < cards.length; i++) {
    //     cards[i].className = 'card';
    //   }
    // }

    const handleCheckoutRedirect = (itemId,val) => {

      // if(showLoginMessage){
      //   showLoginMessage();
      // }
      if(Auth.isLogged){
        Navigate('/checkout/'+itemId+'/'+val);
      }
      else{
        localStorage.setItem('forceRedAfterLogin','checkout/'+itemId);
        window.$('#login').modal('show');
      }
      
    }

    return (

        <div key={item.id} className="card" onClick={()=> canSub && showButtons && handleCheckoutRedirect(item.id,val)} >
          {(item.discount > 0 || item.discount_label_en!='')?
          <div className="ribbon">
             { langDetect == 'en' ? (
            <div>
            <span></span>
            <div>{item.discount_label_en}</div>
            </div>
            ) : (
            <div>
            <span></span>
            <div>{item.discount_label_ar}</div>
            </div>
            )}
          </div>
          :''}
        <ul class="card-info" style={{height: 'auto'}}>
          <li className="pack">{keywords[item.name]}</li>
          {/* {subscriptionType} */}
          
          <li className="price bottom-bar price_amount" id={"promoCodeOverwrite_"+item.id}>{'$'+item.price}<p className='price_type'>{(item.subscription_type==1)?(keywords['yearly_price']):(keywords['monthly_price'])}</p></li>
           
          {/* <li className="price bottom-bar" id={"promoCodeOverwrite_"+item.id}>{'$'+item.price}</li> */}
            

          {/* <li className="bottom-bar">{planTypeText?.key ? keywords[planTypeText.key] : keywords['both_plan']}</li> */}
          {/* <li className="bottom-bar">{subTypeText?.key ? keywords[subTypeText.key] : ''}</li> */}
          {/* <li className="bottom-bar">{(item.discount>0)?(keywords.discount+' '+item.discount+'%'):''}</li> */}
          {/* <li className="bottom-bar">{keywords.checkPromoCode}:</li> */}
          {/* <li className="bottom-bar"><input type="input" name={"promoCode_"+item.id} id={"promoCode_"+item.id} /></li> */}

          {
            canSub == 1 &&  showButtons &&
            <>
            {hasPromoCode ==true &&
                <li className="bottom-bar">
                <div className="reg-field">
                <label className="form-control-placeholder promo-label" style={{"fontSize":"13px"}} htmlFor="promo"><i className='fa fa-tag'></i> {keywords.checkPromoCode}</label>
                   
                <input value={val}  placeholder=" "  onChange={e=>setVal(e.target.value)} type="text" name={"promoCode_"+item.id} id={"promoCode_"+item.id}  className="reg-input promo-input" required/>
                <button onClick={()=>CheckPromo(item.id,item.price)} className="apply-promo ">{keywords.applyPromo}</button>

                </div>

                  {/* <promo>
                  <div className="form-group">
                    <input value={val} onChange={e=>setVal(e.target.value)} type="text" name={"promoCode_"+item.id} id={"promoCode_"+item.id}  className="form-control" required/>
                    <label className="form-control-placeholder" htmlFor="promo"><i className='fa fa-tag'></i> {keywords.checkPromoCode}</label>
                    <button onClick={()=>CheckPromo(item.id,item.price)} className="apply-promo ">{keywords.applyPromo}</button>
                  </div>
                  </promo> */}
              </li>
            }
                <p name={"promoCodeResult_"+item.id} id={"promoCodeResult_"+item.id}></p>
                {/* <li><button className="cardBtn"><a href={item.redirect_url+filedPromoCode} target="_blank">{keywords.subscribe}</a></button></li> */}


              <li><button className="cardBtn">
                {
                  !isLogged
                  ?
                  <div onClick={()=>  handleCheckoutRedirect(item.id,val)}>{keywords.subscribe}</div>
                  : 
                  (/*
                  onClick={sendActivationLink}
                  */
                  isActiveAccount ==0 ?
                  <NavLink >{keywords.subscribe}</NavLink>
                  :
                  <div  onClick={()=>  handleCheckoutRedirect(item.id,val)}>{keywords.subscribe}</div>
                  )

                }
                </button></li> 

                </>                  

          }
            
        </ul>
      </div>        

    )
}

export default PlanItem;