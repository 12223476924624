import Step1 from './msisdnRegisterStep1'
import Step2 from './msisdnRegisterStep2'
import Step3 from './msisdnRegisterStep3'
import {Wizard, WizardStep} from './elements/wizard/wizard';
import { useState } from 'react';
import MessageModal from './modals/messageModal';

const MsisdnRegisterWizard = (props) => {

    const [messageDetails,setMessageDetails] = useState({title:'',message:''});
    const showMessage = (title='', message='') => {
        setMessageDetails({title,message});
        window.$('#otp-message').modal('show');
    }
    return (
        <>
        
            <div className="prices-section">
                {
                    <Wizard>
                        <WizardStep showMessage={showMessage} step={1} > <Step1 reset={props.reset}/> </WizardStep>
                        <WizardStep showMessage={showMessage} step={2} > <Step2 reset={props.reset}/> </WizardStep>
                        <WizardStep showMessage step={3} > <Step3/> </WizardStep>              
                    </Wizard>
                }   
                <div id='recaptcha-container'></div>  
            </div>
                
            <MessageModal title={messageDetails.title} id="otp-message" okLabel="Close">
            {messageDetails.message}
            </MessageModal>                  
        </>
    )
}

export default MsisdnRegisterWizard