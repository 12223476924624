import React, { memo } from "react";
import '../assets/css/footer.css';
import facebookIcon from '../assets/images/fb_icon.svg' ;
import twitterIcon from '../assets/images/twitter_icon.svg' ;
import linkedinIcon from '../assets/images/linkedin_icon.svg' ;
import tiktokIcon from '../assets/images/tiktok_icon.svg' ;
import youtubeIcon from '../assets/images/youtube_icon.svg' ;
import instagramIcon from '../assets/images/instagram_icon.svg' ;
import beecellIcon from '../assets/images/beecell_icon.svg' ;
import { UserContext as language } from '../context/languageContext';
import { NavLink } from "react-router-dom";
import {useState, useContext, useEffect} from 'react';
import InfiniteScroll from "./elements/infiniteScroll";
import uuid from "react-uuid";
import ApiHelper from "../helpers/ApiHelper";
import config from './config.js';
import {AuthContext} from '../context/AuthContext'

const Footer=()=>
{
    const Lang = useContext(language);
    const [sponsors, setSponsors] = useState([]);
    const today = new Date();
    const year = today.getFullYear();    
    const Auth = React.useContext(AuthContext); 

    useEffect(()=>{
        ApiHelper.excuteApi(config.serverDomain+'api/getSponsors?','get',[],setSponsors);

        let $ = window.$
        $(document).ready(function(){

            $("body").on("keyup", "input[name='mobile']", function (event) {
                //alert(this.value[0])
                if ($(this).val()[0]==0) $(this).val($(this).val().substring(1)); // minimum is 1
                else if ($(this).val()[0]=== undefined) $(this).val('');
                else $(this).val(Math.floor($(this).val())); // only integers allowed
            })            

        })

    },[])

    window.$(document).ready(function() {

        window.$(".sc-eDWCr.gvCYGp.rsc-header-close-button").attr('href','#');
        window.$(".sc-bqWxrE.gWiOKS.rsc-float-button").attr('href','#');

        
    })


    return(

        <footer className="footer">
        <div className="footer-sections">
          <div className="footer-section-1">
            <ul>
               <li><NavLink to="/Faqs">{Lang.faqs}</NavLink></li>
               <li><NavLink to="/about">{Lang.about}</NavLink></li>
               <li><NavLink to="/ContactUs">{Lang.contact_us}</NavLink></li>
               <li><NavLink to="/blogs">{Lang.blogs}</NavLink></li>
            </ul>
          </div>
          <div className="footer-section-2">
            <ul>
               <li><NavLink to="/PrivacyPolicy">{Lang.privacy_policy}</NavLink></li>
               <li><NavLink to="/TermsConditions">{Lang.terms_condition}</NavLink></li>
               <li><NavLink to="/join-our-team" style={{color:'#F39422'}}>{Lang.join_our_team}</NavLink></li>
           </ul>
          </div>
          <div className="footer-section-3">
          {Auth.isLogged === false ? 
       <div className="kids_safe_footer hide">
       
       </div> 
       :
        <div className="kids_safe_footer">
        <a href="https://www.kidsafeseal.com/certifiedproducts/masterhobbies.html" target="_blank">
            <img border="0" alt="Masterhobbies.online is certified by the kidSAFE Seal Program." src="https://www.kidsafeseal.com/sealimage/11558462481276463140/masterhobbies_medium_darktm.png"/>
        </a>
        </div>}
     </div>
          <div className="footer-section-4">
                 <p>{Lang.powered_by_beecell}</p>
                 {/* <a href="#"> */}
                   <img src={beecellIcon} alt={Lang.beecell} width="100" height="50" />
               {/* </a> */}
          </div>
          <div className="footer-section-5" style={{color:'#99999999'}}>
             Copyright© Masterhobbies {year}
          </div>
          <div className="footer-section-6">
            <a href="https://www.facebook.com/people/Master-Hobbies/61550748707893/" target="_blank"><img src={facebookIcon} alt={Lang.mawaheb_facebook} width="20" height="20" /></a>
            <a href="https://www.instagram.com/masterhobbies.online/" target="_blank"><img src={instagramIcon} alt={Lang.mawaheb_instagram} width="20" height="20" /></a>
            <a href="https://twitter.com/Master_Hobbies1" target="_blank"><img src={twitterIcon} alt={Lang.mawaheb_twitter} width="20" height="20" /></a>
            <a href="https://linkedin.com/company/masterhobbies" target="_blank"><img src={linkedinIcon} alt={Lang.mawaheb_linkedin} width="20" height="20" /></a>
            <a href="https://www.youtube.com/@MasterHobbies.online" target="_blank"><img src={youtubeIcon} alt={Lang.mawaheb_youtube} width="20" height="20" /></a>
            <a href="https://www.tiktok.com/@master.hobbies?_t=8fZbEd3DMuu&_r=1" target="_blank"><img src={tiktokIcon} alt={Lang.mawaheb_tiktok} width="20" height="20" /></a>
          </div>
        </div>
        <section className="footer_2 hide">
           <div className="text-side">
             <p>2 MEMBERSHIPS FOR THE <span>PRICE OF 1</span></p>
             <p>STARTING AT 10/MONTH (BILLED ANNUALLY)</p>
           </div>
           <div className="btn-side">
               <button className="footer2-btn">Start Now</button>
           </div>
        </section>
      </footer>         
    )
}
export default memo(Footer)