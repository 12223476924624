import type {PlayerAPI, PlayerEventBase, SeekEvent, UserInteractionEvent} from 'bitmovin-player';

import {Bitmovin8AnalyticsStateMachine} from '../../analyticsStateMachines/Bitmovin8AnalyticsStateMachine';
import {Analytics} from '../../core/Analytics';
import {DownloadSpeedMeter} from '../../core/DownloadSpeedMeter';
import {SegmentTracker} from '../../core/SegmentTracker';
import VideoCompletionTracker from '../../core/VideoCompletionTracker';
import {CastTech} from '../../enums/CastTech';
import {ErrorCode} from '../../enums/ErrorCode';
import {Event} from '../../enums/Event';
import {Player} from '../../enums/Player';
import {PlayerSize} from '../../enums/PlayerSize';
import {Feature} from '../../features/Feature';
import {FeatureConfig} from '../../features/FeatureConfig';
import {ErrorDetailBackend} from '../../features/errordetails/ErrorDetailBackend';
import {ErrorDetailTracking} from '../../features/errordetails/ErrorDetailTracking';
import {HttpRequestTracking} from '../../features/httprequesttracking/HttpRequestTracking';
import {AnalyticsConfig} from '../../types/AnalyticsConfig';
import {AnalyticsStateMachineOptions} from '../../types/AnalyticsStateMachineOptions';
import {DownloadSpeedInfo} from '../../types/DownloadSpeedInfo';
import {DrmPerformanceInfo} from '../../types/DrmPerformanceInfo';
import {FeatureConfigContainer} from '../../types/FeatureConfigContainer';
import {PlaybackInfo, normalizeVideoDuration} from '../../types/PlaybackInfo';
import {SegmentInfo} from '../../types/SegmentInfo';
import {getSourceInfoFromBitmovinSourceConfig} from '../../utils/BitmovinProgressiveSourceHelper';
import {logger} from '../../utils/Logger';
import * as AnalyticsSettings from '../../utils/Settings';

import {Bitmovin8HttpRequestTrackingAdapter} from './Bitmovin8HttpRequestTrackingAdapter';
import {Bitmovin8SegmentTrackerAdapter} from './Bitmovin8SegmentTrackerAdapter';
import {Bitmovin8SpeedMeterAdapter} from './Bitmovin8SpeedMeterAdapter';
import {InternalAdapter} from './InternalAdapter';
import {InternalAdapterAPI} from './InternalAdapterAPI';
import {AdModuleAPI} from './ads/AdModuleAPI';
import {Bitmovin8AdModule} from './ads/Bitmovin8AdModule';

enum ViewMode {
  Inline = 'inline',
  Fullscreen = 'fullscreen',
  PictureInPicture = 'pictureinpicture',
}

export class Bitmovin8InternalAdapter extends InternalAdapter implements InternalAdapterAPI {
  override get downloadSpeedInfo(): DownloadSpeedInfo {
    return this.speedMeter.getInfo();
  }

  override get segments(): SegmentInfo[] {
    return this.segmentTracker.getSegments();
  }

  get adModule(): AdModuleAPI | undefined {
    return this._adModule;
  }

  get supportsDeferredLicenseLoading(): boolean {
    return true;
  }
  readonly videoCompletionTracker: VideoCompletionTracker;

  private onBeforeUnLoadEvent = false;
  private speedMeter: DownloadSpeedMeter;
  private segmentTracker: SegmentTracker;
  private _adModule?: AdModuleAPI;
  private lastTrackedCurrentTime: number | undefined;
  private videoDuration: number | undefined;
  private httpRequestTrackingAdapter: Bitmovin8HttpRequestTrackingAdapter;

  constructor(
    private player: PlayerAPI,
    opts?: AnalyticsStateMachineOptions,
  ) {
    super(opts);
    this.stateMachine = new Bitmovin8AnalyticsStateMachine(this.stateMachineCallbacks, this.opts);
    this.speedMeter = new Bitmovin8SpeedMeterAdapter(player, new DownloadSpeedMeter()).getDownloadSpeedMeter();
    this.segmentTracker = new Bitmovin8SegmentTrackerAdapter(player, new SegmentTracker()).getSegmentTracker();
    this.httpRequestTrackingAdapter = new Bitmovin8HttpRequestTrackingAdapter(player);
    this.videoCompletionTracker = new VideoCompletionTracker();
  }
  protected get currentTime(): number {
    try {
      // during ad this.player.getCurrentTime() returns time of ad and not video
      // this then messes with videotime_start and videotime_end measurements of video
      // we don't track videotimes for ads
      if (this.player !== null && this.player.getCurrentTime !== null && !this.isAdPlaying) {
        return this.player.getCurrentTime();
      }
    } catch {
      logger.warning('Analytics Collector attempted to access player, but player is not available anymore');
    }
    return this.lastTrackedCurrentTime ? this.lastTrackedCurrentTime : 0;
  }

  protected get isAdPlaying(): boolean {
    const currentAd = this.player.ads && this.player.ads.getActiveAd();
    return currentAd !== null && currentAd !== undefined;
  }

  initialize(analytics: Analytics): Array<Feature<FeatureConfigContainer, FeatureConfig>> {
    this.registerPlayerEventListeners();
    this.registerUnloadEventListeners();

    this._adModule = new Bitmovin8AdModule(this.player, this.windowEventTracker);
    const requestTracking = new HttpRequestTracking([this.httpRequestTrackingAdapter]);
    const errorDetailTracking = new ErrorDetailTracking(
      analytics.errorDetailTrackingSettingsProvider,
      new ErrorDetailBackend(analytics.errorDetailTrackingSettingsProvider.collectorConfig),
      [analytics.errorDetailSubscribable],
      requestTracking,
    );
    return [errorDetailTracking];
  }

  override clearValues(): void {
    this.speedMeter.reset();
  }

  override clearSegments(): void {
    this.segmentTracker.reset();
  }

  getPlayerVersion = () => this.player.version;
  getPlayerName = () => Player.BITMOVIN;
  getPlayerTech = () => this.player.getPlayerType();
  getAutoPlay(): boolean {
    if (this.player.getConfig().playback) {
      return (this.player.getConfig() as any).playback.autoplay || false;
    }
    return false;
  }
  getDrmPerformanceInfo = (): DrmPerformanceInfo | undefined => this.drmPerformanceInfo;

  getCurrentPlaybackInfo(): PlaybackInfo {
    const info: PlaybackInfo = {
      ...super.getCommonPlaybackInfo(),
      size: this.player.getViewMode() === ViewMode.Fullscreen ? PlayerSize.Fullscreen : PlayerSize.Window,
      playerTech: this.getPlayerTech(),
      isLive: this.player.isLive(),
      videoDuration: normalizeVideoDuration(this.videoDuration),
      streamFormat: this.player.getStreamType(),
      videoWindowWidth: this.player.getContainer().offsetWidth,
      videoWindowHeight: this.player.getContainer().offsetHeight,
      isMuted: this.player.isMuted(),
      isCasting: this.player.isCasting(),
      audioLanguage: this.player.getAudio() != null ? this.player.getAudio()?.lang : undefined,
      subtitleEnabled: false,
      droppedFrames: this.player.getSource() != null ? this.player.getDroppedVideoFrames() : 0,
    };

    this.applySubtitleProperties(info);
    this.applyPlaybackQualityProperties(info);
    this.applyCastingProperties(info);
    this.applySourceProperties(info);

    return info;
  }

  sourceChange(config: AnalyticsConfig, timestamp: number) {
    this.stateMachine.sourceChange(config, timestamp, this.currentTime);
  }

  onError(): void {
    this.clearSegments();
  }

  private registerPlayerEventListeners() {
    this.player.on(this.player.exports.PlayerEvent.SourceUnloaded, (_event: any) => {
      this.segmentTracker.reset();
      this.eventCallback(Event.SOURCE_UNLOADED, {
        currentTime: this.currentTime,
      });
    });

    this.player.on(this.player.exports.PlayerEvent.SourceLoaded, (_event: any) => {
      this.videoCompletionTracker.reset();
      this.resetSourceRelatedState();
      this.videoDuration = this.player.getDuration();
      this.videoCompletionTracker.setVideoDuration(this.player.getDuration());
      this.eventCallback(Event.SOURCE_LOADED, {});
    });

    this.player.on(this.player.exports.PlayerEvent.CastStarted, (event: any) => {
      this.eventCallback(Event.START_CAST, event);
    });

    this.player.on(this.player.exports.PlayerEvent.CastStopped, () => {
      this.eventCallback(Event.END_CAST, {
        currentTime: this.currentTime,
      });
    });

    this.player.on(this.player.exports.PlayerEvent.Play, (e: UserInteractionEvent) => {
      if (e.issuer !== 'ui-seek') {
        this.eventCallback(Event.PLAY, {
          currentTime: this.currentTime,
        });
      }
    });

    this.player.on(this.player.exports.PlayerEvent.Playing, (e: UserInteractionEvent) => {
      if (e.issuer !== 'advertising-api') {
        this.eventCallback(Event.PLAYING, {
          currentTime: this.currentTime,
        });
      }
    });

    this.player.on(this.player.exports.PlayerEvent.Paused, (e: UserInteractionEvent) => {
      if (e.issuer !== 'ui-seek') {
        this.eventCallback(Event.PAUSE, {
          currentTime: this.currentTime,
        });
      }
    });

    this.player.on(this.player.exports.PlayerEvent.TimeChanged, (_event: PlayerEventBase) => {
      if (!this.isAdPlaying) {
        this.lastTrackedCurrentTime = this.player.getCurrentTime();
      }
      this.eventCallback(Event.TIMECHANGED, {
        currentTime: this.lastTrackedCurrentTime,
      });
    });

    this.player.on(this.player.exports.PlayerEvent.Seek, (e: UserInteractionEvent) => {
      if (this.allowSeeking(e)) {
        this.eventCallback(Event.SEEK, {
          currentTime: this.currentTime,
        });
      }
    });

    this.player.on(this.player.exports.PlayerEvent.Seeked, () => {
      this.eventCallback(Event.SEEKED, {
        currentTime: this.currentTime,
      });
    });

    this.player.on(this.player.exports.PlayerEvent.StallStarted, () => {
      this.eventCallback(Event.START_BUFFERING, {
        currentTime: this.currentTime,
      });
    });

    this.player.on(this.player.exports.PlayerEvent.StallEnded, () => {
      this.eventCallback(Event.END_BUFFERING, {
        currentTime: this.currentTime,
      });
    });

    this.player.on(this.player.exports.PlayerEvent.AudioPlaybackQualityChanged, () => {
      const quality = this.player.getPlaybackAudioData();

      if (this.qualityChangeService.shouldAllowAudioQualityChange(quality.bitrate)) {
        this.qualityChangeService.setAudioBitrate(quality.bitrate);
        this.eventCallback(Event.AUDIO_CHANGE, {
          bitrate: quality.bitrate,
          currentTime: this.currentTime,
          codec: (quality as any).codec,
        });
      }
    });

    this.player.on(this.player.exports.PlayerEvent.VideoPlaybackQualityChanged, () => {
      const quality = this.player.getPlaybackVideoData();

      if (this.qualityChangeService.shouldAllowVideoQualityChange(quality.bitrate)) {
        this.qualityChangeService.setVideoBitrate(quality.bitrate);
        this.eventCallback(Event.VIDEO_CHANGE, {
          width: quality.width,
          height: quality.height,
          bitrate: quality.bitrate,
          currentTime: this.currentTime,
          codec: (quality as any).codec,
        });
      }
    });

    this.player.on(this.player.exports.PlayerEvent.ViewModeChanged, (e: any) => {
      if (e.to === 'fullscreen') {
        this.eventCallback(Event.START_FULLSCREEN, {
          currentTime: this.currentTime,
        });
      } else if (e.from === 'fullscreen') {
        this.eventCallback(Event.END_FULLSCREEN, {
          currentTime: this.currentTime,
        });
      }
    });

    this.player.on(this.player.exports.PlayerEvent.AdStarted, (_event: any) => {
      this.eventCallback(Event.START_AD, {
        currentTime: this.currentTime,
      });
    });

    this.player.on(this.player.exports.PlayerEvent.AdFinished, (_event: any) => {
      this.eventCallback(Event.END_AD, {
        currentTime: this.currentTime,
      });
    });

    this.player.on(this.player.exports.PlayerEvent.AdSkipped, (_event: any) => {
      this.eventCallback(Event.END_AD, {
        currentTime: this.currentTime,
      });
    });

    this.player.on(this.player.exports.PlayerEvent.AdError, (_event: any) => {
      this.eventCallback(Event.END_AD, {
        currentTime: this.currentTime,
      });
    });

    this.player.on(this.player.exports.PlayerEvent.Muted, () => {
      this.eventCallback(Event.MUTE, {
        currentTime: this.currentTime,
      });
    });

    this.player.on(this.player.exports.PlayerEvent.Unmuted, () => {
      this.eventCallback(Event.UN_MUTE, {
        currentTime: this.currentTime,
      });
    });

    this.player.on(this.player.exports.PlayerEvent.Error, (event: any) => {
      this.eventCallback(Event.ERROR, {
        code: event.code,
        message: event.name,
        legacyData: event.data,
        currentTime: this.currentTime,
        data: {
          additionalData: JSON.stringify(event.data),
        },
      });
      if (
        event.code === ErrorCode.BITMOVIN_PLAYER_LICENSING_ERROR.code ||
        event.code === ErrorCode.SETUP_MISSING_LICENSE_WHITELIST.code
      ) {
        this._onLicenseCallFailed.dispatch({});
      }
    });

    this.player.on(this.player.exports.PlayerEvent.PlaybackFinished, () => {
      this.eventCallback(Event.END, {currentTime: this.currentTime});
    });

    this.player.on(this.player.exports.PlayerEvent.DownloadFinished, (event: any) => {
      if (event.downloadType.indexOf('drm/license/') === 0) {
        this.drmPerformanceInfo = {
          drmType: event.downloadType.replace('drm/license/', ''),
          drmLoadTime: event.downloadTime * 1000,
        };
      }
    });

    this.player.on(this.player.exports.PlayerEvent.AudioChanged, (_event: any) => {
      this.eventCallback(Event.AUDIOTRACK_CHANGED, {
        currentTime: this.currentTime,
      });
    });

    this.player.on(this.player.exports.PlayerEvent.SubtitleEnabled, (_event: any) => {
      this.eventCallback(Event.SUBTITLE_CHANGE, {
        currentTime: this.currentTime,
      });
    });

    this.player.on(this.player.exports.PlayerEvent.SubtitleDisabled, (_event: any) => {
      this.eventCallback(Event.SUBTITLE_CHANGE, {
        currentTime: this.currentTime,
      });
    });

    this.player.on(this.player.exports.PlayerEvent.LicenseValidated, (event: any) => {
      if (event.data.analytics && event.data.analytics.key !== undefined) {
        this._onLicenseKeyReceived.dispatch({licenseKey: event.data.analytics.key});
      } else {
        this._onLicenseCallFailed.dispatch({});
      }
    });
  }

  private registerUnloadEventListeners() {
    let handleCollectorUnload = () => {
      if (!this.onBeforeUnLoadEvent) {
        this.onBeforeUnLoadEvent = true;
        // we use here the last tracked video time as we cannot be sure if the player is still working at this place
        const currentTime = this.lastTrackedCurrentTime;
        this.eventCallback(Event.UNLOAD, {currentTime});
      }
      this.release();
    };
    handleCollectorUnload = handleCollectorUnload.bind(true);

    this.player.on(this.player.exports.PlayerEvent.Destroy, handleCollectorUnload);
    this.windowEventTracker.addEventListener('beforeunload', handleCollectorUnload);
    this.windowEventTracker.addEventListener('unload', handleCollectorUnload);
  }

  private applySourceProperties(info: PlaybackInfo) {
    const source = this.player.getSource();
    if (source == null) {
      return;
    }

    info.videoTitle = source.title;
    info.mpdUrl = source.dash;
    info.m3u8Url = source.hls;

    const progSourceInfo = getSourceInfoFromBitmovinSourceConfig(source.progressive, this.player);
    info.progUrl = progSourceInfo.progUrl;
    if (this.player.getStreamType() === 'progressive') {
      info.videoBitrate = progSourceInfo.progBitrate;
    }
  }

  private applySubtitleProperties(info: PlaybackInfo) {
    let enabledSubtitle;
    if (this.player.subtitles) {
      enabledSubtitle = this.player.subtitles.list().find((s) => s.enabled === true);
    }

    info.subtitleEnabled = enabledSubtitle != null;
    info.subtitleLanguage = enabledSubtitle != null ? enabledSubtitle.lang : null;
  }

  private applyPlaybackQualityProperties(info: PlaybackInfo) {
    const videoQuality = this.player.getPlaybackVideoData();
    if (videoQuality !== null) {
      info.videoBitrate = videoQuality.bitrate;
      info.videoPlaybackHeight = videoQuality.height;
      info.videoPlaybackWidth = videoQuality.width;
      info.videoCodec = (videoQuality as any).codec;
    }

    const audioQuality = this.player.getPlaybackAudioData();
    if (audioQuality !== null) {
      info.audioBitrate = audioQuality.bitrate;
      info.audioCodec = (audioQuality as any).codec;
    }
  }

  private applyCastingProperties(info: PlaybackInfo) {
    const isCasting = this.player.isCasting() || this.player.isAirplayActive() || false;
    info.isCasting = isCasting;
    if (!isCasting) {
      return;
    }

    // AirPlay
    if (this.player.isAirplayActive()) {
      info.castTech = CastTech.AirPlay;
      return;
    }

    // Casting Tech from RemoteControlConfig
    const remoteControlConfig = this.player.getConfig().remotecontrol;
    if (remoteControlConfig === null || remoteControlConfig === undefined) {
      return;
    }

    switch (remoteControlConfig.type) {
      case 'googlecast':
        info.castTech = CastTech.GoogleCast;
        break;
      case 'websocket':
        info.castTech = CastTech.WebSocket;
        break;
    }
  }

  // with this check we ignore seek events which are smaller than the Threshold
  // mainly to ignore keyboard triggered seeks
  private allowSeeking(e: UserInteractionEvent): boolean {
    const seekEvent = e as SeekEvent;
    const seekDifference = Math.abs(seekEvent.position - seekEvent.seekTarget);
    return seekDifference > AnalyticsSettings.ANALYTICS_MIN_SEEK_DIFFERENCE_THRESHOLD;
  }
}
