import React from 'react';
import { useEffect, useState, useContext, useRef } from "react";
import {UserContext} from '../../context/languageContext'
import {loadCaptionFiles} from "../../helpers/functions"
// import { AzureMP } from 'react-azure-mp'
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import config from '../config';
import BitmovinPlayer from './bitmovinPlayer';
import CustomVideoPlayer from './CustomVideoPlayer/CustomVideoPlayer';
const VideoPlayerGeneral = (props) => {
    const lang = React.useContext(UserContext);
    const isCompleted = props?.isCompleted || false;
    const autoplay = props?.autoplay == true || false;
    const moduleId = props?.moduleId || 0;
    const courseId = props?.courseId || 0;
    const markAsCompleted = props?.markAsCompleted || (()=>'');   
    const [currentTime, setCurrentTime] = useState(null);
    const videoEl = useRef(null);
    const signsVideoEl = useRef(null);
    const captionFilesEn = loadCaptionFiles(props.url,'en');
    const captionFilesAr = loadCaptionFiles(props.url,'ar');  
    const signsVideoSrc = props.signsVideoSrc;
    const timeoutIdRef = useRef();
    let currentLang = lang.getSelectedLanguage();

    const {id, courseId:courseIdFromParam, courseName, videoLang} = useParams([]);
    if(videoLang && videoLang !== undefined){
      currentLang = videoLang;
    }
    const navigate = useNavigate();
  
    //console.log(actionTaken)
      useEffect(() => {
        if (videoEl) {

        }
    
        return () => {};
      }, [props.url]);

    const handleLoadedMetadata = () => {
        //console.log(videoEl)
        const video = videoEl.current;
        const signsVideo = signsVideoEl.current;
        if (!video) return;
        //console.log(`The video is ${video.duration} seconds long.`);


        video.ontimeupdate = () => {
            const duration = video.duration
            const current = video.currentTime
            const fixedPercantage = 0.8;
            if(current/duration >= fixedPercantage){
                markAsCompleted(moduleId,courseId);
            }
        }

        video.onended = () => {
            props.playNext();
        }

        if(signsVideoSrc){

                video.onplay = () => {
                    signsVideo.play();
                //  alert(6);
                }
                video.onpause = () =>{
                    signsVideo.pause();
                //  alert(7);
                }
        }
        
        
      };
      const onProgress = (event,player) => {
        //

        const durationV = player.cache_.duration
        const currentV = player.currentTime()  
        onProgressShared(currentV, durationV);
        
      }

      const onProgressShared = (currentTime, duration) => {
          //console.log(currentTime)
          //console.log(duration)             
          const fixedPercantage = 0.75;
          const maxfixedPercantage = 0.87;
          if((currentTime/duration) >= fixedPercantage && (currentTime/duration) <= maxfixedPercantage){
                  //console.log(" mark course as completed "+moduleId+" 0000 "+courseId);
                  markAsCompleted(moduleId,courseId);
          }
      }

      const onEnd = () => {
        //
        //console.log("ended")
        //console.log(" mark course as completed "+moduleId+" 0000 "+courseId);
        markAsCompleted(moduleId,courseId);
        props.playNext();
        
      }      
      const onInstanceCreated = (player) => {
        if (player) {
          //{ console.log(player.cache_)}
           player.on("progress", event => onProgress(event, player));
           player.on("ended", onEnd);
        
        }
      };
      var urlsSpliter=props.url
      urlsSpliter=urlsSpliter.split('&token=');
      urlsSpliter=urlsSpliter[0]
      console.log("my file"+props.url)
    return (
        
        <>
            <div className="module-contents" style={{maxWidth: '580px', margin: '0 auto',borderRadius: '15px',overflow: 'hidden'}}>

                { signsVideoSrc &&
                <video ref={signsVideoEl} class="signs-video"  muted>
                {/* <source src={`https://www.w3schools.com/html/mov_bbb.mp4`} type="video/mp4"  /> */}
                <source src={signsVideoSrc} type="video/mp4"  />
                </video>
                }
                {/* <BitmovinPlayer defaultQuality={720} onFinish={onEnd} onProgress={onProgressShared} className="landing-video" src={urlsSpliter} poster={props?.poster || config.imgDns+"icon/intro_poster.png"}/> */}
                <CustomVideoPlayer  src={urlsSpliter} showTranscript={false} poster={props?.poster || config.imgDns+"icon/intro_poster.png"}/>
                {/* {
                  config?.useBitmovinPlayer ? 
                    <BitmovinPlayer onFinish={onEnd} onProgress={onProgressShared} className="landing-video" src={urlsSpliter} poster={props?.poster || config.imgDns+"icon/intro_poster.png"}/>
                  :
                    <AzureMP  onInstanceCreated={onInstanceCreated} options={{controls: true, autoplay: false, hotKeys: {enableVolumeScroll: false}}} src={[{src: props.url, type: "application/vnd.ms-sstr+xml" }]}></AzureMP>
                } */}

            </div>
        </>
    )
}


export default VideoPlayerGeneral