import { useContext, useState } from "react";
import {Form, Formik} from 'formik';
import JoinUsSchema from '../Components/schema/joinUsFormSchema';
import { UserContext } from "../context/languageContext";
import Elements from './elements/elements';
import config from './config';
import { AuthContext } from "../context/AuthContext";
import MessageModal from "./modals/messageModal";
const {CustomInput, SelectInput, LoadingButton, CustomTextarea} = Elements;
const JoinOurTeam = () => {

    const Auth = useContext(AuthContext);
    const lagKeywords = useContext(UserContext);
    const [isLoading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    const showMessage = (message='') => {
        setMessage(message);
        window.$('#general-message').modal('show');
    }    
    

    const initValues = {
        name:  "", 
        email: "", 
        mobile:"", 
        mobile_prefix:'+962', 
        course_title: "",         
        social_link:""
    };
    

    const onSubmitForm = async(values, actions) => {
        setLoading(true);
        const response = await Auth.saveJoinOurTeam(values)
        setLoading(false);
        //console.log(response)
        let msg = "";
        if(response == 1){
            actions.resetForm();
            msg = lagKeywords.joinus_success;
        }
        else{
            msg=lagKeywords.joinus_failed;
        }
        showMessage(msg);
    }
    // window.$(".reg-field , .reg-field-tel").on("click", function(e) {
    //     //  window.$(this).find('label').addClass('non-empty');
    //       if (window.$(this).find('.reg-input').val() != " ") {
    //           window.$(this).find('label').addClass('non-empty');
    //       }else{
    //           window.$(this).find('label').removeClass('non-empty');
    //       }
    //     });
    //     window.$(document).ready(function(){
    //       window.$( ".reg-field , .reg-field-tel" ).each(function() {
    
    //     var value = window.$(this).find('.reg-input').val();
    //     var length_ = value.length;        
     
    //     if(length_ > 0){
    //       window.$(this).find('label').addClass('non-empty');
    //     }else if(length_ == 0){
    //       window.$(this).find('label').removeClass('non-empty');
  
    //      }
    //   });
    // });
    //   window.$(".reg-field , .reg-field-tel").on('blur', function(e) {
    //      // window.$('.reg-field').find('label').removeClass('non-empty');
    //      if (window.$(this).find('.reg-input').val() != " ") {
    //       window.$(this).find('label').addClass('non-empty');
    //   }else{
    //       window.$(this).find('label').removeClass('non-empty');
    //   }
    //   });
  
    return (
        <>
        <div className='contact-us-container'>
    
            <div className="contact-us-intro">
            <div className="contact-us-text">
            <h1>{lagKeywords.join_our_team}</h1>
            {/* <p>{lagKeywords.join_our_team_par1} <br/></p> */}
            <p>{lagKeywords.join_our_team_par2} <br/></p>
            </div>
    
            </div> 


            <div className="contact-us-lower-container" style={{padding:'50px 0 0 0'}}>
            <div className="white-area">            
            <div className="contact-us-form">
 


            <Formik
            enableReinitialize
            initialValues={initValues}
            validationSchema={JoinUsSchema}
            onSubmit={onSubmitForm}  
            >

            {(props) => {
                let {values, errors, touched, handleChange, setFieldValue, setValues} = props;

            return(    
                <Form>

                    <div className="form-group" style={{"margin": "0"}}>
                        <div className="reg-field">
                            <label htmlFor="name">{lagKeywords.enter_name+'*'}</label>
                            <CustomInput name="name" id="name" type="text" placeholder=" " className="reg-input" />
                        </div>
                    </div>

                    <div className="form-group" style={{"margin": "0"}}>
                        <div className="reg-field joinus-input">
                            <label htmlFor="email">{lagKeywords.enter_email+'*'}</label>
                            <CustomInput name="email" id="email" type="text" placeholder=" " className="reg-input" />
                        </div>
                    </div>

                    <div className="form-group" style={{"margin": "0", position: 'relative'}}>
                        <SelectInput className="form-select" id="mobile_prefix" name="mobile_prefix" >
                            {
                                config.countries.map( item => {
                                    return (
                                        <option key={item.prefix.id} value={item.prefix.id}>{item.prefix.name}</option>
                                    )
                                })
                            }                    
                        </SelectInput>
                        <div className="reg-field-tel joinus-field">
                            <label htmlFor="mobile" className="joinus-field-mob placeholder-mob">{lagKeywords.mobile_number}</label>
                            <CustomInput id="mobile" name="mobile" type="tel" placeholder=" " className="reg-input" />
                        </div>
                    </div> 

                    <div className="form-group" style={{"margin": "0"}}>
                        <div className="reg-field">
                            <label htmlFor="course_title">{lagKeywords.course_idea+'*'}</label>
                            <CustomTextarea name="course_title" id="course_title" placeholder=" " className="reg-input joinus-textarea" />
                        </div>
                    </div>

                    <div className="form-group" style={{"margin": "0"}}>
                        <div className="reg-field">
                            <label htmlFor="social_link">{lagKeywords.social_link}</label>
                            <CustomInput name="social_link" id="social_link" type="text" placeholder=" " className="reg-input" />
                        </div>
                    </div>                                                           

                    <LoadingButton loading={isLoading.toString()} type="submit" className="submit-button-auto reg-btn" loading-text="<i class='fa fa-circle-o-notch fa-spin'></i> Processing" >{lagKeywords.join}</LoadingButton>


                </Form>
            )
            }}
            </Formik>
            </div>
            </div>
            </div>
    
        </div>
        <MessageModal title={''} id="general-message" okLabel="Close">
            {message}
        </MessageModal> 
        </>        
    )
}

export default JoinOurTeam;