import Config from '../../Components/config';
import { useContext, useEffect, useState } from "react";
import { UserContext } from '../../context/languageContext';
import ApiHelper from '../../helpers/ApiHelper';
import {NavLink} from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import '../../assets/css/courses.css'
import AllCourses from './allCourses'
import LatestCourses from './latestCourses'

const Courses = () => {

    let [coursesList, setCourses] = useState([]); 
    if(typeof(coursesList) == 'string'){
        coursesList = JSON.parse(coursesList);
    }    
    const lagKeywords = useContext(UserContext);
    useEffect(()=>{
        //ApiHelper.excuteApi(Config.coursesApi,'get','{}',setCourses);
    },[])    
    return(
        <>
    <section className="gray-bg">
    <Tabs
      defaultActiveKey="LatestCourses"
      transition={false}
      id="noanim-tab-example"
      className="mb-3"
    >

      <Tab eventKey="LatestCourses" title={lagKeywords.latest_courses}>
        <LatestCourses />
      </Tab>      
      <Tab eventKey="AllCourses" title={<a href="/courses">{lagKeywords.all_courses}</a>} style={{
			transition: 'all .1s'}}>
       <AllCourses />
      </Tab>

    </Tabs>

    {/* <div className='show_all_course_link'>
      <NavLink to={'/courses'}><button type="button" id="" class="nav-link active">{lagKeywords.view_all_courses}</button></NavLink>
    </div> */}
    </section>
        </>        
    )
}

export default Courses;