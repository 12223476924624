import React, {useEffect, useRef, useState} from 'react';
import {Player, PlayerEvent} from 'bitmovin-player/modules/bitmovinplayer-core';
import EngineBitmovinModule from 'bitmovin-player/modules/bitmovinplayer-engine-bitmovin';
import MseRendererModule from 'bitmovin-player/modules/bitmovinplayer-mserenderer';
import HlsModule from 'bitmovin-player/modules/bitmovinplayer-hls';
import DashModule from 'bitmovin-player/modules/bitmovinplayer-dash';
import AbrModule from 'bitmovin-player/modules/bitmovinplayer-abr';
import XmlModule from 'bitmovin-player/modules/bitmovinplayer-xml';
import ContainerTSModule from 'bitmovin-player/modules/bitmovinplayer-container-ts';
import ContainerMp4Module from 'bitmovin-player/modules/bitmovinplayer-container-mp4';
import SubtitlesModule from 'bitmovin-player/modules/bitmovinplayer-subtitles';
import SubtitlesCEA608Module from 'bitmovin-player/modules/bitmovinplayer-subtitles-cea608';
import PolyfillModule from 'bitmovin-player/modules/bitmovinplayer-polyfill';
import StyleModule from 'bitmovin-player/modules/bitmovinplayer-style';
import {UIFactory} from 'bitmovin-player/bitmovinplayer-ui';


import progresssiveModule from 'bitmovin-player/modules/bitmovinplayer-engine-native'; // module for mp4

import {PlayerModule as AnalyticsModule} from 'bitmovin-analytics';
import 'bitmovin-player/bitmovinplayer-ui.css';

function BitmovinPlayer({src='',poster='', onFinish=null, onProgress=null, defaultQuality=null, ...rest}) {

  let [player, setPlayer] = useState(null);

  let qualityLabels = function (data) {
    return data.height+'p';
  }

//   const playlist = [
//     {
//         "name": "Sintel",
//         "dash": "https://bitdash-a.akamaihd.net/content/sintel/sintel.mpd",
//         "hls": "https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8"
//     },
//     {
//         "name": "Bitmovin Demo Video",
//         "dash": "//bitdash-a.akamaihd.net/content/MI201109210084_1/mpds/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.mpd",
//         "hls:": "//bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8"
//     }
// ];
  //72be8db5-7f4e-4ec8-b72c-461ca1665439
  const playerConfig = {
    key: '101f46fe-add0-4caa-9515-e305d9c2fce0',
    analytics: {
        //key: '72be8db5-7f4e-4ec8-b72c-461ca1665439',
        key: '0e034128-1650-4846-a9c8-4d21b0ea80c1',
        // videoId: 'VIDEO_ID',
      },     
    playback : {
        autoplay: false,
        muted: false,
    }
  };

  // const playerSource = {
  //   //dash: 'https://bitdash-a.akamaihd.net/content/MI201109210084_1/mpds/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.mpd',
  //   hls: 'https://streams.bitmovin.com/clb09kkapk7jn1is1890/manifest.m3u8',
  //   poster: 'https://bitdash-a.akamaihd.net/content/MI201109210084_1/poster.jpg'
  //   //http://localhost/mwahebAdmin/public/index.m3u8
  // };

  const ext = src.split('.').pop()?.toLowerCase();

  const playerSource = {
    ...(ext == 'mp4' ? {progressive: src} : {hls: src}),
    poster: poster,
    labeling: {
      dash: {
        qualities: qualityLabels
      },
      hls: {
        qualities: qualityLabels
      }
    }     
  };  
  const playerDiv = useRef();

  useEffect(() => {
    function setupPlayer() {
      Player.addModule(EngineBitmovinModule);
      Player.addModule(MseRendererModule);
      Player.addModule(HlsModule);
      Player.addModule(XmlModule);
      Player.addModule(DashModule);
      Player.addModule(AbrModule);
      Player.addModule(ContainerTSModule);
      Player.addModule(ContainerMp4Module);
      Player.addModule(SubtitlesModule);
      Player.addModule(SubtitlesCEA608Module);
      Player.addModule(PolyfillModule);
      Player.addModule(StyleModule);  
      Player.addModule(AnalyticsModule);
      Player.addModule(progresssiveModule);
      

      const playerInstance = new Player(playerDiv.current, playerConfig);
      

      playerInstance.on(PlayerEvent.TimeChanged, function ({timestamp, time, type}) {
          //console.log(playerInstance.getDuration());
          let duration = playerInstance.getDuration();
          if(onProgress != null){
            onProgress(time,duration)
          }
      })
      playerInstance.on(PlayerEvent.PlaybackFinished, function () {
                if (onFinish != null) {
                    onFinish();
                }
      })

      // playerInstance.on(PlayerEvent.PlaybackFinished, function () {
      //           if (i < playlist.length) {
      //               playerInstance.load({dash: playlist[i].dash, hls: playlist[i].hls});
      //               console.log('now playing ' + playlist[i].name);
      //               i++;
      //           }
      // })

      // let i = 0;
      // playerInstance.on(PlayerEvent.Ready, function () {
      //       if (i > 0) {
      //           playerInstance.play();
      //       }        
      // })
      setPlayer(playerInstance);
      player = playerInstance;
      UIFactory.buildDefaultUI(playerInstance);
      playerInstance.load(playerSource).then(() => {

        if(defaultQuality != null){
          let availableQualities = playerInstance.getAvailableVideoQualities();
          if(availableQualities.length >0){
            //console.log(defaultQuality);
            for (const qulaityObj of availableQualities)  {            
                if(qulaityObj.height >= defaultQuality){       
                    //console.log(qulaityObj.id)         
                    playerInstance.setVideoQuality(qulaityObj.id);
                    break;
                }
            }
          }
      }        
        
        console.log('Successfully loaded source');
      }, () => {
        console.log('Error while loading source');
      });
    }

    setupPlayer();

    return () => {
      function destroyPlayer() {
        if (player != null) {
          player.unload();
          //player.destroy();
          setPlayer(null);
        }
      }

      destroyPlayer();
    }
  }, [src])

  return <div id='player' ref={playerDiv} {...rest} />;
}

export default BitmovinPlayer;