import React, {  useState, useEffect, useContext, useReducer } from 'react';
import '../index.css';
import {UserContext} from '../context/languageContext'
import Config from '../Components/config';
import noProjectsFounds from '../assets/images/noProjectsFounds.png';
// import playIcon from '../assets/images/parts_play_icon.svg';
import ApiHelper from "../helpers/ApiHelper";
import {useParams, useNavigate, NavLink} from "react-router-dom";
import { AuthContext } from '../context/AuthContext';
import { ProfileContext } from '../context/ProfileContext';
import MessageModal from './modals/messageModal';
// import ConfirmModal from './modals/confirmModal';
import ProjectItem from './sections/projectItem';
import uuid from 'react-uuid';
import CourseReducer, { CourseInitState } from './reducers/courseReducer';
import CourseModuleLoader from './loaders/courseModuleLoader';
import TextLineLoader from './loaders/textLineLoader';
import BoxLoader from './loaders/boxLoader';
import '../assets/css/landingPage.css';
import '../assets/css/courseDetails.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import leftArrow from '../assets/images/left-orange-arrow.svg';
import rightArrow from '../assets/images/right-orange-arrow.svg';
import 'swiper/css';
import { Navigation} from 'swiper/modules';
import 'swiper/css/navigation';
import { ReplaceTagStringWithElement } from '../helpers/functions';
import Cookies from 'universal-cookie';
// import { gtag } from 'ga-gtag';
// import googleConfig from './config/google';
import VideoPlayerGeneral from './elements/VideoPlayerGeneral';
/*
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
// Import Swiper styles
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
*/
import {getDeviceInfo} from '../helpers/deviceInfo';
const CourseDetails = (props) => {
    const  Auth = useContext(AuthContext);
    const Profile = useContext(ProfileContext);
    let {id,name} = useParams([]);
    const navigate = useNavigate();
    const lagKeywords = React.useContext(UserContext);
    var Language = localStorage.getItem('lang');
    const cookies= new Cookies();
    const isVas = cookies.get('isVas') || 0;
    window.$('#enroll-message2').modal('show');
    const startCourse = async(courseId, enrolled=false, courseModule=0, directAccessCourse=false, courseName='') => {  
        if(courseName){
            name = courseName;
        }          
        let isActiveSubscriber = await Auth.isActiveSubscriber(true);    
        if(isActiveSubscriber[0]==1 && isActiveSubscriber[1]==1){ // active subscriber also active user
            if(enrolled == true){
                navigate('/courseModules/'+courseModule+'/'+courseId+'/'+name);
                return;
            }
            //console.log('active');
            // handle start course
            //navigate('/course/'+courseId);
            let enroll = await Profile.enrollCourse(courseId);
            //let enroll=1;
            if(enroll == 1){
                if(directAccessCourse){
                    navigate('/courseModules/'+courseModule+'/'+courseId+'/'+name);
                    return;
                }
                setEnrollment(true);
                setEnrollMessage(lagKeywords.course_success_enrol);
                window.$('#enroll-message').modal('show');

                setTimeout(() => {
                    window.$('#enroll-message').modal('hide'); 
                    navigate('/courseModules/'+courseModule+'/'+courseId+'/'+name);
                }, 2000);
                return;
                
            }
            else{
                setEnrollMessage(lagKeywords.course_failed_enrol);
                window.$('#enroll-message').modal('show');
            }
        }
        else{
            if(!Auth.isLogged){
                localStorage.setItem('redAfterLogin','course/'+id+'/'+name?.split(' ').join('-'));
                setEnrollMessage( () =>(<>Please <NavLink onClick={showLoginModal}>login/regitser</NavLink> to be able to start this course</>));
                window.$('#enroll-message').modal('show');                
                //window.$('#login').modal('show');
                return;
            }

            //console.log(isActiveSubscriber)
            if(isActiveSubscriber[0]==1 && isActiveSubscriber[1]==0){ // active subscriber but not active user
                setEnrollMessage( () =>(
                <>
                {
                              ReplaceTagStringWithElement(lagKeywords.account_not_activated_click, 'link', 
                              <NavLink onClick={
                                ()=>{
                                    setEnrollMessage(lagKeywords.processingDots);
                                    Auth.sendActivationEmail().then(res =>  
                                    res==true ? 
                                    setEnrollMessage(lagKeywords.success_send_activation) :
                                    setEnrollMessage(lagKeywords.failed_send_activation))
                                }
                              }/>
                          )
                }
                </>));
                window.$('#enroll-message').modal('show');                
                //window.$('#login').modal('show');
                return;
            }            

            if(Auth.getAccountType() == 3){// child account                

                if(isActiveSubscriber[0]==0){

                    setEnrollMessage( () =>(lagKeywords.child_course_not_sub));           

                }
                else{


                    setEnrollMessage( () => ReplaceTagStringWithElement(lagKeywords.after_reg_message_child, 'navlink', 
                    <NavLink onClick={
                        ()=>{
                            setEnrollMessage( () => lagKeywords.processingDots);
                            Auth.childActivationJourney().then( res => {
                                
                                res==true ? 
                                setEnrollMessage( () => lagKeywords.success_send_activation_parent) :
                                setEnrollMessage( () => lagKeywords.failed_send_activation_parent);            
                                
                            });
                        }
                      }/>
    
                      ));

                }

            }
            else{
                setEnrollMessage( () =>{

                    if(isVas){
                        return (<>{lagKeywords.vas_not_sub_msg}</>)
                    }
                    // return (<>{lagKeywords.go_to} <a onClick={navigatePlans}>{lagKeywords.plans}</a> {lagKeywords.page_to_sub}</>);
                    return (<>{
                        ReplaceTagStringWithElement(lagKeywords.page_to_sub2, 'link', 
                        <a onClick={navigatePlans}/>
        
                          )                       
                    }</>);
                });           
            }            
            window.$('#enroll-message').modal('show');            
        }
    }

    const showLoginModal = () => {
        window.$('.modal').modal('hide'); 
        window.$('#login').modal('show');
    }

    const navigatePlans = () => {        
        window.$('.modal').modal('hide');
        // redirect to plans page
        navigate('/prices');
    }    

    const unenrollCourse = async(courseId) => {
        let unenroll = await Profile.unenrollCourse(courseId);
        if(unenroll == 1){
            setEnrollment(false);
            setEnrollMessage('Course Successfully Unenrolled');
            window.$('#enroll-message').modal('show');
            
        }
        else{
            setEnrollMessage('Course Unenrollment Faild');
            window.$('#enroll-message').modal('show');
        }        
    }

    const renderCourseContent = async(id,courseId,title='') => {       
        if(!Auth.isLogged){
            setEnrollMessage( () =>(<>{ReplaceTagStringWithElement(lagKeywords.login_to_see_content,'navlink',<NavLink onClick={showLoginModal}/>)}</>));
            window.$('#enroll-message').modal('show');                
            return;
        }    
        else if(Auth.getAccountType() == 2){//parent
            setEnrollMessage( () =>(<>{lagKeywords.parent_cannot_enroll}</>));           
            window.$('#enroll-message').modal('show');
            return;
        }        
        else{
            let isActiveSubscriber = await Auth.isActiveSubscriber(); 
            if(isActiveSubscriber!=1){
                setEnrollMessage( () =>(<>{ReplaceTagStringWithElement(lagKeywords.go_to_pans, 'link',<a onClick={navigatePlans}/>)}</>));           
                window.$('#enroll-message').modal('show');           
                return; 
            }
            else if(!isEnrolled){
                /**
                 * logged and active subscriber
                 * enroll course automatically
                 */
                startCourse(courseId, false, id, true);
                return;
                setEnrollMessage( () =>(<>{lagKeywords.should_enroll_before}</>));           
                window.$('#enroll-message').modal('show');           
                return;                 
            }

        }    

        navigate('/courseModules/'+id+'/'+courseId+'/'+title);
    }
    
    const options = {
        margin: 5,
        responsiveClass: true,
        nav: true,
        rewind: false, /* use rewind if you don't want loop */
        autoplay: false,
        navText : [""],
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 1,
            },
            700: {
                items: 2,
            },
            1000: {
                items: 3,
            }
        },
    };     

    const breakpoints={
        0: {
            slidesPerView: 1.25,
        },
        450: {
            slidesPerView: 1.25,
        },
        600: {
            slidesPerView: 1.25,
        },
        700: {
            slidesPerView: 2,
        },
        1000: {
            slidesPerView: 3,
        },
        1500:{
            slidesPerView: 4,
        },
        2000:{
            slidesPerView: 6,
        }
    }

    const value = React.useContext(UserContext);  
    //console.log(user)
    //let [courseDetailsData, setcourseDetailsData] = useState([]); 
    //let [courseModules, setCourseModules] = useState([]);
    //let [courseProjects, setCourseProjects] = useState([]);
    //let [courseContent, setCourseContent] = useState(null);
    //let [contentTitle, setContentTitle] = useState('');
    let [isEnrolled, setEnrollment] = useState(-1);
    let [enrollMessage, setEnrollMessage] = useState('');
    let [messageTitle,setMessageTitle] = useState(lagKeywords.message);
    let courseDetailsApi=Config.courseDetailsApi+'/'+id+'?';
    let [courseUserDetails, setCourseUserDetails] = useState({});
    // let [isReadMore, setIsReadMore] = useState(true);
    const [CourseStates, dispatch] = useReducer(CourseReducer,CourseInitState)
    const [isMobile, setIsMobile] = useState(true);
    useEffect(()=>{    
        //console.log('called')      
            dispatch({type:'DETAILS_LOADING'});  
            ApiHelper.excuteApi2(courseDetailsApi,'get','{}',dispatch).then((res)=>{
                
            });
            Profile.isEnrolled(id,setEnrollment);
            

            if(Auth.isLogged){
                Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('course_user_details/'+id).then( (res) => {
                    if(res.data.status==1){
                        setCourseUserDetails(res.data.payload);
                    }
                })
            }
            
    },[id]        
    ) 
    useEffect(()=>{
       // if(courseDetailsData.length>0){
            //ApiHelper.excuteApi(courseDetailsApi,'get','{}',setcourseDetailsData);
            dispatch({type:'MODULES_LOAING'});
            Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('course_modules/'+id).then( (res) => {
                //setCourseModules(res.data.payload);
                //console.log(res.data.payload);
                dispatch({type:'MODULES_RESPONSE', data:res.data.payload});
            }).catch(()=>{
                //setCourseModules([]);
                dispatch({type:'MODULES_RESPONSE', data:[]});
            });

            dispatch({type:'PROJECTS_LOADING'});  
            Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('course_projects/'+id).then( (res) => {
                //setCourseProjects(res.data.payload);
                dispatch({type:'PROJECTS_RESPONSE', data:res.data.payload});
            }).catch(()=>{
                //setCourseProjects([]);
                dispatch({type:'PROJECTS_RESPONSE', data:[]});
            });

       // }
    },[id])
    useEffect(()=>{  
        getDeviceInfo().then(res => {
            setIsMobile(res?.isMobile);
        });  
        },[]        
    ) 
    const getCourseName = (data) => {
        let selectedLang = lagKeywords.getSelectedLanguage();
        if(selectedLang == 'ar'){
            return data.namear || data.name;
        }
        return data.name;
    }
    //alert(isEnrolled)            
    //console.log(id)
    // console.log(courseDetailsData);
    let displayname = '',
    format = '',
    numsections = '',
    introVideo = '',
    progress = 10,
    videoIntroRaw = '',
    summary = '',
    customfields = '',
    lobjRaw = '',
    extresRaw = '',
    abuexpRaw = '',
    introVideoUrl = '';
    let currentlanguage=lagKeywords.getSelectedLanguage();
    const appendHtml = (text, appendDirect=false) => {
        let shortLength = 100; // words
        let $ = window.$;
        let readMore = '<details><summary><span id="open" onclick="">Read More</span></summary></details>';
        let wrapper = $('<div/>')
        let wrapperTree = wrapper.append(text);
        let remaining = shortLength;            

        if(appendDirect){
            //$('.desc-box').html('');
            //$('.desc-box').html(text);
            reInitializeDescArea(text);
            return;
        }
        
        let shortText = '';
        let loop = true;  
        wrapper.find('.box-title').each(function(){                                              
            let child = $(this);                
            let nextChild = child;
            if(loop){
                shortText+='<div class="box-title">'+child.html()+'</div>';
            }
            while(loop){                    
                nextChild = nextChild.next();                    
                if(nextChild.attr('class') != 'box-text'){
                    break;
                }
                else{
                    remaining = remaining - nextChild.html().split(' ').length;
                    let currentText = nextChild.html();
                    if(remaining <=0){
                        shortLength = nextChild.html().split(' ').length + remaining;
                        let wordsArr = nextChild.html().split(' ');                            
                        let wordsStr = '';
                        for(let i=0;i<wordsArr.length;i++){                                
                            wordsStr += ' '+wordsArr[i];
                            if(i ==shortLength){
                                break;
                            }
                        }
                        shortText += '<div class="box-text">'+wordsStr+' ...</div>';                            
                        loop = false;                            
                        
                    }    
                    else{
                        shortText += '<div class="box-text">'+currentText+'</div>';
                        loop = false; 
                        break;
                    }                   
                    // console.log(nextChild.attr('class'))
                }
            }
            
        });
        //console.log(shortText);
        reInitializeDescArea(shortText)
        
        
    }
    const reInitializeDescArea = (shortText) =>{
        let $ = window.$;
        $(document).ready(function(){
            $('.desc-box').children().each(function(){
                if($(this).hasClass('read-more-btn')){

                }
                else{
                    $(this).remove();
                }
            })
            $('.desc-box').prepend(shortText);
        }) 
    }
    const handleReadMore = (text) => {
        window.$('.obj-box').removeClass('hide');
        window.$('.ext-box').removeClass('hide');
        appendHtml(text,true);
        window.$('.read-more-area').addClass('hide');
        window.$('.read-less-area').removeClass('hide');
    }
    const handleReadLess = (text) => {
        window.$('.obj-box').addClass('hide');
        window.$('.ext-box').addClass('hide');
        appendHtml(text);
        window.$('.read-less-area').addClass('hide');
        window.$('.read-more-area').removeClass('hide');
    } 
    const [activeTab, setActiveTab] = useState(1);
    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
    }; 
    let formatCourseDescription = (description) => {
        let firstDashIndex = description.indexOf('-');
        let secondDashIndex = description.indexOf('-', firstDashIndex + 1);
        if (secondDashIndex !== -1) {
            let formattedDescription = description.slice(0, secondDashIndex) + '<br />' + description.slice(secondDashIndex + 1);
            return <span dangerouslySetInnerHTML={{ __html: formattedDescription }} />;
        } else {
            return <span>{description}</span>;
        }
    };
    let addBreakLine = (text) => {
        let words = text.split(' ');
        let result = [];
        for (let i = 0; i < words.length; i++) {
            result.push(words[i]);
            if ((i + 1) % 4 === 0) {
                result.push('<br />');
            }
        }
        return { __html: result.join(' ') };
    };  
    if (isMobile) {
        return (
            <>
                {CourseStates.details && CourseStates.details.length > 0 && CourseStates.details.map((courseDetailsDataObj, index) => {
                    displayname=courseDetailsDataObj.displayname;
                    document.querySelector('meta[name="description"]').setAttribute("content", displayname);
                    document.querySelector('meta[name="ogTitle"]').setAttribute("content", displayname);
                    document.querySelector('meta[name="ogDescription"]').setAttribute("content", displayname);
                    document.title="Master Hobbies - "+displayname;                          
                        summary=courseDetailsDataObj.summary;
                        format=courseDetailsDataObj.format;
                        numsections=courseDetailsDataObj.numsections;
                        customfields=courseDetailsDataObj.customfields;
                        {customfields && customfields.length>0 && customfields.map((customfieldsObj)=>{
                            if (customfieldsObj.shortname == "videosource" )
                                videoIntroRaw='https://www.youtube.com/embed/'+customfieldsObj.valueraw;
                            if(currentlanguage=='ar')
                            {                                
                                if (customfieldsObj.shortname == "lerar" )
                                    lobjRaw=''+customfieldsObj.valueraw;
                                if (customfieldsObj.shortname == "extar" )
                                    extresRaw=''+customfieldsObj.valueraw;
                                if (customfieldsObj.shortname == "aboutexar" )
                                    abuexpRaw=''+customfieldsObj.valueraw;
                                if (customfieldsObj.shortname == "iv" ){
                                    introVideoUrl=''+customfieldsObj.valueraw; 
                                }
                                if (customfieldsObj.shortname == "descar" )
                                    summary=''+customfieldsObj.value;     
                                if (customfieldsObj.shortname == "cnar" )
                                    displayname=''+customfieldsObj.value;                                      
                            }
                            else
                            {
                                //iven
                                if (customfieldsObj.shortname == "lobj" )
                                    lobjRaw=''+customfieldsObj.valueraw;
                                if (customfieldsObj.shortname == "extres" )
                                    extresRaw=''+customfieldsObj.valueraw;
                                if (customfieldsObj.shortname == "abuexp" )
                                    abuexpRaw=''+customfieldsObj.valueraw;
                                if (customfieldsObj.shortname == "iv" )
                                    introVideoUrl=''+customfieldsObj.value;  
                                if (customfieldsObj.shortname == "iven" && customfieldsObj.value)
                                    introVideoUrl=''+customfieldsObj.valueraw;                                          
                                                                    
                            }
                            if(currentlanguage=='ar' && customfieldsObj?.shortname == "tags_ar")
                            {
                                if(customfieldsObj?.value!='')
                                    document.querySelector('meta[name="keywords"]').setAttribute("content", customfieldsObj.value);
                            }
                            if(currentlanguage=='en' && customfieldsObj?.shortname == "tags_en")
                            {
                                if(customfieldsObj?.value!='')
                                    document.querySelector('meta[name="keywords"]').setAttribute("content", customfieldsObj.value);
                            } 
                            if(currentlanguage=='ar' && customfieldsObj?.shortname == "seot_ar")
                            {
                                if(customfieldsObj?.value!='')
                                    document.querySelector('meta[name="description"]').setAttribute("content", customfieldsObj.value);
                            }
                            if(currentlanguage=='en' && customfieldsObj?.shortname == "seot_en")
                            {
                                if(customfieldsObj?.value!='')
                                    document.querySelector('meta[name="description"]').setAttribute("content", customfieldsObj.value);
                            }    
                            
                            if(currentlanguage=='ar' && customfieldsObj?.shortname == "cust_title_ar")
                            {
                                if(customfieldsObj?.value!='')
                                    document.title = customfieldsObj.value;
                            }
                            if(currentlanguage=='en' && customfieldsObj?.shortname == "cust_title_en ")
                            {
                                if(customfieldsObj?.value!='')
                                    document.title = customfieldsObj.value;
                            }                                                                                                                         
                        })}
                    }                 
                    )}  
                <div className="courses-opacity-container" id="page-wrapper">
                    <section className="white-box">
                        <div className="title-box">{displayname}</div>
                        <div className="video-box">
                            {console.log(introVideoUrl)}
                            {introVideoUrl != '' && introVideoUrl.includes('source') && <div dangerouslySetInnerHTML={ {__html: introVideoUrl} } ></div>}
                            {introVideoUrl != '' && !introVideoUrl.includes('source') && <div><VideoPlayerGeneral poster={CourseStates.details[0]?.introPoster || ``} autoplay={false}   url={introVideoUrl} /></div>}
                        </div>
                        <div className={`start-course-btn ${Auth.getAccountType() ==2 && 'hide'}`}>
                            { isEnrolled === true? 
                                <>
                                {courseUserDetails.progress > 0 && 
                                    <button onClick={ () => 
                                        //navigate('/courseModules/'+courseUserDetails.current_module+'/'+id+'/'+getCourseName(courseUserDetails))
                                        startCourse(id, true, courseUserDetails.current_module, false, getCourseName(courseUserDetails))
                                        }>
                                        {lagKeywords.continue}
                                    </button>
                                }     
                                <div className="user-enrollrd-container">
                                    <button onClick={()=> startCourse(id, true)}>{lagKeywords.start_beginning}</button>
                                    <button onClick={()=> unenrollCourse(id)}>{lagKeywords.unenroll}</button>
                                </div>
                                </>
                                :
                                (isEnrolled === false?  
                                <button onClick={()=>startCourse(id)}>{lagKeywords.start_course}</button>
                                :
                                <button></button>
                                )
                            } 
                        </div>
                    </section> 
                    
                    <section className="coursesDeatils">
                        <ul>
                            <li className={`categoryList ${activeTab === 1 ? "active" : ""}`} id={`outline`} key={`outline`} data-toggle="collapse" onClick={() => handleTabClick(1)}>
                            {lagKeywords.outline}
                            </li>
                            <li className={`categoryList ${activeTab === 2 ? "active" : ""}`} id={`overview`} key={`overview`} data-toggle="collapse" onClick={() => handleTabClick(2)}>
                            {lagKeywords.overview}
                            </li>
                            <li className={`categoryList ${activeTab === 3 ? "active" : ""}`} id={`relatedCources`} key={`relatedCources`} data-toggle="collapse" onClick={() => handleTabClick(3)}>
                            {lagKeywords.related_cources}
                            </li>
                        </ul>
                        <div className="tab-content">
                            {activeTab === 1 && 
                                <div className="tab-cources tab-outline">
                                    { (CourseStates.modulesStatus==1 && CourseStates.modules && CourseStates.modules.length > 0 ) &&
                                        <>
                                        {
                                            CourseStates.modules.map( (item) => {                
                                                return (
                                                    <div className="coursesCard" key={item.id} onClick={()=>renderCourseContent(item.id, id, item.name)} >
                                                        <div className="coursesCardImg">
                                                            <img src={item.cover} alt={Language=='ar'&&item.name_ar ? item.name_ar : item.name} />
                                                        </div>
                                                        <div>
                                                            <div className="courseDesc">{Language=='ar'&&item.name_ar ? formatCourseDescription(item.name_ar) : formatCourseDescription(item.name)}</div>
                                                        </div>
                                                    </div> 
                                                )
                                            }) 
                                        }
                                        </>
                                    }
                                </div>
                            }
                            {activeTab === 2 && 
                                <div className="tab-overview">
                                    {CourseStates.detailsStatus==2 ? <TextLineLoader totalLines={5}/>:
                                        <p className="desc-box">
                                            <div dangerouslySetInnerHTML={ {__html: summary} } ></div>
                                        </p>
                                    }
                                    {CourseStates.detailsStatus==2 ? <TextLineLoader totalLines={5}/>: <p className="ext-box" dangerouslySetInnerHTML={ {__html: extresRaw} } ></p>}
                                    {CourseStates.detailsStatus==2 ? <TextLineLoader totalLines={5}/>: <p className="obj-box" dangerouslySetInnerHTML={ {__html: lobjRaw} } ></p>}
                                </div>
                            }
                            {activeTab === 3 && 
                                <div className="tab-cources tab-relatedCources">
                                    {
                                        CourseStates.details[0]?.relatedCourses?.length > 0 && CourseStates.details[0]['relatedCourses'].map((relatedCoursesObj, index) => {    
                                            let courseName  = lagKeywords.getSelectedLanguage() == 'ar' ? relatedCoursesObj.shortname_ar :relatedCoursesObj.shortname;
                                            let courseDesc  = lagKeywords.getSelectedLanguage() == 'ar' ? relatedCoursesObj.shortdesc_ar :relatedCoursesObj.shortdesc;
                                            if(index > 3)
                                                return'';
                                            return ( 
                                            <>
                                                <>
                                                    <div className="coursesCard" key={relatedCoursesObj.courseId} id={relatedCoursesObj.courseId}>
                                                        <div className="coursesCardImg">
                                                            <img src={Config.imgDns+ "icon/"+relatedCoursesObj.courseId+"_large.png"} alt={courseName} />
                                                        </div>
                                                        <div>
                                                            <div className="courseName">{courseName}</div>
                                                            <div className="courseDesc" dangerouslySetInnerHTML={addBreakLine(courseDesc)}></div>
                                                            <NavLink className="courseMore" to={'/course/'+relatedCoursesObj.courseId+'/'+courseName?.trim().split(' ').join('-')} onClick={() => handleTabClick(1)}>{lagKeywords.more}</NavLink>
                                                        </div>
                                                    </div> 
                                                </>
                                            </>
                                            )
                                        })
                                    }
                                </div>
                            }
                        </div>
                        <MessageModal lang={currentlanguage} title={messageTitle} id="enroll-message" okLabel={lagKeywords.close} >
                            {enrollMessage}
                        </MessageModal>  
                        {/* <MessageModal lang={currentlanguage} title={messageTitle} id="enroll-message-2" okLabel={lagKeywords.close} >
                            <>{ReplaceTagStringWithElement(lagKeywords.login_to_see_content,'navlink',<NavLink onClick={showLoginModal}/>)}</>
                        </MessageModal> 
                        <MessageModal lang={currentlanguage} title={messageTitle} id="enroll-message-3" okLabel={lagKeywords.close} >
                            <>{lagKeywords.parent_cannot_enroll}</>
                        </MessageModal>  */}
                    </section>
                    { CourseStates?.projects?.length!=0 && CourseStates?.projectsStatus !=2 &&
                    <section className="white-box2">
                    <div className='submitted-projects'>
                            <h4 className='title-box'>{lagKeywords.project_gallery}</h4>
                            <div className="project-wrapper">
                            <section className="project-item" style={{marginTop:'0'}}>
                                {
                                    (() => {                             
                                        if(CourseStates.projectsStatus == 2){
                                            return (
                                                <Swiper
                                                modules={[Navigation]}
                                                breakpoints ={breakpoints}
                                                navigation 
                                                slidesPerView={3}
                                                spaceBetween={30}
                                                slidesPerGroup={1}
                                                grabCursor={true}
                                                className="slider-items"
                                                onSlideChange={() => console.log('slide change')}
                                                //onSwiper={(swiper) => console.log(swiper)}
                                                >
                                                    
                                                    <SwiperSlide><BoxLoader style={{margin:"15px",width:"300px"}}/></SwiperSlide>
                                                    <SwiperSlide><BoxLoader style={{margin:"15px",width:"300px"}}/></SwiperSlide>
                                                    <SwiperSlide><BoxLoader style={{margin:"15px",width:"300px"}}/></SwiperSlide>
                                                    
                                                </Swiper>                                        
                                            );
                                        }
                                        else if (CourseStates.projects.length==0 && CourseStates.projectsStatus !=2){
                                            return (<div className='noProjectMsg'><p>{lagKeywords.noProjectsFound}</p><img src={noProjectsFounds}/></div>);
                                        }
                                        else{
                                            let prs = CourseStates.projects.map( (item) => {                                
                                                return <SwiperSlide key={item.id}><ProjectItem className="item course-item" key={item.id} data={item} setMessageContent={setEnrollMessage}  setMessageTitle={setMessageTitle} showLoginModal={showLoginModal}/></SwiperSlide>                                        
                                            });  
                                            
                                            //return  (<OwlCarousel className="slider-items owl-carousel" {...options}>{prs}</OwlCarousel>);                               
                                            //return  (<div style={{'display':'flex','columnGap':'20px','justifyContent':'flex-start'}} >{prs}</div>);     
                                            return (
                                            <Swiper                                    
                                            modules={[Navigation]}
                                            spaceBetween={30}
                                            navigation 
                                            breakpoints ={breakpoints}
                                            slidesPerView={3}
                                            slidesPerGroup={1}
                                            observer
                                            observeParents
                                            parallax
                                            className="slider-items"
                                            onSlideChange={() => console.log('slide change')}
                                            //onSwiper={(swiper) => console.log(swiper)}
                                            >
                                                {prs}
                                            </Swiper>
                                            );                          
                                        }
                                    })()
                                }
                            </section> 
                            </div>
                        </div> 
                    </section> 
                    } 
                </div> 
            </>
        );
    }  
    return(
    <>
        {CourseStates.details && CourseStates.details.length > 0 && CourseStates.details.map((courseDetailsDataObj, index) => {
            displayname=courseDetailsDataObj.displayname;
            let courseTitle = displayname + '_tag_title'; 
            let courseKeywords = displayname + '_tag_keywords';
            let courseDesc = displayname + '_tag_desc';
            document.querySelector('meta[name="description"]').setAttribute("content", lagKeywords[courseDesc]);
            document.querySelector('meta[name="ogTitle"]').setAttribute("content", displayname);
            document.querySelector('meta[name="ogDescription"]').setAttribute("content", displayname);
            document.querySelector('meta[name="keywords"]').setAttribute("content", lagKeywords[courseKeywords]);
            //   if(lagKeywords[courseTitle] && lagKeywords[courseTitle] != undefined){
            //     document.title = (lagKeywords[courseTitle]);
            //   }else{
            //     document.title="Master Hobbies - "+displayname;
            //   }
            document.title="Master Hobbies - "+displayname;
                            summary=courseDetailsDataObj.summary;
                            format=courseDetailsDataObj.format;
                            numsections=courseDetailsDataObj.numsections;
                            customfields=courseDetailsDataObj.customfields;
                            {customfields && customfields.length>0 && customfields.map((customfieldsObj)=>{
                                if (customfieldsObj.shortname == "videosource" )
                                    videoIntroRaw='https://www.youtube.com/embed/'+customfieldsObj.valueraw;
                                if(currentlanguage=='ar')
                                {
                                    if (customfieldsObj.shortname == "lerar" )
                                        lobjRaw=''+customfieldsObj.valueraw;
                                    if (customfieldsObj.shortname == "extar" )
                                        extresRaw=''+customfieldsObj.valueraw;
                                    if (customfieldsObj.shortname == "aboutexar" )
                                        abuexpRaw=''+customfieldsObj.valueraw;
                                    if (customfieldsObj.shortname == "iv" )
                                        introVideoUrl=''+customfieldsObj.valueraw; //valueraw once we moved to bitmovin
                                    if (customfieldsObj.shortname == "descar" )
                                        summary=''+customfieldsObj.value;     
                                    if (customfieldsObj.shortname == "cnar" )
                                        displayname=''+customfieldsObj.value;                                      
                                }
                                else
                                {
                                    //iven
                                    if (customfieldsObj.shortname == "lobj" )
                                        lobjRaw=''+customfieldsObj.valueraw;
                                    if (customfieldsObj.shortname == "extres" )
                                        extresRaw=''+customfieldsObj.valueraw;
                                    if (customfieldsObj.shortname == "abuexp" )
                                        abuexpRaw=''+customfieldsObj.valueraw;
                                    if (customfieldsObj.shortname == "iv" )
                                        introVideoUrl=''+customfieldsObj.valueraw; //valueraw once we moved to bitmovin  
                                    if (customfieldsObj.shortname == "iven" && customfieldsObj.value)
                                        introVideoUrl=''+customfieldsObj.valueraw; //valueraw once we moved to bitmovin                                           
                                                                          
                                }
                                if(currentlanguage=='ar' && customfieldsObj?.shortname == "tags_ar")
                                {
                                    if(customfieldsObj?.value!='')
                                        document.querySelector('meta[name="keywords"]').setAttribute("content", customfieldsObj.value);
                                }
                                if(currentlanguage=='en' && customfieldsObj?.shortname == "tags_en")
                                {
                                    if(customfieldsObj?.value!='')
                                        document.querySelector('meta[name="keywords"]').setAttribute("content", customfieldsObj.value);
                                } 
                                if(currentlanguage=='ar' && customfieldsObj?.shortname == "seot_ar")
                                {
                                    if(customfieldsObj?.value!='')
                                        document.querySelector('meta[name="description"]').setAttribute("content", customfieldsObj.value);
                                }
                                if(currentlanguage=='en' && customfieldsObj?.shortname == "seot_en")
                                {
                                    if(customfieldsObj?.value!='')
                                        document.querySelector('meta[name="description"]').setAttribute("content", customfieldsObj.value);
                                }    
                                if(currentlanguage=='ar' && customfieldsObj?.shortname == "cust_title_ar")
                                {
                                    if(customfieldsObj?.value!='')
                                        document.title = customfieldsObj.value;
                                }
                                if(currentlanguage=='en' && customfieldsObj?.shortname == "cust_title_en ")
                                {
                                    if(customfieldsObj?.value!='')
                                        document.title = customfieldsObj.value;
                                }  
                            })}
                    }
        )}  
{appendHtml(summary)}
<div className="courses-opacity-container" id="page-wrapper">
    <section className="white-box">
        <div className='title-box'>{displayname}</div>
        <div className="video-box">
            {/* <img className="iframe-video" src="https://i.ytimg.com/vi/oIqsiOIPkag/maxresdefault.jpg" title='tetsing' />            
            <img src={playIcon} alt="play icon" className='videoPlayIcon'  /> */}
            {/* <div dangerouslySetInnerHTML={ {__html: introVideoUrl} } ></div> */}
            {introVideoUrl != '' && introVideoUrl.includes('source') && <div dangerouslySetInnerHTML={ {__html: introVideoUrl} } ></div>}
            {introVideoUrl != '' && !introVideoUrl.includes('source') && <div><VideoPlayerGeneral poster={CourseStates.details[0]?.introPoster || ``} autoplay={false}   url={introVideoUrl} /></div>}            
        </div>
        <div className={`start-course-btn ${Auth.getAccountType() ==2 && 'hide'}`}>
            {/* <div className={`start-course-btn`}> */}
            { isEnrolled === true? 
                <>
                {courseUserDetails.progress > 0 && 
                    <button onClick={ () => 
                        //navigate('/courseModules/'+courseUserDetails.current_module+'/'+id+'/'+getCourseName(courseUserDetails))
                        startCourse(id, true, courseUserDetails.current_module, false, getCourseName(courseUserDetails))
                        }>
                        {lagKeywords.continue}
                    </button>
                }     
                <div className="user-enrollrd-container">
                    <button onClick={()=> startCourse(id, true)}>{lagKeywords.start_beginning}</button>
                    <button onClick={()=> unenrollCourse(id)}>{lagKeywords.unenroll}</button>
                </div>
                </>
                :
                (isEnrolled === false?  
                <button onClick={()=> startCourse(id)}>{lagKeywords.start_course}</button>
                :
                <button></button>
                )
            }  
        </div>
        {/* {CourseStates.detailsStatus==2 ? <TextLineLoader totalLines={5}/>:<p className="desc-box" dangerouslySetInnerHTML={ {__html: summary} } ></p>} */}
        {
            CourseStates.detailsStatus==2 ? <TextLineLoader totalLines={5}/>:
            <p className="desc-box">
                <details className="read-more-btn read-more-area"><summary><span onClick={()=>{handleReadMore(summary)}}>{lagKeywords.read_more}</span></summary></details>
                <details className="read-more-btn read-less-area hide"><summary><span onClick={()=>{handleReadLess(summary)}}>{lagKeywords.read_less}</span></summary></details>
            </p>
        }
        {CourseStates.detailsStatus==2 ? <TextLineLoader totalLines={5}/>: <p className="obj-box hide" dangerouslySetInnerHTML={ {__html: lobjRaw} } ></p>}
        {CourseStates.detailsStatus==2 ? <TextLineLoader totalLines={5}/>: <p className="ext-box hide" dangerouslySetInnerHTML={ {__html: extresRaw} } ></p>}       
        {/* {CourseStates.detailsStatus==2 ? <TextLineLoader totalLines={5}/>:<p className='video-box' dangerouslySetInnerHTML={ {__html: videoIntroRaw} } ></p>} */}
        {/* The below is a dummy data , the actual code of importing video is the commented above*/}
        <div className="slider-box">
            <h4 className="box-title" style={{ width: '88%'}}>{lagKeywords.course_outline}</h4>
            <section className="videos-carousel-container" style={{marginTop:'0', backgroundColor: '#D9D9D9', borderRadius:'0'}}>
            {(CourseStates.modulesStatus==1 && CourseStates.modules && CourseStates.modules.length > 0 ) &&
            <div class="bk-slider-course">
                <div class="swiper-course">
                    <div class="swiper-wrapper course-swiper">
                    <Swiper key={uuid()} 
                    modules={[Navigation]}
                    breakpoints={breakpoints}
                    navigation={{
                        prevEl: Language == 'ar' ? '.swiper-button-next' : '.swiper-button-prev',
                        nextEl: Language == 'ar' ? '.swiper-button-prev' : '.swiper-button-next'
                    }}
                    spaceBetween={30}
                    initialSlide={0}
                    slidesPerGroup={1}
                    observer
                    observeParents
                    parallax
                    className="slider-items"
                    loop={true}
                    grabCursor={true}
                    slidesPerView={4.5}
                    dir={Language == 'ar' ? 'rtl' : 'ltr'}
                    simulateTouch={false}
                    >
                        <div className="swiper-button-prev">
                            <img src={leftArrow} alt="Previous" />
                        </div>
                        <div className="swiper-button-next">
                            <img src={rightArrow} alt="Next" />
                        </div>
                    {
                    CourseStates.modules.map((item) => {                 
                        return (
                            <SwiperSlide>
                                <div onClick={()=>renderCourseContent(item.id, id, item.name)} key={item.id} className="swiper-item">
                                <div className="thumbnail-container">
                                    <img style={{'width':'inherit'}} className="video-poster" src={item.cover} />
                                    {/* <img src={playIcon} alt="play icon" className='playIcon3' /> */}
                                </div>  
                                <p className="item-title-slider">{Language=='ar'&&item.name_ar ? item.name_ar : item.name}</p>
                                </div>  
                            </SwiperSlide>
                        )
                    }) 
                    }
                    </Swiper>
                    </div>            
                </div>
            </div>
            }
            {(CourseStates.modulesStatus ==2) &&
                //  {/* <OwlCarousel key={uuid()} className="slider-items owl-carousel" {...options}> */}
                <div style={{display:'flex', justifyContent: 'space-evenly'}}><CourseModuleLoader total={3}/></div>
                // {/* </OwlCarousel>  */}
            }
            </section>
        </div>

    {/****the below format, I commented it because the design not include it here  ****/}
    {/* <div className="course-details">
            <h4>Course Format</h4>
            <p> {format}</p>
        </div>  */}
        
    {/****the below numsections, I commented it because the design not include it here  *****/}
    {/* <div className="course-details">
            <h4>Number of section</h4>
            <p>{numsections}</p>
        </div>  */}
    {/*****the below progress bar, I commented it because the design not include it here  *****/}
    {/* <div className="course-progress">
        <h4>Progress</h4>
        <div className="bar">
            <div className="flexiblebar" data-parcent={progress} id="progress-bar1"><p className="percent" data-number={progress}></p></div>
        </div> 
    </div>  */}
        
        <MessageModal lang={currentlanguage} title={messageTitle} id="enroll-message" okLabel="Close">
            {enrollMessage}
        </MessageModal>   
    </section> 
        {CourseStates.detailsStatus==2 || abuexpRaw == '' ? '':<p className='about-expert' dangerouslySetInnerHTML={ {__html: abuexpRaw} } ></p>}

        <section className="relatedCourses">
                <h4 className='title-box'>{lagKeywords.related_cources}</h4>
                <div className="coursesCards">
                {
                    CourseStates.details[0]?.relatedCourses?.length > 0 && CourseStates.details[0]['relatedCourses'].map((relatedCoursesObj, index) => {    
                        let courseName  = relatedCoursesObj.shortname;
                        let courseDesc  = relatedCoursesObj.shortdesc;
                        if(index > 3)
                            return'';
                        return ( 
                        <>
                            <>
                                <div className="courseCard" key={relatedCoursesObj.courseId} id={relatedCoursesObj.courseId}>
                                    <NavLink className="courseCardAncor" to={'/course/'+relatedCoursesObj.courseId+'/'+courseName?.trim().split(' ').join('-')}>
                                        <img src={Config.imgDns+ "icon/"+relatedCoursesObj.courseId+"_large.png"} alt={courseName} />
                                        <div>
                                            <div className="courseName">{courseName}</div>
                                            <div className="courseDesc">{courseDesc }</div>
                                            <NavLink className="courseMore" to={'/course/'+relatedCoursesObj.courseId+'/'+courseName?.trim().split(' ').join('-')}>{lagKeywords.more}</NavLink>
                                        </div>
                                    </NavLink>
                                </div> 
                            </>
                        </>
                        )
                    })
                }
                {/* {
                    console.log(CourseStates)
                } */}
                </div>
        </section>

        { CourseStates?.projects?.length!=0 && CourseStates?.projectsStatus !=2 &&
        <section className="white-box2">
            <div className='submitted-projects'>
                <h4 className='title-box'>{lagKeywords.project_gallery}</h4>
                <div className="project-wrapper">
                <section className="project-item" style={{marginTop:'0'}}>
                    {
                        (() => {  
                            if(CourseStates.projectsStatus == 2){
                                //return (<OwlCarousel className="slider-items owl-carousel" {...options}><BoxLoader total={2} style={{margin:"15px",width:"300px"}}/></OwlCarousel>)
                                return (
                                    <Swiper
                                    modules={[Navigation]}
                                    breakpoints ={breakpoints}
                                    navigation 
                                    slidesPerView={3}
                                    spaceBetween={30}
                                    slidesPerGroup={1}
                                    className="slider-items"
                                    onSlideChange={() => console.log('slide change')}
                                    //onSwiper={(swiper) => console.log(swiper)}
                                    >
                                        
                                        <SwiperSlide><BoxLoader style={{margin:"15px",width:"300px"}}/></SwiperSlide>
                                        <SwiperSlide><BoxLoader style={{margin:"15px",width:"300px"}}/></SwiperSlide>
                                        <SwiperSlide><BoxLoader style={{margin:"15px",width:"300px"}}/></SwiperSlide>
                                        
                                    </Swiper>                                        
                                );
                            }
                            else if (CourseStates.projects.length==0 && CourseStates.projectsStatus !=2){
                                return (<div className='noProjectMsg'><p>{lagKeywords.noProjectsFound}</p><img src={noProjectsFounds}/></div>);
                            }
                            else{
                                let prs = CourseStates.projects.map( (item) => {
                            
                                    return <SwiperSlide key={item.id}><ProjectItem className="item course-item" key={item.id} data={item} setMessageContent={setEnrollMessage}  setMessageTitle={setMessageTitle} showLoginModal={showLoginModal}/></SwiperSlide>
                                    
                                });  
                                
                                //return  (<OwlCarousel className="slider-items owl-carousel" {...options}>{prs}</OwlCarousel>);                               
                                //return  (<div style={{'display':'flex','columnGap':'20px','justifyContent':'flex-start'}} >{prs}</div>);     
                                return (
                                <Swiper                                    
                                modules={[Navigation]}
                                spaceBetween={30}
                                navigation 
                                breakpoints ={breakpoints}
                                slidesPerView={3}
                                slidesPerGroup={1}
                                observer
                                observeParents
                                parallax
                                className="slider-items"
                                onSlideChange={() => console.log('slide change')}
                                //onSwiper={(swiper) => console.log(swiper)}
                                >
                                    {prs}
                                </Swiper>
                                );                          
                            }
                        })()
                    }
                </section> 
                </div>
            </div> 
        </section>   
        }           
    </div>  
    </>
    )
}
export default CourseDetails 
