import { useContext, useEffect, useState } from "react";
import { Wizard, WizardStep } from "../Components/elements/wizard/wizard";
import { UserContext } from "../context/languageContext";
import Question from "./question";
import SurveyEnd from "./surveyEnd";
import SurveyEndMobile from "./surveyEndMobile";
import SurveyHeader from "./surveyHeader";
import Intro from './surveyIntro'




const Survey = ({lang,isLong, fullWidth=false, isPopup = false, basename=''}) => {


    const searchParams = new URLSearchParams(document.location.search);
    const language = useContext(UserContext);
    
    
    if(!lang){
        lang = 'en';
    }    

    const langQuery = searchParams.get('lang') || '';
    if(langQuery && lang != langQuery){
        lang = langQuery;
        language.setLanguage(lang)
    }

    require('./survey_'+lang+'.css');

    let filterQuestions = {};
    if(isLong){
        filterQuestions = require('./questions/'+lang+'_long.json');
    }
    else{
        filterQuestions = require('./questions/'+lang+'.json');
    }    

    const [questions,setQuestion] = useState({});
    const [totalSteps,settotalSteps] = useState(1);
    const [loading,setLoading] = useState(true);    
    const [parentQA,setParent] = useState([]); //[parentquestion, parentanser]

    const pagePath = window.location.pathname;
    if (pagePath === '/survey' || pagePath === '/survey/long' ){
        window.$('#root').css('background', '#808080');
    }else{
        window.$('#root').css('background', '#fff');
    }
    const isMobileSurvey = searchParams.get('mobile') || 0;    


    
    // const elements = questions.map( (question, index) => {
    //     return <WizardStep key={(index+1)} step={(index+1)}> <Question data={question}/> </WizardStep>   
    // })
    const endLoading = () =>{
        setLoading(false)
    }
    useEffect(()=>{

        // console.log(parentQA)
        let NewQuestions = filterQuestions?.questions.filter(q => (!q.parents || q.parents.length ==0  || q.parents.includes(parentQA[0]+"-"+parentQA[1])) );
        settotalSteps(NewQuestions.length + 1)
        setQuestion({questions:NewQuestions,total_steps:(NewQuestions.length + 1)})
        language.setLanguage(lang,false,false,'',endLoading);


    },[parentQA])
    //const [surveyData, setSurveyData] = useState({currentStep:1,answers:{}});    


    return (
        <>   
        {loading == true ? '':   
        <Wizard startFrom={0} totalSteps={(totalSteps) || 0} initValues={questions}>
            <SurveyHeader totalSteps = {totalSteps}/>
            <WizardStep key={0} step={0}> <Intro fullWidth={fullWidth}/> </WizardStep>
            { questions.questions[0]  && <WizardStep step={1}>    <Question fullWidth={fullWidth} setParent={setParent} questions={questions} data={questions.questions[0]}/>     </WizardStep>}   
            { questions.questions[1]  && <WizardStep step={2}>    <Question fullWidth={fullWidth} setParent={setParent} questions={questions} data={questions.questions[1]}/>     </WizardStep>}   
            { questions.questions[2]  && <WizardStep step={3}>    <Question fullWidth={fullWidth} setParent={setParent} questions={questions} data={questions.questions[2]}/>     </WizardStep>}   
            { questions.questions[3]  && <WizardStep step={4}>    <Question fullWidth={fullWidth} setParent={setParent} questions={questions} data={questions.questions[3]}/>     </WizardStep>}   
            { questions.questions[4]  && <WizardStep step={5}>    <Question fullWidth={fullWidth} setParent={setParent} questions={questions} data={questions.questions[4]}/>     </WizardStep>}   
            { questions.questions[5]  && <WizardStep step={6}>    <Question fullWidth={fullWidth} setParent={setParent} questions={questions} data={questions.questions[5]}/>     </WizardStep>}   
            { questions.questions[6]  && <WizardStep step={7}>    <Question fullWidth={fullWidth} setParent={setParent} questions={questions} data={questions.questions[6]}/>     </WizardStep>}   
            { questions.questions[7]  && <WizardStep step={8}>    <Question fullWidth={fullWidth} setParent={setParent} questions={questions} data={questions.questions[7]}/>     </WizardStep>}   
            { questions.questions[8]  && <WizardStep step={9}>    <Question fullWidth={fullWidth} setParent={setParent} questions={questions} data={questions.questions[8]}/>     </WizardStep>}   
            { questions.questions[9]  && <WizardStep step={10}>   <Question fullWidth={fullWidth} setParent={setParent} questions={questions} data={questions.questions[9]}/>    </WizardStep>}   
            { questions.questions[10]  && <WizardStep step={11}>   <Question fullWidth={fullWidth} setParent={setParent} questions={questions} data={questions.questions[10]}/>    </WizardStep>}   
            { questions.questions[11]  && <WizardStep step={12}>   <Question fullWidth={fullWidth} setParent={setParent} questions={questions} data={questions.questions[11]}/>    </WizardStep>}   
            { questions.questions[12]  && <WizardStep step={13}>   <Question fullWidth={fullWidth} setParent={setParent} questions={questions} data={questions.questions[12]}/>    </WizardStep>}   
            { questions.questions[13]  && <WizardStep step={14}>   <Question fullWidth={fullWidth} setParent={setParent} questions={questions} data={questions.questions[13]}/>    </WizardStep>}   
            { questions.questions[14]  && <WizardStep step={15}>   <Question fullWidth={fullWidth} setParent={setParent} questions={questions} data={questions.questions[14]}/>    </WizardStep>}   
            <WizardStep key={totalSteps} step={totalSteps}> 
                {/* {isMobileSurvey ? <SurveyEndMobile questions={questions}/> : <SurveyEnd questions={questions}/> } */}
                {<SurveyEnd fullWidth={fullWidth} questions={questions}/> }
            </WizardStep>
        </Wizard>  
        }   
        </>
    )
}

export default Survey